import React from 'react';
const prettyMilliseconds = require('pretty-ms');


class Log extends React.Component {

  isFast(score){
    if (score>=0.90) return true;
    return false;
  }

  colorScore(score,border=""){
    if (score>=0.90){
      return `text-green-600 border-black border-dotted ${border} pt-1 text-2xl text-strong`
    }
    if ((score<0.90)&&(score>=0.50)) {
      return `text-yellow-600 border-black border-dotted ${border} pt-1 text-2xl text-strong`;
    }
    if (score<50) {
      return `text-red-600 border-black border-dotted ${border} pt-1 text-2xl text-strong`;
    }
  }

  getColor(score){
    if (score>=0.90){
      return 'green';
    }
    if ((score<0.90)&&(score>=0.50)) {
      return 'yellow'
    }
    if (score<50) {
      return 'red'
    }
  }

  render(){
    return (
      <div className="grid grid-cols-3 gap-4 mr-9 2xl:mt-4 2xl:gap-6">
        <div className="text-center">
          <h2 className={`text-3xl mb-2 uppercase text-${this.props.crux.lcp.status!=="passed" ? "red" : "green"}-600`}>{this.props.crux.lcp.status}: LCP</h2>
          <p className="border-black border-dotted border-t col-span-2 p-1 text-center text-gray-500 text-xs uppercase">Created: {this.props.analysis.fcp} FCP and {this.props.analysis.lcp} LCP fixes</p>
          <div className="grid grid-cols-2 grid-rows-2 border-t border-black border-dotted">
            <p className={this.colorScore(this.props.cwv.lcp.score,"border-r")}>{prettyMilliseconds(this.props.cwv.lcp.numericValue)}</p>
            <p className={this.colorScore(this.props.crux.lcp.score)}>{prettyMilliseconds(this.props.crux.lcp.numericValue)}</p>
            <p className="text-xs uppercase text-gray-500">This Run</p>
            <p className={`text-xs uppercase text-gray-${this.props.crux.lcp.source==="field" ? "500" : "400"}`}>{this.props.crux.lcp.source==="field" ? "Your Page" : "Your Site"}</p>
          </div>
        </div>
        <div className="text-center">
          <h2 className={`text-3xl mb-2 uppercase text-${this.props.crux.fid.status!=="passed" ? "red" : "green"}-600`}>{this.props.crux.fid.status}: FID</h2>
          <p className="border-black border-dotted border-t col-span-2 p-1 text-center text-gray-500 text-xs uppercase">Created: {this.props.analysis.fid} FID and {this.props.analysis.tbt} TBT fixes</p>
          <div className="grid grid-cols-2 grid-rows-2 border-t border-black border-dotted">
            <p className={this.colorScore(this.props.cwv.tbt.score,"border-r")}>{prettyMilliseconds(this.props.cwv.tbt.numericValue)}</p>
            <p className={this.colorScore(this.props.crux.fid.score)}>{prettyMilliseconds(this.props.crux.fid.numericValue)}</p>
            <p className="text-xs uppercase text-gray-500">TBT This Run</p>
            <p className="text-xs uppercase text-gray-500">FID {this.props.crux.fid.source==="field" ? "Your Page" : "Your Site"}</p>
          </div>
        </div>
        <div className="text-center">
          <h2 className={`text-3xl mb-2 uppercase text-${this.props.crux.cls.status!=="passed" ? "red" : "green"}-600`}>{this.props.crux.cls.status}: CLS</h2>
          <p className="border-black border-dotted border-t col-span-2 p-1 text-center text-gray-500 text-xs uppercase">Created:  {this.props.analysis.cls} fixes</p>
          <div className="grid grid-cols-2 grid-rows-2 border-t border-black border-dotted">
            <p className={this.colorScore(this.props.cwv.cls.score,"border-r")}>{this.props.cwv.cls.numericValue.toFixed(2)}</p>
            <p className={this.colorScore(this.props.crux.cls.score)}>{(this.props.crux.cls.numericValue).toFixed(2)}</p>
            <p className="text-xs uppercase text-gray-500">This Run</p>
            <p className="text-xs uppercase text-gray-500">{this.props.crux.cls.source==="field" ? "Your Page" : "Your Site"}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Log;
