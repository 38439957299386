import React from 'react';

function filterString(e, datasetName, datasetValue) {
  let buttons = document.querySelectorAll("#filters-waterfall button");
  [].forEach.call(buttons, function(button) {
    button.classList.remove("bg-white", "text-black");
  });
  document.getElementById("screenshot").classList.add("hidden");
  document.getElementById("chart").classList.remove("hidden");
  //document.getElementById("backlog").classList.add("hidden");
  //document.getElementById("sprint").classList.add("hidden");
  //document.getElementById("request").classList.remove("hidden");
  document.getElementById("filters-waterfall").classList.remove("hidden");
  //document.getElementById("filters-backlog").classList.add("hidden");
  document.getElementById("filters-screenshot").classList.add("hidden");
  document.getElementById("tab-1").classList.add("bg-gray-200");
  document.getElementById("tab-2").classList.remove("bg-gray-200");
  //document.getElementById("tab-4").classList.remove("bg-gray-200");
  let rowEls = document.querySelectorAll("#chart div.row");
  let tally = 0;
  [].forEach.call(rowEls, function(row) {
    row.style.display = "";
    if ((datasetValue !== "All") && (row.dataset[datasetName]) && (row.dataset[datasetName].includes(datasetValue))) {
      row.style.display = "flex";
      tally = tally + 1
    } else {
      row.style.display = "none";
    }
    if (datasetValue === "All") {
      row.style.display = "flex";
      tally = tally + 1;
    }
  });
  datasetValue = (datasetValue === "P") ? "Fixes" : datasetValue;
  document.getElementById("tally").innerHTML = (datasetValue === "All") ? `${tally-1}` : `${tally}`;
  if (!e.target.className.includes("btn-tab")){
    e.target.classList.add("bg-white", "text-black");
  } else {
    buttons[0].classList.add("bg-white", "text-black");
  }
}

function Facet (props){
  if (props.count<1) {
    return null;
  }

  //this.props.analysis.fcp

  return (
    <button
      className="rounded my-2 bg-gray-600 my-1 hover:bg-white border-2 border-gray-600 hover:text-gray-600 text-white col-span-2 inline-block mr-2 uppercase px-1 py-0.5 outline-none focus:outline-none mr-3"
      onClick={(e) => filterString(e, "fix", props.filter)}
    >
      {props.filter}
    </button>
  )
}

class Filters extends React.Component {

  handleBox(e, metric) {
    let buttons = document.querySelectorAll("#filters-screenshot button");
    [].forEach.call(buttons, function(button) {
      button.classList.remove("bg-white", "text-black");
    });
    document.getElementById("screenshot").classList.remove("hidden");
    document.getElementById("chart").classList.add("hidden");
    //document.getElementById("backlog").classList.add("hidden");
    //document.getElementById("sprint").classList.add("hidden");
    //document.getElementById("request").classList.remove("hidden");
    document.getElementById("filters-waterfall").classList.add("hidden");
    //document.getElementById("filters-backlog").classList.add("hidden");
    document.getElementById("filters-screenshot").classList.remove("hidden");
    document.getElementById("layer-lcp").classList.add("hidden");
    document.getElementById("layer-cls").classList.add("hidden");
    if (metric === "lcp") {
      document.getElementById("layer-lcp").classList.remove("hidden");
      document.getElementById("tab-1").classList.remove("bg-gray-200");
      document.getElementById("tab-2").classList.add("bg-gray-200");
      //document.getElementById("tab-4").classList.remove("bg-gray-200");
    }
    if (metric === "cls") {
      document.getElementById("layer-cls").classList.remove("hidden");
      document.getElementById("tab-1").classList.remove("bg-gray-200");
      document.getElementById("tab-2").classList.add("bg-gray-200");
      //document.getElementById("tab-4").classList.remove("bg-gray-200");
    }
    if (!e.target.className.includes("btn-tab")){
      e.target.classList.add("bg-white", "text-black");
    } else {
      buttons[0].classList.add("bg-white", "text-black");
    }
  }

  getTypecolor(type){
    let color = "";
    switch (type) {
      case 'Image':
        color = "purple"
        break;
      case 'Script':
        color = "amber"
        break;
      case 'Document':
        color = "blue"
        break;
      case 'Stylesheet':
        color = "teal"
        break;
      case 'Font':
        color = "pink"
        break;
      default:
        color = "gray"
    }
    return `bg-${color}-500 h-3 inline-block ml-1 mr-0.5 rounded-full w-3`
  }

  render() {
    return (
      <section id="filters" className="mb-0">
        <div id="tabs-workspace">
          <ul className="flex cursor-pointer">
            <li id="tab-1" className="ml-4 w-1/2 border-t-2 border-r-2 border-l-2 border-white hover:border-gray-200 bg-gray-200">
              <button
                className="btn-tab outline-none focus:outline-none px-6 py-2 text-center w-full"
                onClick={(e) => filterString(e, "type", "All")}
              >
                Waterfall
              </button>
            </li>
            <li id="tab-2" className="mx-4 w-1/2 border-t-2 border-r-2 border-l-2 border-white hover:border-gray-200">
              <button
                className="btn-tab outline-none focus:outline-none px-6 py-2 text-center w-full"
                onClick={(e) => this.handleBox(e, "lcp")}
              >
                Screenshot
              </button>
            </li>
          </ul>
        </div>
        <div id="filters-workspace">
          <div
            id="filters-waterfall"
            className="bg-gray-200 px-4 py-2 text-xs text-center block"
          >
            <span className="mr-2">Filter by:</span>
            <button
              id="filter-fixes"
              className="rounded my-2 bg-gray-600 my-1 hover:bg-white border-2 border-gray-600 hover:text-gray-600 text-white col-span-2 inline-block mr-2 uppercase px-1 py-0.5 outline-none focus:outline-none mr-3"
              onClick={(e) => filterString(e, "priority", "P")}
            >
              Fixes
            </button>
            <Facet
              count={this.props.analysis.fcp}
              filter="fcp"
            />
            <Facet
              count={this.props.analysis.lcp}
              filter="lcp"
            />
            <Facet
              count={this.props.analysis.tbt}
              filter="tbt"
            />
            <Facet
              count={this.props.analysis.fid}
              filter="fid"
            />
            <Facet
              count={this.props.analysis.cls}
              filter="cls"
            />
            <span className="mr-3">|</span>
            {this.props.filters.map((filter, index) => (
              <button
                key={index}
                className="rounded my-2 bg-gray-600 my-1 hover:bg-white border-2 border-gray-600 hover:text-gray-600 text-white col-span-2 inline-block mr-2 uppercase px-1 py-0.5 outline-none focus:outline-none mr-3"
                onClick={(e) => filterString(e, filter.name, filter.value)}
              >
                {filter.label}<span className={this.getTypecolor(filter.value)}></span>
              </button>
            ))}
            <span className="mr-3">|</span>
            <button
              className="rounded my-2 bg-gray-600 my-1 hover:bg-white border-2 border-gray-600 hover:text-gray-600 text-white col-span-2 inline-block mr-2 uppercase px-1 py-0.5 outline-none focus:outline-none mr-3"
              onClick={(e) => filterString(e, "type", "All")}
            >
              Show All
            </button>
          </div>
          <div
            id="filters-screenshot"
            className="text-gray-500 bg-gray-200 px-4 py-2 text-xs text-center block hidden"
          >
            <button
              id="filter-lcp"
              className="rounded my-2 bg-gray-600 my-1 hover:bg-white border-2 border-gray-600 hover:text-gray-600 text-white col-span-2 inline-block mr-2 uppercase px-1 py-0.5 outline-none focus:outline-none mr-3"
              onClick={(e) => this.handleBox(e, "lcp")}
            >
              LCP
            </button>
            <button
              id="filter-cls"
              className="rounded my-2 bg-gray-600 my-1 hover:bg-white border-2 border-gray-600 hover:text-gray-600 text-white col-span-2 inline-block mr-2 uppercase px-1 py-0.5 outline-none focus:outline-none mr-3"
              onClick={(e) => this.handleBox(e, "cls")}
            >
              CLS
            </button>
          </div>
        </div>
      </section>
    )
  }
}

export default Filters;
