import React from 'react';
import Issue from '../Request/Issue';
import toggleClassName from '../../utilities/toggleClassName';

class Backlog extends React.Component {

  clickRequest(index){
    document.getElementById("screenshot").classList.add("hidden");
    document.getElementById("chart").classList.remove("hidden");
    document.getElementById("backlog").classList.add("hidden");
    document.getElementById("filters-waterfall").classList.remove("hidden");
    document.getElementById("filters-backlog").classList.add("hidden");
    document.getElementById("filters-screenshot").classList.add("hidden");
    document.getElementById("sprint").classList.add("hidden");
    document.getElementById("request").classList.remove("hidden");
    document.getElementById("tab-1").classList.add("bg-gray-200");
    document.getElementById("tab-2").classList.remove("bg-gray-200");
    document.getElementById("tab-4").classList.remove("bg-gray-200");
    document.getElementById(`file-${index}`).click();
    let rowEl = document.getElementById(`request-${index}`);
    rowEl.scrollIntoView();
  }

  displayImpact(impact){
    switch(impact) {
      case 3:
        return 'High'
      case 2:
        return "Normal"
      default:
        return "Low"
    }
  }

  render(){
    return (
      <section id="backlog" className="w-full hidden pb-9 bg-gray-200">

        <div className="grid grid-cols-1 w-reading mx-auto gap-4 px-4 pt-4">
          <p
            className="py-1 text-sm font-bold"
            style={{
              height: '30px'
            }}
          >Showing <span id="tally-tasks" className="uppercase">{this.props.backlog.length}</span> of {this.props.backlog.length}
          </p>
            {this.props.backlog.map((audit, index) => (
              <div
                key={audit.index}
                id={`item-${audit.index}`}
                className="task flex flex-col justify-between text-sm group p-2 bg-white shadow hover:shadow-lg"
                data-fix={audit.fix}
                data-impact={this.displayImpact(audit.impact)}
                data-order={audit.order}
                data-beforelcp={audit.beforeLCP}
                data-auditid={audit.id}
              >
                <div
                  className="text-lg group-hover:font-extrabold cursor-pointer overflow-hidden"
                  onClick={() => toggleClassName(`issue-${audit.index}`,'hidden')}
                >
                  <button
                    className="bg-gray-500 group-hover:bg-gray-900 text-white rounded uppercase px-1 py-0.5 outline-none focus:outline-none text-sm mr-2"
                    title="Click view full task"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 444.8 444.8"><g xmlns="http://www.w3.org/2000/svg"><path d="M434.3 114.2l-21.4-21.4c-7.4-7-16.1-10.6-26-10.6 -10.1 0-18.7 3.5-25.7 10.6L222.4 231.5 83.7 92.8c-7-7-15.6-10.6-25.7-10.6 -9.9 0-18.6 3.5-26 10.6l-21.1 21.4C3.6 121.4 0 130.1 0 140.2c0 10.3 3.6 18.8 10.8 25.7l185.9 185.9c6.9 7.2 15.4 10.8 25.7 10.8 10.1 0 18.8-3.6 26-10.8l185.9-185.9c7-7 10.6-15.6 10.6-25.7C444.8 130.3 441.3 121.6 434.3 114.2z" data-original="#000000" fill="#fff"/></g></svg>
                  </button>
                  <span className="uppercase">[{audit.fix}]</span> {audit.title}
                </div>
                <div className="my-2">
                  <div id={`issue-${audit.index}`} className="mb-2 hidden">
                    <Issue
                      id = {`backlog-${audit.id}`}
                      url = {audit.file}
                      filename = {audit.file}
                      path = {audit.file}
                      issue = {audit.issue}
                      story = {audit.story}
                      ac = {audit.ac}
                      solution = {audit.solution || 'Solution pending...'}
                      estimate = {audit.estimate}
                      impact = {audit.impact}
                      title = {audit.title + " for " + audit.file + " on " + audit.pageUrl}
                      pageUrl = {audit.pageUrl}
                      auditId = {audit.id}
                      fix={audit.fix}
                    />
                  </div>
                  <p className="text-gray-500 group-hover:text-black mb-2">
                    <strong>What is the problem?</strong> {audit.issue}
                  </p>
                  <p className="mb-2">
                    Inspect waterfall details for <button
                      className="underline inline-block outline-none focus:outline-none font-bold"
                      onClick={() => this.clickRequest(audit.fileIndex)}
                    >
                      {audit.file}
                    </button>
                  </p>
                </div>
              </div>
            ))}
          </div>
      </section>
    )
  }

}

export default Backlog;
