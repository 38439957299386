import React from 'react';

class Requesturl extends React.Component {

  render = () => {
    return (
      <div className="w-full whitespace-nowrap overflow-hidden overflow-x-auto blur">
        {this.props.content}
      </div>
    )
  }

}

export default Requesturl;
