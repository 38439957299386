import React from 'react';
import ReactTooltip from 'react-tooltip';

class Screenshot extends React.Component {



  render(){
    const scale = this.props.width>360 ? 2 : 1;
    const pxWidth = `${this.props.width/scale}px`;
    const pxHeight = `${this.props.height/scale}px`;
    return (
      <section id="screenshot" className="w-full hidden">
        <div id="visualization">
          <div
            id="wrapper"
            className="relative w-full"
            style={{
              height: `${(this.props.height>=900) ? this.props.height : 900}px`,
              backgroundImage: "linear-gradient(30deg, #f3f4f6 25%, #e5e7eb 25%, #e5e7eb 50%, #f3f4f6 50%, #f3f4f6 75%, #e5e7eb 75%, #e5e7eb 100%)",
              backgroundSize: "40.00px 23.09px"
            }}
          >
            <div
              id="layer-screenshot"
              className="absolute top-0 left-0"
              style={{
                width: pxWidth,
                height: pxHeight
              }}
            >
              <img
                src={this.props.src}
                className="blur"
                height={this.props.height/scale}
                width={this.props.width/scale}
                alt="Full Page Screenshot"
                loading="lazy"/>
            </div>
            <div
              id="layer-lcp"
              className="absolute top-0 left-0"
              style={{
                width: pxWidth,
                height: pxHeight
              }}
            >
              {this.props.lcpnodes.map((lcpnode, index) => (
                <div
                  className="lcpbox group absolute bg-red-200 hover:opacity-80 opacity-50 text-red-900 border-4 border-red-600"
                  style={{
                    width: `${lcpnode.node.boundingRect.width/scale}px`,
                    height: `${lcpnode.node.boundingRect.height/scale}px`,
                    top: `${lcpnode.node.boundingRect.top/scale}px`,
                    left: `${lcpnode.node.boundingRect.left/scale}px`
                  }}
                  data-tip={lcpnode.node.selector.replaceAll(">", "> <br/>")}
                  data-html={true}
                  key = {index}
                >
                  <span
                    className="group-hover:text-white group-hover:bg-red-600"
                  >
                    {index+1}-LCP
                  </span>
                  <ReactTooltip place="right" data-html={true}/>
                </div>
              ))}
            </div>
            <div
              id="layer-cls"
              className="absolute top-0 left-0"
              style={{
                width: pxWidth,
                height: pxHeight
              }}
            >
              {this.props.clsnodes.map((clsnode, index) => (
                <div
                  className="clsbox group absolute bg-yellow-200 hover:opacity-80 opacity-50 text-yellow-900 border-4 border-yellow-600"
                  style={{
                    width: `${clsnode.node.boundingRect.width/scale}px`,
                    height: `${clsnode.node.boundingRect.height/scale}px`,
                    top: `${clsnode.node.boundingRect.top/scale}px`,
                    left: `${clsnode.node.boundingRect.left/scale}px`
                  }}
                  data-tip={clsnode.node.selector.replaceAll(">", "> <br/>")}
                  data-html={true}
                  key = {index}
                >
                  <span className="group-hover:text-white group-hover:bg-yellow-600">{index+1}-CLS ({clsnode.score.toFixed(3)})</span>
                  <ReactTooltip place="right" data-html={true}/>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    )
  }

}

export default Screenshot;
