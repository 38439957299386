import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
//import ReactMarkdown from "react-markdown";
import Requesturl from '../Common/Requesturl';
import Issue from './Issue';
import toggleClassName from '../../utilities/toggleClassName';

const prettyBytes = require('pretty-bytes');
const ordinal = require('ordinal')

function Image(props){
  if (props.type!=='Image') {
    return null;
  }
  return (
    <img src={props.src} className="max-h-40 border mx-auto shadow mb-9 bg-white blur" alt="Rendered File"/>
  )
}

function getEstimate(estimate){
  switch (estimate) {
    case 1:
      return '1 hour';
    case 2:
      return 'Half a day';
    case 3:
      return '1 day';
    case 5:
      return '2-3 days';
    default:
      return 'No estimate available';
  }
}

function getImpact(impact){
  switch (impact) {
    case 1:
      return '1-Low';
    case 2:
      return '2-Normal';
    case 3:
      return '3-High';
    default:
      return 'No impact available';
  }
}

function clearRowHighlight(){
  let rowEls = document.querySelectorAll(".row.select");
  [].forEach.call(rowEls, function(row) {
    row.classList.remove("select");
  });
}

function fileTimingMessage (beforeEvent,index,fcp,lcp,cls){
  let message = "The rootfile loads this file after the load event, and likely does not impact page speed or core web vital scores.";
  if (beforeEvent==="fcp") message = `The rootfile loads this file before FCP ${fcp} so it is a factor in rendering the first content to the device.`;
  if (beforeEvent==="lcp") message = `The rootfile loads this file before LCP ${lcp} so it contributes to how fast the browser displays components at the top of your page.`;
  if (beforeEvent==="ol") message = `The rootfile loads this file before the browser's load event so it impacts the page load time which is not a core web vital metric.`;
  if (index===0) message = `Your page has a ${fcp} FCP leading to a ${parseFloat(lcp, 10)<2.5 ? "passing" : "failing"} ${lcp} LCP.`;
  return message;
}

function fileBudgetMessage(typeCount,type,index,budget,lcp){
  if (index===0) {
    return `and it loads ${budget.req-1} total files with ${budget.reqBeforeLcp} requested before LCP. ${(budget.reqBeforeLcp>=35) ? `Our research recommends loading 35 files before LCP, so your page is ${budget.reqBeforeLcp-35} files over budget.` : 'Our research recommends to load 35 files before LCP, so your page is on budget.'}`;
  }
  return `and the ${ordinal(typeCount)} ${type} loaded.`;
}

function slowdownMessage(priority,index,auditCount){
  if (index===0) {
    let message = `The browser loads and parses this rootfile then loads additional files required to display your page. Analysis found ${(auditCount>1) ? `${auditCount} high level problems and created tasks to fix them.` : `one high level problem and created a task to fix it.`}`;
    return message;
  }
  if (priority==="P") return `This file is slowing down your page. Analysis found ${(auditCount>1) ? `${auditCount} problems and created tasks to fix them.` : `a problem and created a task to fix it.`}`
  return `It is likely that this file is not causing a slowdown. ${(auditCount>0) ? `However, some low priority task(s) are available for your review.` : ''}`;
}

function handleOpenRequest(requestIndex,request,fcp,lcp,ol,budget){
  const audits = request.audits;

  audits.sort((a,b) => ((a.order) > (b.order) ) ? 1 : -1);

  const requestEl = (
    <>
      <header className="py-9">
        <h3 className="text-2xl truncate font-bold">{request.index===0 ? "Theme and epics" : request.filename}</h3>
        <div className="mb-2 text-sm">
          <Requesturl
            content={request.url}
          />
        </div>
      </header>
      <Image
        src={request.url}
        type={request.resourceType}
      />
      <div className="mb-4 text-lg w-full">
          <p>
            This {prettyBytes(request.transferSize)} {request.party==='third' ? "third-party ": ""}{request.resourceType}{request.party==='third' ? ` from ${request.host} ` : ""} is the {(request.index===0) ? "root" : ordinal(request.index)}  file loaded by your page,  {fileBudgetMessage(request.typeCount,request.resourceType,request.index,budget,lcp)}
          </p>
      </div>
      <div id="audits" className="mb-4">
        {audits.map((audit, index) => (
          <div key={audit.index} className="text-sm group mb-4 px-2 bg-white shadow hover:shadow-lg guide-item">
            <div className="text-lg py-2 mr-4 hover:opacity-100 opacity-60 guide-item--header">
              <button
                className="outline-none inline focus:outline-none text-gray-500 text-black font-bold text-left pl-8"
                onClick={(e) => toggleClassName(`audit-${audit.index}`,'hidden',e,'User','Clicked task',audit.title)}
              >
                [{audit.fix}] {audit.title}
              </button>
            </div>
            <div id={`audit-${audit.index}`} className="pb-2 hidden">
              <Issue
                id = {`issue-copy-${audit.index}`}
                url = {request.url}
                filename = {request.filename}
                path = {request.path}
                issue = {audit.issue}
                story = {audit.story}
                ac = {audit.ac}
                solution = {audit.solution || 'Solution pending...'}
                estimate = {getEstimate(audit.estimate)}
                impact = {getImpact(audit.impact)}
                title = {audit.title}
                pageUrl = {audit.pageUrl}
                auditId = {audit.id}
                fix={audit.fix}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );

  ReactDOM.render(
    requestEl,
    document.getElementById('request')
  );
  clearRowHighlight();
  document.getElementById(`request-${requestIndex}`).classList.add("select");
  ReactGA.event({
    category: 'User',
    action: 'Clicked on request',
    value: request.resourceType
  });
}

export default handleOpenRequest;
