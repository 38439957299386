module.exports = [
  {
    name: 'Google/Doubleclick Ads',
    company: 'Google',
    homepage: 'https://www.doubleclickbygoogle.com/',
    categories: ['ad'],
    domains: [
      'adservice.google.com',
      'adservice.google.com.au',
      'adservice.google.com.sg',
      'adservice.google.com.br',
      'adservice.google.com.ua',
      'adservice.google.co.uk',
      'adservice.google.co.jp',
      'adservice.google.co.in',
      'adservice.google.co.kr',
      'adservice.google.co.id',
      'adservice.google.co.nz',
      'adservice.google.ie',
      'adservice.google.se',
      'adservice.google.de',
      'adservice.google.ca',
      'adservice.google.be',
      'adservice.google.es',
      'adservice.google.ch',
      'adservice.google.fr',
      'adservice.google.nl',
      '*.googleadservices.com',
      '*.googlesyndication.com',
      '*.googletagservices.com',
      '*.2mdn.net',
      '*.doubleclick.net',
    ],
    examples: [
      'pagead2.googlesyndication.com',
      'tpc.googlesyndication.com',
      'ade.googlesyndication.com',
      'googleads.g.doubleclick.net',
      'googleads4.g.doubleclick.net',
      'securepubads.g.doubleclick.net',
      'pubads.g.doubleclick.net',
      'static.doubleclick.net',
      'cm.g.doubleclick.net',
      'bid.g.doubleclick.net',
      's0.2mdn.net',
      'stats.g.doubleclick.net',
      'survey.g.doubleclick.net',
      'fls.doubleclick.net',
      'ad.doubleclick.net',
      'www.googleadservices.com',
      'https://www.googletagservices.com/tag/js/gpt.js',
    ],
  },
  {
    name: 'Facebook',
    homepage: 'https://www.facebook.com',
    categories: ['social'],
    domains: [
      '*.facebook.com',
      '*.atlassbx.com',
      '*.fbsbx.com',
      'fbcdn-photos-e-a.akamaihd.net',
      '*.facebook.net',
      '*.fbcdn.net',
    ],
    examples: [
      'www.facebook.com',
      'connect.facebook.net',
      'staticxx.facebook.com',
      'static.xx.fbcdn.net',
      'm.facebook.com',
      'an.facebook.com',
      'platform-lookaside.fbsbx.com',
    ],
    products: [
      {
        name: 'Facebook Messenger Customer Chat',
        urlPatterns: [/connect\.facebook\.net\/.*\/sdk\/xfbml\.customerchat\.js/],
        facades: [
          {
            name: 'React Live Chat Loader',
            repo: 'https://github.com/calibreapp/react-live-chat-loader',
          },
        ],
      },
    ],
  },
  {
    name: 'Instagram',
    homepage: 'https://www.instagram.com',
    categories: ['social'],
    domains: ['*.cdninstagram.com', '*.instagram.com'],
    examples: ['scontent.cdninstagram.com'],
  },
  {
    name: 'Google CDN',
    company: 'Google',
    homepage: 'https://developers.google.com/speed/libraries/',
    categories: ['cdn'],
    domains: ['ajax.googleapis.com', 'commondatastorage.googleapis.com', 'www.gstatic.com'],
  },
  {
    name: 'Google Maps',
    company: 'Google',
    homepage: 'https://www.google.com/maps',
    categories: ['utility'],
    domains: [
      'maps.google.com',
      'maps-api-ssl.google.com',
      'maps.googleapis.com',
      'mts.googleapis.com',
      'maps.gstatic.com',
    ],
  },
  {
    name: 'Other Google APIs/SDKs',
    company: 'Google',
    homepage: 'https://developers.google.com/apis-explorer/#p/',
    categories: ['utility'],
    domains: [
      'accounts.google.com',
      'apis.google.com',
      'calendar.google.com',
      'clients2.google.com',
      'cse.google.com',
      'news.google.com',
      'pay.google.com',
      'payments.google.com',
      'play.google.com',
      'smartlock.google.com',
      'www.google.com',
      'www.google.de',
      'www.google.co.jp',
      'www.google.com.au',
      'www.google.co.uk',
      'www.google.ie',
      'www.google.com.sg',
      'www.google.co.in',
      'www.google.com.br',
      'www.google.ca',
      'www.google.co.kr',
      'www.google.co.nz',
      'www.google.co.id',
      'www.google.fr',
      'www.google.be',
      'www.google.com.ua',
      'www.google.nl',
      'www.google.ru',
      'www.google.se',
      'imasdk.googleapis.com',
      'storage.googleapis.com',
      'translate.googleapis.com',
      'lh3.googleusercontent.com',
      'csi.gstatic.com',
    ],
  },
  {
    name: 'Firebase',
    homepage: 'https://developers.google.com/apis-explorer/#p/',
    categories: ['utility'],
    domains: ['firebasestorage.googleapis.com', 'firestore.googleapis.com'],
  },
  {
    name: 'Google Analytics',
    company: 'Google',
    homepage: 'https://www.google.com/analytics/analytics/',
    categories: ['analytics'],
    domains: ['*.google-analytics.com', '*.urchin.com', 'analytics.google.com'],
    examples: [
      'www.google-analytics.com',
      'ssl.google-analytics.com',
      'analytics.google.com/g/collect',
    ],
  },
  {
    name: 'Google AMP',
    company: 'Google',
    homepage: 'https://github.com/google/amp-client-id-library',
    categories: ['analytics'],
    domains: ['ampcid.google.com'],
  },
  {
    name: 'Google Tag Manager',
    company: 'Google',
    homepage: 'https://marketingplatform.google.com/about/tag-manager/',
    categories: ['tag-manager'],
    domains: ['*.googletagmanager.com'],
    examples: ['www.googletagmanager.com'],
  },
  {
    name: 'Google Fonts',
    company: 'Google',
    homepage: 'https://fonts.google.com/',
    categories: ['cdn'],
    domains: ['fonts.googleapis.com', 'fonts.gstatic.com'],
  },
  {
    name: 'Adobe TypeKit',
    company: 'Adobe',
    homepage: 'https://fonts.adobe.com/',
    categories: ['cdn'],
    domains: ['*.typekit.com', '*.typekit.net'],
    examples: ['use.typekit.net', 'p.typekit.net'],
  },
  {
    name: 'YouTube',
    homepage: 'https://youtube.com',
    categories: ['video'],
    domains: ['*.youtube.com', '*.ggpht.com', '*.youtube-nocookie.com', '*.ytimg.com'],
    examples: [
      'www.youtube.com',
      's.ytimg.com',
      'yt3.ggpht.com',
      'img.youtube.com',
      'fcmatch.youtube.com',
    ],
    products: [
      {
        name: 'YouTube Embedded Player',
        urlPatterns: ['youtube.com/embed/'],
        facades: [
          {
            name: 'Lite YouTube',
            repo: 'https://github.com/paulirish/lite-youtube-embed',
          },
        ],
      },
    ],
  },
  {
    name: 'Twitter',
    homepage: 'https://twitter.com',
    categories: ['social'],
    domains: [
      '*.vine.co',
      '*.twimg.com',
      '*.twitpic.com',
      'platform.twitter.com',
      'syndication.twitter.com',
    ],
    examples: ['cdn.syndication.twimg.com', 'abs.twimg.com', 'pbs.twimg.com'],
  },
  {
    name: 'AddThis',
    homepage: 'http://www.addthis.com/',
    categories: ['social'],
    domains: ['*.addthis.com', '*.addthiscdn.com', '*.addthisedge.com'],
    examples: ['s7.addthis.com', 'r.dlx.addthis.com', 'su.addthis.com', 'x.dlx.addthis.com'],
  },
  {
    name: 'AddToAny',
    homepage: 'https://www.addtoany.com/',
    categories: ['social'],
    domains: ['*.addtoany.com'],
    examples: ['static.addtoany.com'],
  },
  {
    name: 'Akamai',
    homepage: 'https://www.akamai.com/',
    categories: ['cdn'],
    domains: [
      '23.62.3.183',
      '*.akamaitechnologies.com',
      '*.akamaitechnologies.fr',
      '*.akamai.net',
      '*.akamaiedge.net',
      '*.akamaihd.net',
      '*.akamaized.net',
      '*.edgefcs.net',
      '*.edgekey.net',
      'edgesuite.net',
      '*.srip.net',
    ],
  },
  {
    name: 'Blogger',
    homepage: 'http://www.blogger.com/',
    categories: ['hosting'],
    domains: [
      '*.blogblog.com',
      '*.blogger.com',
      '*.blogspot.com',
      'images-blogger-opensocial.googleusercontent.com',
    ],
    examples: ['1.bp.blogspot.com', 'www.blogger.com'],
  },
  {
    name: 'Gravatar',
    homepage: 'http://en.gravatar.com/',
    categories: ['social'],
    domains: ['*.gravatar.com'],
    examples: ['secure.gravatar.com', 'www.gravatar.com'],
  },
  {
    name: 'Yandex Metrica',
    company: 'Yandex',
    homepage: 'https://metrica.yandex.com/about?',
    categories: ['analytics'],
    domains: ['mc.yandex.ru', 'd31j93rd8oukbv.cloudfront.net'],
  },
  {
    name: 'Hotjar',
    homepage: 'https://www.hotjar.com/',
    categories: ['analytics'],
    domains: ['*.hotjar.com', '*.hotjar.io'],
    examples: [
      'script.hotjar.com',
      'static.hotjar.com',
      'in.hotjar.com',
      'vc.hotjar.io',
      'vars.hotjar.com',
    ],
  },
  {
    name: 'Baidu Analytics',
    homepage: 'https://tongji.baidu.com/web/welcome/login',
    categories: ['analytics'],
    domains: ['hm.baidu.com', 'hmcdn.baidu.com'],
    examples: ['hm.baidu.com', 'hmcdn.baidu.com'],
  },
  {
    name: 'Adobe Tag Manager',
    company: 'Adobe',
    homepage: 'https://www.adobe.com/experience-platform/',
    categories: ['tag-manager'],
    domains: [
      '*.adobedtm.com',
      '*.demdex.net', // all requests to this domain are for Audience Manager
      '*.everesttech.net',
    ],
    examples: ['assets.adobedtm.com', 'sync-tm.everesttech.net', 'cm.everesttech.net'],
  },
  {
    name: 'jQuery CDN',
    homepage: 'https://code.jquery.com/',
    categories: ['cdn'],
    domains: ['*.jquery.com'],
    examples: ['code.jquery.com'],
  },
  {
    name: 'Cloudflare CDN',
    homepage: 'https://cdnjs.com/',
    categories: ['cdn'],
    domains: ['amp.cloudflare.com', 'cdnjs.cloudflare.com'],
  },
  {
    name: 'Cloudflare',
    homepage: 'https://www.cloudflare.com/website-optimization/',
    categories: ['utility'],
    domains: ['ajax.cloudflare.com'],
  },
  {
    name: 'WordPress',
    company: 'Automattic',
    homepage: 'https://wp.com/',
    categories: ['hosting'],
    domains: [
      '*.wordpress.com',
      's0.wp.com',
      's2.wp.com',
      '*.w.org',
      'c0.wp.com',
      's1.wp.com',
      'widgets.wp.com',
    ],
    examples: ['s.w.org'],
  },
  {
    name: 'WordPress Site Stats',
    company: 'Automattic',
    homepage: 'https://wp.com/',
    categories: ['analytics'],
    domains: ['pixel.wp.com', 'stats.wp.com'],
  },
  {
    name: 'Hatena Blog',
    homepage: 'https://hatenablog.com/',
    categories: ['hosting'],
    domains: ['*.st-hatena.com', '*.hatena.ne.jp'],
    examples: [
      'cdn.blog.st-hatena.com',
      'cdn.pool.st-hatena.com',
      'cdn7.www.st-hatena.com',
      's.hatena.ne.jp',
      'b.st-hatena.com',
    ],
  },
  {
    name: 'Shopify',
    homepage: 'https://www.shopify.com/',
    categories: ['hosting'],
    domains: ['*.shopify.com', '*.shopifyapps.com', '*.shopifycdn.com', '*.shopifysvc.com'],
    examples: ['cdn.shopify.com', 'productreviews.shopifycdn.com', 'monorail-edge.shopifysvc.com'],
  },
  {
    name: 'Dealer',
    homepage: 'https://www.dealer.com/',
    categories: ['hosting'],
    domains: ['*.dealer.com'],
    examples: ['static.dealer.com'],
  },
  {
    name: 'PIXNET',
    homepage: 'https://www.pixnet.net/',
    categories: ['social'],
    domains: ['*.pixfs.net', '*.pixnet.net'],
    examples: ['front.pixfs.net', 'falcon-asset.pixfs.net', 'pixgame-asset.pixfs.net'],
  },
  {
    name: 'Moat',
    homepage: 'https://moat.com/',
    categories: ['ad'],
    domains: ['*.moatads.com', '*.moatpixel.com'],
    examples: [
      'z.moatads.com',
      'px.moatads.com',
      'geo.moatads.com',
      'sejs.moatads.com',
      'mb.moatads.com',
      'v4.moatads.com',
    ],
  },
  {
    name: '33 Across',
    homepage: 'https://33across.com/',
    categories: ['ad'],
    domains: ['*.33across.com'],
    examples: ['sic.33across.com', 'cdn-sic.33across.com'],
  },
  {
    name: 'OpenX',
    homepage: 'https://www.openx.com/',
    categories: ['ad'],
    domains: [
      '*.deliverimp.com',
      '*.openxadexchange.com',
      '*.servedbyopenx.com',
      '*.jump-time.net',
      '*.openx.net',
    ],
    examples: [
      'uk-ads.openx.net',
      'us-ads.openx.net',
      '33across-d.openx.net',
      'rtb.openx.net',
      'us-u.openx.net',
      'eu-u.openx.net',
      'u.openx.net',
    ],
  },
  {
    name: 'Amazon Ads',
    homepage: 'https://ad.amazon.com/',
    categories: ['ad'],
    domains: ['*.amazon-adsystem.com'],
    examples: [
      's.amazon-adsystem.com',
      'c.amazon-adsystem.com',
      'aax.amazon-adsystem.com',
      'z-na.amazon-adsystem.com',
      'fls-na.amazon-adsystem.com',
      'aax-us-east.amazon-adsystem.com',
      'ir-na.amazon-adsystem.com',
    ],
  },
  {
    name: 'Rubicon Project',
    homepage: 'https://rubiconproject.com/',
    categories: ['ad'],
    domains: ['*.rubiconproject.com', '*.chango.com', '*.fimserve.com'],
    examples: [
      'pixel.rubiconproject.com',
      'fastlane.rubiconproject.com',
      'secure-assets.rubiconproject.com',
      'eus.rubiconproject.com',
      'pixel-us-east.rubiconproject.com',
      'token.rubiconproject.com',
      'ads.rubiconproject.com',
    ],
  },
  {
    name: 'The Trade Desk',
    homepage: 'https://www.thetradedesk.com/',
    categories: ['ad'],
    domains: ['*.adsrvr.org', 'd1eoo1tco6rr5e.cloudfront.net'],
    examples: [
      'js.adsrvr.org',
      'match.adsrvr.org',
      'insight.adsrvr.org',
      'usw-lax.adsrvr.org',
      'data.adsrvr.org',
      'snap.adsrvr.org', // This domain is used for SnapChat-specific tracking
    ],
  },
  {
    name: 'Bidswitch',
    homepage: 'https://www.bidswitch.com/',
    categories: ['ad'],
    domains: ['*.bidswitch.net'],
    examples: ['x.bidswitch.net'],
  },
  {
    name: 'LiveRamp IdentityLink',
    homepage: 'https://liveramp.com/discover-identitylink/',
    categories: ['analytics'],
    domains: ['*.circulate.com', '*.rlcdn.com'],
    examples: ['idsync.rlcdn.com', 'id.rlcdn.com', 'api.rlcdn.com', 'cdn.rlcdn.com'],
  },
  {
    name: 'Drawbridge',
    homepage: 'https://www.drawbridge.com/',
    categories: ['ad'],
    domains: ['*.adsymptotic.com'],
  },
  {
    name: 'AOL / Oath / Verizon Media',
    homepage: 'https://www.oath.com/',
    categories: ['ad'],
    domains: [
      '*.advertising.com',
      '*.aol.com',
      '*.aolcdn.com',
      '*.blogsmithmedia.com',
      '*.oath.com',
      '*.aol.net',
      '*.tacoda.net',
      '*.aol.co.uk',
    ],
    examples: [
      'pixel.advertising.com',
      'dtm.advertising.com',
      'tag.sp.advertising.com',
      'service.sp.advertising.com',
      'adtech.advertising.com',
      'adaptv.advertising.com',
      'mighty.aol.net',
      'consent.cmp.oath.com',
    ],
  },
  {
    name: 'Xaxis',
    homepage: 'https://www.xaxis.com/',
    categories: ['ad'],
    domains: ['*.247realmedia.com', '*.mookie1.com', '*.gmads.net'],
    examples: ['t.mookie1.com', 'odr.mookie1.com'],
  },
  {
    name: 'Freshdesk',
    homepage: 'https://freshdesk.com/',
    categories: ['customer-success'],
    domains: ['d36mpcpuzc4ztk.cloudfront.net'],
  },
  {
    name: 'Help Scout',
    homepage: 'https://www.helpscout.net/',
    categories: ['customer-success'],
    domains: ['djtflbt20bdde.cloudfront.net', '*.helpscout.net'],
    examples: ['beacon-v2.helpscout.net'],
    products: [
      {
        name: 'Help Scout Beacon',
        urlPatterns: ['beacon-v2.helpscout.net'],
        facades: [
          {
            name: 'React Live Chat Loader',
            repo: 'https://github.com/calibreapp/react-live-chat-loader',
          },
        ],
      },
    ],
  },
  {
    name: 'Alexa',
    homepage: 'https://www.alexa.com/',
    categories: ['analytics'],
    domains: ['*.alexametrics.com', 'd31qbv1cthcecs.cloudfront.net'],
    examples: ['certify.alexametrics.com'],
  },
  {
    name: 'OneSignal',
    homepage: 'https://onesignal.com/',
    categories: ['utility'],
    domains: ['*.onesignal.com'],
    examples: ['cdn.onesignal.com', 'https://onesignal.com/api/v1/sync/'],
  },
  {
    name: 'Lucky Orange',
    homepage: 'https://www.luckyorange.com/',
    categories: ['analytics'],
    domains: ['*.luckyorange.com', 'd10lpsik1i8c69.cloudfront.net', '*.luckyorange.net'],
  },
  {
    name: 'Crazy Egg',
    homepage: 'https://www.crazyegg.com/',
    categories: ['analytics'],
    domains: ['*.cetrk.com', '*.crazyegg.com', '*.hellobar.com', 'dnn506yrbagrg.cloudfront.net'],
  },
  {
    name: 'Yandex Ads',
    company: 'Yandex',
    homepage: 'https://yandex.com/adv/',
    categories: ['ad'],
    domains: ['an.yandex.ru'],
  },
  {
    name: 'Salesforce',
    homepage: 'https://www.salesforce.com/products/marketing-cloud/',
    categories: ['analytics'],
    domains: ['*.krxd.net'],
    examples: ['cdn.krxd.net', 'beacon.krxd.net', 'consumer.krxd.net', 'usermatch.krxd.net'],
  },
  {
    name: 'Salesforce Commerce Cloud',
    homepage: 'https://www.salesforce.com/products/commerce-cloud/overview/',
    categories: ['hosting'],
    domains: ['*.cquotient.com', '*.demandware.net', 'demandware.edgesuite.net'],
  },
  {
    name: 'Optimizely',
    homepage: 'https://www.optimizely.com/',
    categories: ['analytics'],
    domains: ['*.optimizely.com'],
    examples: [
      'cdn.optimizely.com',
      'cdn-pci.optimizely.com',
      'logx.optimizely.com',
      'cdn3.optimizely.com',
    ],
  },
  {
    name: 'LiveChat',
    homepage: 'https://www.livechat.com/',
    categories: ['customer-success'],
    domains: ['*.livechatinc.com', '*.livechat.com', '*.livechat-static.com'],
    examples: ['cdn.livechatinc.com', 'secure.livechatinc.com'],
  },
  {
    name: 'VK',
    homepage: 'https://vk.com/',
    categories: ['social'],
    domains: ['*.vk.com'],
  },
  {
    name: 'Tumblr',
    homepage: 'https://tumblr.com/',
    categories: ['social'],
    domains: ['*.tumblr.com'],
    examples: ['assets.tumblr.com', 'static.tumblr.com'],
  },
  {
    name: 'Wistia',
    homepage: 'https://wistia.com/',
    categories: ['video'],
    domains: [
      '*.wistia.com',
      'embedwistia-a.akamaihd.net', // video thumbnails shown in the player
      '*.wistia.net',
    ],
    examples: [
      'fast.wistia.com',
      'fast.wistia.net',
      'distillery.wistia.com',
      'pipedream.wistia.com',
    ],
  },
  {
    name: 'Brightcove',
    homepage: 'https://www.brightcove.com/en/',
    categories: ['video'],
    domains: ['*.brightcove.com', '*.brightcove.net', '*.zencdn.net'],
    examples: ['vjs.zencdn.net', 'players.brightcove.net'],
  },
  {
    name: 'JSDelivr CDN',
    homepage: 'https://www.jsdelivr.com/',
    categories: ['cdn'],
    domains: ['*.jsdelivr.net'],
    examples: ['cdn.jsdelivr.net'],
  },
  {
    name: 'Sumo',
    homepage: 'https://sumo.com/',
    categories: ['marketing'],
    domains: ['*.sumo.com', '*.sumome.com', 'sumo.b-cdn.net'],
    examples: ['sumo.b-cdn.net', 'load.sumo.com', 'load.sumome.com'],
  },
  {
    name: 'Vimeo',
    homepage: 'http://vimeo.com/',
    categories: ['video'],
    domains: ['*.vimeo.com', '*.vimeocdn.com'],
    examples: ['f.vimeocdn.com', 'player.vimeo.com', 'i.vimeocdn.com'],
    products: [
      {
        name: 'Vimeo Embedded Player',
        urlPatterns: ['player.vimeo.com/video/'],
        facades: [
          {
            name: 'Lite Vimeo',
            repo: 'https://github.com/slightlyoff/lite-vimeo',
          },
          {
            name: 'Lite Vimeo Embed',
            repo: 'https://github.com/luwes/lite-vimeo-embed',
          },
        ],
      },
    ],
  },
  {
    name: 'Disqus',
    homepage: 'http://disqus.com/',
    categories: ['social'],
    domains: ['*.disqus.com', '*.disquscdn.com'],
    examples: ['c.disquscdn.com'],
  },
  {
    name: 'Yandex APIs',
    company: 'Yandex',
    homepage: 'https://yandex.ru/',
    categories: ['utility'],
    domains: ['api-maps.yandex.ru', 'money.yandex.ru'],
  },
  {
    name: 'Yandex CDN',
    company: 'Yandex',
    homepage: 'https://yandex.ru/',
    categories: ['cdn'],
    domains: ['*.yandex.st', '*.yastatic.net'],
    examples: [
      'https://yastatic.net/share2/share.js',
      'https://yastatic.net/jquery/2.1.4/jquery.min.js',
    ],
  },
  {
    name: 'Integral Ad Science',
    homepage: 'https://integralads.com/uk/',
    categories: ['ad'],
    domains: ['*.adsafeprotected.com', '*.iasds01.com'],
    examples: [
      'pixel.adsafeprotected.com',
      'static.adsafeprotected.com',
      'fw.adsafeprotected.com',
      'cdn.adsafeprotected.com',
      'dt.adsafeprotected.com',
    ],
  },
  {
    name: 'Tealium',
    homepage: 'https://tealium.com/',
    categories: ['tag-manager'],
    domains: [
      '*.aniview.com',
      '*.delvenetworks.com',
      '*.limelight.com',
      '*.tiqcdn.com',
      '*.llnwd.net',
      '*.tealiumiq.com',
    ],
    examples: [
      'tags.tiqcdn.com',
      'tealium.hs.llnwd.net',
      'link.videoplatform.limelight.com',
      'datacloud.tealiumiq.com',
    ],
  },
  {
    name: 'Pubmatic',
    homepage: 'https://pubmatic.com/',
    categories: ['ad'],
    domains: ['*.pubmatic.com'],
    examples: [
      'image6.pubmatic.com',
      'ads.pubmatic.com',
      'image2.pubmatic.com',
      'simage2.pubmatic.com',
      'image4.pubmatic.com',
      'simage4.pubmatic.com',
      'image5.pubmatic.com',
      'hbopenbid.pubmatic.com',
    ],
  },
  {
    name: 'Olark',
    homepage: 'https://www.olark.com/',
    categories: ['customer-success'],
    domains: ['*.olark.com'],
    examples: ['static.olark.com'],
  },
  {
    name: 'Tawk.to',
    homepage: 'https://www.tawk.to/',
    categories: ['customer-success'],
    domains: ['*.tawk.to'],
    examples: ['embed.tawk.to'],
  },
  {
    name: 'OptinMonster',
    homepage: 'https://optinmonster.com/',
    categories: ['marketing'],
    domains: ['*.opmnstr.com', '*.optmnstr.com', '*.optmstr.com'],
    examples: ['a.optmstr.com', 'api.opmnstr.com', 'a.optmnstr.com'],
  },
  {
    name: 'ZenDesk',
    homepage: 'https://zendesk.com/',
    categories: ['customer-success'],
    domains: ['*.zdassets.com', '*.zendesk.com', '*.zopim.com'],
    examples: ['assets.zendesk.com', 'static.zdassets.com', 'v2.zopim.com'],
  },
  {
    name: 'Pusher',
    homepage: 'https://pusher.com/',
    categories: ['utility'],
    domains: ['*.pusher.com', '*.pusherapp.com'],
    examples: ['stats.pusher.com'],
  },
  {
    name: 'Drift',
    homepage: 'https://www.drift.com/',
    categories: ['marketing'],
    domains: ['*.drift.com', '*.driftt.com'],
    examples: ['js.driftt.com', 'api.drift.com'],
    products: [
      {
        name: 'Drift Live Chat',
        urlPatterns: [/js\.driftt\.com\/include\/.*\/.*\.js/],
        facades: [
          {
            name: 'React Live Chat Loader',
            repo: 'https://github.com/calibreapp/react-live-chat-loader',
          },
        ],
      },
    ],
  },
  {
    name: 'Sentry',
    homepage: 'https://sentry.io/',
    categories: ['utility'],
    domains: ['*.getsentry.com', '*.ravenjs.com', '*.sentry-cdn.com'],
    examples: ['cdn.ravenjs.com', 'browser.sentry-cdn.com'],
  },
  {
    name: 'Amazon Web Services',
    homepage: 'https://aws.amazon.com/s3/',
    categories: ['other'],
    domains: [
      '*.amazon.com',
      '*.amazonaws.com',
      '*.amazonwebapps.com',
      '*.amazonwebservices.com',
      '*.elasticbeanstalk.com',
      '*.images-amazon.com',
      '*.amazon.co.uk',
    ],
    examples: [
      's3.amazonaws.com',
      'us-east-1.amazonaws.com',
      'api-cdn.amazon.com',
      'ecx.images-amazon.com',
      'ws.amazon.co.uk',
    ],
  },
  {
    name: 'Amazon Pay',
    homepage: 'https://pay.amazon.com',
    categories: ['utility'],
    domains: ['payments.amazon.com', '*.payments-amazon.com'],
  },
  {
    name: 'Media.net',
    homepage: 'https://www.media.net/',
    categories: ['ad'],
    domains: ['*.media.net', '*.mnet-ad.net'],
    examples: [
      'contextual.media.net',
      'cdnwest-xch.media.net',
      'hbx.media.net',
      'cs.media.net',
      'hblg.media.net',
    ],
  },
  {
    name: 'Yahoo!',
    homepage: 'https://www.yahoo.com/',
    categories: ['ad'],
    domains: [
      '*.bluelithium.com',
      '*.hostingprod.com',
      '*.lexity.com',
      '*.yahoo.com',
      '*.yahooapis.com',
      '*.yimg.com',
      '*.zenfs.com',
      '*.yahoo.net',
    ],
    examples: [
      'ads.yahoo.com',
      'analytics.yahoo.com',
      'geo.yahoo.com',
      'udc.yahoo.com',
      'ganon.yahoo.com',
      'ads.yap.yahoo.com',
    ],
  },
  {
    name: 'Adroll',
    homepage: 'https://www.adroll.com/',
    categories: ['ad'],
    domains: ['*.adroll.com'],
    examples: ['d.adroll.com', 's.adroll.com'],
  },
  {
    name: 'Twitch',
    homepage: 'https://twitch.tv/',
    categories: ['video'],
    domains: ['*.twitch.tv'],
    examples: ['player.twitch.tv'],
  },
  {
    name: 'Taboola',
    homepage: 'https://www.taboola.com/',
    categories: ['ad'],
    domains: ['*.taboola.com', '*.taboolasyndication.com'],
    examples: ['cdn.taboola.com', 'trc.taboola.com', 'vidstat.taboola.com', 'images.taboola.com'],
  },
  {
    name: 'Sizmek',
    homepage: 'https://www.sizmek.com/',
    categories: ['ad'],
    domains: ['*.serving-sys.com', '*.peer39.net'],
    examples: ['secure-ds.serving-sys.com', 'ds.serving-sys.com', 'bs.serving-sys.com'],
  },
  {
    name: 'Scorecard Research',
    homepage: 'https://www.scorecardresearch.com/',
    categories: ['ad'],
    domains: ['*.scorecardresearch.com'],
    examples: ['sb.scorecardresearch.com', 'sa.scorecardresearch.com', 'b.scorecardresearch.com'],
  },
  {
    name: 'Criteo',
    homepage: 'https://www.criteo.com/',
    categories: ['ad'],
    domains: ['*.criteo.com', '*.emailretargeting.com', '*.criteo.net'],
    examples: [
      'static.criteo.net',
      'bidder.criteo.com',
      'dis.criteo.com',
      'gum.criteo.com',
      'sslwidget.criteo.com',
      'dis.us.criteo.com',
    ],
  },
  {
    name: 'Segment',
    homepage: 'https://segment.com/',
    categories: ['analytics'],
    domains: ['*.segment.com', '*.segment.io'],
    examples: ['cdn.segment.com', 'api.segment.io'],
  },
  {
    name: 'ShareThis',
    homepage: 'https://www.sharethis.com/',
    categories: ['social'],
    domains: ['*.sharethis.com'],
    examples: ['w.sharethis.com', 'ws.sharethis.com', 't.sharethis.com'],
  },
  {
    name: 'Distil Networks',
    homepage: 'https://www.distilnetworks.com/',
    categories: ['utility'],
    domains: ['*.areyouahuman.com'],
    examples: ['n-cdn.areyouahuman.com'],
  },
  {
    name: 'Connexity',
    homepage: 'http://connexity.com/',
    categories: ['analytics'],
    domains: ['*.connexity.net'],
  },
  {
    name: 'Popads',
    homepage: 'https://www.popads.net/',
    categories: ['ad'],
    domains: ['*.popads.net'],
    examples: ['serve.popads.net', 'c1.popads.net'],
  },
  {
    name: 'CreateJS CDN',
    homepage: 'http://code.createjs.com/',
    categories: ['cdn'],
    domains: ['*.createjs.com'],
    examples: ['code.createjs.com'],
  },
  {
    name: 'Squarespace',
    homepage: 'https://www.squarespace.com/',
    categories: ['hosting'],
    domains: ['*.squarespace.com'],
    examples: ['static.squarespace.com', 'static1.squarespace.com'],
  },
  {
    name: 'Media Math',
    homepage: 'http://www.mediamath.com/',
    categories: ['ad'],
    domains: ['*.mathads.com', '*.mathtag.com'],
    examples: ['mathid.mathtag.com', 'sync.mathtag.com', 'pixel.mathtag.com'],
  },
  {
    name: 'Mixpanel',
    homepage: 'https://mixpanel.com/',
    categories: ['analytics'],
    domains: ['*.mixpanel.com', '*.mxpnl.com'],
    examples: ['cdn.mxpnl.com'],
  },
  {
    name: 'FontAwesome CDN',
    homepage: 'https://fontawesome.com/',
    categories: ['cdn'],
    domains: ['*.fontawesome.com'],
    examples: ['use.fontawesome.com'],
  },
  {
    name: 'Hubspot',
    homepage: 'https://hubspot.com/',
    categories: ['marketing'],
    domains: [
      '*.hs-scripts.com',
      '*.hubspot.com',
      '*.leadin.com',
      '*.hs-analytics.net',
      '*.hscollectedforms.net',
      '*.hscta.net',
      '*.hsforms.net',
      '*.hsleadflows.net',
      '*.hsstatic.net',
      '*.hubspot.net',
    ],
    examples: ['forms.hubspot.com', 'js.hsforms.net', 'js.hs-analytics.net', 'js.leadin.com'],
  },
  {
    name: 'Mailchimp',
    homepage: 'https://mailchimp.com/',
    categories: ['marketing'],
    domains: ['*.chimpstatic.com', '*.list-manage.com', '*.mailchimp.com'],
    examples: ['downloads.mailchimp.com'],
  },
  {
    name: 'MGID',
    homepage: 'https://www.mgid.com/',
    categories: ['ad'],
    domains: ['*.mgid.com', '*.dt07.net'],
    examples: ['servicer.mgid.com'],
  },
  {
    name: 'Stripe',
    homepage: 'https://stripe.com',
    categories: ['utility'],
    domains: ['*.stripe.com', '*.stripecdn.com', '*.stripe.network'],
    examples: ['m.stripe.network', 'js.stripe.com'],
  },
  {
    name: 'PayPal',
    homepage: 'https://paypal.com',
    categories: ['utility'],
    domains: ['*.paypal.com', '*.paypalobjects.com'],
    examples: ['www.paypalobjects.com'],
  },
  {
    name: 'Market GID',
    homepage: 'https://www.marketgid.com/',
    categories: ['ad'],
    domains: ['*.marketgid.com'],
    examples: ['jsc.marketgid.com'],
  },
  {
    name: 'Pinterest',
    homepage: 'https://pinterest.com/',
    categories: ['social'],
    domains: ['*.pinimg.com', '*.pinterest.com'],
    examples: ['assets.pinterest.com', 'ct.pinterest.com', 'log.pinterest.com'],
  },
  {
    name: 'New Relic',
    homepage: 'https://newrelic.com/',
    categories: ['utility'],
    domains: ['*.newrelic.com', '*.nr-data.net'],
    examples: ['js-agent.newrelic.com', 'bam.nr-data.net'],
  },
  {
    name: 'AppDynamics',
    homepage: 'https://www.appdynamics.com/',
    categories: ['utility'],
    domains: ['*.appdynamics.com', '*.eum-appdynamics.com', 'd3tjaysgumg9lf.cloudfront.net'],
    examples: ['cdn.appdynamics.com'],
  },
  {
    name: 'Parking Crew',
    homepage: 'http://parkingcrew.net/',
    categories: ['other'],
    domains: ['d1lxhc4jvstzrp.cloudfront.net', '*.parkingcrew.net'],
  },
  {
    name: 'WordAds',
    company: 'Automattic',
    homepage: 'https://wordads.co/',
    categories: ['ad'],
    domains: ['*.pubmine.com'],
    examples: ['s.pubmine.com'],
  },
  {
    name: 'AppNexus',
    homepage: 'https://www.appnexus.com/',
    categories: ['ad'],
    domains: ['*.adnxs.com', '*.ctasnet.com', '*.adrdgt.com'],
    examples: [
      'acdn.adnxs.com',
      'secure.adnxs.com',
      'ib.adnxs.com',
      'sharethrough.adnxs.com',
      'cdn.adnxs.com',
      'vcdn.adnxs.com',
    ],
  },
  {
    name: 'Histats',
    homepage: 'http://histats.com/',
    categories: ['analytics'],
    domains: ['*.histats.com'],
    examples: ['s10.histats.com'],
  },
  {
    name: 'DoubleVerify',
    homepage: 'https://www.doubleverify.com/',
    categories: ['ad'],
    domains: ['*.doubleverify.com', '*.dvtps.com', '*.iqfp1.com'],
    examples: [
      'cdn.doubleverify.com',
      'cdn3.doubleverify.com',
      'tps.doubleverify.com',
      'tps712.doubleverify.com',
      'tps714.doubleverify.com',
      'tps706.doubleverify.com',
      'tps700.doubleverify.com',
      'tps707.doubleverify.com',
      'rtb2.doubleverify.com',
      'rtb0.doubleverify.com',
      'rtbcdn.doubleverify.com',
      'tps11020.doubleverify.com',
      'tm.iqfp1.com',
    ],
  },
  {
    name: 'Mediavine',
    homepage: 'https://www.mediavine.com/',
    categories: ['ad'],
    domains: ['*.mediavine.com'],
    examples: ['scripts.mediavine.com', 'video.mediavine.com'],
  },
  {
    name: 'Wix',
    homepage: 'https://www.wix.com/',
    categories: ['hosting'],
    domains: ['*.parastorage.com', '*.wix.com', '*.wixstatic.com', '*.wixapps.net'],
    examples: [
      'static.parastorage.com',
      'static.wixstatic.com',
      'www.wix.com',
      'instagram.codev.wixapps.net',
    ],
  },
  {
    name: 'Weebly',
    homepage: 'https://www.weebly.com/',
    categories: ['hosting'],
    domains: ['*.editmysite.com'],
  },
  {
    name: 'LinkedIn',
    homepage: 'https://www.linkedin.com/',
    categories: ['social'],
    domains: [
      '*.bizographics.com',
      'platform.linkedin.com',
      '*.slideshare.com',
      '*.slidesharecdn.com',
    ],
  },
  {
    name: 'LinkedIn Ads',
    categories: ['ad'],
    domains: ['*.licdn.com', 'ads.linkedin.com', 'www.linkedin.com'],
    examples: ['snap.licdn.com'],
  },
  {
    name: 'Vox Media',
    homepage: 'https://www.voxmedia.com/',
    categories: ['content'],
    domains: ['*.vox-cdn.com', '*.voxmedia.com'],
    examples: ['cdn.vox-cdn.com'],
  },
  {
    name: 'Hotmart',
    homepage: 'https://www.hotmart.com/',
    categories: ['content'],
    domains: ['*.hotmart.com'],
    examples: ['launchermodule.hotmart.com'],
  },
  {
    name: 'SoundCloud',
    homepage: 'https://www.soundcloud.com/',
    categories: ['content'],
    domains: ['*.sndcdn.com', '*.soundcloud.com', '*.stratus.sc'],
    examples: ['widget.sndcdn.com'],
  },
  {
    name: 'Spotify',
    homepage: 'https://www.spotify.com/',
    categories: ['content'],
    domains: ['*.scdn.co', '*.spotify.com'],
    examples: ['open.spotify.com', 'open.scdn.co', 'i.scdn.co'],
  },
  {
    name: 'AMP',
    homepage: 'https://amp.dev/',
    categories: ['content'],
    domains: ['*.ampproject.org'],
    examples: ['cdn.ampproject.org'],
  },
  {
    name: 'Beeketing',
    homepage: 'https://beeketing.com/',
    categories: ['marketing'],
    domains: ['*.beeketing.com'],
    examples: ['sdk-cdn.beeketing.com', 'sdk.beeketing.com'],
  },
  {
    name: 'Albacross',
    homepage: 'https://albacross.com/',
    categories: ['marketing'],
    domains: ['*.albacross.com'],
    examples: ['serve.albacross.com'],
  },
  {
    name: 'TrafficJunky',
    homepage: 'https://www.trafficjunky.com/',
    categories: ['ad'],
    domains: ['*.contentabc.com', '*.trafficjunky.net'],
    examples: [
      'ads2.contentabc.com',
      'hw-cdn.contentabc.com',
      'media.trafficjunky.net',
      'ads.trafficjunky.net',
      'hw-cdn.trafficjunky.net',
    ],
  },
  {
    name: 'Bootstrap CDN',
    homepage: 'https://www.bootstrapcdn.com/',
    categories: ['cdn'],
    domains: ['*.bootstrapcdn.com'],
    examples: ['maxcdn.bootstrapcdn.com', 'stackpath.bootstrapcdn.com'],
  },
  {
    name: 'Shareaholic',
    homepage: 'https://www.shareaholic.com/',
    categories: ['social'],
    domains: ['*.shareaholic.com', 'dsms0mj1bbhn4.cloudfront.net'],
  },
  {
    name: 'Snowplow',
    homepage: 'https://snowplowanalytics.com/',
    categories: ['analytics'],
    domains: ['d32hwlnfiv2gyn.cloudfront.net'],
  },
  {
    name: 'RD Station',
    homepage: 'https://www.rdstation.com/en/',
    categories: ['marketing'],
    domains: ['d335luupugsy2.cloudfront.net'],
  },
  {
    name: 'Jivochat',
    homepage: 'https://www.jivochat.com/',
    categories: ['customer-success'],
    domains: ['*.jivosite.com'],
    examples: ['cdn-ca.jivosite.com', 'code.jivosite.com'],
  },
  {
    name: 'Listrak',
    homepage: 'https://www.listrak.com/',
    categories: ['marketing'],
    domains: ['*.listrak.com', '*.listrakbi.com'],
    examples: ['cdn.listrakbi.com', 's1.listrakbi.com'],
  },
  {
    name: 'Ontame',
    homepage: 'https://www.ontame.io',
    categories: ['analytics'],
    domains: ['*.ontame.io'],
    examples: ['cdn.ontame.io', 'collector.ontame.io'],
  },
  {
    name: 'Ipify',
    homepage: 'https://www.ipify.org',
    categories: ['utility'],
    domains: ['*.ipify.org'],
    examples: ['api.ipify.org', 'geo.ipify.org'],
  },
  {
    name: 'Ensighten',
    homepage: 'https://www.ensighten.com/',
    categories: ['tag-manager'],
    domains: ['*.ensighten.com'],
    examples: ['nexus.ensighten.com'],
  },
  {
    name: 'EpiServer',
    homepage: 'https://www.episerver.com',
    categories: ['content'],
    domains: ['*.episerver.net'],
    examples: ['dl.episerver.net'],
  },
  {
    name: 'mPulse',
    homepage: 'https://developer.akamai.com/akamai-mpulse',
    categories: ['analytics'],
    domains: ['*.akstat.io', '*.go-mpulse.net', '*.mpulse.net', '*.mpstat.us'],
    examples: ['c.go-mpulse.net', '0211c83c.akstat.io'],
  },
  {
    name: 'Pingdom RUM',
    homepage: 'https://www.pingdom.com/product/performance-monitoring/',
    categories: ['analytics'],
    domains: ['*.pingdom.net'],
    examples: ['rum-static.pingdom.net', 'rum-collector-2.pingdom.net'],
  },
  {
    name: 'SpeedCurve LUX',
    company: 'SpeedCurve',
    homepage: 'https://speedcurve.com/features/lux/',
    categories: ['analytics'],
    domains: ['*.speedcurve.com'],
    examples: ['cdn.speedcurve.com', 'lux.speedcurve.com'],
  },
  {
    name: 'Radar',
    company: 'Cedexis',
    homepage: 'https://www.cedexis.com/radar/',
    categories: ['analytics'],
    domains: [
      '*.cedexis-test.com',
      '*.cedexis.com',
      '*.cmdolb.com',
      'cedexis.leasewebcdn.com',
      '*.cedexis-radar.net',
      '*.cedexis.net',
      'cedexis-test01.insnw.net',
      'cedexisakamaitest.azureedge.net',
      'cedexispub.cdnetworks.net',
      'cs600.wac.alphacdn.net',
      'cs600.wpc.edgecastdns.net',
      'global2.cmdolb.com',
      'img-cedexis.mncdn.com',
      'a-cedexis.msedge.net',
      'zn3vgszfh.fastestcdn.net',
    ],
    examples: [
      'radar.cedexis.com',
      'rpt.cedexis.com',
      '2-01-49cd-0002.cdx.cedexis.net',
      'bench.cedexis-test.com',
    ],
  },
  {
    name: 'Byside',
    homepage: 'http://www.byside.com',
    categories: ['analytics'],
    domains: ['*.byside.com'],
    examples: ['byce2.byside.com', 'wce2.byside.com'],
  },
  {
    name: 'VWO',
    homepage: 'https://vwo.com',
    categories: ['analytics'],
    domains: ['*.visualwebsiteoptimizer.com'],
    examples: ['dev.visualwebsiteoptimizer.com'],
  },
  {
    name: 'Bing Ads',
    homepage: 'https://bingads.microsoft.com',
    categories: ['ad'],
    domains: [
      '*.bing.com',
      '*.microsoft.com',
      '*.msn.com',
      '*.s-msft.com',
      '*.s-msn.com',
      '*.msads.net',
      '*.msecnd.net',
      '*.windows.net',
    ],
    examples: ['bat.bing.com', 'c.bing.com', 'bat.r.msn.com', 'ajax.microsoft.com'],
  },
  {
    name: 'GoSquared',
    homepage: 'https://www.gosquared.com',
    categories: ['analytics'],
    domains: ['*.gosquared.com', 'd1l6p2sc9645hc.cloudfront.net'],
    examples: ['data.gosquared.com', 'data2.gosquared.com'],
  },
  {
    name: 'Usabilla',
    homepage: 'https://usabilla.com',
    categories: ['analytics'],
    domains: ['*.usabilla.com', 'd6tizftlrpuof.cloudfront.net'],
    examples: ['w.usabilla.com'],
  },
  {
    name: 'Fastly Insights',
    homepage: 'https://insights.fastlylabs.com',
    categories: ['analytics'],
    domains: ['*.fastly-insights.com'],
    examples: ['www.fastly-insights.com'],
  },
  {
    name: 'Visual IQ',
    homepage: 'https://www.visualiq.com',
    categories: ['analytics'],
    domains: ['*.myvisualiq.net'],
    examples: ['t.myvisualiq.net'],
  },
  {
    name: 'Snapchat',
    homepage: 'https://www.snapchat.com',
    categories: ['analytics'],
    domains: ['*.snapchat.com', '*.sc-static.net'],
    examples: ['tr.snapchat.com'],
  },
  {
    name: 'Atlas Solutions',
    homepage: 'https://atlassolutions.com',
    categories: ['analytics'],
    domains: ['*.atdmt.com'],
    examples: ['ad.atdmt.com', 'cx.atdmt.com'],
  },
  {
    name: 'Quantcast',
    homepage: 'https://www.quantcast.com',
    categories: ['analytics'],
    domains: [
      '*.brtstats.com',
      '*.quantcount.com',
      '*.quantserve.com',
      '*.semantictec.com',
      '*.ntv.io',
    ],
    examples: [
      'pixel.quantserve.com',
      'secure.quantserve.com',
      'cms.quantserve.com',
      'rules.quantcount.com',
    ],
  },
  {
    name: 'Spiceworks',
    homepage: 'https://www.spiceworks.com',
    categories: ['analytics'],
    domains: ['*.spiceworks.com'],
    examples: ['px.spiceworks.com'],
  },
  {
    name: 'Marketo',
    homepage: 'https://www.marketo.com',
    categories: ['analytics'],
    domains: ['*.marketo.com', '*.mktoresp.com', '*.marketo.net'],
    examples: ['munchkin.marketo.net'],
  },
  {
    name: 'Intercom',
    homepage: 'https://www.intercom.com',
    categories: ['customer-success'],
    domains: ['*.intercomcdn.com', '*.intercom.io'],
    examples: [
      'js.intercomcdn.com',
      'api-iam.intercom.io',
      'widget.intercom.io',
      'nexus-websocket-a.intercom.io',
    ],
    products: [
      {
        name: 'Intercom Widget',
        urlPatterns: ['widget.intercom.io', 'js.intercomcdn.com/shim.latest.js'],
        facades: [
          {
            name: 'React Live Chat Loader',
            repo: 'https://github.com/calibreapp/react-live-chat-loader',
          },
        ],
      },
    ],
  },
  {
    name: 'Unpkg',
    homepage: 'https://unpkg.com',
    categories: ['cdn'],
    domains: ['*.unpkg.com', '*.npmcdn.com'],
  },
  {
    name: 'ReadSpeaker',
    homepage: 'https://www.readspeaker.com',
    categories: ['other'],
    domains: ['*.readspeaker.com'],
    examples: ['sf1-eu.readspeaker.com'],
  },
  {
    name: 'Browsealoud',
    homepage: 'https://www.texthelp.com/en-gb/products/browsealoud/',
    categories: ['other'],
    domains: ['*.browsealoud.com', '*.texthelp.com'],
    examples: ['www.browsealoud.com'],
  },
  {
    name: '15gifts',
    categories: ['customer-success'],
    domains: ['*.15gifts.com', '*.primefuse.com'],
    examples: ['www.primefuse.com'],
  },
  {
    name: '1xRUN',
    categories: ['utility'],
    domains: ['*.1xrun.com'],
  },
  {
    name: '2AdPro Media Solutions',
    categories: ['ad'],
    domains: ['*.2adpro.com'],
  },
  {
    name: '301 Digital Media',
    categories: ['content'],
    domains: ['*.301ads.com', '*.301network.com'],
  },
  {
    name: '360 picnic platform',
    company: 'MediaV',
    categories: ['ad'],
    domains: ['*.mediav.com'],
  },
  {
    name: '365 Media Group',
    categories: ['content'],
    domains: ['*.365dm.com'],
  },
  {
    name: '365 Tech Services',
    categories: ['hosting'],
    domains: ['*.365webservices.co.uk'],
  },
  {
    name: '3D Issue',
    categories: ['utility'],
    domains: ['*.3dissue.com', '*.pressjack.com'],
  },
  {
    name: '47Line Technologies',
    categories: ['other'],
    domains: ['*.pejs.net'],
  },
  {
    name: '4finance',
    categories: ['utility'],
    domains: ['*.4finance.com'],
  },
  {
    name: '5miles',
    categories: ['content'],
    domains: ['*.5milesapp.com'],
  },
  {
    name: '77Tool',
    company: '77Agency',
    categories: ['analytics'],
    domains: ['*.77tracking.com'],
  },
  {
    name: '9xb',
    categories: ['ad'],
    domains: ['*.9xb.com'],
  },
  {
    name: '@UK',
    categories: ['hosting'],
    domains: ['*.uk-plc.net'],
  },
  {
    name: 'A Perfect Pocket',
    categories: ['hosting'],
    domains: ['*.aperfectpocketdata.com'],
  },
  {
    name: 'A-FIS PTE',
    categories: ['analytics'],
    domains: ['*.websta.me'],
  },
  {
    name: 'AB Tasty',
    categories: ['analytics'],
    domains: ['*.abtasty.com', 'd1447tq2m68ekg.cloudfront.net'],
  },
  {
    name: 'ABA RESEARCH',
    categories: ['analytics'],
    domains: ['*.abaresearch.uk', 'qmodal.azurewebsites.net'],
  },
  {
    name: 'ADMIZED',
    categories: ['ad'],
    domains: ['*.admized.com'],
  },
  {
    name: 'ADNOLOGIES',
    categories: ['ad'],
    domains: ['*.heias.com'],
  },
  {
    name: 'ADventori',
    categories: ['ad'],
    domains: ['*.adventori.com'],
  },
  {
    name: 'AI Media Group',
    categories: ['ad'],
    domains: ['*.aimediagroup.com'],
  },
  {
    name: 'AIR.TV',
    categories: ['ad'],
    domains: ['*.air.tv'],
  },
  {
    name: 'AKQA',
    categories: ['ad'],
    domains: ['*.srtk.net'],
  },
  {
    name: 'AOL ad',
    company: 'AOL',
    categories: ['ad'],
    domains: ['*.atwola.com'],
  },
  {
    name: 'AOL On',
    company: 'AOL',
    categories: ['content'],
    domains: ['*.5min.com'],
  },
  {
    name: 'AOL Sponsored Listiings',
    company: 'AOL',
    categories: ['ad'],
    domains: ['*.adsonar.com'],
  },
  {
    name: 'APSIS Lead',
    company: 'APSIS International AB',
    categories: ['ad'],
    domains: ['*.prospecteye.com'],
  },
  {
    name: 'APSIS Profile Cloud',
    company: 'APSIS',
    categories: ['analytics'],
    domains: ['*.innomdc.com'],
  },
  {
    name: 'APSIS Forms',
    company: 'APSIS',
    categories: ['other'],
    domains: ['*.apsisforms.com'],
    examples: ['forms.apsisforms.com'],
  },
  {
    name: 'ARENA',
    company: 'Altitude',
    categories: ['ad'],
    domains: ['*.altitude-arena.com'],
  },
  {
    name: 'ARM',
    categories: ['analytics'],
    domains: ['*.tag4arm.com'],
  },
  {
    name: 'ASAPP',
    categories: ['other'],
    domains: ['*.asapp.com'],
  },
  {
    name: 'ASP',
    categories: ['hosting'],
    domains: ['*.goshowoff.com'],
  },
  {
    name: 'AT Internet',
    categories: ['analytics'],
    domains: ['*.ati-host.net'],
  },
  {
    name: 'ATTRAQT',
    categories: ['utility'],
    domains: ['*.attraqt.com', '*.locayta.com'],
  },
  {
    name: 'AVANSER',
    categories: ['analytics'],
    domains: ['*.avanser.com.au'],
  },
  {
    name: 'AVG',
    company: 'AVG Technologies',
    categories: ['utility'],
    domains: ['*.avg.com'],
    examples: ['omni.avg.com'],
  },
  {
    name: 'AWeber',
    categories: ['ad'],
    domains: ['*.aweber.com'],
  },
  {
    name: 'AXS',
    categories: ['content'],
    domains: ['*.axs.com'],
  },
  {
    name: 'Accenture',
    categories: ['analytics'],
    domains: ['*.tmvtp.com'],
  },
  {
    name: 'Accord Holdings',
    categories: ['ad'],
    domains: ['*.agcdn.com'],
  },
  {
    name: 'Accordant Media',
    categories: ['ad'],
    domains: ['*.a3cloud.net'],
    examples: ['segment.a3cloud.net'],
  },
  {
    name: 'Account Kit',
    categories: ['other'],
    domains: ['*.accountkit.com'],
  },
  {
    name: 'Accuen Media (Omnicom Media Group)',
    categories: ['content'],
    domains: ['*.p-td.com'],
  },
  {
    name: 'Accuweather',
    categories: ['content'],
    domains: ['*.accuweather.com'],
  },
  {
    name: 'Acquisio',
    categories: ['ad'],
    domains: ['*.acq.io'],
  },
  {
    name: 'Act-On Software',
    categories: ['marketing'],
    domains: ['*.actonsoftware.com'],
  },
  {
    name: 'ActBlue',
    categories: ['other'],
    domains: ['*.actblue.com'],
  },
  {
    name: 'Active Agent',
    categories: ['ad'],
    domains: ['*.active-agent.com'],
  },
  {
    name: 'ActiveCampaign',
    categories: ['ad'],
    domains: ['*.trackcmp.net'],
  },
  {
    name: 'AcuityAds',
    categories: ['ad'],
    domains: ['*.acuityplatform.com'],
  },
  {
    name: 'Acxiom',
    categories: ['ad'],
    domains: ['*.acxiom-online.com', '*.acxiomapac.com', '*.delivery.net'],
  },
  {
    name: 'Ad4Screen',
    categories: ['ad'],
    domains: ['*.a4.tl'],
  },
  {
    name: 'Ad6Media',
    categories: ['ad'],
    domains: ['*.ad6media.fr'],
  },
  {
    name: 'AdCurve',
    categories: ['ad'],
    domains: ['*.shop2market.com'],
  },
  {
    name: 'AdEasy',
    categories: ['ad'],
    domains: ['*.adeasy.ru'],
  },
  {
    name: 'AdExtent',
    categories: ['ad'],
    domains: ['*.adextent.com'],
  },
  {
    name: 'AdForge Edge',
    company: 'AdForge',
    categories: ['ad'],
    domains: ['*.adforgeinc.com'],
  },
  {
    name: 'AdGear',
    company: 'Samsung Electronics',
    categories: ['ad'],
    domains: ['*.adgear.com', '*.adgrx.com'],
  },
  {
    name: 'AdInMedia',
    categories: ['ad'],
    domains: ['*.fastapi.net'],
  },
  {
    name: 'AdJug',
    categories: ['ad'],
    domains: ['*.adjug.com'],
    examples: ['tracking.adjug.com', 'uk.view.adjug.com'],
  },
  {
    name: 'AdMatic',
    categories: ['ad'],
    domains: ['*.admatic.com.tr'],
  },
  {
    name: 'AdMedia',
    categories: ['ad'],
    domains: ['*.admedia.com'],
    examples: ['pixel.admedia.com'],
  },
  {
    name: 'AdRecover',
    categories: ['ad'],
    domains: ['*.adrecover.com'],
  },
  {
    name: 'AdRiver',
    categories: ['ad'],
    domains: ['*.adriver.ru'],
  },
  {
    name: 'AdSniper',
    categories: ['ad'],
    domains: ['*.adsniper.ru', '*.sniperlog.ru'],
  },
  {
    name: 'AdSpeed',
    categories: ['ad'],
    domains: ['*.adspeed.net'],
  },
  {
    name: 'AdSpruce',
    categories: ['ad'],
    domains: ['*.adspruce.com'],
  },
  {
    name: 'AdSupply',
    categories: ['ad'],
    domains: ['*.doublepimp.com'],
  },
  {
    name: 'AdTheorent',
    categories: ['ad'],
    domains: ['*.adentifi.com'],
  },
  {
    name: 'AdThink AudienceInsights',
    company: 'AdThink Media',
    categories: ['analytics'],
    domains: ['*.audienceinsights.net'],
  },
  {
    name: 'AdTrue',
    company: 'FPT AdTrue',
    categories: ['ad'],
    domains: ['*.adtrue.com'],
  },
  {
    name: 'AdYapper',
    categories: ['ad'],
    domains: ['*.adyapper.com'],
  },
  {
    name: 'Adacado',
    categories: ['ad'],
    domains: ['*.adacado.com'],
  },
  {
    name: 'Adap.tv',
    categories: ['ad'],
    domains: ['*.adap.tv'],
  },
  {
    name: 'Adapt Services',
    categories: ['hosting'],
    domains: ['*.adcmps.com'],
  },
  {
    name: 'Adaptive Web',
    categories: ['hosting'],
    domains: ['*.adaptive.co.uk'],
  },
  {
    name: 'Adara Media',
    categories: ['ad'],
    domains: ['*.yieldoptimizer.com'],
  },
  {
    name: 'Adblade',
    categories: ['ad'],
    domains: ['*.adblade.com'],
  },
  {
    name: 'Adbrain',
    categories: ['ad'],
    domains: ['*.adbrn.com'],
  },
  {
    name: 'AddEvent',
    categories: ['utility'],
    domains: ['*.addevent.com'],
    examples: ['www.addevent.com'],
  },
  {
    name: 'AddShoppers',
    categories: ['social'],
    domains: ['*.addshoppers.com', 'd3rr3d0n31t48m.cloudfront.net', '*.shop.pe'],
  },
  {
    name: 'AddThisEvent',
    categories: ['hosting'],
    domains: ['*.addthisevent.com'],
  },
  {
    name: 'Addoox MetaNetwork',
    company: 'Addoox',
    categories: ['ad'],
    domains: ['*.metanetwork.net'],
  },
  {
    name: 'Addvantage Media',
    categories: ['ad'],
    domains: ['*.addvantagemedia.com', '*.simplytechnology.net'],
  },
  {
    name: 'Adecs',
    categories: ['customer-success'],
    domains: ['*.adecs.co.uk'],
    examples: ['www.adecs.co.uk'],
  },
  {
    name: 'Adelphic',
    categories: ['ad'],
    domains: ['*.ipredictive.com'],
  },
  {
    name: 'Adestra',
    categories: ['ad'],
    domains: ['*.adestra.com', '*.msgfocus.com'],
  },
  {
    name: 'Adform',
    categories: ['ad'],
    domains: ['*.adform.net', '*.adformdsp.net'],
  },
  {
    name: 'Adkontekst',
    categories: ['ad'],
    domains: ['*.adkontekst.pl'],
  },
  {
    name: 'Adlead',
    categories: ['ad'],
    domains: ['*.webelapp.com'],
  },
  {
    name: 'Adledge',
    categories: ['utility'],
    domains: ['*.adledge.com'],
  },
  {
    name: 'Adloox',
    categories: ['ad'],
    domains: ['*.adlooxtracking.com'],
  },
  {
    name: 'Adlux',
    categories: ['ad'],
    domains: ['*.adlux.com'],
  },
  {
    name: 'Admedo',
    categories: ['ad'],
    domains: ['*.a8723.com', '*.adizio.com', '*.admedo.com'],
    examples: ['pool.a8723.com'],
  },
  {
    name: 'Admeta',
    company: 'Wideorbit',
    categories: ['ad'],
    domains: ['*.atemda.com'],
  },
  {
    name: 'Admetrics',
    company: 'Next Tuesday',
    categories: ['analytics'],
    domains: ['*.nt.vc'],
    examples: ['metrics.nt.vc'],
  },
  {
    name: 'Admiral',
    categories: ['ad'],
    domains: ['*.unknowntray.com'],
  },
  {
    name: 'Admitad',
    categories: ['ad'],
    domains: ['*.lenmit.com'],
  },
  {
    name: 'Admixer for Publishers',
    company: 'Admixer',
    categories: ['ad'],
    domains: ['*.admixer.net'],
  },
  {
    name: 'Adnium',
    categories: ['ad'],
    domains: ['*.adnium.com'],
  },
  {
    name: 'Adnostic',
    company: 'Dennis Publishing',
    categories: ['ad'],
    domains: ['*.adnostic.co.uk'],
  },
  {
    name: 'Adobe Marketing Cloud',
    company: 'Adobe Systems',
    categories: ['ad'],
    domains: ['*.adobetag.com'],
  },
  {
    name: 'Adobe Scene7',
    company: 'Adobe Systems',
    categories: ['content'],
    domains: ['wwwimages.adobe.com', '*.scene7.com', '*.everestads.net', '*.everestjs.net'],
  },
  {
    name: 'Adobe Surveys',
    company: 'Adobe Systems',
    categories: ['analytics'],
    domains: ['*.2o7.net'],
    examples: ['survey.112.2o7.net'],
  },
  {
    name: 'Adobe Systems',
    categories: ['content'],
    domains: ['adobe.com'],
  },
  {
    name: 'Adobe Business Catalyst',
    homepage: 'https://www.businesscatalyst.com/',
    categories: ['hosting'],
    domains: ['*.businesscatalyst.com'],
  },
  {
    name: 'Adobe Test & Target',
    company: 'Adobe Systems',
    categories: ['analytics'],
    domains: ['*.omtrdc.net'],
    examples: ['tt.omtrdc.net'],
  },
  {
    name: 'Adocean',
    company: 'Gemius',
    categories: ['ad'],
    domains: ['*.adocean.pl'],
  },
  {
    name: 'Adometry',
    company: 'Google',
    categories: ['ad'],
    domains: ['*.dmtry.com'],
  },
  {
    name: 'Adomik',
    categories: ['analytics'],
    domains: ['*.adomik.com'],
  },
  {
    name: 'Adotmob',
    categories: ['ad'],
    domains: ['*.adotmob.com'],
  },
  {
    name: 'Adrian Quevedo',
    categories: ['hosting'],
    domains: ['*.adrianquevedo.com'],
  },
  {
    name: 'Adroit Digital Solutions',
    categories: ['ad'],
    domains: ['*.imiclk.com', '*.abmr.net'],
  },
  {
    name: 'AdsNative',
    categories: ['ad'],
    domains: ['*.adsnative.com'],
  },
  {
    name: 'AdsWizz',
    categories: ['ad'],
    domains: ['*.adswizz.com'],
  },
  {
    name: 'Adscale',
    categories: ['ad'],
    domains: ['*.adscale.de'],
  },
  {
    name: 'Adschoom',
    company: 'JSWeb Production',
    categories: ['ad'],
    domains: ['*.adschoom.com'],
  },
  {
    name: 'Adscience',
    categories: ['ad'],
    domains: ['*.adscience.nl'],
  },
  {
    name: 'Adsiduous',
    categories: ['ad'],
    domains: ['*.adsiduous.com'],
  },
  {
    name: 'Adsty',
    categories: ['ad'],
    domains: ['*.adx1.com'],
  },
  {
    name: 'Adtech (AOL)',
    categories: ['ad'],
    domains: ['*.adtechus.com'],
  },
  {
    name: 'Adtegrity',
    categories: ['ad'],
    domains: ['*.adtpix.com'],
  },
  {
    name: 'Adthink',
    company: 'Adthink Media',
    categories: ['ad'],
    domains: ['*.adxcore.com', '*.dcoengine.com'],
    examples: ['d.adxcore.com'],
  },
  {
    name: 'AdultWebmasterEmpire.Com',
    categories: ['ad'],
    domains: ['*.awempire.com'],
  },
  {
    name: 'Adunity',
    categories: ['ad'],
    domains: ['*.adunity.com'],
  },
  {
    name: 'Advance Magazine Group',
    categories: ['content'],
    domains: ['*.condenastdigital.com', '*.condenet.com', '*.condenast.co.uk'],
  },
  {
    name: 'Adverline Board',
    company: 'Adverline',
    categories: ['ad'],
    domains: ['*.adverline.com', '*.adnext.fr'],
  },
  {
    name: 'AdvertServe',
    categories: ['ad'],
    domains: ['*.advertserve.com'],
  },
  {
    name: 'Advolution',
    categories: ['utility'],
    domains: ['*.advolution.de'],
  },
  {
    name: 'Adwise',
    categories: ['ad'],
    domains: ['*.adwise.bg'],
  },
  {
    name: 'Adyen',
    categories: ['utility'],
    domains: ['*.adyen.com'],
  },
  {
    name: 'Adyoulike',
    categories: ['ad'],
    domains: ['*.adyoulike.com', '*.omnitagjs.com', '*.adyoulike.net'],
  },
  {
    name: 'Adzerk',
    categories: ['ad'],
    domains: ['*.adzerk.net'],
  },
  {
    name: 'Adzip',
    company: 'Adbox Digital',
    categories: ['ad'],
    domains: ['*.adzip.co'],
  },
  {
    name: 'AerServ',
    categories: ['ad'],
    domains: ['*.aerserv.com'],
  },
  {
    name: 'Affectv',
    categories: ['ad'],
    domains: ['*.affectv.com', '*.affec.tv'],
  },
  {
    name: 'Affiliate Window',
    company: 'Digital Window',
    categories: ['ad'],
    domains: ['*.dwin1.com'],
  },
  {
    name: 'Affiliatly',
    categories: ['ad'],
    domains: ['*.affiliatly.com'],
    examples: ['www.affiliatly.com'],
  },
  {
    name: 'Affino',
    categories: ['ad'],
    domains: ['affino.com'],
  },
  {
    name: 'Affirm',
    categories: ['utility'],
    domains: ['*.affirm.com'],
  },
  {
    name: 'Agenda Media',
    categories: ['ad'],
    domains: ['*.agendamedia.co.uk'],
  },
  {
    name: 'Aggregate Knowledge',
    company: 'Neustar',
    categories: ['ad'],
    domains: ['*.agkn.com'],
  },
  {
    name: 'AgilOne',
    categories: ['marketing'],
    domains: ['*.agilone.com'],
  },
  {
    name: 'Agility',
    categories: ['hosting'],
    domains: ['*.agilitycms.com'],
  },
  {
    name: 'Ahalogy',
    categories: ['social'],
    domains: ['*.ahalogy.com'],
  },
  {
    name: 'Aheadworks',
    categories: ['utility'],
    domains: ['*.aheadworks.com'],
  },
  {
    name: 'AirPR',
    categories: ['analytics'],
    domains: ['*.airpr.com'],
  },
  {
    name: 'Aira',
    categories: ['ad'],
    domains: ['*.aira.net'],
    examples: ['www.aira.net'],
  },
  {
    name: 'Airport Parking and Hotels',
    categories: ['content'],
    domains: ['*.aph.com'],
  },
  {
    name: 'Akanoo',
    categories: ['analytics'],
    domains: ['*.akanoo.com'],
  },
  {
    name: 'Alchemy',
    company: 'AndBeyond.Media',
    categories: ['ad'],
    domains: ['*.andbeyond.media'],
  },
  {
    name: 'AlephD',
    company: 'AOL',
    categories: ['ad'],
    domains: ['*.alephd.com'],
  },
  {
    name: 'AliveChat',
    company: 'AYU Technology Solutions',
    categories: ['customer-success'],
    domains: ['*.websitealive.com', '*.websitealive7.com'],
  },
  {
    name: 'All Access',
    categories: ['other'],
    domains: ['*.allaccess.com.ph'],
  },
  {
    name: 'Alliance for Audited Media',
    categories: ['ad'],
    domains: ['*.aamsitecertifier.com'],
  },
  {
    name: 'Allyde',
    categories: ['marketing'],
    domains: ['*.mautic.com'],
  },
  {
    name: 'AlphaSSL',
    categories: ['utility'],
    domains: ['*.alphassl.com'],
  },
  {
    name: 'Altitude',
    categories: ['ad'],
    domains: ['*.altitudeplatform.com'],
  },
  {
    name: 'Altocloud',
    categories: ['analytics'],
    domains: ['*.altocloud.com'],
  },
  {
    name: 'Amadeus',
    categories: ['content'],
    domains: ['*.e-travel.com'],
  },
  {
    name: 'Amazon CloudFront',
    company: 'Amazon',
    categories: ['utility'],
    domains: ['cloudfront.net'],
  },
  {
    name: 'Ambassador',
    categories: ['ad'],
    domains: ['*.getambassador.com'],
  },
  {
    name: 'Ambient',
    company: 'Ericcson',
    categories: ['other'],
    domains: ['*.adnetwork.vn', '*.ambientplatform.vn'],
  },
  {
    name: 'Amelia Communication',
    categories: ['hosting'],
    domains: ['*.sara.media'],
  },
  {
    name: 'Amobee',
    categories: ['marketing'],
    domains: ['*.amgdgt.com', '*.kontera.com'],
  },
  {
    name: 'Amplience',
    categories: ['marketing'],
    domains: ['*.10cms.com', '*.amplience.com', '*.bigcontent.io', '*.adis.ws'],
  },
  {
    name: 'Amplitude Mobile Analytics',
    company: 'Amplitude',
    categories: ['analytics'],
    domains: ['*.amplitude.com', 'd24n15hnbwhuhn.cloudfront.net'],
  },
  {
    name: 'Anametrix',
    company: 'Ensighten',
    categories: ['analytics'],
    domains: ['*.anametrix.com'],
  },
  {
    name: 'Ancora Platform',
    company: 'Ancora Media Solutions',
    categories: ['ad'],
    domains: ['*.ancoraplatform.com'],
  },
  {
    name: 'Anedot',
    categories: ['other'],
    domains: ['*.anedot.com'],
  },
  {
    name: 'AnimateJS',
    categories: ['utility'],
    domains: ['*.animatedjs.com'],
  },
  {
    name: 'AnswerDash',
    categories: ['customer-success'],
    domains: ['*.answerdash.com'],
    examples: ['p1.answerdash.com'],
  },
  {
    name: 'Answers',
    categories: ['analytics'],
    domains: ['*.answcdn.com', '*.answers.com', '*.dsply.com'],
  },
  {
    name: 'Apester',
    categories: ['analytics'],
    domains: ['*.apester.com', '*.qmerce.com'],
  },
  {
    name: 'Apligraf SmartWeb',
    company: 'Apligraf',
    categories: ['utility'],
    domains: ['*.apligraf.com.br'],
  },
  {
    name: 'Appier',
    categories: ['ad'],
    domains: ['*.appier.net'],
  },
  {
    name: 'Apptus eSales',
    company: 'Apptus',
    categories: ['analytics'],
    domains: ['*.apptus.com'],
  },
  {
    name: 'Arbor',
    company: 'LiveRamp',
    categories: ['other'],
    domains: ['*.pippio.com'],
  },
  {
    name: 'Ardent Creative',
    categories: ['hosting'],
    domains: ['*.ardentcreative.co.uk'],
  },
  {
    name: 'Arnold Clark Automobiles',
    categories: ['content'],
    domains: ['*.arnoldclark.com'],
  },
  {
    name: 'Atom Content Marketing',
    categories: ['content'],
    domains: ['*.atomvault.net'],
    examples: ['danu.atomvault.net'],
  },
  {
    name: 'Atom Data',
    categories: ['other'],
    domains: ['*.atomdata.io'],
  },
  {
    name: 'Attribution',
    categories: ['ad'],
    domains: ['*.attributionapp.com'],
  },
  {
    name: 'Audience 360',
    company: 'Datapoint Media',
    categories: ['ad'],
    domains: ['*.dpmsrv.com'],
  },
  {
    name: 'Audience Science',
    categories: ['ad'],
    domains: ['*.revsci.net'],
  },
  {
    name: 'AudienceSearch',
    company: 'Intimate Merger',
    categories: ['ad'],
    domains: ['*.im-apps.net'],
  },
  {
    name: 'Auditorius',
    categories: ['ad'],
    domains: ['*.audtd.com'],
  },
  {
    name: 'Augur',
    categories: ['analytics'],
    domains: ['*.augur.io'],
  },
  {
    name: 'Auto Link Maker',
    company: 'Apple',
    categories: ['ad'],
    domains: ['*.apple.com'],
    examples: ['autolinkmaker.itunes.apple.com'],
  },
  {
    name: 'Autopilot',
    categories: ['ad'],
    domains: ['*.autopilothq.com'],
  },
  {
    name: 'Avail',
    company: 'RichRelevance',
    categories: ['ad'],
    domains: ['*.avail.net'],
  },
  {
    name: 'AvantLink',
    categories: ['ad'],
    domains: ['*.avmws.com'],
  },
  {
    name: 'Avco Systems',
    categories: ['utility'],
    domains: ['*.avcosystems.com'],
  },
  {
    name: 'Avid Media',
    categories: ['customer-success'],
    domains: ['*.adspdbl.com', '*.metadsp.co.uk'],
  },
  {
    name: 'Avocet Systems',
    categories: ['ad'],
    domains: ['*.avocet.io'],
  },
  {
    name: 'Avora',
    categories: ['analytics'],
    domains: ['*.truedash.com'],
    examples: ['truetag.truedash.com'],
  },
  {
    name: 'Azure Traffic Manager',
    company: 'Microsoft',
    categories: ['other'],
    domains: ['*.gateway.net', '*.trafficmanager.net'],
    examples: ['analytics.gateway.net'],
  },
  {
    name: 'Azure Web Services',
    company: 'Microsoft',
    categories: ['cdn'],
    domains: ['*.azurewebsites.net', '*.azureedge.net', '*.msedge.net'],
  },
  {
    name: 'BAM',
    categories: ['analytics'],
    domains: ['*.bam-x.com'],
  },
  {
    name: 'Baifendian Technology',
    categories: ['marketing'],
    domains: ['*.baifendian.com'],
  },
  {
    name: 'Bankrate',
    categories: ['utility'],
    domains: ['*.bankrate.com'],
  },
  {
    name: 'BannerFlow',
    company: 'Nordic Factory Solutions',
    categories: ['ad'],
    domains: ['*.bannerflow.com'],
  },
  {
    name: 'Barclaycard SmartPay',
    company: 'Barclaycard',
    categories: ['utility'],
    domains: ['*.barclaycardsmartpay.com'],
  },
  {
    name: 'Barilliance',
    categories: ['analytics'],
    domains: ['*.barilliance.net', 'dn3y71tq7jf07.cloudfront.net'],
  },
  {
    name: 'Barnebys',
    categories: ['other'],
    domains: ['*.barnebys.com'],
  },
  {
    name: 'Batch Media',
    categories: ['ad'],
    domains: ['*.t4ft.de'],
  },
  {
    name: 'Bauer Consumer Media',
    categories: ['content'],
    domains: ['*.bauercdn.com', '*.greatmagazines.co.uk'],
    examples: ['www.greatmagazines.co.uk'],
  },
  {
    name: 'Baynote',
    categories: ['analytics'],
    domains: ['*.baynote.net'],
  },
  {
    name: 'Bazaarvoice',
    categories: ['analytics'],
    domains: ['*.bazaarvoice.com', '*.feedmagnet.com'],
  },
  {
    name: 'Beachfront Media',
    categories: ['ad'],
    domains: ['*.bfmio.com'],
  },
  {
    name: 'BeamPulse',
    categories: ['analytics'],
    domains: ['*.beampulse.com'],
  },
  {
    name: 'Beeswax',
    categories: ['ad'],
    domains: ['*.bidr.io'],
  },
  {
    name: 'Beetailer',
    categories: ['social'],
    domains: ['*.beetailer.com'],
    examples: ['www.beetailer.com'],
  },
  {
    name: 'Best Of Media S.A.',
    categories: ['content'],
    domains: ['*.servebom.com'],
  },
  {
    name: 'Bet365',
    categories: ['ad'],
    domains: ['*.bet365affiliates.com'],
  },
  {
    name: 'Betfair',
    categories: ['other'],
    domains: ['*.cdnbf.net'],
  },
  {
    name: 'Betgenius',
    company: 'Genius Sports',
    categories: ['content'],
    domains: ['*.connextra.com'],
  },
  {
    name: 'Better Banners',
    categories: ['ad'],
    domains: ['*.betterbannerscloud.com'],
  },
  {
    name: 'Better Business Bureau',
    categories: ['analytics'],
    domains: ['*.bbb.org'],
  },
  {
    name: 'Between Digital',
    categories: ['ad'],
    domains: ['*.betweendigital.com'],
  },
  {
    name: 'BidTheatre',
    categories: ['ad'],
    domains: ['*.bidtheatre.com'],
  },
  {
    name: 'Bidtellect',
    categories: ['ad'],
    domains: ['*.bttrack.com'],
  },
  {
    name: 'Bigcommerce',
    categories: ['marketing'],
    domains: ['*.bigcommerce.com'],
  },
  {
    name: 'BitGravity',
    company: 'Tata Communications',
    categories: ['content'],
    domains: ['*.bitgravity.com'],
  },
  {
    name: 'Bitly',
    categories: ['utility'],
    domains: ['*.bitly.com', '*.lemde.fr', '*.bit.ly'],
  },
  {
    name: 'Bizible',
    categories: ['ad'],
    domains: ['*.bizible.com', '*.bizibly.com'],
  },
  {
    name: 'Bizrate',
    categories: ['analytics'],
    domains: ['*.bizrate.com'],
  },
  {
    name: 'BlastCasta',
    categories: ['social'],
    domains: ['*.poweringnews.com'],
    examples: ['www.poweringnews.com'],
  },
  {
    name: 'Blindado',
    categories: ['utility'],
    domains: ['*.siteblindado.com'],
  },
  {
    name: 'Blis',
    categories: ['ad'],
    domains: ['*.blismedia.com'],
  },
  {
    name: 'Blogg.se',
    categories: ['hosting'],
    domains: ['*.cdnme.se', '*.publishme.se'],
  },
  {
    name: 'BloomReach',
    categories: ['ad'],
    domains: ['*.brcdn.com', '*.brsrvr.com', '*.brsvr.com'],
  },
  {
    name: 'Bloomberg',
    categories: ['content'],
    domains: ['*.gotraffic.net'],
  },
  {
    name: 'Shop Logic',
    company: 'BloomReach',
    categories: ['marketing'],
    domains: ['*.goshoplogic.com'],
  },
  {
    name: 'Blue State Digital',
    categories: ['ad'],
    domains: ['*.bsd.net'],
  },
  {
    name: 'Blue Triangle Technologies',
    categories: ['analytics'],
    domains: ['*.btttag.com'],
  },
  {
    name: 'BlueCava',
    categories: ['ad'],
    domains: ['*.bluecava.com'],
  },
  {
    name: 'BlueKai',
    company: 'Oracle',
    categories: ['ad'],
    domains: ['*.bkrtx.com', '*.bluekai.com'],
  },
  {
    name: 'Bluecore',
    categories: ['analytics'],
    domains: ['*.bluecore.com'],
    examples: ['www.bluecore.com'],
  },
  {
    name: 'Bluegg',
    categories: ['hosting'],
    domains: ['d1va5oqn59yrvt.cloudfront.net'],
  },
  {
    name: 'Bold Commerce',
    categories: ['utility'],
    domains: ['*.shappify-cdn.com', '*.shappify.com', '*.boldapps.net'],
  },
  {
    name: 'BoldChat',
    company: 'LogMeIn',
    categories: ['customer-success'],
    domains: ['*.boldchat.com'],
  },
  {
    name: 'Bombora',
    categories: ['ad'],
    domains: ['*.mlno6.com'],
  },
  {
    name: 'Bonnier',
    categories: ['content'],
    domains: ['*.bonniercorp.com'],
  },
  {
    name: 'Bookatable',
    categories: ['content'],
    domains: ['*.bookatable.com', '*.livebookings.com'],
  },
  {
    name: 'Booking.com',
    categories: ['content'],
    domains: ['*.bstatic.com'],
  },
  {
    name: 'Boomtrain',
    categories: ['ad'],
    domains: ['*.boomtrain.com', '*.boomtrain.net'],
  },
  {
    name: 'BoostSuite',
    categories: ['ad'],
    domains: ['*.poweredbyeden.com'],
  },
  {
    name: 'Boostable',
    categories: ['ad'],
    domains: ['*.boostable.com'],
  },
  {
    name: 'Bootstrap Chinese network',
    categories: ['cdn'],
    domains: ['*.bootcss.com'],
  },
  {
    name: 'Booxscale',
    categories: ['ad'],
    domains: ['*.booxscale.com'],
  },
  {
    name: 'Borderfree',
    company: 'pitney bowes',
    categories: ['utility'],
    domains: ['*.borderfree.com', '*.fiftyone.com'],
  },
  {
    name: 'BounceX',
    categories: ['analytics'],
    homepage: 'https://www.bouncex.com/',
    domains: ['*.bounceexchange.com', '*.bouncex.net'],
    examples: ['events.bouncex.net'],
  },
  {
    name: 'Box',
    categories: ['hosting'],
    domains: ['*.box.com'],
  },
  {
    name: 'Boxever',
    categories: ['analytics'],
    domains: ['*.boxever.com'],
  },
  {
    name: 'Braintree Payments',
    company: 'Paypal',
    categories: ['utility'],
    domains: ['*.braintreegateway.com'],
  },
  {
    name: 'Branch Metrics',
    categories: ['ad'],
    domains: ['*.branch.io', '*.app.link'],
  },
  {
    name: 'Brand Finance',
    categories: ['other'],
    domains: ['*.brandirectory.com'],
  },
  {
    name: 'Brand View',
    categories: ['analytics'],
    domains: ['*.brandview.com'],
  },
  {
    name: 'Brandscreen',
    categories: ['ad'],
    domains: ['*.rtbidder.net'],
    examples: ['match.rtbidder.net'],
  },
  {
    name: 'BridgeTrack',
    company: 'Sapient',
    categories: ['ad'],
    domains: ['*.bridgetrack.com'],
  },
  {
    name: 'BrightRoll',
    company: 'Yahoo!',
    categories: ['ad'],
    domains: ['*.btrll.com'],
  },
  {
    name: 'BrightTag / Signal',
    company: 'Signal',
    homepage: 'https://www.signal.co',
    categories: ['tag-manager'],
    domains: ['*.btstatic.com', '*.thebrighttag.com'],
  },
  {
    name: 'Brightcove ZenCoder',
    company: 'Brightcove',
    categories: ['other'],
    domains: ['*.zencoder.net'],
  },
  {
    name: 'Bronto Software',
    categories: ['marketing'],
    domains: ['*.bm23.com', '*.bronto.com', '*.brontops.com'],
  },
  {
    name: 'Browser-Update.org',
    categories: ['other'],
    domains: ['*.browser-update.org'],
  },
  {
    name: 'Buffer',
    categories: ['social'],
    domains: ['*.bufferapp.com'],
  },
  {
    name: 'Bugsnag',
    categories: ['utility'],
    domains: ['*.bugsnag.com', 'd2wy8f7a9ursnm.cloudfront.net'],
    examples: ['notify.bugsnag.com'],
  },
  {
    name: 'Burst Media',
    categories: ['ad'],
    domains: ['*.burstnet.com', '*.1rx.io'],
    examples: ['usermatch.burstnet.com'],
  },
  {
    name: 'Burt',
    categories: ['analytics'],
    domains: ['*.richmetrics.com', '*.burt.io'],
  },
  {
    name: 'Business Message',
    categories: ['ad'],
    domains: ['*.message-business.com'],
  },
  {
    name: 'Business Week',
    company: 'Bloomberg',
    categories: ['social'],
    domains: ['*.bwbx.io'],
  },
  {
    name: 'Buto',
    company: 'Big Button',
    categories: ['ad'],
    domains: ['*.buto.tv'],
  },
  {
    name: 'Button',
    categories: ['ad'],
    domains: ['*.btncdn.com'],
  },
  {
    name: 'BuySellAds',
    categories: ['ad'],
    domains: ['*.buysellads.com', '*.buysellads.net'],
  },
  {
    name: 'BuySight (AOL)',
    categories: ['ad'],
    domains: ['*.pulsemgr.com'],
  },
  {
    name: 'Buyapowa',
    categories: ['ad'],
    domains: ['*.co-buying.com'],
  },
  {
    name: 'BuzzFeed',
    categories: ['social'],
    domains: ['*.buzzfed.com'],
  },
  {
    name: 'C1X',
    categories: ['ad'],
    domains: ['*.c1exchange.com'],
  },
  {
    name: 'C3 Metrics',
    categories: ['analytics'],
    domains: ['*.c3tag.com'],
  },
  {
    name: 'CANDDi',
    company: 'Campaign and Digital Intelligence',
    categories: ['ad'],
    domains: ['*.canddi.com'],
  },
  {
    name: 'CCM benchmark Group',
    categories: ['social'],
    domains: ['*.ccm2.net'],
  },
  {
    name: 'CD Networks',
    categories: ['utility'],
    domains: ['*.gccdn.net'],
  },
  {
    name: 'CDN Planet',
    categories: ['analytics'],
    domains: ['*.cdnplanet.com'],
  },
  {
    name: 'InAuth',
    categories: ['utility'],
    homepage: 'https://www.inauth.com/',
    domains: ['*.cdn-net.com'],
    examples: ['uk.cdn-net.com'],
  },
  {
    name: 'CJ Affiliate',
    company: 'Conversant',
    categories: ['ad'],
    domains: ['*.cj.com', '*.dpbolvw.net'],
  },
  {
    name: 'CJ Affiliate by Conversant',
    company: 'Conversant',
    categories: ['ad'],
    domains: ['*.ftjcfx.com'],
  },
  {
    name: 'CNBC',
    categories: ['content'],
    domains: ['*.cnbc.com'],
  },
  {
    name: 'CNET Content Solutions',
    company: 'CBS Interactive',
    categories: ['content'],
    domains: ['*.cnetcontent.com'],
    examples: ['cdn.cnetcontent.com', 'ws.cnetcontent.com'],
  },
  {
    name: 'CPEx',
    categories: ['content'],
    domains: ['*.cpex.cz'],
  },
  {
    name: 'CPXi',
    categories: ['ad'],
    domains: ['*.cpxinteractive.com'],
  },
  {
    name: 'CUBED Attribution',
    company: 'CUBED',
    categories: ['ad'],
    domains: ['*.withcubed.com'],
    examples: ['data.withcubed.com'],
  },
  {
    name: 'Cachefly',
    categories: ['utility'],
    domains: ['*.cachefly.net'],
  },
  {
    name: 'Calendly',
    categories: ['other'],
    domains: ['*.calendly.com'],
  },
  {
    name: 'CallRail',
    categories: ['analytics'],
    domains: ['*.callrail.com'],
  },
  {
    name: 'CallTrackingMetrics',
    categories: ['analytics'],
    domains: ['*.tctm.co'],
  },
  {
    name: 'Canned Banners',
    categories: ['ad'],
    domains: ['*.cannedbanners.com'],
  },
  {
    name: 'Canopy Labs',
    categories: ['analytics'],
    domains: ['*.canopylabs.com'],
  },
  {
    name: 'Capita',
    categories: ['utility'],
    domains: ['*.crcom.co.uk'],
    examples: ['emmsrep.crcom.co.uk'],
  },
  {
    name: 'Captify Media',
    categories: ['ad'],
    domains: ['*.cpx.to'],
  },
  {
    name: 'Captiify',
    categories: ['ad'],
    domains: ['*.captifymedia.com'],
  },
  {
    name: 'Captivate Ai',
    categories: ['ad'],
    domains: ['*.captivate.ai'],
  },
  {
    name: 'Captora',
    categories: ['marketing'],
    domains: ['*.captora.com'],
  },
  {
    name: 'Carcloud',
    categories: ['other'],
    domains: ['*.carcloud.co.uk'],
  },
  {
    name: 'Cardlytics',
    categories: ['ad'],
    domains: ['*.cardlytics.com'],
  },
  {
    name: 'Cardosa Enterprises',
    categories: ['analytics'],
    domains: ['*.y-track.com'],
  },
  {
    name: 'Caspian Media',
    categories: ['ad'],
    domains: ['*.caspianmedia.com'],
  },
  {
    name: 'Cast',
    categories: ['utility'],
    domains: ['*.cast.rocks'],
  },
  {
    name: 'Cavisson',
    categories: ['analytics'],
    domains: ['*.cavisson.com'],
  },
  {
    name: 'Cedato',
    categories: ['ad'],
    domains: ['*.algovid.com', '*.vdoserv.com'],
  },
  {
    name: 'Celebrus Technologies',
    categories: ['analytics'],
    domains: ['*.celebrus.com'],
  },
  {
    name: 'Celtra',
    categories: ['ad'],
    domains: ['*.celtra.com'],
  },
  {
    name: 'Centro',
    categories: ['ad'],
    domains: ['*.brand-server.com'],
  },
  {
    name: 'Ceros',
    categories: ['other'],
    domains: ['ceros.com', 'view.ceros.com'],
  },
  {
    name: 'Ceros Analytics',
    company: 'Ceros',
    categories: ['analytics'],
    domains: ['api.ceros.com'],
  },
  {
    name: 'Certona',
    categories: ['analytics'],
    domains: ['*.certona.net'],
  },
  {
    name: 'Certum',
    categories: ['utility'],
    domains: ['*.ocsp-certum.com', '*.certum.pl'],
  },
  {
    name: 'Cgrdirect',
    categories: ['other'],
    domains: ['*.cgrdirect.co.uk'],
  },
  {
    name: 'Channel 5 Media',
    categories: ['ad'],
    domains: ['*.five.tv'],
  },
  {
    name: 'Channel.me',
    categories: ['customer-success'],
    domains: ['*.channel.me'],
  },
  {
    name: 'ChannelAdvisor',
    categories: ['ad'],
    domains: ['*.channeladvisor.com', '*.searchmarketing.com'],
  },
  {
    name: 'Chargeads Oscar',
    company: 'Chargeads',
    categories: ['ad'],
    domains: ['*.chargeads.com'],
  },
  {
    name: 'Charities Aid Foundation',
    categories: ['utility'],
    domains: ['*.cafonline.org'],
  },
  {
    name: 'Chartbeat',
    categories: ['analytics'],
    domains: ['*.chartbeat.com', '*.chartbeat.net'],
  },
  {
    name: 'Cheapflights Media',
    company: 'Momondo',
    categories: ['content'],
    domains: ['*.momondo.net'],
  },
  {
    name: 'CheckM8',
    categories: ['ad'],
    domains: ['*.checkm8.com'],
  },
  {
    name: 'CheckRate',
    company: 'FreeStart',
    categories: ['utility'],
    domains: ['*.checkrate.co.uk'],
  },
  {
    name: 'Checkfront',
    categories: ['other'],
    domains: ['*.checkfront.com', 'dcg3jth5savst.cloudfront.net'],
  },
  {
    name: 'CheetahMail',
    company: 'Experian',
    categories: ['ad'],
    domains: ['*.chtah.com'],
  },
  {
    name: 'Chitika',
    categories: ['ad'],
    domains: ['*.chitika.net'],
  },
  {
    name: 'ChoiceStream',
    categories: ['ad'],
    domains: ['*.choicestream.com'],
  },
  {
    name: 'Cint',
    categories: ['social'],
    domains: ['*.cint.com'],
  },
  {
    name: 'Civic',
    categories: ['hosting'],
    domains: ['*.civiccomputing.com'],
  },
  {
    name: 'ClearRise',
    categories: ['customer-success'],
    domains: ['*.clearrise.com'],
  },
  {
    name: 'Clearstream',
    categories: ['ad'],
    domains: ['*.clrstm.com'],
  },
  {
    name: 'Clerk.io ApS',
    categories: ['analytics'],
    domains: ['*.clerk.io'],
  },
  {
    name: 'CleverDATA',
    categories: ['ad'],
    domains: ['*.1dmp.io'],
  },
  {
    name: 'CleverTap',
    categories: ['analytics'],
    domains: ['d2r1yp2w7bby2u.cloudfront.net'],
  },
  {
    name: 'Click Density',
    categories: ['analytics'],
    domains: ['*.clickdensity.com'],
  },
  {
    name: 'Click4Assistance',
    categories: ['customer-success'],
    domains: ['*.click4assistance.co.uk'],
  },
  {
    name: 'ClickDesk',
    categories: ['customer-success'],
    domains: ['*.clickdesk.com', 'd1gwclp1pmzk26.cloudfront.net'],
  },
  {
    name: 'ClickDimensions',
    categories: ['ad'],
    domains: ['*.clickdimensions.com'],
  },
  {
    name: 'Clickadu (Winner Solutions)',
    categories: ['ad'],
    domains: ['*.clickadu.com'],
  },
  {
    name: 'Clickagy Audience Lab',
    company: 'Clickagy',
    categories: ['ad'],
    domains: ['*.clickagy.com'],
    examples: ['aorta.clickagy.com'],
  },
  {
    name: 'Clickio',
    categories: ['ad'],
    domains: [],
  },
  {
    name: 'Clicktale',
    categories: ['analytics'],
    domains: ['*.cdngc.net', '*.clicktale.net'],
    examples: ['clicktalecdn.sslcs.cdngc.net'],
  },
  {
    name: 'Clicktripz',
    categories: ['content'],
    domains: ['*.clicktripz.com'],
    examples: ['static.clicktripz.com', 'www.clicktripz.com'],
  },
  {
    name: 'Clik.com Websites',
    categories: ['content'],
    domains: ['*.clikpic.com'],
  },
  {
    name: 'Cloud Technologies',
    categories: ['ad'],
    domains: ['*.behavioralengine.com', '*.behavioralmailing.com'],
  },
  {
    name: 'Cloud-A',
    categories: ['other'],
    domains: ['*.bulkstorage.ca'],
  },
  {
    name: 'Cloud.typography',
    company: 'Hoefler &amp; Co',
    categories: ['cdn'],
    domains: ['*.typography.com'],
  },
  {
    name: 'CloudSponge',
    categories: ['ad'],
    domains: ['*.cloudsponge.com'],
  },
  {
    name: 'CloudVPS',
    categories: ['other'],
    domains: ['*.adoftheyear.com', '*.objectstore.eu'],
  },
  {
    name: 'Cloudinary',
    categories: ['content'],
    domains: ['*.cloudinary.com'],
  },
  {
    name: 'Cloudqp',
    company: 'Cloudwp',
    categories: ['other'],
    domains: ['*.cloudwp.io'],
  },
  {
    name: 'Cludo',
    categories: ['utility'],
    domains: ['*.cludo.com'],
  },
  {
    name: 'Cognesia',
    categories: ['marketing'],
    domains: ['*.intelli-direct.com'],
  },
  {
    name: 'CogoCast',
    company: 'Cogo Labs',
    categories: ['ad'],
    domains: ['*.cogocast.net'],
  },
  {
    name: 'Colbenson',
    categories: ['utility'],
    domains: ['*.colbenson.com'],
  },
  {
    name: 'Collective',
    categories: ['ad'],
    domains: ['*.collective-media.net'],
  },
  {
    name: 'Com Laude',
    categories: ['other'],
    domains: ['*.gdimg.net'],
  },
  {
    name: 'Comm100',
    categories: ['customer-success'],
    domains: ['*.comm100.com'],
  },
  {
    name: 'CommerceHub',
    categories: ['marketing'],
    domains: ['*.mercent.com'],
  },
  {
    name: 'Commission Factory',
    categories: ['ad'],
    domains: ['*.cfjump.com'],
  },
  {
    name: 'Communicator',
    categories: ['ad'],
    domains: ['*.communicatorcorp.com', '*.communicatoremail.com'],
  },
  {
    name: 'Comodo',
    categories: ['utility'],
    domains: [
      '*.comodo.com',
      '*.trust-provider.com',
      '*.trustlogo.com',
      'usertrust.com',
      '*.comodo.net',
    ],
    examples: ['ocsp.trust-provider.com'],
  },
  {
    name: 'Comodo Certficate Authority',
    company: 'Comodo',
    categories: ['utility'],
    domains: ['ocsp.comodoca.com', 'ocsp.usertrust.com'],
  },
  {
    name: 'Comodo Certificate Authority',
    company: 'Comodo',
    categories: ['utility'],
    domains: ['crt.comodoca.com', '*.comodoca4.com'],
    examples: ['ocsp.comodoca4.com'],
  },
  {
    name: 'Compete',
    company: 'Millwood Brown Digital',
    categories: ['analytics'],
    domains: ['*.c-col.com', '*.compete.com'],
  },
  {
    name: 'Compuware',
    categories: ['analytics'],
    domains: ['*.axf8.net'],
  },
  {
    name: 'Conductrics',
    categories: ['analytics'],
    domains: ['*.conductrics.com'],
    examples: ['cdn-v3.conductrics.com'],
  },
  {
    name: 'Confirmit',
    categories: ['analytics'],
    domains: ['*.confirmit.com'],
  },
  {
    name: 'Connatix',
    categories: ['ad'],
    domains: ['*.connatix.com'],
  },
  {
    name: 'Connect Events',
    categories: ['hosting'],
    domains: ['*.connectevents.com.au'],
  },
  {
    name: 'Constant Contact',
    categories: ['ad'],
    domains: ['*.ctctcdn.com'],
  },
  {
    name: 'Constructor.io',
    categories: ['utility'],
    domains: ['*.cnstrc.com'],
  },
  {
    name: 'Contabo',
    categories: ['hosting'],
    domains: ['185.2.100.179'],
  },
  {
    name: 'Content Media Corporation',
    categories: ['content'],
    domains: ['*.contentmedia.eu'],
  },
  {
    name: 'ContentSquare',
    categories: ['analytics'],
    domains: ['d1m6l9dfulcyw7.cloudfront.net', '*.content-square.net', '*.contentsquare.net'],
  },
  {
    name: 'ContextWeb',
    categories: ['ad'],
    domains: ['*.contextweb.com'],
  },
  {
    name: 'Continental Exchange Solutions',
    categories: ['utility'],
    domains: ['*.hifx.com'],
  },
  {
    name: 'Converge-Digital',
    categories: ['ad'],
    domains: ['*.converge-digital.com'],
    examples: ['ads.converge-digital.com'],
  },
  {
    name: 'Conversant',
    categories: ['analytics'],
    domains: [
      '*.dotomi.com',
      '*.dtmpub.com',
      '*.emjcd.com',
      'mediaplex.com',
      '*.tqlkg.com',
      '*.fastclick.net',
    ],
    examples: ['www.tqlkg.com'],
  },
  {
    name: 'Conversant Ad Server',
    company: 'Conversant',
    categories: ['ad'],
    domains: ['adfarm.mediaplex.com'],
  },
  {
    name: 'Conversant Tag Manager',
    company: 'Conversant',
    categories: ['tag-manager'],
    domains: ['*.mplxtms.com'],
  },
  {
    name: 'Conversio',
    categories: ['ad'],
    domains: ['*.conversio.com'],
  },
  {
    name: 'Conversion Labs',
    categories: ['ad'],
    domains: ['*.net.pl'],
    examples: ['conversionlabs.net.pl'],
  },
  {
    name: 'Conversion Logic',
    categories: ['ad'],
    domains: ['*.conversionlogic.net'],
  },
  {
    name: 'Convert Insights',
    categories: ['analytics'],
    domains: ['*.convertexperiments.com'],
  },
  {
    name: 'ConvertMedia',
    categories: ['ad'],
    domains: [
      '*.admailtiser.com',
      '*.basebanner.com',
      '*.cmbestsrv.com',
      '*.vidfuture.com',
      '*.zorosrv.com',
    ],
    examples: ['www.cmbestsrv.com'],
  },
  {
    name: 'Convertro',
    categories: ['ad'],
    domains: ['*.convertro.com'],
  },
  {
    name: 'Conviva',
    categories: ['content'],
    domains: ['*.conviva.com'],
  },
  {
    name: 'Cookie Reports',
    categories: ['utility'],
    domains: ['*.cookiereports.com'],
  },
  {
    name: 'Cookie-Script.com',
    categories: ['utility'],
    domains: ['*.cookie-script.com'],
  },
  {
    name: 'CookieQ',
    company: 'Baycloud Systems',
    categories: ['utility'],
    domains: ['*.cookieq.com'],
  },
  {
    name: 'CoolaData',
    categories: ['analytics'],
    domains: ['*.cooladata.com'],
  },
  {
    name: 'CopperEgg',
    categories: ['analytics'],
    domains: ['*.copperegg.com', 'd2vig74li2resi.cloudfront.net'],
  },
  {
    name: 'Council ad Network',
    categories: ['ad'],
    domains: ['*.counciladvertising.net'],
  },
  {
    name: 'Covert Pics',
    categories: ['content'],
    domains: ['*.covet.pics'],
  },
  {
    name: 'Cox Digital Solutions',
    categories: ['ad'],
    domains: ['*.afy11.net'],
  },
  {
    name: 'Creafi Online Media',
    categories: ['ad'],
    domains: ['*.creafi-online-media.com'],
  },
  {
    name: 'Creators',
    categories: ['content'],
    domains: ['*.creators.co'],
  },
  {
    name: 'Crimson Hexagon Analytics',
    company: 'Crimson Hexagon',
    categories: ['analytics'],
    domains: ['*.hexagon-analytics.com'],
  },
  {
    name: 'Crimtan',
    categories: ['ad'],
    domains: ['*.ctnsnet.com'],
  },
  {
    name: 'Cross Pixel Media',
    categories: ['ad'],
    domains: ['*.crsspxl.com'],
  },
  {
    name: 'Crosswise',
    categories: ['ad'],
    domains: ['*.univide.com'],
    examples: ['p.univide.com'],
  },
  {
    name: 'Crowd Control',
    company: 'Lotame',
    categories: ['ad'],
    domains: ['*.crwdcntrl.net'],
  },
  {
    name: 'Crowd Ignite',
    categories: ['ad'],
    domains: ['*.crowdignite.com'],
  },
  {
    name: 'CrowdTwist',
    categories: ['ad'],
    domains: ['*.crowdtwist.com'],
  },
  {
    name: 'Crowdskout',
    categories: ['ad'],
    domains: ['*.crowdskout.com'],
  },
  {
    name: 'Crowdynews',
    categories: ['social'],
    domains: ['*.breakingburner.com'],
  },
  {
    name: 'Curalate',
    categories: ['marketing'],
    domains: ['*.curalate.com', 'd116tqlcqfmz3v.cloudfront.net'],
  },
  {
    name: 'Customer Acquisition Cloud',
    company: '[24]7',
    categories: ['ad'],
    domains: ['*.campanja.com'],
  },
  {
    name: 'Customer.io',
    categories: ['ad'],
    domains: ['*.customer.io'],
  },
  {
    name: 'Custora',
    categories: ['analytics'],
    domains: ['*.custora.com'],
  },
  {
    name: 'Cxense',
    categories: ['ad'],
    domains: ['*.cxense.com', '*.cxpublic.com', '*.emediate.dk', '*.emediate.eu'],
  },
  {
    name: 'CyberKnight',
    company: 'Namogoo',
    categories: ['utility'],
    domains: ['*.namogoo.com'],
  },
  {
    name: 'CyberSource (Visa)',
    categories: ['utility'],
    domains: ['*.authorize.net'],
  },
  {
    name: 'Cybernet Quest',
    categories: ['analytics'],
    domains: ['*.cqcounter.com'],
  },
  {
    name: 'D.A. Consortium',
    categories: ['ad'],
    domains: ['*.eff1.net'],
  },
  {
    name: 'D4t4 Solutions',
    categories: ['analytics'],
    domains: ['*.u5e.com'],
  },
  {
    name: 'DCSL Software',
    categories: ['hosting'],
    domains: ['*.dcslsoftware.com'],
  },
  {
    name: 'DMG Media',
    categories: ['content'],
    domains: ['*.mol.im', '*.and.co.uk', '*.anm.co.uk', '*.dailymail.co.uk'],
  },
  {
    name: 'DTSCOUT',
    categories: ['ad'],
    domains: ['*.dtscout.com'],
  },
  {
    name: 'Dailymotion',
    categories: ['content'],
    domains: [
      '*.dailymotion.com',
      '*.dmxleo.com',
      '*.dm.gg',
      '*.pxlad.io',
      '*.dmcdn.net',
      '*.sublimevideo.net',
    ],
    examples: ['ad.pxlad.io', 'www.dailymotion.com'],
  },
  {
    name: 'Dash Hudson',
    company: 'Dash Hudson',
    categories: ['content'],
    domains: ['*.dashhudson.com'],
    examples: ['cdn.dashhudson.com'],
  },
  {
    name: 'Datacamp',
    categories: ['utility'],
    domains: ['*.cdn77.org'],
  },
  {
    name: 'Datalicious',
    categories: ['tag-manager'],
    domains: ['*.supert.ag', '*.optimahub.com'],
  },
  {
    name: 'Datalogix',
    categories: ['ad'],
    domains: ['*.nexac.com'],
  },
  {
    name: 'Datawrapper',
    categories: ['utility'],
    domains: ['*.datawrapper.de', '*.dwcdn.net'],
    examples: ['www.datawrapper.de'],
  },
  {
    name: 'Dataxu',
    categories: ['marketing'],
    domains: ['*.w55c.net'],
  },
  {
    name: 'Datonics',
    categories: ['ad'],
    domains: ['*.pro-market.net'],
    examples: ['pbid.pro-market.net'],
  },
  {
    name: 'Dealtime',
    categories: ['content'],
    domains: ['*.dealtime.com'],
  },
  {
    name: 'Debenhams Geo Location',
    company: 'Debenhams',
    categories: ['utility'],
    domains: ['176.74.183.134'],
  },
  {
    name: 'Decibel Insight',
    categories: ['analytics'],
    domains: ['*.decibelinsight.net'],
  },
  {
    name: 'Deep Forest Media',
    company: 'Rakuten',
    categories: ['ad'],
    domains: ['*.dpclk.com'],
  },
  {
    name: 'DeepIntent',
    categories: ['ad'],
    domains: ['*.deepintent.com'],
  },
  {
    name: 'Delicious Media',
    categories: ['social'],
    domains: ['*.delicious.com'],
  },
  {
    name: 'Delineo',
    categories: ['ad'],
    domains: ['*.delineo.com'],
    examples: ['www.delineo.com'],
  },
  {
    name: 'Delta Projects AB',
    categories: ['ad'],
    domains: ['*.de17a.com'],
  },
  {
    name: 'Demand Media',
    categories: ['content'],
    domains: ['*.dmtracker.com'],
  },
  {
    name: 'DemandBase',
    categories: ['marketing'],
    domains: ['*.demandbase.com'],
  },
  {
    name: 'DemandJump',
    categories: ['analytics'],
    domains: ['*.demandjump.com'],
  },
  {
    name: 'Dennis Publishing',
    categories: ['content'],
    domains: ['*.alphr.com'],
  },
  {
    name: 'Devatics',
    categories: ['analytics'],
    domains: ['*.devatics.com', '*.devatics.io'],
  },
  {
    name: 'Developer Media',
    categories: ['ad'],
    domains: ['*.developermedia.com'],
  },
  {
    name: 'DialogTech',
    categories: ['ad'],
    domains: ['*.dialogtech.com'],
  },
  {
    name: 'DialogTech SourceTrak',
    company: 'DialogTech',
    categories: ['ad'],
    domains: ['d31y97ze264gaa.cloudfront.net'],
  },
  {
    name: 'DigiCert',
    categories: ['utility'],
    domains: ['*.digicert.com'],
    examples: ['ocsp.digicert.com'],
  },
  {
    name: 'Digioh',
    categories: ['ad'],
    domains: ['*.lightboxcdn.com'],
  },
  {
    name: 'Digital Look',
    categories: ['content'],
    domains: ['*.digitallook.com'],
  },
  {
    name: 'Digital Media Exchange',
    company: 'NDN',
    categories: ['content'],
    domains: ['*.newsinc.com'],
  },
  {
    name: 'Digital Millennium Copyright Act Services',
    categories: ['utility'],
    domains: ['*.dmca.com'],
  },
  {
    name: 'Digital Ocean',
    categories: ['other'],
    domains: ['95.85.62.56'],
  },
  {
    name: 'Digital Remedy',
    categories: ['ad'],
    domains: ['*.consumedmedia.com'],
  },
  {
    name: 'Digital Window',
    categories: ['ad'],
    domains: ['*.awin1.com', '*.zenaps.com'],
  },
  {
    name: 'DigitalScirocco',
    categories: ['analytics'],
    domains: ['*.digitalscirocco.net'],
  },
  {
    name: 'Digitial Point',
    categories: ['utility'],
    domains: ['*.dpstatic.com'],
  },
  {
    name: 'Diligent (Adnetik)',
    categories: ['ad'],
    domains: ['*.wtp101.com'],
  },
  {
    name: 'Directed Edge',
    categories: ['social'],
    domains: ['*.directededge.com'],
    examples: ['shopify.directededge.com'],
  },
  {
    name: 'Distribute Travel',
    categories: ['ad'],
    domains: ['*.dtrck.net'],
  },
  {
    name: 'District M',
    categories: ['ad'],
    domains: ['*.districtm.io'],
  },
  {
    name: 'DistroScale',
    categories: ['ad'],
    domains: ['*.jsrdn.com'],
  },
  {
    name: 'Divido',
    categories: ['utility'],
    domains: ['*.divido.com'],
  },
  {
    name: 'Dow Jones',
    categories: ['content'],
    domains: ['*.dowjones.com', '*.dowjoneson.com'],
  },
  {
    name: 'Drifty Co',
    categories: ['utility'],
    domains: ['*.onicframework.com'],
  },
  {
    name: 'Drip',
    company: 'The Numa Group',
    categories: ['ad'],
    domains: ['*.getdrip.com'],
  },
  {
    name: 'Dropbox',
    categories: ['utility'],
    domains: ['*.dropboxusercontent.com'],
  },
  {
    name: 'Dyn Real User Monitoring',
    company: 'Dyn',
    categories: ['analytics'],
    domains: ['*.jisusaiche.biz', '*.dynapis.com', '*.jisusaiche.com', '*.dynapis.info'],
    examples: ['beacon.rum.dynapis.com'],
  },
  {
    name: 'DynAdmic',
    categories: ['ad'],
    domains: ['*.dyntrk.com'],
  },
  {
    name: 'Dynamic Converter',
    categories: ['utility'],
    domains: ['*.dynamicconverter.com'],
  },
  {
    name: 'Dynamic Dummy Image Generator',
    company: 'Open Source',
    categories: ['utility'],
    domains: ['*.dummyimage.com'],
  },
  {
    name: 'Dynamic Logic',
    categories: ['ad'],
    domains: ['*.dl-rms.com', '*.questionmarket.com'],
  },
  {
    name: 'Dynamic Yield',
    categories: ['customer-success'],
    domains: ['*.dynamicyield.com'],
  },
  {
    name: 'Dynatrace',
    categories: ['analytics'],
    domains: ['*.ruxit.com', 'js-cdn.dynatrace.com'],
  },
  {
    name: 'ECT News Network',
    categories: ['content'],
    domains: ['*.ectnews.com'],
  },
  {
    name: 'ELITechGroup',
    categories: ['analytics'],
    domains: ['*.elitechnology.com'],
  },
  {
    name: 'EMAP',
    categories: ['content'],
    domains: ['*.emap.com'],
  },
  {
    name: 'EMedia Solutions',
    categories: ['ad'],
    domains: ['*.e-shots.eu'],
    examples: ['www.e-shots.eu'],
  },
  {
    name: 'EQ works',
    categories: ['ad'],
    domains: ['*.eqads.com'],
  },
  {
    name: 'ESV Digital',
    categories: ['analytics'],
    domains: ['*.esearchvision.com'],
  },
  {
    name: 'Ebiquity',
    categories: ['analytics'],
    domains: ['*.ebiquitymedia.com'],
  },
  {
    name: 'Eco Rebates',
    categories: ['ad'],
    domains: ['*.ecorebates.com'],
  },
  {
    name: 'Ecwid',
    categories: ['hosting'],
    domains: [
      '*.ecwid.com',
      '*.shopsettings.com',
      'd3fi9i0jj23cau.cloudfront.net',
      'd3j0zfs7paavns.cloudfront.net',
    ],
  },
  {
    name: 'Edge Web Fonts',
    company: 'Adobe Systems',
    categories: ['cdn'],
    domains: ['*.edgefonts.net'],
    examples: ['use.edgefonts.net'],
  },
  {
    name: 'Edition Digital',
    categories: ['ad'],
    domains: ['*.editiondigital.com'],
  },
  {
    name: 'Edot Web Technologies',
    categories: ['hosting'],
    domains: ['*.edot.co.za'],
  },
  {
    name: 'Effective Measure',
    categories: ['ad'],
    domains: ['*.effectivemeasure.net'],
  },
  {
    name: 'Effiliation sa',
    categories: ['ad'],
    domains: ['*.effiliation.com'],
  },
  {
    name: 'Ekm Systems',
    categories: ['analytics'],
    domains: ['*.ekmsecure.com', '*.ekmpinpoint.co.uk'],
    examples: ['globalstats.ekmsecure.com'],
  },
  {
    name: 'Elastera',
    categories: ['hosting'],
    domains: ['*.elastera.net'],
  },
  {
    name: 'Elastic Ad',
    categories: ['ad'],
    domains: ['*.elasticad.net'],
  },
  {
    name: 'Elastic Load Balancing',
    company: 'Amazon Web Services',
    categories: ['hosting'],
    domains: ['*.105app.com'],
    examples: ['rhpury.105app.com', 'rhxtd.105app.com'],
  },
  {
    name: 'Elecard StreamEye',
    company: 'Elecard',
    categories: ['other'],
    domains: ['*.streameye.net'],
  },
  {
    name: 'Elevate',
    company: 'Elevate Technology Solutions',
    categories: ['utility'],
    domains: ['*.elevaate.technology'],
  },
  {
    name: 'Elicit',
    categories: ['utility'],
    domains: ['*.elicitapp.com'],
  },
  {
    name: 'Elogia',
    categories: ['ad'],
    domains: ['*.elogia.net'],
  },
  {
    name: 'Email Attitude',
    company: '1000mercis',
    categories: ['ad'],
    domains: ['*.email-attitude.com'],
  },
  {
    name: 'EmailCenter',
    categories: ['ad'],
    domains: ['*.emailcenteruk.com'],
  },
  {
    name: 'Embedly',
    categories: ['content'],
    domains: ['*.embedly.com', '*.embed.ly'],
  },
  {
    name: 'EmpathyBroker Site Search',
    company: 'EmpathyBroker',
    categories: ['utility'],
    domains: ['*.empathybroker.com'],
  },
  {
    name: 'Enfusen',
    categories: ['analytics'],
    domains: ['*.enfusen.com'],
  },
  {
    name: 'Engadget',
    company: 'Engadget (AOL)',
    categories: ['content'],
    domains: ['*.gdgt.com'],
    examples: ['media.gdgt.com'],
  },
  {
    name: 'Engagio',
    categories: ['marketing'],
    domains: ['*.engagio.com'],
  },
  {
    name: 'Ensighten Manage',
    company: 'Ensighten',
    categories: ['tag-manager'],
    domains: ['*.levexis.com'],
  },
  {
    name: 'EntityLink',
    categories: ['other'],
    domains: ['*.entitytag.co.uk'],
  },
  {
    name: 'Entrust Datacard',
    categories: ['utility'],
    domains: ['*.entrust.com', '*.entrust.net'],
    examples: ['ocsp.entrust.com', 'ocsp.entrust.net'],
  },
  {
    name: 'Equiniti',
    categories: ['utility'],
    domains: ['*.equiniti.com'],
  },
  {
    name: 'Errorception',
    categories: ['utility'],
    domains: ['*.errorception.com'],
  },
  {
    name: 'Esri ArcGIS',
    company: 'Esri',
    categories: ['utility'],
    domains: ['*.arcgis.com', '*.arcgisonline.com'],
  },
  {
    name: 'Ethnio',
    categories: ['analytics'],
    domains: ['*.ethn.io'],
  },
  {
    name: 'Eulerian Technologies',
    categories: ['ad'],
    domains: ['*.eolcdn.com'],
  },
  {
    name: 'Euroland',
    categories: ['utility'],
    domains: ['*.euroland.com'],
  },
  {
    name: 'European Interactive Digital ad Alli',
    categories: ['utility'],
    domains: ['*.edaa.eu'],
  },
  {
    name: 'Eventbrite',
    categories: ['hosting'],
    domains: ['*.evbuc.com', '*.eventbrite.co.uk'],
  },
  {
    name: 'Evergage',
    categories: ['analytics'],
    domains: ['*.evergage.com', '*.evgnet.com'],
    examples: ['cdn.evgnet.com'],
  },
  {
    name: 'Everquote',
    categories: ['content'],
    domains: ['*.evq1.com'],
  },
  {
    name: 'Everyday Health',
    categories: ['ad'],
    domains: ['*.agoramedia.com'],
  },
  {
    name: 'Evidon',
    categories: ['analytics'],
    domains: ['*.evidon.com'],
  },
  {
    name: 'Evolve Media',
    categories: ['content'],
    domains: ['*.evolvemediallc.com'],
  },
  {
    name: 'Exactag',
    categories: ['ad'],
    domains: ['*.exactag.com'],
  },
  {
    name: 'ExoClick',
    categories: ['ad'],
    domains: ['*.exoclick.com'],
  },
  {
    name: 'Expedia',
    categories: ['content'],
    domains: ['*.travel-assets.com', '*.trvl-media.com', '*.trvl-px.com', '*.uciservice.com'],
    examples: ['www.trvl-px.com', 'www.uciservice.com'],
  },
  {
    name: 'Expedia Australia',
    company: 'Expedia',
    categories: ['content'],
    domains: ['*.expedia.com.au'],
    examples: ['www.expedia.com.au'],
  },
  {
    name: 'Expedia Canada',
    company: 'Expedia',
    categories: ['content'],
    domains: ['*.expedia.ca'],
    examples: ['www.expedia.ca'],
  },
  {
    name: 'Expedia France',
    company: 'Expedia',
    categories: ['content'],
    domains: ['*.expedia.fr'],
    examples: ['www.expedia.fr'],
  },
  {
    name: 'Expedia Germany',
    company: 'Expedia',
    categories: ['content'],
    domains: ['*.expedia.de'],
    examples: ['www.expedia.de'],
  },
  {
    name: 'Expedia Italy',
    company: 'Expedia',
    categories: ['content'],
    domains: ['*.expedia.it'],
    examples: ['www.expedia.it'],
  },
  {
    name: 'Expedia Japan',
    company: 'Expedia',
    categories: ['content'],
    domains: ['*.expedia.co.jp'],
    examples: ['www.expedia.co.jp'],
  },
  {
    name: 'Expedia USA',
    company: 'Expedia',
    categories: ['content'],
    domains: ['*.expedia.com'],
    examples: ['www.expedia.com'],
  },
  {
    name: 'Expedia United Kingdom',
    company: 'Expedia',
    categories: ['content'],
    domains: ['*.expedia.co.uk'],
    examples: ['www.expedia.co.uk'],
  },
  {
    name: 'Experian',
    categories: ['utility'],
    domains: ['*.audienceiq.com', '*.experian.com', '*.experianmarketingservices.digital'],
  },
  {
    name: 'Experian Cross-Channel Marketing Platform',
    company: 'Experian',
    categories: ['marketing'],
    domains: ['*.eccmp.com', '*.ccmp.eu'],
  },
  {
    name: 'Exponea',
    categories: ['analytics'],
    domains: ['*.exponea.com'],
  },
  {
    name: 'Exponential Interactive',
    categories: ['ad'],
    domains: ['*.exponential.com'],
  },
  {
    name: 'Extensis WebInk',
    categories: ['cdn'],
    domains: ['*.webink.com'],
  },
  {
    name: 'Extole',
    categories: ['ad'],
    domains: ['*.extole.com', '*.extole.io'],
    examples: ['origin.extole.io'],
  },
  {
    name: 'Ey-Seren',
    categories: ['analytics'],
    domains: ['*.webabacus.com'],
  },
  {
    name: 'EyeView',
    categories: ['ad'],
    domains: ['*.eyeviewads.com'],
  },
  {
    name: 'Eyeota',
    categories: ['ad'],
    domains: ['*.eyeota.net'],
  },
  {
    name: 'Ezakus Pretargeting',
    company: 'Ezakus',
    categories: ['ad'],
    domains: ['*.ezakus.net'],
  },
  {
    name: 'Ezoic',
    categories: ['analytics'],
    domains: ['*.ezoic.net'],
  },
  {
    name: 'FLXone',
    company: 'Teradata',
    categories: ['ad'],
    domains: ['*.pangolin.blue', '*.flx1.com', 'd2hlpp31teaww3.cloudfront.net', '*.flxpxl.com'],
  },
  {
    name: 'Fairfax Media',
    categories: ['content'],
    domains: ['ads.fairfax.com.au', 'resources.fairfax.com.au'],
  },
  {
    name: 'Fairfax Media Analtics',
    company: 'Fairfax Media',
    categories: ['analytics'],
    domains: ['analytics.fairfax.com.au'],
  },
  {
    name: 'Falk Technologies',
    categories: ['ad'],
    domains: ['*.angsrvr.com'],
  },
  {
    name: 'Fanplayr',
    categories: ['analytics'],
    domains: ['*.fanplayr.com', 'd38nbbai6u794i.cloudfront.net'],
  },
  {
    name: 'Fast Thinking',
    company: 'NE Marketing',
    categories: ['marketing'],
    domains: ['*.fast-thinking.co.uk'],
  },
  {
    name: 'Fastest Forward',
    categories: ['analytics'],
    domains: ['*.gaug.es'],
  },
  {
    name: 'Fastly',
    categories: ['utility'],
    domains: ['*.fastly.net'],
  },
  {
    name: 'Feedbackify',
    company: 'InsideMetrics',
    categories: ['analytics'],
    domains: ['*.feedbackify.com'],
  },
  {
    name: 'Feefo.com',
    company: 'Feefo',
    categories: ['analytics'],
    domains: ['*.feefo.com'],
  },
  {
    name: 'Fidelity Media',
    categories: ['ad'],
    domains: ['*.fidelity-media.com'],
    examples: ['x.fidelity-media.com'],
  },
  {
    name: 'Filestack',
    categories: ['content'],
    domains: ['*.filepicker.io'],
    examples: ['api.filepicker.io', 'dialog.filepicker.io', 'www.filepicker.io'],
  },
  {
    name: 'Finsbury Media',
    categories: ['ad'],
    domains: ['*.finsburymedia.com'],
  },
  {
    name: 'Firepush',
    categories: ['utility'],
    domains: ['*.firepush.io'],
  },
  {
    name: 'FirstImpression',
    categories: ['ad'],
    domains: ['*.firstimpression.io'],
  },
  {
    name: 'Fits Me',
    categories: ['analytics'],
    domains: ['*.fits.me'],
  },
  {
    name: 'Fivetran',
    categories: ['analytics'],
    domains: ['*.fivetran.com'],
  },
  {
    name: 'FlexShopper',
    categories: ['utility'],
    domains: ['*.flexshopper.com'],
  },
  {
    name: 'Flickr',
    categories: ['content'],
    domains: ['*.flickr.com', '*.staticflickr.com'],
  },
  {
    name: 'Flipboard',
    categories: ['social'],
    domains: ['*.flipboard.com'],
  },
  {
    name: 'Flipp',
    categories: ['analytics'],
    domains: ['*.wishabi.com', 'd2e0sxz09bo7k2.cloudfront.net', '*.wishabi.net'],
  },
  {
    name: 'Flite',
    categories: ['ad'],
    domains: ['*.flite.com'],
  },
  {
    name: 'Flixmedia',
    categories: ['analytics'],
    domains: [
      '*.flix360.com',
      '*.flixcar.com',
      '*.flixfacts.com',
      '*.flixsyndication.net',
      '*.flixfacts.co.uk',
    ],
  },
  {
    name: 'Flockler',
    categories: ['ad'],
    domains: ['*.flockler.com'],
  },
  {
    name: 'Flowplayer',
    categories: ['content'],
    domains: ['*.flowplayer.org'],
  },
  {
    name: 'Flowzymes Ky',
    categories: ['cdn'],
    domains: ['*.jquerytools.org'],
  },
  {
    name: 'Fomo',
    categories: ['ad'],
    domains: ['*.notifyapp.io'],
  },
  {
    name: 'Fonecall',
    categories: ['analytics'],
    domains: ['*.web-call-analytics.com'],
  },
  {
    name: 'Fontdeck',
    categories: ['cdn'],
    domains: ['*.fontdeck.com'],
  },
  {
    name: 'Foodity Technologies',
    categories: ['ad'],
    domains: ['*.foodity.com'],
  },
  {
    name: 'Force24',
    categories: ['ad'],
    domains: ['*.force24.co.uk'],
  },
  {
    name: 'ForeSee',
    company: 'Answers',
    categories: ['analytics'],
    domains: ['*.4seeresults.com', '*.answerscloud.com', '*.foresee.com', '*.foreseeresults.com'],
  },
  {
    name: 'Forensiq',
    categories: ['utility'],
    domains: ['*.fqtag.com'],
  },
  {
    name: 'Fort Awesome',
    categories: ['cdn'],
    domains: ['*.fortawesome.com'],
  },
  {
    name: 'Forter',
    categories: ['utility'],
    domains: ['*.forter.com'],
  },
  {
    name: 'Forward Internet Group',
    categories: ['hosting'],
    domains: ['*.f3d.io'],
  },
  {
    name: 'Forward3D',
    categories: ['ad'],
    domains: ['*.forward3d.com'],
  },
  {
    name: 'Fospha',
    categories: ['analytics'],
    domains: ['*.fospha.com'],
    examples: ['router.fospha.com'],
  },
  {
    name: 'Foursixty',
    categories: ['customer-success'],
    domains: ['*.foursixty.com'],
  },
  {
    name: 'FoxyCart',
    categories: ['utility'],
    domains: ['*.foxycart.com'],
  },
  {
    name: 'Fraudlogix',
    categories: ['utility'],
    domains: ['*.yabidos.com'],
  },
  {
    name: 'FreakOut',
    categories: ['ad'],
    domains: ['*.fout.jp'],
  },
  {
    name: 'Freespee',
    categories: ['customer-success'],
    domains: ['*.freespee.com'],
    examples: ['analytics.freespee.com'],
  },
  {
    name: 'Freetobook',
    categories: ['content'],
    domains: ['*.freetobook.com'],
    examples: ['www.freetobook.com'],
  },
  {
    name: 'Fresh 8 Gaming',
    categories: ['ad'],
    domains: ['*.fresh8.co'],
  },
  {
    name: 'Fresh Relevance',
    categories: ['analytics'],
    domains: [
      '*.freshrelevance.com',
      '*.cloudfront.ne',
      'd1y9qtn9cuc3xw.cloudfront.net',
      'd81mfvml8p5ml.cloudfront.net',
      'dkpklk99llpj0.cloudfront.net',
    ],
    examples: ['d1y9qtn9cuc3xw.cloudfront.ne'],
  },
  {
    name: 'Friendbuy',
    categories: ['ad'],
    domains: ['*.friendbuy.com', 'djnf6e5yyirys.cloudfront.net'],
  },
  {
    name: 'Frienefit',
    categories: ['ad'],
    domains: ['*.frienefit.com'],
  },
  {
    name: 'FuelX',
    categories: ['ad'],
    domains: ['*.fuelx.com'],
  },
  {
    name: 'Full Circle Studies',
    categories: ['analytics'],
    domains: ['*.securestudies.com'],
  },
  {
    name: 'FullStory',
    categories: ['analytics'],
    domains: ['*.fullstory.com'],
    examples: ['rs.fullstory.com'],
  },
  {
    name: 'Fyber',
    categories: ['ad'],
    domains: ['*.fyber.com'],
  },
  {
    name: 'G-Forces Web Management',
    categories: ['hosting'],
    domains: ['*.gforcesinternal.co.uk'],
  },
  {
    name: 'G4 Native',
    company: 'Gravity4',
    categories: ['ad'],
    domains: ['*.triggit.com'],
  },
  {
    name: 'GET ME IN!  (TicketMaster)',
    categories: ['content'],
    domains: ['*.getmein.com'],
  },
  {
    name: 'GIPHY',
    categories: ['content'],
    domains: ['*.giphy.com'],
  },
  {
    name: 'GainCloud',
    company: 'GainCloud Systems',
    categories: ['other'],
    domains: ['*.egaincloud.net'],
  },
  {
    name: 'Gath Adams',
    categories: ['content'],
    domains: ['*.iwantthatflight.com.au'],
  },
  {
    name: 'Gecko Tribe',
    categories: ['social'],
    domains: ['*.geckotribe.com'],
  },
  {
    name: 'Gemius',
    categories: ['ad'],
    domains: ['*.gemius.pl'],
  },
  {
    name: 'Genesis Media',
    categories: ['ad'],
    domains: ['*.bzgint.com', '*.genesismedia.com', '*.genesismediaus.com'],
  },
  {
    name: 'Genie Ventures',
    categories: ['ad'],
    domains: ['*.genieventures.co.uk'],
  },
  {
    name: 'Geniee',
    categories: ['ad'],
    domains: ['*.href.asia', '*.genieessp.jp', '*.genieesspv.jp', '*.gssprt.jp'],
    examples: ['cs.gssprt.jp'],
  },
  {
    name: 'Geniuslink',
    categories: ['analytics'],
    domains: ['*.geni.us'],
  },
  {
    name: 'GeoRiot',
    categories: ['other'],
    domains: ['*.georiot.com'],
  },
  {
    name: 'GeoTrust',
    categories: ['utility'],
    domains: ['*.geotrust.com'],
  },
  {
    name: 'Geoplugin',
    categories: ['utility'],
    domains: ['*.geoplugin.com', '*.geoplugin.net'],
  },
  {
    name: 'Georeferencer',
    company: 'Klokan Technologies',
    categories: ['utility'],
    domains: ['*.georeferencer.com'],
  },
  {
    name: 'GetIntent RTBSuite',
    company: 'GetIntent',
    categories: ['ad'],
    domains: ['*.adhigh.net'],
  },
  {
    name: 'GetResponse',
    categories: ['ad'],
    domains: ['*.getresponse.com'],
  },
  {
    name: 'GetSiteControl',
    company: 'GetWebCraft',
    categories: ['utility'],
    domains: ['*.getsitecontrol.com'],
  },
  {
    name: 'GetSocial',
    categories: ['social'],
    domains: ['*.getsocial.io'],
  },
  {
    name: 'Getty Images',
    categories: ['content'],
    domains: ['*.gettyimages.com', '*.gettyimages.co.uk'],
    examples: ['www.gettyimages.com'],
  },
  {
    name: 'Gfycat',
    company: 'Gycat',
    categories: ['utility'],
    domains: ['*.gfycat.com'],
  },
  {
    name: 'Ghostery Enterprise',
    company: 'Ghostery',
    categories: ['marketing'],
    domains: ['*.betrad.com'],
  },
  {
    name: 'Giant Media',
    categories: ['ad'],
    domains: ['*.videostat.com'],
  },
  {
    name: 'Gigya',
    categories: ['analytics'],
    domains: ['*.gigya.com'],
  },
  {
    name: 'GitHub',
    categories: ['utility'],
    domains: ['*.github.com', '*.githubusercontent.com', '*.github.io', '*.rawgit.com'],
    examples: ['raw.githubusercontent.com', 'cdn.rawgit.com'],
  },
  {
    name: 'Glassdoor',
    categories: ['content'],
    domains: ['*.glassdoor.com'],
  },
  {
    name: 'Gleam',
    categories: ['marketing'],
    domains: ['*.gleam.io'],
  },
  {
    name: 'Global Digital Markets',
    categories: ['ad'],
    domains: ['*.gdmdigital.com'],
  },
  {
    name: 'Global-e',
    categories: ['hosting'],
    domains: ['*.global-e.com'],
  },
  {
    name: 'GlobalSign',
    categories: ['utility'],
    domains: ['*.globalsign.com', '*.globalsign.net'],
  },
  {
    name: 'GlobalWebIndex',
    categories: ['analytics'],
    domains: ['*.globalwebindex.net'],
  },
  {
    name: 'Globase International',
    categories: ['ad'],
    domains: ['*.globase.com'],
  },
  {
    name: 'GoDataFeed',
    categories: ['other'],
    domains: ['*.godatafeed.com'],
  },
  {
    name: 'Google APIs',
    company: 'Google',
    categories: ['utility'],
    domains: ['googleapis.com'],
  },
  {
    name: 'Google Ad Block Detection',
    company: 'Google',
    categories: ['ad'],
    domains: ['*.0emn.com', '*.0fmm.com'],
  },
  {
    name: 'Google Analytics Experiments',
    company: 'Google',
    categories: ['analytics'],
    domains: ['*.gexperiments1.com'],
  },
  {
    name: 'Google DoubleClick Ad Exchange',
    company: 'Google',
    categories: ['ad'],
    domains: ['*.admeld.com'],
  },
  {
    name: 'Google IPV6 Metrics',
    company: 'Google',
    categories: ['analytics'],
    domains: ['*.ipv6test.net'],
  },
  {
    name: 'Google Plus',
    company: 'Google',
    categories: ['social'],
    domains: ['plus.google.com'],
  },
  {
    name: 'Google Trusted Stores',
    company: 'Google',
    categories: ['utility'],
    domains: ['*.googlecommerce.com'],
  },
  {
    name: 'Google Video',
    company: 'Google',
    categories: ['content'],
    domains: ['*.googlevideo.com'],
  },
  {
    name: 'Google reCAPTCHA',
    company: 'Google',
    categories: ['utility'],
    domains: ['*.recaptcha.net'],
    examples: ['api.recaptcha.net'],
  },
  {
    name: 'GovMetric',
    company: 'ROL Solutions',
    categories: ['analytics'],
    domains: ['*.govmetric.com'],
  },
  {
    name: 'Granify',
    categories: ['analytics'],
    domains: ['*.granify.com'],
  },
  {
    name: 'Grapeshot',
    categories: ['ad'],
    domains: ['*.gscontxt.net', '*.grapeshot.co.uk'],
  },
  {
    name: 'Gravity (AOL)',
    categories: ['analytics'],
    domains: ['*.grvcdn.com'],
  },
  {
    name: 'Groovy Gecko',
    categories: ['content'],
    domains: ['*.ggwebcast.com', '*.groovygecko.net'],
  },
  {
    name: 'GroupM',
    categories: ['ad'],
    domains: ['*.qservz.com'],
  },
  {
    name: 'Guardian Media',
    categories: ['ad'],
    domains: ['*.theguardian.com', '*.guardian.co.uk'],
    examples: ['oas.theguardian.com'],
  },
  {
    name: 'GumGum',
    categories: ['ad'],
    domains: ['*.gumgum.com'],
  },
  {
    name: 'Gumtree',
    categories: ['content'],
    domains: ['*.gumtree.com'],
  },
  {
    name: 'H264 Codec',
    company: 'Cisco',
    categories: ['other'],
    domains: ['*.openh264.org'],
  },
  {
    name: 'HERE',
    categories: ['analytics'],
    domains: ['*.medio.com'],
  },
  {
    name: 'HP Optimost',
    company: 'Hewlett-Packard Development Company',
    categories: ['marketing'],
    domains: ['*.hp.com', 'd2uncb19xzxhzx.cloudfront.net'],
    examples: ['by.marketinghub.hp.com', 'marketinghub.hp.com'],
  },
  {
    name: 'Has Offers',
    company: 'TUNE',
    categories: ['ad'],
    domains: ['*.go2cloud.org'],
  },
  {
    name: 'Hawk Search',
    categories: ['utility'],
    domains: ['*.hawksearch.com'],
  },
  {
    name: 'Haymarket Media Group',
    categories: ['content'],
    domains: ['*.brandrepublic.com', '*.hbpl.co.uk'],
  },
  {
    name: 'Heap',
    categories: ['analytics'],
    domains: ['*.heapanalytics.com'],
  },
  {
    name: 'Hearst Communications',
    categories: ['content'],
    domains: [
      '*.h-cdn.co',
      '*.hearstdigital.com',
      '*.hearstlabs.com',
      '*.hearst.io',
      '*.cdnds.net',
    ],
  },
  {
    name: 'Heatmap',
    categories: ['analytics'],
    domains: ['*.heatmap.it'],
  },
  {
    name: 'Heroku',
    categories: ['other'],
    domains: ['*.herokuapp.com'],
  },
  {
    name: 'Hexton',
    categories: ['utility'],
    domains: ['*.hextom.com'],
  },
  {
    name: 'Hibernia Networks',
    categories: ['utility'],
    domains: ['*.hiberniacdn.com'],
  },
  {
    name: 'High Impact Media',
    categories: ['ad'],
    domains: ['*.reactx.com'],
  },
  {
    name: 'Highcharts',
    categories: ['utility'],
    domains: ['*.highcharts.com'],
  },
  {
    name: 'Highwinds',
    categories: ['utility'],
    domains: ['*.hwcdn.net'],
  },
  {
    name: 'HitsLink',
    categories: ['analytics'],
    domains: ['*.hitslink.com'],
  },
  {
    name: 'Hola Networks',
    categories: ['other'],
    domains: ['*.h-cdn.com'],
  },
  {
    name: 'Hootsuite',
    categories: ['analytics'],
    domains: ['*.hootsuite.com'],
  },
  {
    name: 'HotUKDeals',
    categories: ['analytics'],
    domains: ['*.hotukdeals.com'],
  },
  {
    name: 'HotWords',
    company: 'Media Response Group',
    categories: ['ad'],
    domains: ['*.hotwords.com.br'],
  },
  {
    name: 'HotelsCombined',
    categories: ['content'],
    domains: ['*.datahc.com'],
  },
  {
    name: 'Hoverr',
    categories: ['ad'],
    domains: ['*.hoverr.media'],
  },
  {
    name: 'Hull.js',
    categories: ['utility'],
    domains: ['*.hull.io', '*.hullapp.io'],
  },
  {
    name: 'Hupso Website Analyzer',
    company: 'Hupso',
    categories: ['analytics'],
    domains: ['*.hupso.com'],
  },
  {
    name: 'I-Behavior',
    company: 'WPP',
    categories: ['ad'],
    domains: ['*.ib-ibi.com'],
  },
  {
    name: 'i-mobile',
    company: 'i-mobile',
    categories: ['ad'],
    domains: ['*.i-mobile.co.jp'],
    examples: ['ssp-sync.i-mobile.co.jp'],
  },
  {
    name: 'IBM Digital Analytics',
    company: 'IBM',
    categories: ['analytics'],
    domains: [
      '*.cmcore.com',
      'coremetrics.com',
      'data.coremetrics.com',
      'data.de.coremetrics.com',
      'libs.de.coremetrics.com',
      'tmscdn.de.coremetrics.com',
      'iocdn.coremetrics.com',
      'libs.coremetrics.com',
      'tmscdn.coremetrics.com',
      '*.s81c.com',
      '*.unica.com',
      '*.coremetrics.eu',
    ],
    examples: ['data.coremetrics.eu'],
  },
  {
    name: 'IBM Digital Data Exchange',
    company: 'IBM',
    categories: ['tag-manager'],
    domains: ['tagmanager.coremetrics.com'],
  },
  {
    name: 'IBM Tealeaf',
    company: 'IBM',
    categories: ['analytics'],
    domains: ['*.ibmcloud.com'],
    examples: ['uscollector.tealeaf.ibmcloud.com'],
  },
  {
    name: 'IBM Acoustic Campaign',
    company: 'IBM',
    categories: ['analytics'],
    domains: [
      'www.sc.pages01.net',
      'www.sc.pages02.net',
      'www.sc.pages03.net',
      'www.sc.pages04.net',
      'www.sc.pages05.net',
      'www.sc.pages06.net',
      'www.sc.pages07.net',
      'www.sc.pages08.net',
      'www.sc.pages09.net',
      'www.sc.pagesA.net',
    ],
    examples: ['https://www.sc.pages01.net/lp/static/js/iMAWebCookie.js'],
  },
  {
    name: 'ICF Technology',
    categories: ['content'],
    domains: ['*.camads.net'],
  },
  {
    name: 'IFDNRG',
    categories: ['hosting'],
    domains: ['*.ifdnrg.com'],
  },
  {
    name: 'IMRG',
    categories: ['analytics'],
    domains: ['*.peermap.com', '*.imrg.org'],
    examples: ['benchmarking.imrg.org'],
  },
  {
    name: 'IPONWEB',
    categories: ['ad'],
    domains: ['*.company-target.com', '*.liadm.com', '*.iponweb.net', '*.p161.net'],
    examples: ['pool.udsp.iponweb.net'],
  },
  {
    name: 'IQ Mobile',
    categories: ['utility'],
    domains: ['*.iqm.cc'],
  },
  {
    name: 'IS Group',
    categories: ['hosting'],
    domains: ['*.creative-serving.com'],
  },
  {
    name: 'IT Dienstleistungen Tim Prinzkosky',
    categories: ['utility'],
    domains: ['*.flaticons.net'],
  },
  {
    name: 'IXI Digital',
    company: 'Equifax',
    categories: ['ad'],
    domains: ['*.ixiaa.com'],
  },
  {
    name: 'IcoMoon',
    categories: ['cdn'],
    domains: ['d19ayerf5ehaab.cloudfront.net', 'd1azc1qln24ryf.cloudfront.net'],
  },
  {
    name: 'IdenTrust',
    categories: ['utility'],
    domains: ['*.identrust.com'],
  },
  {
    name: 'Ido',
    categories: ['customer-success'],
    domains: ['*.idio.co'],
  },
  {
    name: 'Ignition One',
    categories: ['marketing'],
    domains: ['*.searchignite.com'],
  },
  {
    name: 'ImageShack',
    categories: ['content'],
    domains: ['*.yfrog.com'],
  },
  {
    name: 'Imagen Studio',
    categories: ['utility'],
    domains: ['*.telephonesky.com'],
  },
  {
    name: 'Imagini Holdings',
    categories: ['ad'],
    domains: ['*.vdna-assets.com'],
  },
  {
    name: 'Img Safe',
    categories: ['content'],
    domains: ['*.imgsafe.org'],
  },
  {
    name: 'Imgur',
    categories: ['utility'],
    domains: ['*.imgur.com'],
  },
  {
    name: 'Impact Radius',
    categories: ['ad'],
    domains: [
      '*.impactradius-event.com',
      '*.impactradius-go.com',
      '*.7eer.net',
      'd3cxv97fi8q177.cloudfront.net',
      '*.evyy.net',
      '*.ojrq.net',
    ],
    examples: ['a.impactradius-go.com', 'microsoft-uk.evyy.net'],
  },
  {
    name: 'Improve Digital',
    categories: ['ad'],
    domains: ['*.360yield.com'],
  },
  {
    name: 'Improvely',
    categories: ['analytics'],
    domains: ['*.iljmp.com'],
  },
  {
    name: 'InMobi',
    categories: ['ad'],
    domains: ['*.inmobi.com'],
  },
  {
    name: 'InSkin Media',
    categories: ['ad'],
    domains: ['*.inskinad.com', '*.inskinmedia.com'],
  },
  {
    name: 'Inbenta',
    categories: ['customer-success'],
    domains: ['*.inbenta.com'],
  },
  {
    name: 'Incisive Media',
    categories: ['content'],
    domains: ['*.incisivemedia.com'],
  },
  {
    name: 'Indeed',
    categories: ['content'],
    domains: ['*.indeed.com'],
  },
  {
    name: 'Index Exchange',
    company: 'WPP',
    categories: ['ad'],
    domains: ['*.casalemedia.com', '*.indexww.com'],
  },
  {
    name: 'Indoona',
    categories: ['other'],
    domains: ['*.indoona.com'],
  },
  {
    name: 'Infectious Media',
    categories: ['ad'],
    domains: ['*.impdesk.com', '*.impressiondesk.com', '*.inmz.net'],
  },
  {
    name: 'Inference Mobile',
    categories: ['ad'],
    domains: ['*.inferencemobile.com'],
  },
  {
    name: 'Infinity Tracking',
    categories: ['analytics'],
    domains: ['*.infinity-tracking.net'],
  },
  {
    name: 'Infoline',
    categories: ['analytics'],
    domains: ['*.ioam.de'],
  },
  {
    name: 'Infolinks',
    categories: ['ad'],
    domains: ['*.infolinks.com'],
  },
  {
    name: 'Infopark',
    categories: ['hosting'],
    domains: ['*.scrvt.com'],
  },
  {
    name: 'Infusionsoft',
    categories: ['ad'],
    domains: ['*.infusionsoft.com'],
  },
  {
    name: 'Ink',
    categories: ['ad'],
    domains: ['*.inktad.com'],
  },
  {
    name: 'Inktel Contact Center Solutions',
    company: 'Inktel',
    categories: ['customer-success'],
    domains: ['*.inktel.com'],
  },
  {
    name: 'Inneractive',
    categories: ['ad'],
    domains: ['*.inner-active.mobi'],
  },
  {
    name: 'Innovid',
    categories: ['ad'],
    homepage: 'https://www.innovid.com/',
    domains: ['*.innovid.com'],
    examples: ['ag.innovid.com', 'rtr.innovid.com'],
  },
  {
    name: 'Insight Express',
    categories: ['analytics'],
    domains: ['*.insightexpressai.com'],
  },
  {
    name: 'Insipio',
    categories: ['other'],
    domains: ['*.insipio.com'],
  },
  {
    name: 'Inspectlet',
    categories: ['analytics'],
    domains: ['*.inspectlet.com'],
  },
  {
    name: 'Instansive',
    categories: ['utility'],
    domains: ['*.instansive.com'],
  },
  {
    name: 'Instart',
    homepage: 'https://www.instart.com/',
    categories: ['utility'],
    domains: ['*.insnw.net'],
  },
  {
    name: 'Instembedder',
    categories: ['content'],
    domains: ['*.instaembedder.com'],
  },
  {
    name: 'Instinctive',
    categories: ['ad'],
    domains: ['*.instinctiveads.com'],
  },
  {
    name: 'Intelligent Reach',
    categories: ['ad'],
    domains: ['*.ist-track.com'],
  },
  {
    name: 'Intent HQ',
    categories: ['analytics'],
    domains: ['*.intenthq.com'],
  },
  {
    name: 'Intent IQ',
    categories: ['ad'],
    domains: ['*.intentiq.com'],
  },
  {
    name: 'Intercept Interactive',
    categories: ['ad'],
    domains: ['*.undertone.com'],
  },
  {
    name: 'Interest Graph',
    company: 'AOL',
    categories: ['ad'],
    domains: ['*.gravity.com'],
  },
  {
    name: 'Internet Brands',
    categories: ['content'],
    domains: ['*.ibpxl.com'],
  },
  {
    name: 'Interpublic Group',
    categories: ['ad'],
    domains: ['*.mbww.com'],
  },
  {
    name: 'Interstate',
    categories: ['analytics'],
    domains: ['*.interstateanalytics.com'],
  },
  {
    name: 'Interview',
    categories: ['analytics'],
    domains: ['*.efm.me'],
  },
  {
    name: 'Intilery',
    categories: ['customer-success'],
    domains: ['*.intilery-analytics.com'],
  },
  {
    name: 'Investis',
    categories: ['utility'],
    domains: ['*.investis.com'],
  },
  {
    name: 'Investis Flife',
    categories: ['hosting'],
    domains: ['*.quartalflife.com'],
  },
  {
    name: 'Invodo',
    categories: ['ad'],
    domains: ['*.invodo.com'],
    examples: ['e.invodo.com'],
  },
  {
    name: 'Issue',
    categories: ['content'],
    domains: ['*.issue.by'],
  },
  {
    name: 'J.D. Williams & Co',
    categories: ['content'],
    domains: ['*.drct2u.com'],
  },
  {
    name: 'Janrain',
    categories: ['analytics'],
    domains: [
      '*.janrain.com',
      '*.janrainbackplane.com',
      '*.rpxnow.com',
      'd3hmp0045zy3cs.cloudfront.net',
    ],
  },
  {
    name: 'Jellyfish',
    categories: ['ad'],
    domains: ['*.jellyfish.net'],
  },
  {
    name: 'JetStream',
    categories: ['content'],
    domains: ['*.xlcdn.com'],
  },
  {
    name: 'JingDong',
    categories: ['content'],
    domains: ['*.3.com', '*.jd.com'],
  },
  {
    name: 'Jivox',
    categories: ['ad'],
    domains: ['*.jivox.com'],
  },
  {
    name: 'Jobvite',
    categories: ['content'],
    domains: ['*.jobvite.com'],
  },
  {
    name: 'Johnston Press',
    categories: ['content'],
    domains: ['*.johnstonpress.co.uk', '*.jpress.co.uk'],
  },
  {
    name: 'Join the Dots (Research)',
    categories: ['social'],
    domains: ['*.jtdiscuss.com'],
  },
  {
    name: 'JotForm',
    categories: ['utility'],
    domains: ['*.jotformpro.com'],
  },
  {
    name: 'JuicyAds',
    categories: ['ad'],
    domains: ['*.juicyads.com'],
  },
  {
    name: 'JustPremium',
    categories: ['ad'],
    domains: ['*.net.net'],
    examples: ['d2nvliyzbo36lk.cloudfrontd2nvliyzbo36lk.cloudfront.net.net'],
  },
  {
    name: 'JustPremium Ads',
    company: 'JustPremium',
    categories: ['ad'],
    domains: ['*.justpremium.com'],
  },
  {
    name: 'JustUno',
    categories: ['ad'],
    domains: ['*.justuno.com', 'd2j3qa5nc37287.cloudfront.net'],
  },
  {
    name: 'KINX (Korea Internet Neutral eXchange)',
    categories: ['other'],
    domains: ['*.kinxcdn.com'],
  },
  {
    name: 'KISSmetrics',
    categories: ['analytics'],
    domains: ['*.kissmetrics.com', 'doug1izaerwt3.cloudfront.net', 'dsyszv14g9ymi.cloudfront.net'],
  },
  {
    name: 'Kaizen Platform',
    categories: ['analytics'],
    domains: ['*.kaizenplatform.net'],
    examples: ['cdn.kaizenplatform.net', 'log-v4.kaizenplatform.net'],
  },
  {
    name: 'Kakao',
    categories: ['social'],
    domains: ['*.daum.net', '*.daumcdn.net'],
  },
  {
    name: 'Kaltura Video Platform',
    company: 'Kaltura',
    categories: ['content'],
    domains: ['*.kaltura.com'],
    examples: ['cdnsecakmi.kaltura.com'],
  },
  {
    name: 'Kameleoon',
    categories: ['analytics'],
    domains: ['*.kameleoon.com'],
  },
  {
    name: 'Kampyle',
    categories: ['analytics'],
    domains: ['*.kampyle.com'],
  },
  {
    name: 'Kantar',
    categories: ['analytics'],
    domains: ['*.sesamestats.com'],
  },
  {
    name: 'Kargo',
    categories: ['marketing'],
    domains: ['*.kargo.com'],
  },
  {
    name: 'Kauli',
    categories: ['ad'],
    domains: ['*.kau.li'],
  },
  {
    name: 'Keen',
    company: 'Keen',
    homepage: 'https://keen.io/',
    categories: ['analytics'],
    domains: ['*.keen.io', 'd26b395fwzu5fz.cloudfront.net'],
  },
  {
    name: 'Kelkoo',
    categories: ['hosting'],
    domains: ['*.kelkoo.com'],
  },
  {
    name: 'Kenshoo',
    categories: ['marketing'],
    domains: ['*.xg4ken.com'],
  },
  {
    name: 'Key CDN',
    categories: ['utility'],
    domains: ['*.kxcdn.com'],
  },
  {
    name: 'Keynote',
    company: 'Dynatrace',
    categories: ['analytics'],
    domains: ['*.keynote.com'],
  },
  {
    name: 'Keywee',
    categories: ['ad'],
    domains: ['*.keywee.co'],
  },
  {
    name: 'Kiosked',
    categories: ['ad'],
    domains: ['*.kiosked.com'],
  },
  {
    name: 'Klarna',
    categories: ['utility'],
    domains: ['*.klarna.com'],
  },
  {
    name: 'Klaviyo',
    categories: ['ad'],
    domains: ['*.klaviyo.com'],
  },
  {
    name: 'Klevu Search',
    company: 'Klevu',
    categories: ['utility'],
    domains: ['*.klevu.com'],
  },
  {
    name: 'Klick2Contact',
    categories: ['customer-success'],
    domains: ['*.klick2contact.com'],
  },
  {
    name: 'Knight Lab',
    company: 'Northwestern University',
    categories: ['utility'],
    domains: ['*.knightlab.com'],
  },
  {
    name: 'Kodajo',
    categories: ['other'],
    domains: ['*.kodajo.com'],
  },
  {
    name: 'Komoona',
    categories: ['ad'],
    domains: ['*.komoona.com'],
  },
  {
    name: 'Korrelate',
    company: 'JD Power',
    categories: ['analytics'],
    domains: ['*.korrelate.net'],
  },
  {
    name: 'LKQD',
    categories: ['ad'],
    domains: ['*.lkqd.net'],
  },
  {
    name: 'Layershift',
    categories: ['hosting'],
    domains: ['109.109.138.174'],
  },
  {
    name: 'Lead Forensics',
    categories: ['ad'],
    domains: [
      '*.200summit.com',
      '*.baw5tracker.com',
      '*.business-path-55.com',
      '*.bux1le001.com',
      '*.central-core-7.com',
      '*.direct-azr-78.com',
      '*.explore-123.com',
      '*.forensics1000.com',
      '*.gldsta-02-or.com',
      '*.green-bloc9.com',
      '*.lansrv040.com',
      '*.lead-123.com',
      '*.leadforensics.com',
      '*.mavic852.com',
      '*.mon-com-net.com',
      '*.peak-ip-54.com',
      '*.snta0034.com',
      '*.svr-prc-01.com',
      '*.syntace-094.com',
      '*.tghbn12.com',
      '*.trail-web.com',
      '*.web-01-gbl.com',
      '*.web-cntr-07.com',
      '*.trackdiscovery.net',
    ],
    examples: [
      'www.baw5tracker.com',
      'www.lansrv040.com',
      'www.mon-com-net.com',
      'www.peak-ip-54.com',
      'www.tghbn12.com',
      'www.web-01-gbl.com',
    ],
  },
  {
    name: 'Lead Intelligence',
    company: 'Magnetise Solutions',
    categories: ['ad'],
    domains: ['*.leadintelligence.co.uk'],
  },
  {
    name: 'LeadLander',
    categories: ['analytics'],
    domains: ['*.formalyzer.com', '*.trackalyzer.com'],
  },
  {
    name: 'Leaflet',
    categories: ['utility'],
    domains: ['*.leafletjs.com'],
  },
  {
    name: 'LeasdBoxer',
    company: 'LeadBoxer',
    categories: ['ad'],
    domains: ['*.leadboxer.com'],
  },
  {
    name: 'LeaseWeb',
    homepage: 'https://www.leaseweb.com/',
    categories: ['cdn'],
    domains: ['*.lswcdn.net', '*.leasewebcdn.com'],
  },
  {
    name: 'Leboncoin',
    categories: ['content'],
    domains: ['*.leboncoin.fr'],
  },
  {
    name: 'Lengow',
    categories: ['hosting'],
    domains: ['*.lengow.com'],
  },
  {
    name: 'Lessbuttons',
    categories: ['social'],
    domains: ['*.lessbuttons.com'],
  },
  {
    name: 'Letter Press',
    categories: ['ad'],
    domains: ['*.getletterpress.com'],
  },
  {
    name: 'Level 3 Communications',
    categories: ['utility'],
    domains: ['footprint.net'],
  },
  {
    name: 'Level3',
    categories: ['other'],
    domains: ['secure.footprint.net'],
  },
  {
    name: 'Lifestreet Media',
    categories: ['social'],
    domains: ['*.lfstmedia.com'],
  },
  {
    name: 'LiftSuggest',
    categories: ['analytics'],
    domains: ['d2blwevgjs7yom.cloudfront.net'],
  },
  {
    name: 'Ligatus',
    categories: ['ad'],
    domains: ['*.ligadx.com'],
  },
  {
    name: 'LightStep',
    categories: ['analytics'],
    domains: ['*.lightstep.com'],
  },
  {
    name: 'LightWidget',
    categories: ['utility'],
    domains: ['*.lightwidget.com'],
  },
  {
    name: 'Likelihood',
    company: 'LIkeihood',
    categories: ['hosting'],
    domains: ['*.likelihood.com'],
    examples: ['client.likelihood.com'],
  },
  {
    name: 'LikeShop',
    company: 'Dash Hudson',
    categories: ['content'],
    domains: ['likeshop.me'],
    examples: ['likeshop.me'],
  },
  {
    name: 'LINE Corporation',
    categories: ['ad'],
    domains: ['*.line-scdn.net', '*.line.me'],
    examples: ['d.line-scdn.net', 'tr.line.me'],
  },
  {
    name: 'Linkcious',
    categories: ['analytics'],
    domains: ['*.linkcious.com'],
  },
  {
    name: 'Linking Mobile',
    categories: ['ad'],
    domains: ['*.linkingmobile.com'],
  },
  {
    name: 'LiveBurst',
    categories: ['ad'],
    domains: ['*.liveburst.com'],
  },
  {
    name: 'LiveClicker',
    categories: ['ad'],
    domains: ['*.liveclicker.net'],
  },
  {
    name: 'LiveHelpNow',
    categories: ['customer-success'],
    domains: ['*.livehelpnow.net'],
  },
  {
    name: 'LiveInternet',
    categories: ['analytics'],
    domains: ['*.yadro.ru'],
  },
  {
    name: 'LiveJournal',
    categories: ['social'],
    domains: ['*.livejournal.com', '*.livejournal.net'],
  },
  {
    name: 'LivePerson',
    categories: ['customer-success'],
    homepage: 'https://www.liveperson.com/',
    domains: ['*.liveperson.com', '*.look.io', '*.liveperson.net', '*.lpsnmedia.net'],
  },
  {
    name: 'LiveRail',
    company: 'Facebook',
    categories: ['ad'],
    domains: ['*.liverail.com', '*.lrcdn.net'],
    examples: ['scontent.lrcdn.net'],
  },
  {
    name: 'LiveTex',
    categories: ['customer-success'],
    domains: ['*.livetex.ru'],
  },
  {
    name: 'Livefyre',
    categories: ['content'],
    domains: ['*.fyre.co', '*.livefyre.com'],
  },
  {
    name: 'Living Map Company',
    categories: ['utility'],
    domains: ['*.livingmap.com'],
  },
  {
    name: 'Local World',
    categories: ['content'],
    domains: ['*.thelocalpeople.co.uk'],
  },
  {
    name: 'LockerDome',
    categories: ['analytics'],
    domains: ['*.lockerdome.com'],
  },
  {
    name: 'Logentries',
    company: 'Rapid',
    categories: ['utility'],
    domains: ['*.logentries.com'],
    examples: ['js.logentries.com'],
  },
  {
    name: 'Logicalis',
    categories: ['analytics'],
    domains: ['*.trovus.co.uk'],
  },
  {
    name: 'LoginRadius',
    categories: ['social'],
    domains: ['*.loginradius.com'],
  },
  {
    name: 'LongTail Ad Solutions',
    categories: ['ad'],
    domains: [
      '*.jwpcdn.com',
      '*.jwplatform.com',
      '*.jwplayer.com',
      '*.jwpltx.com',
      '*.jwpsrv.com',
      '*.longtailvideo.com',
    ],
  },
  {
    name: 'Loop Commerce',
    categories: ['other'],
    domains: ['*.loopassets.net'],
  },
  {
    name: 'Loop11',
    categories: ['analytics'],
    domains: ['*.loop11.com'],
  },
  {
    name: 'LoopMe',
    categories: ['ad'],
    domains: ['*.loopme.biz', '*.loopme.com', '*.vntsm.com', '*.loopme.me'],
  },
  {
    name: 'Looper',
    categories: ['content'],
    domains: ['*.looper.com'],
  },
  {
    name: 'Loyalty Point',
    categories: ['ad'],
    domains: ['*.loyaltypoint.pl'],
  },
  {
    name: 'LoyaltyLion',
    categories: ['ad'],
    domains: ['*.loyaltylion.com', '*.loyaltylion.net', 'dg1f2pfrgjxdq.cloudfront.net'],
  },
  {
    name: 'Luma Tag',
    categories: ['analytics'],
    domains: ['*.lumatag.co.uk'],
  },
  {
    name: 'Lumesse',
    categories: ['content'],
    domains: ['*.recruitmentplatform.com'],
  },
  {
    name: 'Luminate',
    categories: ['ad'],
    domains: ['*.luminate.com'],
  },
  {
    name: 'Lynchpin Analytics',
    categories: ['analytics'],
    domains: ['*.lypn.net'],
  },
  {
    name: 'Lyris',
    categories: ['ad'],
    domains: ['*.clicktracks.com'],
  },
  {
    name: 'Lytics',
    categories: ['ad'],
    domains: ['*.lytics.io'],
  },
  {
    name: 'MEC WebTrack',
    company: 'MEC',
    categories: ['ad'],
    domains: ['*.e-webtrack.net'],
  },
  {
    name: 'MECLABS Institute',
    categories: ['analytics'],
    domains: ['*.meclabs.com', '*.meclabsdata.com'],
  },
  {
    name: 'MLveda',
    categories: ['utility'],
    domains: ['*.mlveda.com'],
    examples: ['www.mlveda.com'],
  },
  {
    name: 'Macromill',
    company: 'Macromill',
    categories: ['analytics'],
    homepage: 'https://group.macromill.com/',
    domains: ['*.macromill.com'],
    examples: ['img.macromill.com/js/us000131vfg/4000000570-56/lognos.js']
  },
  {
    name: 'Macropod BugHerd',
    company: 'Macropod',
    categories: ['utility'],
    domains: ['*.bugherd.com'],
    examples: ['www.bugherd.com'],
  },
  {
    name: 'Madison Logic',
    categories: ['marketing'],
    domains: ['*.ml314.com'],
  },
  {
    name: 'Madmetrics',
    company: 'Keyade',
    categories: ['analytics'],
    domains: ['*.keyade.com'],
  },
  {
    name: 'Magnetic',
    categories: ['ad'],
    domains: ['*.domdex.com', 'd3ezl4ajpp2zy8.cloudfront.net'],
  },
  {
    name: 'Magnetic Platform',
    company: 'Magnetic',
    categories: ['ad'],
    domains: ['*.magnetic.is'],
  },
  {
    name: 'MailMunch',
    categories: ['ad'],
    domains: ['*.mailmunch.co'],
  },
  {
    name: 'MailPlus',
    categories: ['ad'],
    domains: ['*.mailplus.nl'],
  },
  {
    name: 'Mapbox',
    categories: ['utility'],
    domains: ['*.mapbox.com'],
  },
  {
    name: 'Maptive',
    categories: ['utility'],
    domains: ['*.maptive.com'],
  },
  {
    name: 'Marcaria.com',
    categories: ['other'],
    domains: ['*.gooo.al'],
  },
  {
    name: 'Marchex',
    categories: ['analytics'],
    domains: ['*.voicestar.com', '*.marchex.io'],
  },
  {
    name: 'Mark and Mini',
    categories: ['ad'],
    domains: ['*.markandmini.com'],
    examples: ['www.markandmini.com'],
  },
  {
    name: 'Marketing Dashboards',
    company: 'GroupM',
    categories: ['analytics'],
    domains: ['*.m-decision.com'],
  },
  {
    name: 'Marketizator',
    categories: ['analytics'],
    domains: ['*.marketizator.com'],
  },
  {
    name: 'Marketplace Web Service',
    company: 'Amazon',
    categories: ['other'],
    domains: ['*.ssl-images-amazon.com'],
  },
  {
    name: 'Mashable',
    categories: ['social'],
    domains: ['*.mshcdn.com'],
  },
  {
    name: 'MatchWork',
    categories: ['utility'],
    domains: ['*.matchwork.com'],
  },
  {
    name: 'MathJax',
    categories: ['utility'],
    domains: ['*.mathjax.org'],
  },
  {
    name: 'Mather Economics',
    categories: ['analytics'],
    domains: ['*.matheranalytics.com'],
  },
  {
    name: 'MaxCDN Enterprise',
    company: 'MaxCDN',
    categories: ['utility'],
    domains: ['*.netdna-cdn.com', '*.netdna-ssl.com'],
  },
  {
    name: 'MaxMind',
    categories: ['utility'],
    domains: ['*.maxmind.com'],
  },
  {
    name: 'MaxPoint Interactive',
    categories: ['ad'],
    domains: ['*.mxptint.net'],
  },
  {
    name: 'Maxsi',
    categories: ['analytics'],
    domains: ['*.evisitanalyst.com'],
  },
  {
    name: 'Maxymiser',
    categories: ['analytics'],
    domains: ['*.maxymiser.net'],
  },
  {
    name: 'McAffee',
    categories: ['utility'],
    domains: ['*.mcafeesecure.com', '*.scanalert.com'],
  },
  {
    name: 'Media IQ',
    categories: ['analytics'],
    domains: ['*.mediaiqdigital.com'],
  },
  {
    name: 'Media Management Technologies',
    categories: ['ad'],
    domains: ['*.speedshiftmedia.com'],
  },
  {
    name: 'Media Temple',
    categories: ['hosting'],
    domains: ['*.goodlayers2.com'],
  },
  {
    name: 'Mediabong',
    categories: ['ad'],
    domains: ['*.mediabong.net'],
  },
  {
    name: 'Mediahawk',
    categories: ['analytics'],
    domains: ['*.mediahawk.co.uk'],
  },
  {
    name: 'Mediahub',
    categories: ['ad'],
    domains: ['*.hubverifyandoptimize.com', '*.projectwatchtower.com'],
  },
  {
    name: 'Mediasyndicator',
    categories: ['ad'],
    domains: ['*.creativesyndicator.com'],
  },
  {
    name: 'Medium',
    categories: ['content'],
    domains: ['*.medium.com'],
  },
  {
    name: 'Meetrics',
    categories: ['ad'],
    domains: ['*.de.com', '*.meetrics.net', '*.mxcdn.net'],
    examples: ['research.de.com'],
  },
  {
    name: 'Mega',
    company: 'Mega Information Technology',
    categories: ['other'],
    domains: ['*.mgcdn.com'],
  },
  {
    name: 'Melt',
    categories: ['ad'],
    domains: ['*.meltdsp.com', '*.mesp.com'],
  },
  {
    name: 'Meltwater Group',
    categories: ['customer-success'],
    domains: ['*.meltwaternews.com'],
  },
  {
    name: 'Meme',
    categories: ['ad'],
    domains: ['*.viewwonder.com'],
  },
  {
    name: 'MentAd',
    categories: ['ad'],
    domains: ['*.mentad.com'],
  },
  {
    name: 'Mention Me',
    categories: ['ad'],
    domains: ['*.mention-me.com'],
    examples: ['tag.mention-me.com'],
  },
  {
    name: 'Merchant Equipment Store',
    categories: ['utility'],
    domains: ['*.merchantequip.com'],
  },
  {
    name: 'Merchenta',
    categories: ['customer-success'],
    domains: ['*.merchenta.com'],
  },
  {
    name: 'Merkle Digital Data Exchange',
    company: 'Merkle',
    categories: ['ad'],
    domains: ['*.brilig.com'],
  },
  {
    name: 'Merkle Paid Search',
    company: 'Merkle',
    categories: ['ad'],
    domains: ['*.rkdms.com'],
  },
  {
    name: 'Met Office',
    categories: ['content'],
    domains: ['*.metoffice.gov.uk'],
  },
  {
    name: 'Meta Broadcast',
    categories: ['social'],
    domains: ['*.metabroadcast.com'],
    examples: ['voila.metabroadcast.com'],
  },
  {
    name: 'Michael Associates',
    categories: ['ad'],
    domains: ['*.checktestsite.com'],
    examples: ['www.checktestsite.com'],
  },
  {
    name: 'Michelin',
    categories: ['content'],
    domains: ['*.viamichelin.com'],
  },
  {
    name: 'Microad',
    categories: ['ad'],
    domains: ['*.microad.jp'],
  },
  {
    name: 'Microsoft Certificate Services',
    company: 'Microsoft',
    categories: ['utility'],
    domains: ['*.msocsp.com'],
  },
  {
    name: 'Microsoft Hosted Libs',
    company: 'Microsoft',
    categories: ['cdn'],
    domains: ['*.aspnetcdn.com'],
    examples: ['ajax.aspnetcdn.com'],
  },
  {
    name: 'Microsoft XBox Live',
    company: 'Microsoft',
    categories: ['marketing'],
    domains: ['*.xboxlive.com'],
  },
  {
    name: 'Mightypop',
    categories: ['ad'],
    domains: ['*.mightypop.ca'],
  },
  {
    name: 'Mika Tuupola',
    categories: ['utility'],
    domains: ['*.appelsiini.net'],
  },
  {
    name: 'Millennial Media',
    categories: ['ad'],
    domains: ['*.jumptap.com'],
  },
  {
    name: 'Mirror Image Internet',
    categories: ['utility'],
    domains: ['*.miisolutions.net'],
  },
  {
    name: 'Mobify',
    categories: ['utility'],
    domains: ['*.mobify.com', '*.mobify.net'],
  },
  {
    name: 'Mobile Nations',
    categories: ['social'],
    domains: ['*.mobilenations.com'],
  },
  {
    name: 'Mobivate',
    categories: ['ad'],
    domains: ['*.mobivatebulksms.com'],
  },
  {
    name: 'Momondo',
    categories: ['content'],
    domains: ['*.momondo.dk'],
  },
  {
    name: 'Momondo Group',
    categories: ['content'],
    domains: ['*.momondogrouo.com', '*.momondogroup.com'],
  },
  {
    name: 'Monarch Ads',
    categories: ['ad'],
    domains: ['*.monarchads.com'],
  },
  {
    name: 'Monetate',
    categories: ['analytics'],
    domains: ['*.monetate.net'],
  },
  {
    name: 'MonetizeMore',
    categories: ['ad'],
    domains: ['*.m2.ai'],
  },
  {
    name: 'Monitor',
    company: 'Econda',
    categories: ['analytics'],
    domains: ['*.econda-monitor.de'],
    examples: ['www.econda-monitor.de'],
  },
  {
    name: 'Monkey Frog Media',
    categories: ['content'],
    domains: ['*.monkeyfrogmedia.com'],
  },
  {
    name: 'Monotype',
    categories: ['cdn'],
    domains: ['*.fonts.com', '*.fonts.net'],
  },
  {
    name: 'Moore-Wilson',
    categories: ['ad'],
    domains: ['*.mwdev.co.uk'],
  },
  {
    name: 'Moovweb',
    categories: ['utility'],
    domains: ['*.moovweb.net'],
  },
  {
    name: 'Mopinion',
    categories: ['analytics'],
    domains: ['*.mopinion.com'],
  },
  {
    name: 'MotionPoint',
    categories: ['other'],
    domains: ['*.convertlanguage.com'],
  },
  {
    name: 'Mouse3K',
    categories: ['analytics'],
    domains: ['*.mouse3k.com'],
  },
  {
    name: 'MouseStats',
    categories: ['analytics'],
    domains: ['*.mousestats.com'],
  },
  {
    name: 'Mouseflow',
    categories: ['analytics'],
    domains: ['*.mouseflow.com'],
  },
  {
    name: 'Movable Ink',
    categories: ['analytics'],
    domains: ['*.micpn.com'],
  },
  {
    name: 'MovingIMAGE24',
    categories: ['content'],
    domains: ['*.edge-cdn.net'],
  },
  {
    name: 'Moxielinks',
    categories: ['ad'],
    domains: ['*.moxielinks.com'],
  },
  {
    name: 'Moz Recommended Companies',
    company: 'Moz',
    categories: ['analytics'],
    domains: ['d2eeipcrcdle6.cloudfront.net'],
  },
  {
    name: 'Mozilla',
    categories: ['utility'],
    domains: ['*.mozilla.org'],
    examples: ['aus5.mozilla.org'],
  },
  {
    name: 'Multiview',
    categories: ['content'],
    domains: ['*.multiview.com', '*.track-mv.com'],
  },
  {
    name: 'Mux',
    categories: ['analytics'],
    domains: ['*.litix.io'],
  },
  {
    name: 'MyAds',
    company: 'MyBuys',
    categories: ['analytics'],
    domains: ['*.veruta.com'],
  },
  {
    name: 'MyBuys',
    categories: ['analytics'],
    domains: ['*.mybuys.com'],
  },
  {
    name: 'MyFonts',
    categories: ['cdn'],
    domains: ['*.myfonts.net'],
  },
  {
    name: 'MyRegistry',
    categories: ['other'],
    domains: ['*.myregistry.com'],
  },
  {
    name: 'MySpace',
    company: 'Specific Media',
    categories: ['social'],
    domains: ['*.myspace.com'],
  },
  {
    name: 'Mynewsdesk',
    categories: ['utility'],
    domains: ['*.mynewsdesk.com'],
  },
  {
    name: 'NAVIS',
    categories: ['content'],
    domains: ['*.navistechnologies.info'],
  },
  {
    name: 'NCC Group Real User Monitoring',
    company: 'NCC Group',
    categories: ['analytics'],
    domains: ['*.nccgroup-webperf.com'],
    examples: [
      'beacon-rumlive.rum.nccgroup-webperf.com',
      'config-rumlive.rum.nccgroup-webperf.com',
      'script-rumlive.rum.nccgroup-webperf.com',
    ],
  },
  {
    name: 'NEORY Marketing Cloud',
    company: 'NEORY',
    categories: ['marketing'],
    domains: ['*.ad-srv.net'],
  },
  {
    name: 'Nanigans',
    categories: ['ad'],
    domains: ['*.nanigans.com'],
  },
  {
    name: 'Nano Interactive',
    categories: ['ad'],
    domains: ['*.audiencemanager.de'],
  },
  {
    name: 'Nanorep',
    company: 'Nanorep Technologies',
    categories: ['customer-success'],
    domains: ['*.nanorep.com'],
  },
  {
    name: 'Narrative',
    categories: ['ad'],
    domains: ['*.narrative.io'],
  },
  {
    name: 'Native Ads',
    categories: ['ad'],
    domains: ['*.nativeads.com'],
  },
  {
    name: 'Nativo',
    categories: ['ad'],
    domains: ['*.postrelease.com'],
  },
  {
    name: 'Navegg',
    categories: ['ad'],
    domains: ['*.navdmp.com'],
  },
  {
    name: 'NaviStone',
    categories: ['ad'],
    domains: ['*.murdoog.com'],
  },
  {
    name: 'Naytev',
    categories: ['analytics'],
    domains: ['*.naytev.com'],
  },
  {
    name: 'Needle',
    categories: ['analytics'],
    domains: ['*.needle.com'],
  },
  {
    name: 'Neiman Marcus',
    categories: ['content'],
    domains: ['*.ctscdn.com'],
  },
  {
    name: 'Nend',
    categories: ['ad'],
    domains: ['*.nend.net'],
  },
  {
    name: 'Neodata',
    categories: ['ad'],
    domains: ['*.neodatagroup.com'],
  },
  {
    name: 'Net Applications',
    categories: ['analytics'],
    domains: ['*.hitsprocessor.com'],
  },
  {
    name: 'Net Reviews',
    categories: ['analytics'],
    domains: ['*.avis-verifies.com'],
    examples: ['www.avis-verifies.com'],
  },
  {
    name: 'NetAffiliation',
    company: 'Kwanco',
    categories: ['ad'],
    domains: ['*.metaffiliation.com'],
  },
  {
    name: 'NetDirector',
    company: 'G-Forces Web Management',
    categories: ['other'],
    domains: ['*.netdirector.co.uk'],
  },
  {
    name: 'NetFlix',
    categories: ['content'],
    domains: ['*.nflxext.com', '*.nflximg.net'],
  },
  {
    name: 'Nielsen NetRatings SiteCensus',
    company: 'The Nielsen Company',
    homepage: 'http://www.nielsen-online.com/intlpage.html',
    categories: ['analytics'],
    domains: ['*.imrworldwide.com'],
  },
  {
    name: 'NetSeer',
    categories: ['ad'],
    domains: ['*.netseer.com', '*.ns-cdn.com'],
  },
  {
    name: 'NetShelter',
    company: 'Ziff Davis Tech',
    categories: ['ad'],
    domains: ['*.netshelter.net'],
  },
  {
    name: 'Netmining',
    company: 'Ignition One',
    categories: ['ad'],
    domains: ['*.netmng.com'],
  },
  {
    name: 'Netop',
    categories: ['customer-success'],
    domains: ['*.netop.com'],
  },
  {
    name: 'Network Solutions',
    categories: ['utility'],
    domains: ['*.netsolssl.com', '*.networksolutions.com'],
    examples: ['ocsp.netsolssl.com'],
  },
  {
    name: 'Neustar AdAdvisor',
    company: 'Neustar',
    categories: ['ad'],
    domains: ['*.adadvisor.net'],
  },
  {
    name: 'New Approach Media',
    categories: ['ad'],
    domains: ['*.newapproachmedia.co.uk'],
  },
  {
    name: 'NewShareCounts',
    categories: ['social'],
    domains: ['*.newsharecounts.com'],
  },
  {
    name: 'News',
    categories: ['social'],
    domains: [
      '*.news.com.au',
      '*.newsanalytics.com.au',
      '*.newsapi.com.au',
      '*.newscdn.com.au',
      '*.newsdata.com.au',
      '*.newsdiscover.com.au',
      '*.news-static.com',
    ],
  },
  {
    name: 'Newsquest',
    categories: ['content'],
    domains: ['*.newsquestdigital.co.uk'],
  },
  {
    name: 'Newzulu',
    categories: ['content'],
    domains: ['*.filemobile.com', '*.projects.fm'],
  },
  {
    name: 'Nexcess.Net',
    categories: ['hosting'],
    domains: ['*.nexcesscdn.net'],
  },
  {
    name: 'Nexstar Media Group',
    categories: ['ad'],
    domains: ['*.yashi.com'],
  },
  {
    name: 'NextPerf',
    company: 'Rakuten Marketing',
    categories: ['ad'],
    domains: ['*.nxtck.com'],
  },
  {
    name: 'Nine.com.au',
    company: 'Nine Digital',
    categories: ['content'],
    domains: ['*.9msn.com.au'],
  },
  {
    name: 'NitroSell',
    categories: ['hosting'],
    domains: ['*.nitrosell.com'],
  },
  {
    name: 'Nochex',
    categories: ['utility'],
    domains: ['*.nochex.com'],
  },
  {
    name: 'Northern &amp; Shell Media Group',
    categories: ['content'],
    domains: ['*.northernandshell.co.uk'],
  },
  {
    name: 'Nosto',
    categories: ['analytics'],
    domains: ['*.nosto.com'],
  },
  {
    name: 'Now Interact',
    categories: ['analytics'],
    domains: ['*.nowinteract.com'],
  },
  {
    name: 'Numberly',
    company: '1000mercis',
    categories: ['ad'],
    domains: ['*.mmtro.com', '*.nzaza.com'],
  },
  {
    name: 'NyaConcepts',
    categories: ['analytics'],
    domains: ['*.xclusive.ly'],
  },
  {
    name: 'O2',
    categories: ['other'],
    domains: ['*.o2.co.uk'],
    examples: ['servedby.o2.co.uk'],
  },
  {
    name: 'GoDaddy',
    homepage: 'https://www.godaddy.com/',
    categories: ['utility'],
    domains: ['*.godaddy.com', '*.wsimg.com'],
    examples: ['ocsp.godaddy.com', 'seal.godaddy.com'],
  },
  {
    name: 'ObjectPlanet',
    categories: ['analytics'],
    domains: ['*.easypolls.net'],
  },
  {
    name: 'OhMyAd',
    categories: ['ad'],
    domains: ['*.ohmyad.co'],
    examples: ['pr.ohmyad.co'],
  },
  {
    name: 'Okas Concepts',
    categories: ['utility'],
    domains: ['*.okasconcepts.com'],
  },
  {
    name: 'Okta',
    categories: ['analytics'],
    domains: ['*.okta.com'],
  },
  {
    name: 'Olapic',
    categories: ['content'],
    domains: ['*.photorank.me'],
  },
  {
    name: 'Ometria',
    categories: ['analytics'],
    domains: ['*.ometria.com'],
  },
  {
    name: 'Omniconvert',
    categories: ['analytics'],
    domains: [
      '*.omniconvert.com',
      'd2tgfbvjf3q6hn.cloudfront.net',
      'd3vbj265bmdenw.cloudfront.net',
    ],
  },
  {
    name: 'Omniroot',
    company: 'Verizon',
    categories: ['utility'],
    domains: ['*.omniroot.com'],
    examples: ['ocsp.omniroot.com', 'vassg142.ocsp.omniroot.com'],
  },
  {
    name: 'OnAudience',
    company: 'Cloud Technologies',
    categories: ['ad'],
    domains: ['*.onaudience.com'],
  },
  {
    name: 'OnScroll',
    categories: ['ad'],
    domains: ['*.onscroll.com'],
  },
  {
    name: 'OnState',
    categories: ['ad'],
    domains: ['*.onstate.co.uk'],
  },
  {
    name: 'OnYourMap',
    categories: ['utility'],
    domains: ['*.onyourmap.com'],
  },
  {
    name: 'One by AOL',
    company: 'AOL',
    categories: ['ad'],
    domains: ['*.adtechjp.com', '*.adtech.de'],
  },
  {
    name: 'One by AOL:Mobile',
    company: 'AOL',
    categories: ['ad'],
    domains: ['*.nexage.com'],
    examples: ['ads.nexage.com', 'hb.nexage.com'],
  },
  {
    name: 'OneAll',
    categories: ['analytics'],
    domains: ['*.oneall.com'],
  },
  {
    name: 'OneSoon',
    categories: ['analytics'],
    domains: ['*.adalyser.com'],
  },
  {
    name: 'OneTag',
    categories: ['ad'],
    domains: ['*.onetag-sys.com'],
  },
  {
    name: 'Onet',
    categories: ['ad'],
    domains: ['*.onet.pl'],
  },
  {
    name: 'Online Rewards',
    company: 'Mastercard',
    categories: ['ad'],
    domains: ['*.loyaltygateway.com'],
  },
  {
    name: 'Online republic',
    categories: ['content'],
    domains: ['*.imallcdn.net'],
  },
  {
    name: 'Ooyala',
    categories: ['ad'],
    domains: ['*.ooyala.com'],
  },
  {
    name: 'OpenTable',
    company: 'Priceline Group',
    categories: ['content'],
    domains: ['*.opentable.com', '*.opentable.co.uk', '*.toptable.co.uk'],
    examples: ['www.toptable.co.uk'],
  },
  {
    name: 'OpenX Ad Exchange',
    company: 'OpenX Technologies',
    categories: ['ad'],
    domains: ['*.liftdna.com'],
  },
  {
    name: 'Opentag',
    company: 'Qubit',
    categories: ['tag-manager'],
    domains: ['*.qutics.com', 'd3c3cq33003psk.cloudfront.net'],
    examples: ['opentag-stats.qutics.com'],
  },
  {
    name: 'Opinion Stage',
    categories: ['analytics'],
    domains: ['*.opinionstage.com'],
    examples: ['www.opinionstage.com'],
  },
  {
    name: 'OpinionBar',
    categories: ['analytics'],
    domains: ['*.opinionbar.com'],
  },
  {
    name: 'Opta',
    company: 'Perform Group',
    categories: ['content'],
    domains: ['*.opta.net'],
  },
  {
    name: 'OptiMonk',
    categories: ['ad'],
    domains: ['*.optimonk.com'],
  },
  {
    name: 'Optilead',
    categories: ['analytics'],
    domains: ['*.dyn-img.com', '*.leadcall.co.uk', '*.optilead.co.uk'],
  },
  {
    name: 'Optimatic',
    categories: ['ad'],
    domains: ['*.optimatic.com'],
    examples: ['synch.optimatic.com'],
  },
  {
    name: 'Optimise Media Group',
    categories: ['utility'],
    domains: ['*.omguk.com'],
  },
  {
    name: 'Optimost',
    company: 'OpenText',
    categories: ['ad'],
    domains: ['*.optimost.com'],
  },
  {
    name: 'Optimove',
    company: 'Mobius Solutions',
    categories: ['analytics'],
    domains: ['*.optimove.net'],
  },
  {
    name: 'Optorb',
    categories: ['ad'],
    domains: ['*.optorb.com'],
  },
  {
    name: 'Oracle',
    categories: ['marketing'],
    domains: [
      '*.custhelp.com',
      '*.eloqua.com',
      '*.en25.com',
      '*.estara.com',
      '*.instantservice.com',
    ],
  },
  {
    name: 'Oracle Recommendations On Demand',
    company: 'Oracle',
    categories: ['analytics'],
    domains: ['*.atgsvcs.com'],
  },
  {
    name: 'Oracle Responsys',
    company: 'Oracle',
    categories: ['marketing'],
    domains: ['*.adrsp.net', '*.responsys.net'],
  },
  {
    name: 'Order Security-VOID',
    company: 'Order Security',
    categories: ['analytics'],
    domains: ['*.order-security.com'],
  },
  {
    name: 'Oriel',
    categories: ['ad'],
    domains: ['*.oriel.io'],
  },
  {
    name: 'Outbrain',
    homepage: 'https://www.outbrain.com/',
    categories: ['ad'],
    domains: ['*.outbrain.com', '*.outbrainimg.com', '*.visualrevenue.com'],
  },
  {
    name: 'OverStream',
    company: 'Coull',
    categories: ['ad'],
    domains: ['*.coull.com'],
    examples: ['ex1.coull.com'],
  },
  {
    name: 'Overdrive',
    categories: ['content'],
    domains: ['*.contentreserve.com'],
  },
  {
    name: 'Overstock',
    categories: ['utility'],
    domains: ['*.ostkcdn.com'],
  },
  {
    name: 'OwnerIQ',
    categories: ['ad'],
    domains: ['*.owneriq.net'],
  },
  {
    name: 'OzCart',
    categories: ['utility'],
    domains: ['*.ozcart.com.au'],
  },
  {
    name: 'Ozone Media',
    categories: ['ad'],
    domains: ['*.adadyn.com'],
  },
  {
    name: 'PCA Predict',
    company: 'Postcode Anywhere',
    categories: ['other'],
    domains: ['*.pcapredict.com'],
  },
  {
    name: 'PEER 1 Hosting',
    categories: ['hosting'],
    domains: ['*.peer1.com'],
  },
  {
    name: 'PERFORM',
    categories: ['content'],
    domains: ['*.performgroup.com'],
  },
  {
    name: 'PICnet',
    categories: ['hosting'],
    domains: ['*.nonprofitsoapbox.com'],
  },
  {
    name: 'Pacnet',
    company: 'Telstra',
    categories: ['other'],
    domains: ['*.cdndelivery.net'],
    examples: ['682968324.r.cdndelivery.net'],
  },
  {
    name: 'Pagefair',
    categories: ['ad'],
    domains: ['*.pagefair.com', '*.pagefair.net'],
  },
  {
    name: 'Pagely',
    categories: ['other'],
    domains: ['*.optnmstr.com'],
  },
  {
    name: 'Pagesuite',
    categories: ['ad'],
    domains: ['*.pagesuite-professional.co.uk'],
  },
  {
    name: 'Pardot',
    categories: ['marketing'],
    domains: ['*.pardot.com'],
  },
  {
    name: 'Parse.ly',
    categories: ['analytics'],
    domains: ['*.parsely.com', 'd1z2jf7jlzjs58.cloudfront.net'],
  },
  {
    name: 'Pay per Click',
    company: 'Eysys',
    categories: ['ad'],
    domains: ['*.eysys.com'],
    examples: ['pla27.eysys.com'],
  },
  {
    name: 'PayPal Ads',
    categories: ['ad'],
    domains: ['*.where.com'],
  },
  {
    name: 'Peaks & Pies',
    categories: ['analytics'],
    domains: ['*.bunchbox.co'],
  },
  {
    name: 'PebblePost',
    categories: ['ad'],
    domains: ['*.pbbl.co'],
  },
  {
    name: 'Peerius',
    categories: ['analytics'],
    domains: ['*.peerius.com'],
  },
  {
    name: 'Peermap',
    company: 'IMRG',
    categories: ['analytics'],
    domains: ['peermapcontent.affino.com'],
  },
  {
    name: 'Penske Media',
    categories: ['content'],
    domains: ['*.pmc.com'],
  },
  {
    name: 'Penton',
    categories: ['utility'],
    domains: ['*.pisces-penton.com'],
  },
  {
    name: 'Pepper',
    categories: ['ad'],
    domains: ['*.peppercorp.com'],
  },
  {
    name: 'Perfect Audience',
    company: 'Marin Software',
    categories: ['ad'],
    domains: ['*.prfct.co', '*.marinsm.com', '*.perfectaudience.com'],
  },
  {
    name: 'Perfect Market',
    categories: ['ad'],
    domains: ['*.perfectmarket.com'],
  },
  {
    name: 'Perfect Privacy',
    categories: ['other'],
    domains: ['*.suitesmart.com'],
  },
  {
    name: 'Perform Group',
    categories: ['content'],
    domains: ['*.performfeeds.com', '*.premiumtv.co.uk'],
  },
  {
    name: 'Performio',
    categories: ['ad'],
    domains: ['*.performax.cz'],
    examples: ['ut.performax.cz'],
  },
  {
    name: 'PerimeterX Bot Defender',
    company: 'PerimeterX',
    categories: ['utility'],
    domains: ['*.perimeterx.net', '*.pxi.pub'],
  },
  {
    name: 'Periscope',
    categories: ['content'],
    domains: ['*.periscope.tv'],
  },
  {
    name: 'Permutive',
    categories: ['ad'],
    domains: ['*.permutive.com', 'd3alqb8vzo7fun.cloudfront.net'],
  },
  {
    name: 'Petametrics',
    categories: ['analytics'],
    domains: ['*.petametrics.com'],
  },
  {
    name: 'PhotoBucket',
    categories: ['content'],
    domains: ['*.photobucket.com'],
  },
  {
    name: 'Picreel',
    categories: ['analytics'],
    domains: ['*.pcrl.co', '*.picreel.com'],
  },
  {
    name: 'Pictela (AOL)',
    categories: ['analytics'],
    domains: ['*.pictela.net'],
  },
  {
    name: 'PistonHeads',
    categories: ['social'],
    domains: ['*.pistonheads.com'],
  },
  {
    name: 'Piwik',
    categories: ['analytics'],
    domains: ['*.drtvtracker.com', '*.piwikpro.com', '*.raac33.net'],
  },
  {
    name: 'Pixalate',
    categories: ['utility'],
    domains: ['*.adrta.com'],
  },
  {
    name: 'Pixlee',
    categories: ['social'],
    domains: ['*.pixlee.com'],
  },
  {
    name: 'Placed',
    categories: ['analytics'],
    domains: ['*.placed.com'],
  },
  {
    name: 'Planning-inc',
    categories: ['analytics'],
    domains: ['*.planning-inc.co.uk'],
  },
  {
    name: 'PlayAd Media Group',
    categories: ['ad'],
    domains: ['*.youplay.se'],
  },
  {
    name: 'Playbuzz',
    categories: ['hosting'],
    domains: ['*.playbuzz.com'],
  },
  {
    name: 'Pleenq',
    categories: ['ad'],
    domains: ['*.pleenq.com'],
  },
  {
    name: 'Plentific',
    categories: ['content'],
    domains: ['*.plentific.com'],
  },
  {
    name: 'PluginDetect',
    categories: ['other'],
    domains: ['dtlilztwypawv.cloudfront.net'],
  },
  {
    name: 'Po.st',
    company: 'RadiumOne',
    categories: ['utility'],
    domains: ['*.po.st'],
  },
  {
    name: 'Pointpin',
    categories: ['utility'],
    domains: ['*.pointp.in'],
  },
  {
    name: 'Pointroll (Garnett)',
    categories: ['ad'],
    domains: ['*.pointroll.com'],
  },
  {
    name: 'Polar',
    homepage: 'https://polar.me/',
    categories: ['ad'],
    domains: [
      '*.polarmobile.ca',
      '*.mediaeverywhere.com',
      '*.mediavoice.com',
      '*.plrsrvcs.com',
      '*.polarcdn-engine.com',
      '*.polarcdn-meraxes.com',
      '*.polarcdn-pentos.com',
      '*.polarcdn-static.com',
      '*.polarcdn-terrax.com',
      '*.polarcdn.com',
      '*.polarmobile.com',
      '*.poweredbypolar.com',
      '*.mediaconductor.me',
      '*.polaracademy.me',
    ],
  },
  {
    name: 'PollDaddy (Automattic)',
    categories: ['ad'],
    domains: ['static.polldaddy.com', '*.poll.fm'],
  },
  {
    name: 'Polldaddy',
    company: 'Automattic',
    categories: ['analytics'],
    domains: ['polldaddy.com'],
  },
  {
    name: 'Polyfill service',
    company: 'Polyfill.io',
    categories: ['other'],
    domains: ['*.polyfill.io'],
  },
  {
    name: 'MegaPopAds',
    categories: ['ad'],
    domains: ['*.megapopads.com'],
  },
  {
    name: 'Populis',
    categories: ['ad'],
    domains: ['*.populisengage.com'],
  },
  {
    name: 'Postcode Anywhere (Holdings)',
    categories: ['utility'],
    domains: ['*.postcodeanywhere.co.uk'],
  },
  {
    name: 'Postimage.org',
    categories: ['content'],
    domains: ['*.postimg.org'],
  },
  {
    name: 'PowerFront',
    categories: ['hosting'],
    domains: ['*.inside-graph.com'],
  },
  {
    name: 'PowerReviews',
    categories: ['analytics'],
    domains: ['*.powerreviews.com'],
  },
  {
    name: 'Powerlinks.com',
    categories: ['ad'],
    domains: ['*.powerlinks.com'],
  },
  {
    name: 'Press+',
    categories: ['ad'],
    domains: ['*.pipol.com', '*.ppjol.com', '*.ppjol.net'],
  },
  {
    name: 'PressArea',
    categories: ['utility'],
    domains: ['*.pressarea.com'],
    examples: ['www.pressarea.com'],
  },
  {
    name: 'Pretio Interactive',
    categories: ['ad'],
    domains: ['*.pretio.in'],
  },
  {
    name: 'Prezi',
    categories: ['utility'],
    domains: ['*.prezi.com'],
  },
  {
    name: 'PriceGrabber',
    categories: ['content'],
    domains: ['*.pgcdn.com', '*.pricegrabber.com'],
  },
  {
    name: 'PriceRunner',
    categories: ['content'],
    domains: ['*.pricerunner.com'],
  },
  {
    name: 'PrintFriendly',
    categories: ['utility'],
    domains: ['*.printfriendly.com'],
  },
  {
    name: 'Privy',
    categories: ['ad'],
    domains: ['*.privy.com', '*.privymktg.com'],
  },
  {
    name: 'Proclivity Media',
    categories: ['analytics'],
    domains: ['*.pswec.com'],
  },
  {
    name: 'Profitshare',
    categories: ['ad'],
    domains: ['*.profitshare.ro'],
  },
  {
    name: 'Programattik',
    categories: ['ad'],
    domains: ['*.programattik.com'],
  },
  {
    name: 'Proper Media',
    categories: ['content'],
    domains: ['*.proper.io'],
  },
  {
    name: 'Property Week',
    categories: ['content'],
    domains: ['*.propertyweek.com'],
    examples: ['www.propertyweek.com'],
  },
  {
    name: 'Provide Support',
    categories: ['customer-success'],
    domains: ['*.providesupport.com'],
  },
  {
    name: 'Proweb Uk',
    categories: ['hosting'],
    domains: ['*.proweb.net'],
  },
  {
    name: 'Proximic (ComScore)',
    categories: ['ad'],
    domains: ['*.proximic.com'],
  },
  {
    name: 'Psyma',
    categories: ['ad'],
    domains: ['*.psyma.com'],
  },
  {
    name: 'PubFactory',
    company: 'Safari Books Online',
    categories: ['content'],
    domains: ['*.pubfactory.com'],
  },
  {
    name: 'PubNation',
    categories: ['ad'],
    domains: ['*.pubnation.com'],
  },
  {
    name: 'Publicidad.net',
    categories: ['ad'],
    domains: ['*.publicidad.tv'],
  },
  {
    name: 'PublishThis',
    company: 'Ultra Unlimited',
    categories: ['ad'],
    domains: ['*.publishthis.com'],
  },
  {
    name: 'Pulse Insights',
    categories: ['analytics'],
    domains: ['*.pulseinsights.com'],
  },
  {
    name: 'Pulsepoint',
    categories: ['marketing'],
    domains: ['*.displaymarketplace.com'],
  },
  {
    name: 'Purch',
    categories: ['ad'],
    domains: ['*.bestofmedia.com', '*.purch.com'],
    examples: ['ramp.purch.com'],
  },
  {
    name: 'Pure Chat',
    categories: ['customer-success'],
    domains: ['*.purechat.com'],
  },
  {
    name: 'PushCrew',
    categories: ['ad'],
    domains: ['*.pushcrew.com'],
  },
  {
    name: 'Q1Media',
    categories: ['ad'],
    domains: ['*.q1media.com', '*.q1mediahydraplatform.com'],
  },
  {
    name: 'Qbase Software Development',
    categories: ['hosting'],
    domains: ['*.smartwebportal.co.uk'],
  },
  {
    name: 'Qeryz',
    categories: ['analytics'],
    domains: ['*.qeryz.com'],
  },
  {
    name: 'Qode Interactive',
    categories: ['hosting'],
    domains: ['*.qodeinteractive.com'],
  },
  {
    name: 'Qrius',
    categories: ['social'],
    domains: ['*.qrius.me'],
  },
  {
    name: 'Qualaroo',
    categories: ['analytics'],
    domains: ['*.qualaroo.com'],
  },
  {
    name: 'Qualtrics',
    categories: ['analytics'],
    domains: ['*.qualtrics.com'],
  },
  {
    name: 'Qubit',
    categories: ['analytics'],
    domains: ['*.qubit.com', '*.qubitproducts.com'],
  },
  {
    name: 'Qubit Deliver',
    company: 'Qubit',
    categories: ['analytics'],
    domains: [
      'd1m54pdnjzjnhe.cloudfront.net',
      'd22rutvoghj3db.cloudfront.net',
      'dd6zx4ibq538k.cloudfront.net',
    ],
  },
  {
    name: 'QuestionPro',
    categories: ['analytics'],
    domains: ['*.questionpro.com'],
  },
  {
    name: 'Queue-it',
    categories: ['other'],
    domains: ['*.queue-it.net'],
  },
  {
    name: 'QuinStreet',
    categories: ['ad'],
    domains: ['*.Quinstreet.com', '*.b2btechleadform.com', '*.qnsr.com', '*.qsstats.com'],
    examples: ['www.qsstats.com'],
  },
  {
    name: 'QuoVadis',
    categories: ['utility'],
    domains: ['*.quovadisglobal.com'],
  },
  {
    name: 'Qzzr',
    categories: ['analytics'],
    domains: ['*.movementventures.com', '*.qzzr.com'],
    examples: ['www.qzzr.com'],
  },
  {
    name: 'RapidAPI',
    categories: ['utility'],
    domains: ['*.rapidapi.com'],
    examples: ['telize-v1.p.rapidapi.com'],
  },
  {
    name: 'RCS Media Group',
    categories: ['ad'],
    domains: ['*.rcsadv.it'],
  },
  {
    name: 'REVIVVE',
    categories: ['ad'],
    domains: ['*.revivve.com'],
  },
  {
    name: 'RSSinclude',
    categories: ['social'],
    domains: ['*.rssinclude.com'],
  },
  {
    name: 'RTB House AdPilot',
    company: 'RTB House',
    categories: ['ad'],
    domains: ['*.erne.co', '*.creativecdn.com'],
  },
  {
    name: 'RTB Media',
    categories: ['ad'],
    domains: ['*.rtb-media.me'],
  },
  {
    name: 'RUN',
    categories: ['ad'],
    domains: ['*.runadtag.com', '*.rundsp.com'],
  },
  {
    name: 'Rackspace',
    categories: ['hosting'],
    domains: ['*.rackcdn.com', '*.rackspacecloud.com', '*.raxcdn.com', '*.websitetestlink.com'],
  },
  {
    name: 'RadiumOne',
    categories: ['ad'],
    domains: ['*.gwallet.com', '*.r1-cdn.net'],
  },
  {
    name: 'Rakuten DC Storm',
    company: 'Rakuten',
    categories: ['analytics'],
    domains: ['*.dc-storm.com', '*.h4k5.com', '*.stormiq.com'],
  },
  {
    name: 'Rakuten LinkShare',
    company: 'Rakuten',
    categories: ['ad'],
    domains: ['*.linksynergy.com'],
  },
  {
    name: 'Rakuten Marketing',
    company: 'Rakuten',
    categories: ['ad'],
    domains: ['*.rakuten-static.com', '*.rmtag.com'],
  },
  {
    name: 'Rakuten MediaForge',
    company: 'Rakuten',
    categories: ['ad'],
    domains: ['*.mediaforge.com'],
  },
  {
    name: 'Rambler',
    company: 'Rambler & Co',
    categories: ['utility'],
    domains: ['*.rambler.ru'],
  },
  {
    name: 'Ranker',
    categories: ['content'],
    domains: ['*.ranker.com', '*.rnkr-static.com'],
  },
  {
    name: 'Ravelin',
    categories: ['utility'],
    domains: ['*.ravelin.com'],
  },
  {
    name: 'Raygun',
    categories: ['utility'],
    domains: ['*.raygun.io'],
  },
  {
    name: 'ReCollect',
    categories: ['utility'],
    domains: ['*.recollect.net'],
  },
  {
    name: 'ReSRC',
    categories: ['utility'],
    domains: ['*.resrc.it'],
  },
  {
    name: 'ReTargeter',
    categories: ['ad'],
    domains: ['*.retargeter.com'],
  },
  {
    name: 'Reach Group',
    categories: ['ad'],
    domains: ['*.redintelligence.net'],
  },
  {
    name: 'ReachDynamics',
    categories: ['ad'],
    domains: ['*.rdcdn.com'],
  },
  {
    name: 'ReachForce',
    categories: ['ad'],
    domains: ['*.reachforce.com'],
  },
  {
    name: 'ReachLocal',
    categories: ['ad'],
    domains: ['*.rtrk.co.nz'],
    examples: ['rtsys.rtrk.co.nz'],
  },
  {
    name: 'ReachMee',
    categories: ['content'],
    domains: ['*.reachmee.com'],
  },
  {
    name: 'Reactful',
    categories: ['analytics'],
    domains: ['*.reactful.com'],
  },
  {
    name: 'Realtime',
    company: 'internet business technologies',
    categories: ['utility'],
    domains: ['*.realtime.co'],
  },
  {
    name: 'Realtime Media (Brian Communications)',
    categories: ['ad'],
    domains: ['*.rtm.com'],
  },
  {
    name: 'Realtime Targeting',
    categories: ['ad'],
    domains: ['*.idtargeting.com'],
  },
  {
    name: 'Realytics',
    categories: ['analytics'],
    domains: ['dcniko1cv0rz.cloudfront.net', '*.realytics.net'],
  },
  {
    name: 'RebelMouse',
    categories: ['ad'],
    domains: ['*.rebelmouse.com', '*.rbl.ms'],
    examples: ['www.rebelmouse.com'],
  },
  {
    name: 'Receiptful',
    categories: ['utility'],
    domains: ['*.receiptful.com'],
  },
  {
    name: 'Recite Me',
    categories: ['other'],
    domains: ['*.reciteme.com'],
  },
  {
    name: 'RecoBell',
    categories: ['analytics'],
    domains: ['*.recobell.io'],
  },
  {
    name: 'Recommend',
    categories: ['analytics'],
    domains: ['*.recommend.pro'],
  },
  {
    name: 'Red Eye International',
    categories: ['ad'],
    domains: ['*.pajmc.com'],
  },
  {
    name: 'Redfish Group',
    categories: ['ad'],
    domains: ['*.wmps.com'],
  },
  {
    name: 'Reevoo',
    categories: ['analytics'],
    domains: ['*.reevoo.com'],
  },
  {
    name: 'Refersion',
    categories: ['ad'],
    domains: ['*.refersion.com'],
  },
  {
    name: 'Refined Ads',
    categories: ['ad'],
    domains: ['*.refinedads.com'],
  },
  {
    name: 'Reflektion',
    categories: ['analytics'],
    domains: ['*.reflektion.com', 'd26opx5dl8t69i.cloudfront.net'],
  },
  {
    name: 'Reflow',
    company: 'Scenestealer',
    categories: ['ad'],
    domains: ['*.reflow.tv'],
  },
  {
    name: 'Reklama',
    categories: ['ad'],
    domains: ['*.o2.pl', '*.wp.pl'],
    examples: ['dot.wp.pl', 'px.o2.pl', 'px.wp.pl'],
  },
  {
    name: 'Relevad ReleStar',
    company: 'Relevad',
    categories: ['ad'],
    domains: ['*.relestar.com'],
  },
  {
    name: 'Remarketing Pixel',
    company: 'Adsterra Network',
    categories: ['ad'],
    domains: ['*.datadbs.com', '*.remarketingpixel.com'],
  },
  {
    name: 'Remintrex',
    company: 'SmartUp Venture',
    categories: ['ad'],
    domains: ['*.remintrex.com'],
  },
  {
    name: 'Republer',
    categories: ['ad'],
    domains: ['*.republer.com'],
    examples: ['sync.republer.com'],
  },
  {
    name: 'Research Now',
    categories: ['analytics'],
    domains: ['*.researchgnow.com', '*.researchnow.com'],
    examples: ['tag.researchgnow.com'],
  },
  {
    name: 'Research Online',
    company: 'Skills Development Scotland',
    categories: ['content'],
    domains: ['*.researchonline.org.uk'],
    examples: ['www.researchonline.org.uk'],
  },
  {
    name: 'Resonance Insights',
    categories: ['analytics'],
    domains: ['*.res-x.com'],
  },
  {
    name: 'Resonate Networks',
    categories: ['analytics'],
    domains: ['*.reson8.com'],
  },
  {
    name: 'Response Team',
    categories: ['ad'],
    domains: ['*.i-transactads.com'],
  },
  {
    name: 'ResponseTap',
    categories: ['analytics'],
    domains: ['*.adinsight.com', '*.responsetap.com'],
  },
  {
    name: 'ResponsiveVoice',
    categories: ['other'],
    domains: ['*.responsivevoice.org'],
  },
  {
    name: 'Retention Science',
    categories: ['ad'],
    domains: ['*.retentionscience.com', 'd1stxfv94hrhia.cloudfront.net'],
  },
  {
    name: 'Revcontent',
    categories: ['content'],
    domains: ['*.revcontent.com'],
  },
  {
    name: 'Revee',
    categories: ['ad'],
    domains: ['*.revee.com'],
  },
  {
    name: 'Revenue Conduit',
    categories: ['utility'],
    domains: ['*.revenueconduit.com'],
  },
  {
    name: 'RevenueMantra',
    categories: ['ad'],
    domains: ['*.revenuemantra.com'],
  },
  {
    name: 'Reviews.co.uk',
    categories: ['analytics'],
    domains: ['*.reviews.co.uk'],
  },
  {
    name: 'Reviews.io',
    categories: ['analytics'],
    domains: ['*.reviews.io'],
  },
  {
    name: 'Revolver Maps',
    categories: ['analytics'],
    domains: ['*.revolvermaps.com'],
  },
  {
    name: 'Revv',
    categories: ['utility'],
    domains: ['*.revv.co'],
  },
  {
    name: 'RichRelevance',
    categories: ['analytics'],
    domains: ['*.richrelevance.com'],
  },
  {
    name: 'RightNow Service Cloud',
    company: 'Oracle',
    categories: ['customer-success'],
    domains: ['*.rightnowtech.com', '*.rnengage.com'],
  },
  {
    name: 'Rightster',
    categories: ['ad'],
    domains: ['*.ads-creativesyndicator.com'],
  },
  {
    name: 'Riskified',
    categories: ['utility'],
    domains: ['*.riskified.com'],
  },
  {
    name: 'Rocket Fuel',
    categories: ['ad'],
    domains: ['*.rfihub.com', '*.ru4.com', '*.rfihub.net', '*.ad1x.com'],
  },
  {
    name: 'Rollbar',
    categories: ['utility'],
    domains: ['*.rollbar.com', 'd37gvrvc0wt4s1.cloudfront.net'],
    examples: ['api.rollbar.com'],
  },
  {
    name: 'RomanCart',
    categories: ['utility'],
    domains: ['*.romancart.com'],
  },
  {
    name: 'Rondavu',
    categories: ['ad'],
    domains: ['*.rondavu.com'],
  },
  {
    name: 'Roomkey',
    categories: ['content'],
    domains: ['*.roomkey.com'],
    examples: ['www.roomkey.com'],
  },
  {
    name: 'Roost',
    categories: ['utility'],
    domains: ['*.goroost.com'],
  },
  {
    name: 'Roxot',
    categories: ['ad'],
    domains: ['*.rxthdr.com'],
  },
  {
    name: 'Roxr Software',
    categories: ['analytics'],
    domains: ['*.getclicky.com'],
  },
  {
    name: 'Rubikloud.com',
    categories: ['analytics'],
    domains: ['*.rubikloud.com'],
  },
  {
    name: 'Ruler Analytics',
    company: 'Ruler',
    categories: ['analytics'],
    domains: ['*.nyltx.com', '*.ruleranalytics.com'],
    examples: ['www.ruleranalytics.com'],
  },
  {
    name: 'Runner',
    company: 'Rambler & Co',
    categories: ['content'],
    domains: ['*.begun.ru'],
  },
  {
    name: 'S4M',
    categories: ['ad'],
    domains: ['*.sam4m.com'],
  },
  {
    name: 'SAP Hybris Marketing Convert',
    company: 'SAP',
    categories: ['ad'],
    domains: ['*.seewhy.com'],
  },
  {
    name: 'SAS Institute',
    categories: ['ad'],
    domains: ['*.aimatch.com', '*.sas.com'],
  },
  {
    name: 'SC ShopMania Net SRL',
    categories: ['content'],
    domains: ['*.shopmania.com'],
  },
  {
    name: 'SDL Media Manager',
    company: 'SDL',
    categories: ['other'],
    domains: ['*.sdlmedia.com'],
  },
  {
    name: 'SFR',
    categories: ['other'],
    domains: ['*.sfr.fr'],
    examples: ['elr.sfr.fr'],
  },
  {
    name: 'SLI Systems',
    categories: ['utility'],
    domains: ['*.resultslist.com', '*.resultspage.com', '*.sli-spark.com'],
  },
  {
    name: 'SMARTASSISTANT',
    company: 'Smart Information Systems',
    categories: ['customer-success'],
    domains: ['*.smartassistant.com'],
  },
  {
    name: 'SMARTSTREAM.TV',
    categories: ['ad'],
    domains: ['*.smartstream.tv'],
  },
  {
    name: 'SPX',
    company: 'Smaato',
    categories: ['ad'],
    domains: ['*.smaato.net'],
  },
  {
    name: 'Sabio',
    categories: ['customer-success'],
    domains: ['*.sabio.co.uk'],
    examples: ['www.sabio.co.uk'],
  },
  {
    name: 'Sailthru',
    categories: ['analytics'],
    domains: ['*.sail-horizon.com', '*.sail-personalize.com', '*.sail-track.com'],
  },
  {
    name: 'Sailthru Sightlines',
    company: 'Sailthru',
    categories: ['marketing'],
    domains: ['*.sailthru.com'],
  },
  {
    name: 'Sajari Pty',
    categories: ['utility'],
    domains: ['*.sajari.com'],
  },
  {
    name: 'SaleCycle',
    categories: ['ad'],
    domains: [
      '*.salecycle.com',
      'd16fk4ms6rqz1v.cloudfront.net',
      'd22j4fzzszoii2.cloudfront.net',
      'd30ke5tqu2tkyx.cloudfront.net',
      'dn1i8v75r669j.cloudfront.net',
    ],
  },
  {
    name: 'Salesforce Live Agent',
    company: 'Salesforce.com',
    categories: ['customer-success'],
    domains: ['*.salesforceliveagent.com'],
  },
  {
    name: 'Salesforce.com',
    categories: ['ad'],
    domains: ['*.force.com', '*.salesforce.com'],
    examples: ['secure.force.com'],
  },
  {
    name: 'Samba TV',
    company: 'Samba',
    categories: ['content'],
    domains: ['*.samba.tv'],
  },
  {
    name: 'Samplicio.us',
    categories: ['analytics'],
    domains: ['*.samplicio.us'],
  },
  {
    name: 'Say Media',
    categories: ['ad'],
    domains: ['*.saymedia.com'],
  },
  {
    name: 'Scenario',
    categories: ['analytics'],
    domains: ['*.getscenario.com'],
  },
  {
    name: 'Schuh (image shard)',
    company: 'Schuh',
    categories: ['other'],
    domains: ['d2ob0iztsaxy5v.cloudfront.net'],
  },
  {
    name: 'Science Rockstars',
    categories: ['analytics'],
    domains: ['*.persuasionapi.com'],
  },
  {
    name: 'ScientiaMobile',
    categories: ['analytics'],
    domains: ['*.wurflcloud.com', '*.wurfl.io'],
  },
  {
    name: 'Scoota',
    categories: ['ad'],
    domains: [
      '*.rockabox.co',
      '*.scoota.co',
      'd31i2625d5nv27.cloudfront.net',
      'dyjnzf8evxrp2.cloudfront.net',
    ],
  },
  {
    name: 'ScribbleLive',
    categories: ['ad'],
    domains: ['*.scribblelive.com'],
  },
  {
    name: 'SearchForce',
    categories: ['ad'],
    domains: ['*.searchforce.net'],
  },
  {
    name: 'SearchSpring',
    categories: ['utility'],
    domains: ['*.searchspring.net'],
  },
  {
    name: 'Searchanise',
    categories: ['analytics'],
    domains: ['*.searchanise.com'],
    examples: ['www.searchanise.com'],
  },
  {
    name: 'Sears Holdings',
    categories: ['content'],
    domains: ['*.shld.net'],
  },
  {
    name: 'Secomapp',
    categories: ['utility'],
    domains: ['*.secomapp.com'],
  },
  {
    name: 'SecuredVisit',
    company: '4Cite Marketing',
    categories: ['ad'],
    domains: ['*.securedvisit.com'],
  },
  {
    name: 'SecurityMetrics',
    categories: ['utility'],
    domains: ['*.securitymetrics.com'],
  },
  {
    name: 'Segmento',
    categories: ['ad'],
    domains: ['*.rutarget.ru'],
  },
  {
    name: 'Segmint',
    categories: ['analytics'],
    domains: ['*.segmint.net'],
  },
  {
    name: 'Sekindo',
    categories: ['content'],
    domains: ['*.sekindo.com'],
  },
  {
    name: 'Seldon',
    categories: ['analytics'],
    domains: ['*.rummblelabs.com'],
  },
  {
    name: 'SelectMedia International',
    categories: ['content'],
    domains: ['*.selectmedia.asia'],
  },
  {
    name: 'Selligent',
    categories: ['ad'],
    domains: ['*.emsecure.net'],
  },
  {
    name: 'Sellpoints',
    categories: ['analytics'],
    domains: ['*.sellpoints.com'],
  },
  {
    name: 'Semantics3',
    categories: ['analytics'],
    domains: ['*.hits.io'],
  },
  {
    name: 'Semasio',
    categories: ['analytics'],
    domains: ['*.semasio.net'],
  },
  {
    name: 'Semcasting Site Visitor Attribution',
    company: 'Semcasting',
    categories: ['ad'],
    domains: ['*.smartzonessva.com'],
  },
  {
    name: 'Sentifi',
    categories: ['social'],
    domains: ['*.sentifi.com'],
  },
  {
    name: 'ServMetric',
    categories: ['analytics'],
    domains: ['*.servmetric.com'],
  },
  {
    name: 'ServiceSource International',
    categories: ['marketing'],
    domains: ['*.scoutanalytics.net'],
    examples: ['scout.scoutanalytics.net'],
  },
  {
    name: 'ServiceTick',
    categories: ['analytics'],
    domains: ['*.servicetick.com'],
  },
  {
    name: 'Servo',
    company: 'Xervo',
    categories: ['hosting'],
    domains: ['*.onmodulus.net'],
  },
  {
    name: 'SessionCam',
    company: 'ServiceTick',
    categories: ['analytics'],
    domains: ['*.sessioncam.com', 'd2oh4tlt9mrke9.cloudfront.net'],
  },
  {
    name: 'Seznam',
    categories: ['utility'],
    domains: ['*.imedia.cz'],
  },
  {
    name: 'Sharethrough',
    categories: ['ad'],
    domains: ['*.sharethrough.com'],
  },
  {
    name: 'SharpSpring',
    categories: ['marketing'],
    domains: ['*.sharpspring.com', '*.marketingautomation.services'],
  },
  {
    name: 'ShopRunner',
    categories: ['content'],
    domains: ['*.shoprunner.com', '*.s-9.us'],
  },
  {
    name: 'ShopStorm',
    categories: ['utility'],
    domains: ['*.shopstorm.com'],
  },
  {
    name: 'Shopatron',
    categories: ['hosting'],
    domains: ['*.shopatron.com'],
  },
  {
    name: 'Shopgate',
    categories: ['utility'],
    domains: ['*.shopgate.com'],
  },
  {
    name: 'ShopiMind',
    company: 'ShopIMind',
    categories: ['ad'],
    domains: ['*.shopimind.com'],
  },
  {
    name: 'Shopkeeper Tools',
    categories: ['utility'],
    domains: ['*.shopkeepertools.com'],
  },
  {
    name: 'Sidecar',
    categories: ['other'],
    domains: ['*.getsidecar.com', 'd3v27wwd40f0xu.cloudfront.net'],
  },
  {
    name: 'Sidereel',
    categories: ['analytics'],
    domains: ['*.sidereel.com'],
  },
  {
    name: 'Sift Science',
    categories: ['utility'],
    domains: ['*.siftscience.com'],
  },
  {
    name: 'Signal',
    categories: ['tag-manager'],
    domains: ['*.sitetagger.co.uk'],
  },
  {
    name: 'Signyfyd',
    categories: ['utility'],
    domains: ['*.signifyd.com'],
  },
  {
    name: 'Silktide',
    categories: ['hosting'],
    domains: ['*.silktide.com'],
  },
  {
    name: 'Silverpop',
    company: 'IBM',
    categories: ['ad'],
    domains: [
      '*.mkt912.com',
      '*.mkt922.com',
      '*.mkt932.com',
      '*.mkt941.com',
      '*.mkt51.net',
      '*.mkt61.net',
      '*.pages01.net',
      '*.pages02.net',
      '*.pages03.net',
      '*.pages04.net',
      '*.pages05.net',
    ],
  },
  {
    name: 'Simplaex',
    categories: ['marketing'],
    domains: ['*.simplaex.net'],
  },
  {
    name: 'SimpleReach',
    categories: ['analytics'],
    domains: ['*.simplereach.com', 'd8rk54i4mohrb.cloudfront.net'],
  },
  {
    name: 'Simplestream',
    categories: ['content'],
    domains: ['*.simplestream.com'],
    examples: ['player.simplestream.com'],
  },
  {
    name: 'Simpli.fi',
    categories: ['ad'],
    domains: ['*.simpli.fi'],
  },
  {
    name: 'Simplicity Marketing',
    categories: ['ad'],
    domains: ['*.flashtalking.com'],
  },
  {
    name: 'SinnerSchrader Deutschland',
    categories: ['ad'],
    domains: ['*.s2Betrieb.de'],
  },
  {
    name: 'Sirv',
    categories: ['other'],
    domains: ['*.sirv.com'],
  },
  {
    name: 'Site Meter',
    categories: ['analytics'],
    domains: ['*.sitemeter.com'],
  },
  {
    name: 'Site24x7 Real User Monitoring',
    company: 'Site24x7',
    categories: ['analytics'],
    domains: ['*.site24x7rum.com'],
  },
  {
    name: 'SiteGainer',
    categories: ['analytics'],
    domains: ['*.sitegainer.com', 'd191y0yd6d0jy4.cloudfront.net'],
  },
  {
    name: 'SiteScout',
    company: 'Centro',
    categories: ['ad'],
    domains: ['*.pixel.ad', '*.sitescout.com'],
  },
  {
    name: 'Siteimprove',
    categories: ['utility'],
    domains: ['*.siteimprove.com', '*.siteimproveanalytics.com'],
  },
  {
    name: 'Six Degrees Group',
    categories: ['hosting'],
    domains: ['*.fstech.co.uk'],
  },
  {
    name: 'Skimbit',
    categories: ['ad'],
    domains: ['*.redirectingat.com', '*.skimresources.com', '*.skimresources.net'],
  },
  {
    name: 'Skimlinks',
    categories: ['ad'],
    domains: ['*.skimlinks.com'],
  },
  {
    name: 'SkyGlue Technology',
    categories: ['analytics'],
    domains: ['*.skyglue.com'],
  },
  {
    name: 'SkyScanner',
    categories: ['content'],
    domains: ['*.skyscanner.net'],
    examples: ['api.skyscanner.net'],
  },
  {
    name: 'Skybet',
    company: 'Bonne Terre t/a Sky Vegas (Sky)',
    categories: ['other'],
    domains: ['*.skybet.com'],
  },
  {
    name: 'Skype',
    categories: ['other'],
    domains: ['*.skype.com'],
  },
  {
    name: 'Slate Group',
    categories: ['content'],
    domains: ['*.cdnslate.com'],
  },
  {
    name: 'SlimCut Media Outstream',
    company: 'SlimCut Media',
    categories: ['ad'],
    domains: ['*.freeskreen.com'],
  },
  {
    name: 'Smart Insight Tracking',
    company: 'Emarsys',
    categories: ['analytics'],
    domains: ['*.scarabresearch.com'],
  },
  {
    name: 'Smart AdServer',
    categories: ['ad'],
    domains: ['*.01net.com', '*.sascdn.com', '*.sasqos.com', '*.smartadserver.com'],
    examples: ['securite.01net.com'],
  },
  {
    name: 'SmartFocus',
    categories: ['analytics'],
    domains: [
      '*.emv2.com',
      '*.emv3.com',
      '*.predictiveintent.com',
      '*.smartfocus.com',
      '*.themessagecloud.com',
    ],
  },
  {
    name: 'Smarter Click',
    categories: ['ad'],
    domains: ['*.smct.co', '*.smarterclick.co.uk'],
  },
  {
    name: 'SmarterHQ',
    categories: ['analytics'],
    domains: ['*.smarterhq.io', 'd1n00d49gkbray.cloudfront.net', '*.smarterremarketer.net'],
  },
  {
    name: 'Smarttools',
    categories: ['customer-success'],
    domains: ['*.smartertrack.com'],
  },
  {
    name: 'Smartzer',
    categories: ['ad'],
    domains: ['*.smartzer.com'],
  },
  {
    name: 'Snack Media',
    categories: ['content'],
    domains: ['*.snack-media.com'],
  },
  {
    name: 'Snacktools',
    categories: ['ad'],
    domains: ['*.bannersnack.com'],
  },
  {
    name: 'SnapEngage',
    categories: ['customer-success'],
    domains: ['*.snapengage.com'],
  },
  {
    name: 'SnapWidget',
    categories: ['content'],
    domains: ['*.snapwidget.com'],
  },
  {
    name: 'Soasta',
    categories: ['analytics'],
    domains: ['*.lognormal.net'],
  },
  {
    name: 'SociableLabs',
    categories: ['ad'],
    domains: ['*.sociablelabs.net', '*.sociablelabs.com'],
  },
  {
    name: 'Social Annex',
    categories: ['customer-success'],
    domains: ['*.socialannex.com'],
  },
  {
    name: 'SocialShopWave',
    categories: ['social'],
    domains: ['*.socialshopwave.com'],
  },
  {
    name: 'Socialphotos',
    categories: ['social'],
    domains: ['*.slpht.com'],
  },
  {
    name: 'Sociomantic Labs',
    company: 'DunnHumby',
    categories: ['ad'],
    domains: ['*.sociomantic.com'],
  },
  {
    name: 'SodaHead',
    categories: ['analytics'],
    domains: ['*.sodahead.com'],
    examples: ['pollware-cdn.sodahead.com'],
  },
  {
    name: 'Softwebzone',
    categories: ['hosting'],
    domains: ['*.softwebzone.com'],
    examples: ['www.softwebzone.com'],
  },
  {
    name: 'Sojern',
    categories: ['marketing'],
    domains: ['*.sojern.com'],
  },
  {
    name: 'Sokrati',
    categories: ['marketing'],
    domains: ['*.sokrati.com'],
  },
  {
    name: 'Sonobi',
    categories: ['ad'],
    domains: ['*.sonobi.com'],
  },
  {
    name: 'Sooqr Search',
    company: 'Sooqr',
    categories: ['utility'],
    domains: ['*.sooqr.com'],
  },
  {
    name: 'Sophus3',
    categories: ['analytics'],
    domains: ['*.s3ae.com', '*.sophus3.com'],
  },
  {
    name: 'Sorenson Media',
    categories: ['content'],
    domains: ['*.sorensonmedia.com'],
  },
  {
    name: 'Sortable',
    categories: ['ad'],
    domains: ['*.deployads.com'],
  },
  {
    name: 'Sotic',
    categories: ['hosting'],
    domains: ['*.sotic.net', '*.soticservers.net'],
  },
  {
    name: 'Soundest',
    categories: ['ad'],
    domains: ['*.soundestlink.com', '*.soundest.net'],
  },
  {
    name: 'Sourcepoint',
    categories: ['ad'],
    domains: [
      '*.decenthat.com',
      '*.fallingfalcon.com',
      '*.summerhamster.com',
      'd2lv4zbk7v5f93.cloudfront.net',
      'd3qxwzhswv93jk.cloudfront.net',
    ],
    examples: ['www.decenthat.com', 'www.fallingfalcon.com', 'www.summerhamster.com'],
  },
  {
    name: 'SourceKnowledge',
    homepage: 'http://www.sourceknowledge.com',
    categories: ['ad'],
    domains: ['*.provenpixel.com'],
  },
  {
    name: 'SpaceNet',
    categories: ['hosting'],
    domains: ['*.nmm.de'],
  },
  {
    name: 'Sparkflow',
    company: 'Intercept Interactive',
    categories: ['ad'],
    domains: ['*.sparkflow.net'],
  },
  {
    name: 'Specific Media',
    categories: ['ad'],
    domains: ['*.specificmedia.com', '*.adviva.net', '*.specificclick.net'],
  },
  {
    name: 'Spicy',
    company: 'Data-Centric Alliance',
    categories: ['ad'],
    domains: ['*.sspicy.ru'],
  },
  {
    name: 'Spoke',
    categories: ['customer-success'],
    domains: ['*.121d8.com'],
  },
  {
    name: 'Spongecell',
    categories: ['ad'],
    domains: ['*.spongecell.com'],
  },
  {
    name: 'Spot.IM',
    categories: ['social'],
    domains: ['*.spot.im', '*.spotim.market'],
  },
  {
    name: 'SpotXchange',
    categories: ['ad'],
    domains: ['*.spotxcdn.com', '*.spotxchange.com', '*.spotx.tv'],
  },
  {
    name: 'SpringServer',
    categories: ['ad'],
    domains: ['*.springserve.com'],
  },
  {
    name: 'Spylight',
    categories: ['other'],
    domains: ['*.spylight.com'],
  },
  {
    name: 'SreamAMG',
    company: 'StreamAMG',
    categories: ['other'],
    domains: ['*.streamamg.com'],
  },
  {
    name: 'StackAdapt',
    categories: ['ad'],
    domains: ['*.stackadapt.com'],
  },
  {
    name: 'StackExchange',
    categories: ['social'],
    domains: ['*.sstatic.net'],
  },
  {
    name: 'Stackla PTY',
    categories: ['social'],
    domains: ['*.stackla.com'],
  },
  {
    name: 'Stailamedia',
    categories: ['ad'],
    domains: ['*.stailamedia.com'],
  },
  {
    name: 'Stamped.io',
    categories: ['analytics'],
    domains: ['*.stamped.io'],
  },
  {
    name: 'Starfield Services Root Certificate Authority',
    company: 'Starfield Technologies',
    categories: ['utility'],
    domains: ['*.starfieldtech.com', 'ss2.us'],
    examples: ['ocsp.starfieldtech.com'],
  },
  {
    name: 'Starfield Technologies',
    categories: ['utility'],
    domains: ['*.websiteprotection.com'],
    examples: ['seals.websiteprotection.com'],
  },
  {
    name: 'StatCounter',
    categories: ['analytics'],
    domains: ['*.statcounter.com'],
  },
  {
    name: 'Statful',
    categories: ['analytics'],
    domains: ['*.statful.com'],
  },
  {
    name: 'Steelhouse',
    categories: ['ad'],
    domains: ['*.steelhousemedia.com'],
  },
  {
    name: 'Steepto',
    categories: ['ad'],
    domains: ['*.steepto.com'],
  },
  {
    name: 'StellaService',
    categories: ['analytics'],
    domains: ['*.stellaservice.com'],
  },
  {
    name: 'StickyADS.tv',
    categories: ['ad'],
    domains: ['*.stickyadstv.com'],
  },
  {
    name: 'STINGRAY',
    company: 'FlexOne',
    categories: ['ad'],
    domains: ['*.impact-ad.jp'],
    examples: ['y.one.impact-ad.jp'],
  },
  {
    name: 'Storify',
    company: 'Adobe Systems',
    categories: ['social'],
    domains: ['*.storify.com'],
  },
  {
    name: 'Storm Tag Manager',
    company: 'Rakuten',
    categories: ['tag-manager'],
    domains: ['*.stormcontainertag.com'],
  },
  {
    name: 'Storygize',
    categories: ['ad'],
    domains: ['*.storygize.net'],
    examples: ['www.storygize.net'],
  },
  {
    name: 'Strands',
    categories: ['utility'],
    domains: ['*.strands.com'],
  },
  {
    name: 'StreamRail',
    categories: ['ad'],
    domains: ['*.streamrail.com', '*.streamrail.net'],
  },
  {
    name: 'StrikeAd',
    categories: ['ad'],
    domains: ['*.strikead.com'],
  },
  {
    name: 'Struq',
    company: 'Quantcast',
    categories: ['ad'],
    domains: ['*.struq.com'],
  },
  {
    name: 'StrÃ¶er Digital Media',
    categories: ['ad'],
    domains: ['*.stroeerdigitalmedia.de'],
  },
  {
    name: 'StumbleUpon',
    categories: ['content'],
    domains: ['*.stumble-upon.com', '*.stumbleupon.com'],
  },
  {
    name: 'Sub2 Technologies',
    categories: ['analytics'],
    domains: ['*.sub2tech.com'],
  },
  {
    name: 'SublimeSkinz',
    categories: ['ad'],
    domains: ['*.ayads.co'],
  },
  {
    name: 'Sumo Logic',
    categories: ['utility'],
    domains: ['*.sumologic.com'],
  },
  {
    name: 'Sunday Times Driving',
    categories: ['content'],
    domains: ['*.driving.co.uk'],
  },
  {
    name: 'SundaySky',
    categories: ['ad'],
    domains: ['*.sundaysky.com', 'dds6m601du5ji.cloudfront.net'],
  },
  {
    name: 'Sunrise Integration',
    categories: ['utility'],
    domains: ['*.sunriseintegration.com'],
  },
  {
    name: 'Supertool Network Technology',
    categories: ['analytics'],
    domains: ['*.miaozhen.com'],
  },
  {
    name: 'Survata',
    categories: ['analytics'],
    domains: ['*.survata.com'],
  },
  {
    name: 'SurveyGizmo',
    categories: ['analytics'],
    domains: ['*.surveygizmo.eu'],
    examples: ['www.surveygizmo.eu'],
  },
  {
    name: 'SurveyMonkey',
    categories: ['analytics'],
    domains: ['*.surveymonkey.com'],
  },
  {
    name: 'Survicate',
    categories: ['analytics'],
    domains: ['*.survicate.com'],
  },
  {
    name: 'Sweet Tooth',
    categories: ['ad'],
    domains: ['*.sweettooth.io'],
  },
  {
    name: 'Swiftype',
    categories: ['utility'],
    domains: ['*.swiftype.com', '*.swiftypecdn.com'],
  },
  {
    name: 'Switch Concepts',
    categories: ['ad'],
    domains: ['*.switchadhub.com'],
  },
  {
    name: 'SwitchAds',
    company: 'Switch Concepts',
    categories: ['ad'],
    domains: ['*.switchads.com'],
  },
  {
    name: 'Swogo',
    categories: ['analytics'],
    domains: ['*.xsellapp.com'],
  },
  {
    name: 'Swoop',
    categories: ['ad'],
    domains: ['*.swoop.com'],
  },
  {
    name: 'Symantec',
    categories: ['utility'],
    domains: ['*.norton.com', '*.symantec.com', '*.symcb.com', '*.symcd.com'],
    examples: ['extended-validation-ssl.websecurity.symantec.com'],
  },
  {
    name: 'Syncapse',
    categories: ['social'],
    domains: ['*.clickable.net'],
  },
  {
    name: 'Synergetic',
    categories: ['ad'],
    domains: ['*.synergetic.ag'],
  },
  {
    name: 'Synthetix',
    categories: ['customer-success'],
    domains: ['*.syn-finity.com', '*.synthetix-ec1.com', '*.synthetix.com'],
    examples: ['www.synthetix-ec1.com'],
  },
  {
    name: 'TINT',
    categories: ['content'],
    domains: [
      '*.71n7.com',
      'd33w9bm0n1egwm.cloudfront.net',
      'd36hc0p18k1aoc.cloudfront.net',
      'd3l7tj34e9fc43.cloudfront.net',
    ],
    examples: ['www.71n7.com'],
  },
  {
    name: 'TNS (Kantar Group)',
    categories: ['analytics'],
    domains: ['*.tns-counter.ru'],
  },
  {
    name: 'TRUSTe',
    categories: ['utility'],
    domains: ['*.truste.com'],
  },
  {
    name: 'TV Genius',
    company: 'Ericcson Media Services',
    categories: ['content'],
    domains: ['*.tvgenius.net'],
  },
  {
    name: 'TVSquared',
    categories: ['ad'],
    domains: ['*.tvsquared.com'],
  },
  {
    name: 'TVTY',
    categories: ['ad'],
    domains: ['*.distribeo.com', '*.ogigl.com'],
  },
  {
    name: 'Tactics bvba',
    categories: ['hosting'],
    domains: ['*.influid.co'],
  },
  {
    name: 'Tag Inspector',
    company: 'InfoTrust',
    categories: ['analytics'],
    domains: ['d22xmn10vbouk4.cloudfront.net'],
  },
  {
    name: 'TagCommander',
    categories: ['tag-manager'],
    domains: ['*.commander1.com', '*.tagcommander.com'],
  },
  {
    name: 'Tagboard',
    categories: ['social'],
    domains: ['*.tagboard.com'],
  },
  {
    name: 'Taggstar',
    company: 'Taggstar UK',
    categories: ['ad'],
    domains: ['*.taggstar.com'],
  },
  {
    name: 'Tail Target',
    company: 'Tail',
    categories: ['ad'],
    domains: ['*.tailtarget.com'],
  },
  {
    name: 'Tailored',
    categories: ['other'],
    domains: ['d24qm7bu56swjs.cloudfront.net', 'dw3vahmen1rfy.cloudfront.net', '*.tailored.to'],
  },
  {
    name: 'Taleo Enterprise Cloud Service',
    company: 'Oracle',
    categories: ['content'],
    domains: ['*.taleo.net'],
  },
  {
    name: 'Talkable',
    categories: ['ad'],
    domains: ['*.talkable.com', 'd2jjzw81hqbuqv.cloudfront.net'],
    examples: ['www.talkable.com'],
  },
  {
    name: 'TapSense',
    categories: ['ad'],
    domains: ['*.tapsense.com'],
  },
  {
    name: 'Tapad',
    categories: ['ad'],
    domains: ['*.tapad.com'],
  },
  {
    name: 'Teads',
    categories: ['ad'],
    domains: ['*.teads.tv'],
  },
  {
    name: 'Team Internet Tonic',
    company: 'Team Internet',
    categories: ['ad'],
    domains: ['*.dntrax.com'],
  },
  {
    name: 'TechTarget',
    categories: ['content'],
    domains: ['*.techtarget.com', '*.ttgtmedia.com'],
  },
  {
    name: 'Technorati',
    company: 'Synacor',
    categories: ['ad'],
    domains: ['*.technoratimedia.com'],
  },
  {
    name: 'Teedhaze',
    categories: ['content'],
    domains: ['*.fuel451.com'],
  },
  {
    name: 'Tell Apart',
    categories: ['analytics'],
    domains: ['*.tellapart.com', '*.tellaparts.com'],
  },
  {
    name: 'Tencent',
    categories: ['content'],
    domains: ['*.qq.com', '*.ywxi.net'],
  },
  {
    name: 'Thanx Media',
    categories: ['utility'],
    domains: ['*.hawksearch.info'],
  },
  {
    name: 'Thawte',
    categories: ['utility'],
    domains: ['*.thawte.com'],
    examples: ['ocsp.thawte.com', 'seal.thawte.com'],
  },
  {
    name: 'The AA',
    categories: ['ad'],
    domains: ['*.adstheaa.com'],
  },
  {
    name: 'The ADEX',
    categories: ['ad'],
    domains: ['*.theadex.com'],
  },
  {
    name: 'The Best Day',
    categories: ['social'],
    domains: ['*.thebestday.com'],
  },
  {
    name: 'The Filter',
    company: 'Exabre',
    categories: ['analytics'],
    domains: ['*.thefilter.com'],
  },
  {
    name: 'The Guardian',
    categories: ['analytics'],
    domains: ['*.ophan.co.uk'],
  },
  {
    name: 'The Hut Group',
    categories: ['content'],
    domains: ['*.thcdn.com'],
  },
  {
    name: 'The Numa Group',
    categories: ['other'],
    domains: ['*.hittail.com'],
  },
  {
    name: 'The Publisher Desk',
    categories: ['ad'],
    domains: ['*.206ads.com', '*.publisherdesk.com'],
  },
  {
    name: 'The Sydney Morning Herald',
    company: 'Fairfax Media',
    categories: ['content'],
    domains: ['*.smh.com.au'],
  },
  {
    name: 'The Wall Street Jounal',
    categories: ['content'],
    domains: ['*.wsj.net'],
  },
  {
    name: 'The Wall Street Journal',
    categories: ['content'],
    domains: ['*.marketwatch.com'],
  },
  {
    name: 'TheFind',
    categories: ['content'],
    domains: ['*.thefind.com'],
  },
  {
    name: 'Thinglink',
    categories: ['utility'],
    domains: ['*.thinglink.com'],
  },
  {
    name: 'Thirdpresence',
    categories: ['ad'],
    domains: ['*.thirdpresence.com'],
  },
  {
    name: 'ThreatMetrix',
    categories: ['utility'],
    domains: ['*.online-metrix.net'],
  },
  {
    name: 'Throtle',
    homepage: 'https://throtle.io/',
    categories: ['analytics'],
    domains: ['*.thrtle.com', '*.v12group.com'],
  },
  {
    name: 'TicketMaster',
    categories: ['content'],
    domains: ['*.t-x.io', '*.tmcs.net'],
  },
  {
    name: 'TikTok',
    company: 'ByteDance Ltd',
    homepage: 'https://www.tiktok.com/en/',
    categories: ['social'],
    domains: ['*.tiktok.com', '*.ipstatp.com'],
    examples: ['analytics.tiktok.com', 'https://s0.ipstatp.com/ad/business/track-log.js'],
  },
  {
    name: 'Tidio Live Chat',
    company: 'Tidio',
    homepage: 'https://www.tidiochat.com/en/',
    categories: ['customer-success'],
    domains: ['*.tidiochat.com'],
  },
  {
    name: 'Time',
    categories: ['content'],
    domains: ['*.timeinc.net'],
  },
  {
    name: 'Time2Perf',
    categories: ['ad'],
    domains: ['*.time2perf.com'],
  },
  {
    name: 'TinyURL',
    categories: ['utility'],
    domains: ['*.tinyurl.com'],
  },
  {
    name: 'Tivo',
    categories: ['analytics'],
    domains: ['*.rovicorp.com'],
  },
  {
    name: 'Tom&Co',
    categories: ['hosting'],
    domains: ['*.tomandco.uk'],
  },
  {
    name: 'Toms Native Ads',
    company: 'Purch',
    categories: ['ad'],
    domains: ['*.natoms.com'],
  },
  {
    name: 'ToneMedia',
    categories: ['ad'],
    domains: ['*.clickfuse.com'],
  },
  {
    name: 'Tonic',
    company: 'Team Internet',
    categories: ['ad'],
    domains: ['*.dntx.com'],
  },
  {
    name: 'Touch Commerce',
    categories: ['customer-success'],
    domains: ['*.inq.com', '*.touchcommerce.com'],
  },
  {
    name: 'ToutApp',
    categories: ['ad'],
    domains: ['*.toutapp.com'],
  },
  {
    name: 'TraceView',
    company: 'Solarwinds',
    categories: ['analytics'],
    domains: ['*.tracelytics.com', 'd2gfdmu30u15x7.cloudfront.net'],
  },
  {
    name: 'TrackJS',
    categories: ['analytics'],
    domains: ['*.trackjs.com', 'd2zah9y47r7bi2.cloudfront.net'],
    examples: ['usage.trackjs.com'],
  },
  {
    name: 'Tradedoubler',
    categories: ['ad'],
    domains: ['*.pvnsolutions.com', '*.tradedoubler.com'],
  },
  {
    name: 'Tradelab',
    categories: ['ad'],
    domains: ['*.tradelab.fr'],
  },
  {
    name: 'TrafficFactory',
    categories: ['ad'],
    domains: ['*.trafficfactory.biz'],
  },
  {
    name: 'TrafficHunt',
    categories: ['ad'],
    domains: ['*.traffichunt.com'],
  },
  {
    name: 'TrafficStars',
    categories: ['ad'],
    domains: ['*.trafficstars.com', '*.tsyndicate.com'],
  },
  {
    name: 'Transifex',
    categories: ['utility'],
    domains: ['*.transifex.com'],
  },
  {
    name: 'Travelex',
    categories: ['utility'],
    domains: ['*.travelex.net', '*.travelex.co.uk'],
    examples: ['api.travelex.net', 'travelmoney.travelex.co.uk'],
  },
  {
    name: 'Travelocity Canada',
    company: 'Travelocity',
    categories: ['content'],
    domains: ['*.travelocity.ca'],
    examples: ['www.travelocity.ca'],
  },
  {
    name: 'Travelocity USA',
    company: 'Travelocity',
    categories: ['content'],
    domains: ['*.travelocity.com'],
    examples: ['www.travelocity.com'],
  },
  {
    name: 'Travelzoo',
    categories: ['content'],
    domains: ['*.travelzoo.com'],
  },
  {
    name: 'Treasure Data',
    categories: ['analytics'],
    domains: ['*.treasuredata.com'],
  },
  {
    name: 'Tremor Video',
    categories: ['ad'],
    domains: ['*.tremorhub.com', '*.videohub.tv'],
  },
  {
    name: 'Trialfire',
    categories: ['analytics'],
    domains: ['*.trialfire.com'],
  },
  {
    name: 'Tribal Fusion',
    company: 'Exponential Interactive',
    categories: ['ad'],
    domains: ['*.tribalfusion.com'],
  },
  {
    name: 'Triblio',
    categories: ['marketing'],
    domains: ['*.tribl.io'],
  },
  {
    name: 'Triggered Messaging',
    company: 'Fresh Relevance',
    categories: ['ad'],
    domains: ['*.triggeredmessaging.com'],
  },
  {
    name: 'Trinity Mirror',
    categories: ['content'],
    domains: ['*.mirror.co.uk'],
  },
  {
    name: 'Trinity Mirror Digital Media',
    categories: ['social'],
    domains: ['*.tm-aws.com', '*.icnetwork.co.uk'],
  },
  {
    name: 'TripAdvisor',
    categories: ['content'],
    domains: [
      '*.jscache.com',
      '*.tacdn.com',
      '*.tamgrt.com',
      '*.tripadvisor.com',
      '*.viator.com',
      '*.tripadvisor.co.uk',
    ],
    examples: ['www.jscache.com', 'www.tamgrt.com'],
  },
  {
    name: 'TripleLift',
    categories: ['ad'],
    domains: ['*.3lift.com'],
  },
  {
    name: 'Tru Optik',
    categories: ['ad'],
    domains: ['*.truoptik.com'],
  },
  {
    name: 'TruConversion',
    categories: ['analytics'],
    domains: ['*.truconversion.com'],
  },
  {
    name: 'Trueffect',
    categories: ['marketing'],
    domains: ['*.adlegend.com'],
  },
  {
    name: 'Truefit',
    categories: ['analytics'],
    domains: ['*.truefitcorp.com'],
    examples: ['cdn.truefitcorp.com', 'fitrec.truefitcorp.com', 'sch-cdn.truefitcorp.com'],
  },
  {
    name: 'Trust Guard',
    categories: ['utility'],
    domains: ['*.trust-guard.com'],
  },
  {
    name: 'Trust Pilot',
    categories: ['analytics'],
    domains: ['*.trustpilot.com'],
  },
  {
    name: 'Amazon Trust Services',
    company: 'Amazon',
    categories: ['utility'],
    domains: ['*.amazontrust.com', 'o.ss2.us'],
    examples: ['ocsp.rootca1.amazontrust.com'],
  },
  {
    name: 'Google Trust Services',
    company: 'Google',
    categories: ['utility'],
    domains: ['*.pki.goog'],
    examples: ['ocsp.pki.goog'],
  },
  {
    name: "Let's Encrypt",
    homepage: 'https://letsencrypt.org/',
    categories: ['utility'],
    domains: ['*.letsencrypt.org'],
    examples: ['ocsp.int-x3.letsencrypt.org'],
  },
  {
    name: 'TrustX',
    categories: ['ad'],
    domains: ['*.trustx.org'],
  },
  {
    name: 'Trusted Shops',
    categories: ['utility'],
    domains: ['*.trustedshops.com'],
  },
  {
    name: 'Trustev',
    company: 'TransUnion',
    categories: ['utility'],
    domains: ['*.trustev.com'],
  },
  {
    name: 'Trustwave',
    categories: ['utility'],
    domains: ['*.trustwave.com'],
  },
  {
    name: 'Tryzens TradeState',
    company: 'Tryzens',
    categories: ['analytics'],
    domains: ['*.tryzens-analytics.com'],
  },
  {
    name: 'TubeMogul',
    categories: ['ad'],
    domains: ['*.tubemogul.com'],
  },
  {
    name: 'Turn',
    categories: ['ad'],
    domains: ['*.turn.com'],
  },
  {
    name: 'Tutorialize',
    categories: ['customer-success'],
    domains: ['*.tutorialize.me'],
  },
  {
    name: 'Twenga',
    categories: ['content'],
    domains: ['*.twenga.fr', '*.c4tw.net', '*.twenga.co.uk'],
    examples: ['tracker.twenga.co.uk'],
  },
  {
    name: 'Twitframe',
    company: 'Superblock',
    categories: ['utility'],
    domains: ['*.twitframe.com'],
  },
  {
    name: 'Twitter Online Conversion Tracking',
    company: 'Twitter',
    categories: ['ad'],
    domains: ['*.ads-twitter.com', 'analytics.twitter.com'],
    examples: ['static.ads-twitter.com'],
  },
  {
    name: 'Twitter Short URL',
    company: 'Twitter',
    categories: ['social'],
    domains: ['*.t.co'],
  },
  {
    name: 'Twyn Group',
    categories: ['ad'],
    domains: ['*.twyn.com'],
  },
  {
    name: 'Tynt',
    company: '33 Across',
    categories: ['ad'],
    domains: ['*.tynt.com'],
  },
  {
    name: 'Typepad',
    categories: ['hosting'],
    domains: ['*.typepad.com'],
  },
  {
    name: 'TyrbooBytes',
    categories: ['utility'],
    domains: ['*.turbobytes.net'],
  },
  {
    name: 'UPS i-parcel',
    company: 'UPS',
    categories: ['other'],
    domains: ['*.i-parcel.com'],
  },
  {
    name: 'US Media Consulting',
    categories: ['ad'],
    domains: ['*.mediade.sk'],
  },
  {
    name: 'Ubertags',
    categories: ['tag-manager'],
    domains: ['*.ubertags.com'],
  },
  {
    name: 'Umbel',
    categories: ['analytics'],
    domains: ['*.umbel.com'],
  },
  {
    name: 'Unanimis',
    company: 'Switch',
    categories: ['ad'],
    domains: ['*.unanimis.co.uk'],
  },
  {
    name: 'Unbounce',
    categories: ['ad'],
    domains: [
      '*.ubembed.com',
      '*.unbounce.com',
      'd2xxq4ijfwetlm.cloudfront.net',
      'd9hhrg4mnvzow.cloudfront.net',
    ],
  },
  {
    name: 'Underdog Media',
    categories: ['ad'],
    domains: ['*.underdog.media', '*.udmserve.net'],
  },
  {
    name: 'Understand Digital',
    categories: ['ad'],
    domains: ['*.redirecting2.net'],
  },
  {
    name: 'Undertone',
    company: 'Perion',
    categories: ['ad'],
    domains: ['*.legolas-media.com'],
    examples: ['rt.legolas-media.com'],
  },
  {
    name: 'Unidays',
    categories: ['ad'],
    domains: ['*.myunidays.com', '*.unidays.world'],
  },
  {
    name: 'Uniqodo',
    categories: ['ad'],
    domains: ['*.uniqodo.com'],
  },
  {
    name: 'Unite',
    categories: ['ad'],
    domains: ['*.uadx.com'],
  },
  {
    name: 'United Card Services',
    categories: ['utility'],
    domains: ['*.ucs.su'],
  },
  {
    name: 'United Internet',
    categories: ['hosting'],
    domains: ['*.uicdn.com'],
  },
  {
    name: 'United Internet Media',
    categories: ['ad'],
    domains: ['*.ui-portal.de'],
  },
  {
    name: 'United Internet Media AG',
    categories: ['hosting'],
    domains: ['*.tifbs.net', '*.uicdn.net', '*.uimserv.net'],
  },
  {
    name: 'Unknown',
    categories: ['other'],
    domains: [],
  },
  {
    name: 'Unruly Media',
    categories: ['ad'],
    domains: ['*.unrulymedia.com'],
  },
  {
    name: 'UpBuild',
    categories: ['ad'],
    domains: ['*.upbuild.io'],
    examples: ['www.upbuild.io'],
  },
  {
    name: 'UpSellit',
    categories: ['analytics'],
    domains: ['*.upsellit.com'],
    examples: ['www.upsellit.com'],
  },
  {
    name: 'Upland Software',
    categories: ['hosting'],
    domains: ['*.clickability.com'],
  },
  {
    name: 'Airship',
    categories: ['marketing'],
    domains: ['*.urbanairship.com', '*.aswpsdkus.com'],
  },
  {
    name: 'UsabilityTools',
    categories: ['analytics'],
    domains: ['*.usabilitytools.com'],
  },
  {
    name: 'Usablenet.net',
    categories: ['utility'],
    domains: ['*.usablenet.net'],
  },
  {
    name: 'Use It Better',
    categories: ['analytics'],
    domains: ['*.useitbetter.com'],
  },
  {
    name: 'User Replay',
    categories: ['analytics'],
    domains: ['*.userreplay.net'],
  },
  {
    name: 'UserReport',
    categories: ['analytics'],
    domains: ['*.userreport.com'],
  },
  {
    name: 'Userneeds',
    categories: ['analytics'],
    domains: ['*.userneeds.dk'],
  },
  {
    name: 'Userzoom',
    categories: ['analytics'],
    domains: ['*.userzoom.com'],
  },
  {
    name: 'V12 Retail Finance',
    categories: ['utility'],
    domains: ['*.v12finance.com'],
  },
  {
    name: 'Vacaciones eDreams',
    categories: ['content'],
    domains: ['*.odistatic.net'],
  },
  {
    name: 'Varick Media Management',
    categories: ['ad'],
    domains: ['*.vmmpxl.com'],
  },
  {
    name: 'Vdopia Chocolate',
    company: 'Vdopia',
    categories: ['ad'],
    domains: ['*.vdopia.com'],
  },
  {
    name: 'Ve',
    company: 'Ve Interactive',
    categories: ['marketing'],
    domains: ['*.veinteractive.com'],
  },
  {
    name: 'Ve Interactive',
    categories: ['ad'],
    domains: ['*.vepxl1.net', '*.adgenie.co.uk'],
  },
  {
    name: 'Vee24',
    categories: ['customer-success'],
    domains: ['*.vee24.com'],
  },
  {
    name: 'Veeseo',
    categories: ['content'],
    domains: ['*.veeseo.com'],
  },
  {
    name: 'Venatus Media',
    categories: ['marketing'],
    domains: ['*.alcvid.com', '*.venatusmedia.com'],
  },
  {
    name: 'Veoxa',
    categories: ['ad'],
    domains: ['*.veoxa.com'],
  },
  {
    name: 'Vergic AB',
    categories: ['customer-success'],
    domains: ['*.psplugin.com'],
  },
  {
    name: 'Vergic Engage Platform',
    company: 'Vergic',
    categories: ['customer-success'],
    domains: ['*.vergic.com'],
  },
  {
    name: 'Verisign (Symantec)',
    categories: ['utility'],
    domains: ['*.verisign.com'],
  },
  {
    name: 'Verizon',
    categories: ['utility'],
    domains: ['*.public-trust.com'],
    examples: ['www.public-trust.com'],
  },
  {
    name: 'Verizon Digital Media CDN',
    homepage: 'https://www.verizondigitalmedia.com/',
    categories: ['cdn'],
    domains: ['*.edgecastcdn.net', '*.edgecastdns.net'],
  },
  {
    name: 'Verizon Uplynk',
    company: 'Verizon',
    categories: ['content'],
    domains: ['*.uplynk.com'],
  },
  {
    name: 'Vero',
    company: 'Semblance',
    categories: ['ad'],
    domains: ['*.getvero.com', 'd3qxef4rp70elm.cloudfront.net'],
  },
  {
    name: 'VertaMedia',
    categories: ['ad'],
    domains: ['*.vertamedia.com'],
  },
  {
    name: 'Vertical Mass',
    categories: ['ad'],
    domains: ['*.vmweb.net'],
  },
  {
    name: 'Vestorly',
    categories: ['ad'],
    domains: ['*.oodalab.com'],
  },
  {
    name: 'Vextras',
    categories: ['other'],
    domains: ['*.vextras.com'],
  },
  {
    name: 'Viacom',
    categories: ['content'],
    domains: ['*.mtvnservices.com'],
  },
  {
    name: 'Vibrant Media',
    categories: ['ad'],
    domains: ['*.intellitxt.com', '*.picadmedia.com'],
  },
  {
    name: 'VidPulse',
    categories: ['analytics'],
    domains: ['*.vidpulse.com'],
  },
  {
    name: 'Video Media Groep',
    categories: ['ad'],
    domains: ['*.vmg.host', '*.inpagevideo.nl'],
  },
  {
    name: 'VideoHub',
    company: 'Tremor Video',
    categories: ['ad'],
    domains: ['*.scanscout.com'],
    examples: ['dt.scanscout.com'],
  },
  {
    name: 'Videology',
    categories: ['ad'],
    domains: ['*.tidaltv.com'],
  },
  {
    name: 'Vidible',
    categories: ['ad'],
    domains: ['*.vidible.tv'],
  },
  {
    name: 'VigLink',
    categories: ['ad'],
    domains: ['*.viglink.com'],
  },
  {
    name: 'Vindico',
    company: 'Viant',
    categories: ['ad'],
    domains: ['*.vindicosuite.com'],
  },
  {
    name: 'Viocorp International',
    categories: ['content'],
    domains: ['*.vioapi.com'],
  },
  {
    name: 'ViralNinjas',
    categories: ['ad'],
    domains: ['*.viralninjas.com'],
  },
  {
    name: 'Virool',
    categories: ['ad'],
    domains: ['*.virool.com'],
  },
  {
    name: 'Virtual Earth',
    company: 'Microsoft',
    categories: ['utility'],
    domains: ['*.virtualearth.net'],
  },
  {
    name: 'VisScore',
    categories: ['analytics'],
    domains: ['*.visscore.com', 'd2hkbi3gan6yg6.cloudfront.net'],
  },
  {
    name: 'Visible Measures',
    categories: ['ad'],
    domains: ['*.visiblemeasures.com'],
  },
  {
    name: 'Visual Studio',
    company: 'Microsoft',
    categories: ['utility'],
    domains: ['*.visualstudio.com'],
  },
  {
    name: 'Visual Web Optimizer',
    categories: ['analytics'],
    domains: ['*.vwo.com'],
  },
  {
    name: 'VisualDNA',
    categories: ['ad'],
    domains: ['*.visualdna.com'],
  },
  {
    name: 'VisualVisitor',
    categories: ['ad'],
    domains: ['*.id-visitors.com'],
    examples: ['frontend.id-visitors.com'],
  },
  {
    name: 'Vivocha S.p.A',
    categories: ['customer-success'],
    domains: ['*.vivocha.com'],
  },
  {
    name: 'Vizu (Nielsen)',
    categories: ['analytics'],
    domains: ['*.vizu.com'],
  },
  {
    name: 'Vizury',
    categories: ['ad'],
    domains: ['*.vizury.com'],
  },
  {
    name: 'VoiceFive',
    categories: ['analytics'],
    domains: ['*.voicefive.com'],
  },
  {
    name: 'Volvelle',
    company: 'Optomaton',
    categories: ['ad'],
    domains: ['*.volvelle.tech'],
  },
  {
    name: 'VouchedFor',
    categories: ['analytics'],
    domains: ['*.vouchedfor.co.uk'],
  },
  {
    name: 'WARPCACHE',
    categories: ['utility'],
    domains: ['*.warpcache.net'],
  },
  {
    name: 'WISHLIST',
    company: 'Shopapps',
    categories: ['social'],
    domains: ['*.shopapps.in'],
  },
  {
    name: 'WP Engine',
    categories: ['hosting'],
    domains: ['*.wpengine.com'],
  },
  {
    name: 'WalkMe',
    categories: ['customer-success'],
    domains: ['*.walkme.com'],
  },
  {
    name: 'Watching That',
    categories: ['other'],
    domains: ['*.watchingthat.com'],
  },
  {
    name: 'Wayfair',
    categories: ['analytics'],
    domains: ['*.wayfair.com'],
    examples: ['t.wayfair.com'],
  },
  {
    name: 'Web CEO',
    categories: ['other'],
    domains: ['*.websiteceo.com'],
    examples: ['www.websiteceo.com'],
  },
  {
    name: 'Web Dissector',
    company: 'Beijing Gridsum Technologies',
    categories: ['analytics'],
    domains: ['*.gridsumdissector.com', '*.webdissector.com'],
    examples: ['www.webdissector.com'],
  },
  {
    name: 'Web Forensics',
    categories: ['analytics'],
    domains: ['*.webforensics.co.uk'],
  },
  {
    name: 'Web Security and Performance',
    company: 'NCC Group',
    categories: ['utility'],
    domains: ['*.nccgroup.trust'],
  },
  {
    name: 'WebEngage',
    categories: ['customer-success'],
    domains: [
      '*.webengage.co',
      '*.webengage.com',
      'd23nd6ymopvz52.cloudfront.net',
      'd3701cc9l7v9a6.cloudfront.net',
    ],
  },
  {
    name: 'WebInsight',
    company: 'dotMailer',
    categories: ['analytics'],
    domains: ['*.trackedlink.net', '*.trackedweb.net'],
  },
  {
    name: 'WebPageOne Solutions',
    categories: ['other'],
    domains: ['*.webpageone.com'],
  },
  {
    name: 'WebSpectator',
    categories: ['ad'],
    domains: ['*.webspectator.com'],
  },
  {
    name: 'WebTuna',
    company: 'Application Performance',
    categories: ['analytics'],
    domains: ['*.webtuna.com'],
  },
  {
    name: 'WebVideoCore',
    company: 'StreamingVideoProvider',
    categories: ['content'],
    domains: ['*.webvideocore.net'],
  },
  {
    name: 'WebWombat',
    categories: ['utility'],
    domains: ['*.ic.com.au'],
  },
  {
    name: 'Webcollage',
    categories: ['customer-success'],
    domains: ['*.webcollage.net'],
  },
  {
    name: 'Webcore',
    categories: ['ad'],
    domains: ['*.onefeed.co.uk'],
  },
  {
    name: 'Webkul',
    company: 'Webkul Software',
    categories: ['utility'],
    domains: ['*.webkul.com'],
  },
  {
    name: 'Webmarked',
    categories: ['utility'],
    domains: ['*.webmarked.net'],
  },
  {
    name: 'Weborama',
    categories: ['ad'],
    domains: ['*.weborama.com', '*.weborama.fr'],
  },
  {
    name: 'WebpageFX',
    categories: ['ad'],
    domains: ['*.leadmanagerfx.com'],
  },
  {
    name: 'Webphone',
    company: 'IP WEB SERVICES',
    categories: ['customer-success'],
    domains: ['*.webphone.net'],
  },
  {
    name: 'Webselect selectcommerce',
    company: 'Webselect Internet',
    categories: ['hosting'],
    domains: ['*.webselect.net'],
  },
  {
    name: 'Webthinking',
    categories: ['hosting'],
    domains: ['*.webthinking.co.uk'],
  },
  {
    name: 'Webtrekk',
    categories: ['analytics'],
    domains: ['*.wbtrk.net', '*.webtrekk-asia.net', '*.webtrekk.net', '*.wt-eu02.net'],
  },
  {
    name: 'Webtrends',
    categories: ['analytics'],
    domains: ['*.webtrends.com', '*.webtrendslive.com', 'd1q62gfb8siqnm.cloudfront.net'],
  },
  {
    name: 'Webtype',
    categories: ['cdn'],
    domains: ['*.webtype.com'],
  },
  {
    name: 'White Ops',
    categories: ['utility'],
    domains: ['*.acexedge.com', '*.tagsrvcs.com'],
  },
  {
    name: 'Whitespace',
    categories: ['ad'],
    domains: ['*.whitespacers.com'],
  },
  {
    name: 'WhosOn Live Chat Software',
    categories: ['customer-success'],
    domains: ['*.whoson.com'],
  },
  {
    name: 'Wibbitz',
    categories: ['other'],
    domains: ['*.wibbitz.com'],
  },
  {
    name: 'Wide Area Communications',
    categories: ['hosting'],
    domains: ['*.widearea.co.uk'],
  },
  {
    name: 'WideOrbit',
    categories: ['marketing'],
    domains: ['*.admaym.com'],
  },
  {
    name: 'William Reed',
    categories: ['content'],
    domains: ['*.wrbm.com'],
  },
  {
    name: 'WillyFogg.com',
    categories: ['content'],
    domains: ['*.willyfogg.com'],
  },
  {
    name: 'Windows',
    company: 'Microsoft',
    categories: ['utility'],
    domains: ['*.windowsupdate.com'],
    examples: ['ctldl.windowsupdate.com'],
  },
  {
    name: 'WisePops',
    categories: ['utility'],
    domains: ['*.wisepops.com'],
  },
  {
    name: 'Wishpond Technologies',
    categories: ['marketing'],
    domains: ['*.wishpond.com', '*.wishpond.net'],
  },
  {
    name: 'WizRocket Technologies',
    categories: ['analytics'],
    domains: ['*.wzrkt.com'],
  },
  {
    name: 'Woopra',
    categories: ['analytics'],
    domains: ['*.woopra.com'],
  },
  {
    name: 'Woosmap',
    categories: ['utility'],
    domains: ['*.woosmap.com'],
  },
  {
    name: 'WorkCast',
    categories: ['hosting'],
    domains: ['*.workcast.net'],
  },
  {
    name: 'World News Media',
    categories: ['content'],
    domains: ['*.wnmedia.co.uk'],
  },
  {
    name: 'Worldpay',
    categories: ['utility'],
    domains: ['*.worldpay.com'],
  },
  {
    name: 'Wow Analytics',
    categories: ['analytics'],
    domains: ['*.wowanalytics.co.uk'],
  },
  {
    name: 'Wowcher',
    categories: ['ad'],
    domains: ['*.wowcher.co.uk'],
  },
  {
    name: 'Wufoo',
    categories: ['utility'],
    domains: ['*.wufoo.com'],
  },
  {
    name: 'Wyng',
    categories: ['ad'],
    domains: ['*.offerpop.com'],
  },
  {
    name: 'XMLSHOP',
    categories: ['hosting'],
    domains: ['*.xmlshop.biz'],
  },
  {
    name: 'XiTi',
    company: 'AT Internet',
    categories: ['analytics'],
    domains: ['*.xiti.com', '*.aticdn.net'],
    homepage: 'https://www.atinternet.com/en/',
    examples: ['tag.aticdn.net/123456789/smarttag.js'],
  },
  {
    name: 'YUDU',
    categories: ['content'],
    domains: ['*.yudu.com'],
  },
  {
    name: 'Yahoo! Ad Exchange',
    company: 'Yahoo!',
    categories: ['ad'],
    domains: ['*.yieldmanager.com', '*.browsiprod.com'],
    examples: ['yield-manager.browsiprod.com'],
  },
  {
    name: 'Yahoo! JAPAN Ads',
    company: 'Yahoo! JAPAN',
    categories: ['ad'],
    homepage: 'https://marketing.yahoo.co.jp/service/yahooads/',
    domains: ['yads.c.yimg.jp', 's.yimg.jp', 'b92.yahoo.co.jp'],
    examples: ['yads.c.yimg.jp/js/yads-async.js', 's.yimg.jp/images/listing/tool/yads/ydn/creative/video/ytop_video_timeline_sp.min.js', 'b92.yahoo.co.jp/js/s_retargeting.js'],
  },
  {
    name: 'Yahoo! Tag Manager',
    company: 'Yahoo! JAPAN',
    categories: ['tag-manager'],
    homepage: 'https://marketing.yahoo.co.jp/service/tagmanager/',
    domains: ['*.yjtag.jp'],
    examples: ['s.yjtag.jp/tag.js']
  },
  {
    name: 'Yahoo! Small Business',
    company: 'Yahoo!',
    categories: ['hosting'],
    domains: ['*.aabacosmallbusiness.com'],
  },
  {
    name: 'Yellow Robot',
    categories: ['ad'],
    domains: ['*.backinstock.org'],
  },
  {
    name: 'YieldPartners',
    categories: ['ad'],
    domains: ['*.yieldpartners.com'],
  },
  {
    name: 'Yieldbot',
    categories: ['ad'],
    domains: ['*.yldbt.com'],
  },
  {
    name: 'Yieldify',
    categories: ['ad'],
    domains: [
      '*.yieldify.com',
      '*.yieldifylabs.com',
      'd33wq5gej88ld6.cloudfront.net',
      'dwmvwp56lzq5t.cloudfront.net',
    ],
    examples: ['geo.yieldifylabs.com'],
  },
  {
    name: 'Yieldlab',
    categories: ['ad'],
    domains: ['*.yieldlab.net'],
  },
  {
    name: 'Yieldmo',
    categories: ['ad'],
    domains: ['*.yieldmo.com'],
  },
  {
    name: 'Yieldr',
    categories: ['ad'],
    domains: ['*.254a.com'],
  },
  {
    name: 'Yo',
    categories: ['utility'],
    domains: ['*.yopify.com'],
  },
  {
    name: 'YoYo',
    categories: ['utility'],
    domains: ['*.goadservices.com'],
  },
  {
    name: 'Yotpo',
    homepage: 'https://www.yotpo.com/',
    categories: ['marketing'],
    domains: ['*.yotpo.com'],
  },
  {
    name: 'Yottaa',
    categories: ['hosting'],
    domains: ['*.yottaa.com', '*.yottaa.net'],
  },
  {
    name: 'YourAmigo',
    categories: ['utility'],
    domains: ['*.youramigo.com'],
  },
  {
    name: 'YuMe',
    categories: ['ad'],
    domains: ['*.yume.com', '*.yumenetworks.com'],
    examples: ['cks.yumenetworks.com'],
  },
  {
    name: 'Yummley',
    categories: ['other'],
    domains: ['*.yummly.com'],
  },
  {
    name: 'ZEDO',
    categories: ['ad'],
    domains: ['*.zedo.com'],
  },
  {
    name: 'Zafu',
    categories: ['analytics'],
    domains: ['*.zafu.com'],
  },
  {
    name: 'Zaius',
    categories: ['ad'],
    domains: ['*.zaius.com'],
  },
  {
    name: 'Zamplus ad',
    categories: ['ad'],
    domains: ['*.zampda.net'],
  },
  {
    name: 'Zanox',
    categories: ['ad'],
    domains: ['*.zanox.com', '*.zanox.ws'],
  },
  {
    name: 'Zapper',
    categories: ['utility'],
    domains: ['*.zapper.com'],
  },
  {
    name: 'Zarget',
    categories: ['analytics'],
    domains: ['*.zarget.com'],
  },
  {
    name: 'Zemanta',
    categories: ['ad'],
    domains: ['*.zemanta.com'],
  },
  {
    name: 'Zen Internet',
    categories: ['other'],
    domains: ['*.zyen.com'],
  },
  {
    name: 'Zenovia Digital Exchange',
    categories: ['ad'],
    domains: ['*.rhythmxchange.com', '*.zenoviaexchange.com'],
  },
  {
    name: 'ZergNet',
    categories: ['content'],
    domains: ['*.zergnet.com'],
  },
  {
    name: 'Zerogrey',
    categories: ['hosting'],
    domains: ['*.zerogrey.com'],
  },
  {
    name: 'Ziff Davis Tech',
    categories: ['ad'],
    domains: ['*.adziff.com', '*.zdbb.net'],
  },
  {
    name: 'Zmags',
    categories: ['marketing'],
    domains: ['*.zmags.com'],
  },
  {
    name: 'Zolando',
    categories: ['content'],
    domains: ['*.ztat.net'],
  },
  {
    name: 'Zoover',
    categories: ['analytics'],
    domains: ['*.zoover.nl', '*.zoover.co.uk'],
  },
  {
    name: 'Zopim',
    categories: ['customer-success'],
    domains: ['*.zopim.io'],
  },
  {
    name: '[24]7',
    categories: ['customer-success'],
    domains: ['*.247-inc.net', '*.247inc.net', 'd1af033869koo7.cloudfront.net'],
  },
  {
    name: 'adKernel',
    categories: ['ad'],
    domains: ['*.adkernel.com'],
  },
  {
    name: 'adMarketplace',
    company: 'AMPexchange',
    categories: ['ad'],
    domains: ['*.ampxchange.com', '*.admarketplace.net'],
  },
  {
    name: 'addtocalendar',
    categories: ['utility'],
    domains: ['*.addtocalendar.com'],
  },
  {
    name: 'adnanny',
    categories: ['ad'],
    domains: ['*.adserver01.de'],
  },
  {
    name: 'affilinet',
    categories: ['ad'],
    domains: ['*.reussissonsensemble.fr', '*.successfultogether.co.uk'],
  },
  {
    name: 'audioBoom',
    categories: ['social'],
    domains: ['*.audioboom.com', '*.audioboo.fm'],
  },
  {
    name: 'bPay by Barclaycard',
    company: 'Barclays Bank',
    categories: ['utility'],
    domains: ['*.bpay.co.uk'],
  },
  {
    name: 'bRealTime',
    categories: ['ad'],
    domains: ['*.brealtime.com'],
  },
  {
    name: 'bd4travel',
    categories: ['analytics'],
    domains: ['*.bd4travel.com'],
  },
  {
    name: 'bizinformation-VOID',
    company: 'bizinformation',
    categories: ['analytics'],
    domains: ['*.bizinformation.org'],
  },
  {
    name: 'carrot',
    categories: ['social'],
    domains: ['*.sharebutton.co'],
  },
  {
    name: 'cloudIQ',
    categories: ['analytics'],
    domains: ['*.cloud-iq.com'],
  },
  {
    name: 'comScore',
    categories: ['analytics'],
    domains: ['*.adxpose.com', '*.comscore.com', '*.sitestat.com', '*.zqtk.net'],
  },
  {
    name: 'content.ad',
    categories: ['ad'],
    domains: ['*.content.ad'],
  },
  {
    name: 'd3 Media',
    company: 'd3 Technologies',
    categories: ['other'],
    domains: ['*.d3sv.net'],
  },
  {
    name: 'dexiMEDIA',
    categories: ['ad'],
    domains: ['*.deximedia.com'],
  },
  {
    name: 'dianomi',
    categories: ['ad'],
    domains: ['*.dianomi.com', '*.dianomioffers.co.uk'],
  },
  {
    name: 'donReach',
    categories: ['social'],
    domains: ['*.donreach.com'],
  },
  {
    name: 'dotMailer',
    categories: ['ad'],
    domains: ['*.dmtrk.com', '*.dotmailer.com', '*.emlfiles.com'],
  },
  {
    name: 'dotMailer Surveys',
    company: 'dotMailer',
    categories: ['analytics'],
    domains: ['*.dotmailer-surveys.com'],
  },
  {
    name: 'dstillery',
    categories: ['ad'],
    domains: ['*.dstillery.com', '*.media6degrees.com'],
  },
  {
    name: 'eBay',
    categories: ['ad'],
    domains: ['*.ebay.com', '*.ebayimg.com', '*.fetchback.com'],
  },
  {
    name: 'eBay Enterprise',
    categories: ['hosting'],
    domains: ['*.csdata1.com', '*.gsipartners.com'],
  },
  {
    name: 'eBuzzing',
    company: 'Teads Managed Services',
    categories: ['ad'],
    domains: ['*.ebz.io'],
  },
  {
    name: 'eDigital Research',
    categories: ['customer-success'],
    domains: [
      '*.edigitalresearch.com',
      '*.edigitalsurvey.com',
      '*.edrcdn.com',
      '*.ecustomeropinions.com',
    ],
  },
  {
    name: 'eGain',
    categories: ['analytics'],
    domains: ['*.analytics-egain.com', '*.egain.com'],
  },
  {
    name: 'eHost',
    categories: ['hosting'],
    domains: ['*.ehosts.net'],
  },
  {
    name: 'eKomi',
    categories: ['analytics'],
    domains: ['*.ekomi.com', '*.ekomi.de'],
  },
  {
    name: 'eWAY',
    company: 'Web Active Pty',
    categories: ['utility'],
    domains: ['*.eway.com.au'],
    examples: ['www.eway.com.au'],
  },
  {
    name: 'eXTReMe digital',
    categories: ['analytics'],
    domains: ['*.extreme-dm.com'],
  },
  {
    name: 'eXelate',
    categories: ['ad'],
    domains: ['*.exelator.com'],
  },
  {
    name: 'ecommercefeed.net',
    categories: ['marketing'],
    domains: ['*.ecommercefeed.net'],
  },
  {
    name: 'engage:BDR',
    categories: ['ad'],
    domains: ['*.bnmla.com', '*.ebdr3.com'],
  },
  {
    name: 'epago',
    categories: ['ad'],
    domains: ['*.adaos-ads.net'],
  },
  {
    name: 'epoq internet services',
    categories: ['analytics'],
    domains: ['*.epoq.de'],
  },
  {
    name: 'etouches',
    categories: ['hosting'],
    domains: ['*.etouches.com'],
    examples: ['www.etouches.com'],
  },
  {
    name: 'etracker',
    categories: ['analytics'],
    domains: ['*.etracker.com', '*.etracker.de'],
    examples: ['www.etracker.com'],
  },
  {
    name: 'everestads.com',
    categories: ['content'],
    domains: ['*.verestads.net'],
  },
  {
    name: 'exebid.DCA',
    company: 'Data-Centric Alliance',
    categories: ['ad'],
    domains: ['*.exe.bid'],
  },
  {
    name: 'eyeReturn Marketing',
    categories: ['marketing'],
    domains: ['*.eyereturn.com'],
  },
  {
    name: 'feedoptimise',
    categories: ['hosting'],
    domains: ['*.feedoptimise.com', 'd1w78njrm56n7g.cloudfront.net'],
  },
  {
    name: 'fifty-five',
    categories: ['ad'],
    domains: ['*.55labs.com'],
  },
  {
    name: 'fluct',
    categories: ['ad'],
    domains: ['*.adingo.jp'],
  },
  {
    name: 'freegeoip.net',
    company: '(community-funded)',
    categories: ['utility'],
    domains: ['*.freegeoip.net'],
  },
  {
    name: 'freewheel.tv',
    categories: ['content'],
    domains: ['*.fwmrm.net'],
  },
  {
    name: 'gnatta',
    categories: ['customer-success'],
    domains: ['*.gnatta.com'],
  },
  {
    name: 'home.pl',
    categories: ['hosting'],
    domains: ['*.nscontext.eu'],
  },
  {
    name: 'hyfn',
    categories: ['ad'],
    domains: ['*.hyfn.com'],
  },
  {
    name: 'iAdvize SAS',
    categories: ['customer-success'],
    domains: ['*.iadvize.com'],
  },
  {
    name: 'iBillboard',
    categories: ['ad'],
    domains: ['*.ibillboard.com'],
  },
  {
    name: 'iCrossing',
    categories: ['ad'],
    domains: ['*.ic-live.com'],
  },
  {
    name: 'iFactory',
    company: 'RDW Group',
    categories: ['hosting'],
    domains: ['*.ifactory.com'],
  },
  {
    name: 'iGoDigital',
    categories: ['analytics'],
    domains: ['*.igodigital.com'],
  },
  {
    name: 'iJento',
    company: 'Fopsha',
    categories: ['ad'],
    domains: ['*.ijento.com'],
  },
  {
    name: 'iPage',
    categories: ['hosting'],
    domains: ['*.ipage.com'],
    examples: ['www.ipage.com'],
  },
  {
    name: 'iPerceptions',
    categories: ['customer-success'],
    domains: ['*.iperceptions.com'],
  },
  {
    name: 'iTunes',
    company: 'Apple',
    categories: ['content'],
    domains: ['*.mzstatic.com'],
  },
  {
    name: 'imgix',
    company: 'Zebrafish Labs',
    categories: ['utility'],
    domains: ['*.imgix.net'],
  },
  {
    name: 'infogr.am',
    categories: ['utility'],
    domains: ['*.infogr.am', '*.jifo.co'],
  },
  {
    name: 'iotec',
    categories: ['analytics'],
    domains: ['*.dsp.io'],
  },
  {
    name: 'iovation',
    categories: ['utility'],
    domains: ['*.iesnare.com'],
  },
  {
    name: 'ipinfo.io',
    categories: ['utility'],
    domains: ['*.ipinfo.io'],
  },
  {
    name: 'issuu',
    categories: ['content'],
    domains: ['*.issuu.com', '*.isu.pub'],
  },
  {
    name: 'iubenda',
    categories: ['utility'],
    domains: ['*.iubenda.com'],
    examples: ['www.iubenda.com'],
  },
  {
    name: 'j2 Cloud Services',
    categories: ['ad'],
    domains: ['*.campaigner.com'],
  },
  {
    name: 'jsonip.com',
    categories: ['analytics'],
    domains: ['*.jsonip.com'],
  },
  {
    name: 'linkpulse',
    categories: ['analytics'],
    domains: ['*.lp4.io'],
  },
  {
    name: 'loGo_net',
    categories: ['analytics'],
    domains: ['*.logo-net.co.uk'],
  },
  {
    name: 'mainADV',
    categories: ['ad'],
    domains: ['*.httptrack.com', '*.solocpm.com'],
  },
  {
    name: 'mbr targeting',
    categories: ['ad'],
    domains: ['*.m6r.eu'],
  },
  {
    name: 'media.ventive',
    categories: ['ad'],
    domains: ['*.contentspread.net'],
  },
  {
    name: 'metrigo',
    categories: ['ad'],
    domains: ['*.metrigo.com'],
  },
  {
    name: 'minicabit.com',
    categories: ['content'],
    domains: ['*.minicabit.com'],
  },
  {
    name: 'mobiManage',
    categories: ['hosting'],
    domains: ['*.mobimanage.com'],
  },
  {
    name: 'moving-pictures',
    categories: ['other'],
    domains: [
      '*.moving-pictures.biz',
      '*.v6-moving-pictures.com',
      '*.vtstat.com',
      '*.moving-pictures.de',
    ],
  },
  {
    name: 'my6sense',
    categories: ['ad'],
    domains: ['*.mynativeplatform.com'],
  },
  {
    name: 'myThings',
    categories: ['ad'],
    domains: ['*.mythings.com', '*.mythingsmedia.net'],
  },
  {
    name: 'mymovies',
    categories: ['content'],
    domains: ['*.mymovies.net'],
  },
  {
    name: 'nRelate-VOID',
    company: 'nRelate',
    categories: ['content'],
    domains: ['*.nrelate.com'],
  },
  {
    name: 'nToklo',
    categories: ['analytics'],
    domains: ['*.ntoklo.com'],
  },
  {
    name: 'neXeps',
    categories: ['ad'],
    domains: ['*.nexeps.com'],
  },
  {
    name: 'ninemsn Pty.',
    categories: ['utility'],
    domains: ['*.ninemsn.com.au'],
  },
  {
    name: 'nugg.ad',
    categories: ['ad'],
    domains: ['*.nuggad.net'],
  },
  {
    name: 'numero interactive',
    company: 'numero',
    categories: ['ad'],
    domains: ['*.numerointeractive.com'],
  },
  {
    name: 'optMD',
    company: 'Optimax Media Delivery',
    categories: ['ad'],
    domains: ['*.optmd.com'],
  },
  {
    name: 'otracking.com',
    categories: ['analytics'],
    domains: ['*.otracking.com'],
  },
  {
    name: 'paysafecard',
    company: 'Paysafe Group',
    categories: ['utility'],
    domains: ['*.paysafecard.com'],
  },
  {
    name: 'piano',
    categories: ['ad'],
    domains: ['*.npttech.com', '*.tinypass.com'],
    examples: ['www.npttech.com'],
  },
  {
    name: 'piclike',
    categories: ['ad'],
    domains: ['*.piclike.us'],
  },
  {
    name: 'placehold.it',
    categories: ['utility'],
    domains: ['*.placehold.it'],
  },
  {
    name: 'plista',
    categories: ['ad'],
    domains: ['*.plista.com'],
  },
  {
    name: 'prebid.org',
    categories: ['utility'],
    domains: ['*.prebid.org'],
  },
  {
    name: 'reEmbed',
    categories: ['other'],
    domains: ['*.reembed.com'],
  },
  {
    name: 'reddit',
    categories: ['social'],
    domains: ['*.reddit.com', '*.redditstatic.com'],
    examples: ['www.redditstatic.com'],
  },
  {
    name: 'rewardStyle.com',
    categories: ['ad'],
    domains: ['*.rewardstyle.com'],
  },
  {
    name: 'rss2json',
    categories: ['utility'],
    domains: ['*.rss2json.com'],
  },
  {
    name: 'sage Pay',
    company: 'Sage Pay Europe',
    categories: ['utility'],
    domains: ['*.sagepay.com'],
  },
  {
    name: 'section.io',
    categories: ['utility'],
    domains: ['*.squixa.net'],
    examples: ['beacon.squixa.net', 's.squixa.net'],
  },
  {
    name: 'smartclip',
    categories: ['ad'],
    domains: ['*.smartclip.net'],
  },
  {
    name: 'sovrn',
    categories: ['ad'],
    domains: ['*.lijit.com'],
  },
  {
    name: 'stackpile.io',
    company: 'StackPile',
    categories: ['tag-manager'],
    domains: ['*.stackpile.io'],
  },
  {
    name: 'template-help.com',
    categories: ['hosting'],
    domains: ['*.template-help.com'],
  },
  {
    name: 'test',
    company: 'test only',
    categories: ['other'],
    domains: ['*.testtesttest.com'],
  },
  {
    name: 'trueAnthem',
    categories: ['social'],
    domains: ['*.tru.am'],
  },
  {
    name: 'tweetmeme-VOID',
    company: 'tweetmeme',
    categories: ['analytics'],
    domains: ['*.tweetmeme.com'],
  },
  {
    name: 'uLogin',
    categories: ['other'],
    domains: ['*.ulogin.ru'],
  },
  {
    name: 'uLogix',
    categories: ['ad'],
    domains: ['*.ulogix.ru'],
  },
  {
    name: 'ucfunnel ucX',
    company: 'ucfunnel',
    categories: ['ad'],
    domains: ['*.aralego.com'],
  },
  {
    name: 'up-value',
    categories: ['ad'],
    domains: ['*.up-value.de'],
  },
  {
    name: 'wywy',
    categories: ['ad'],
    domains: ['*.wywy.com', '*.wywyuserservice.com'],
  },
  {
    name: 'CDK Dealer Management',
    company: 'CDK Global',
    homepage: 'https://www.cdkglobal.com/us',
    categories: ['hosting'],
    domains: ['*.assets-cdk.com'],
    examples: ['media-cf.assets-cdk.com'],
  },
  {
    name: 'fam',
    company: 'Fing Co Ltd.',
    homepage: 'http://admin.fam-ad.com/report/',
    categories: ['ad'],
    domains: ['*.fam-ad.com'],
    examples: ['img.fam-ad.com'],
  },
  {
    name: 'zypmedia',
    categories: ['ad'],
    domains: ['*.extend.tv'],
  },
  {
    name: 'codigo',
    homepage: 'http://www.codigo.se',
    categories: ['analytics'],
    domains: ['*.codigo.se'],
    examples: ['analytics.codigo.se'],
  },
  {
    name: 'Playground',
    homepage: 'https://playground.xyz',
    categories: ['ad'],
    domains: ['*.playground.xyz'],
    examples: ['ads.playground.xyz'],
  },
  {
    name: 'RAM',
    homepage: 'http://www2.rampanel.com/en/',
    categories: ['analytics'],
    domains: ['*.rampanel.com'],
    examples: ['inviso.rampanel.com'],
  },
  {
    name: 'Adition',
    homepage: 'https://www.adition.com',
    categories: ['ad'],
    domains: ['*.adition.com'],
    examples: ['dsp.adfarm1.adition.com'],
  },
  {
    name: 'Widespace',
    homepage: 'https://www.widespace.com',
    categories: ['ad'],
    domains: ['*.widespace.com'],
    examples: ['sync.widespace.com'],
  },
  {
    name: 'Colpirio',
    homepage: 'https://www.widespace.com',
    categories: ['analytics'],
    domains: ['*.colpirio.com'],
    examples: ['proxy-tracker.colpirio.com'],
  },
  {
    name: 'Brandmetrics',
    homepage: 'https://www.brandmetrics.com',
    categories: ['analytics'],
    domains: ['*.brandmetrics.com'],
    examples: ['collector.brandmetrics.com', 'cdn.brandmetrics.com'],
  },
  {
    name: 'EasyAd',
    homepage: 'http://www.easy-ads.com/en/',
    categories: ['ad'],
    domains: ['*.easy-ads.com'],
    examples: ['ads.easy-ads.com'],
  },
  {
    name: 'Glimr',
    homepage: 'https://glimr.io/',
    categories: ['analytics'],
    domains: ['*.glimr.io'],
    examples: ['pixel.glimr.io'],
  },
  {
    name: 'Webtreck',
    homepage: 'https://www.webtrekk.com/en/home/',
    categories: ['analytics'],
    domains: ['*.wcfbc.net'],
    examples: ['fbc.wcfbc.net'],
  },
  {
    name: 'DigiTrust',
    homepage: 'http://www.digitru.st/',
    categories: ['analytics'],
    domains: ['*.digitru.st'],
    examples: ['cdn.digitru.st'],
  },
  {
    name: 'Kantar Sifo',
    homepage: 'https://www.kantarsifo.se',
    categories: ['analytics'],
    domains: ['*.research-int.se'],
    examples: ['trafficgateway.research-int.se'],
  },
  {
    name: 'Concert',
    homepage: 'https://concert.io/',
    categories: ['ad'],
    domains: ['*.concert.io'],
    examples: ['cdn.concert.io'],
  },
  {
    name: 'Emerse',
    homepage: 'https://www.emerse.com/',
    categories: ['ad'],
    domains: ['*.emerse.com'],
    examples: ['tracking.emerse.com'],
  },
  {
    name: 'Iterate',
    homepage: 'https://iteratehq.com/',
    categories: ['analytics'],
    domains: ['*.iteratehq.com'],
  },
  {
    name: 'Cookiebot',
    homepage: 'https://www.cookiebot.com/',
    categories: ['utility'],
    domains: ['*.cookiebot.com'],
    examples: ['consent.cookiebot.com'],
  },
  {
    name: 'Netlify',
    homepage: 'https://www.netlify.com/',
    categories: ['utility'],
    domains: ['*.netlify.com', '*.netlifyusercontent.com'],
    examples: ['cloud.netlifyusercontent.com'],
  },
  {
    name: 'Scroll',
    homepage: 'https://scroll.com/',
    categories: ['utility'],
    domains: ['*.scroll.com'],
    examples: ['static.scroll.com', 'connect.scroll.com'],
  },
  {
    name: 'Consumable',
    homepage: 'http://consumable.com/',
    categories: ['ad'],
    domains: ['*.serverbid.com'],
  },
  {
    name: 'DMD Marketing',
    homepage: 'https://www.dmdconnects.com/',
    categories: ['ad'],
    domains: ['*.medtargetsystem.com'],
  },
  {
    name: 'Catchpoint',
    homepage: 'https://www.catchpoint.com/',
    categories: ['analytics'],
    domains: ['*.3gl.net'],
  },
  {
    name: 'Terminus',
    homepage: 'https://terminus.com/',
    categories: ['ad'],
    domains: ['*.terminus.services'],
  },
  {
    name: 'Acceptable Ads',
    homepage: 'https://acceptableads.com/',
    categories: ['ad'],
    domains: ['*.aaxads.com', '*.aaxdetect.com'],
  },
  {
    name: 'ClearBrain',
    homepage: 'https://www.clearbrain.com/',
    categories: ['analytics'],
    domains: ['*.clearbrain.com'],
    examples: ['api.clearbrain.com'],
  },
  {
    name: 'Optanon',
    homepage: 'https://www.cookielaw.org/',
    categories: ['consent-provider'],
    domains: [
      '*.onetrust.com', // onetrust is the parent company
      '*.cookielaw.org',
    ],
    examples: ['cdn.cookielaw.org', 'geolocation.onetrust.com'],
  },
  {
    name: 'TrustArc',
    homepage: 'https://www.trustarc.com/',
    categories: ['utility'],
    domains: ['*.trustarc.com'],
    examples: ['choices.trustarc.com', 'consent.trustarc.com'],
  },
  {
    name: 'iSpot.tv',
    homepage: 'https://www.ispot.tv/',
    categories: ['ad'],
    domains: ['*.ispot.tv'],
    examples: ['pt.ispot.tv'],
  },
  {
    name: 'RevJet',
    homepage: 'https://www.revjet.com/',
    categories: ['ad'],
    domains: ['*.revjet.com'],
    examples: ['pix.revjet.com', 'ads.revjet.com'],
  },
  {
    name: 'atlasRTX',
    homepage: 'https://www.atlasrtx.com/',
    categories: ['customer-success'],
    domains: ['*.atlasrtx.com'],
    examples: ['app.atlasrtx.com', 'cdn.atlasrtx.com', 'dev.atlasrtx.com'],
  },
  {
    name: 'ContactAtOnce',
    homepage: 'https://www.contactatonce.com/',
    categories: ['customer-success'],
    domains: ['*.contactatonce.com'],
    examples: ['tag.contactatonce.com', 'agentpresence.contactatonce.com'],
  },
  {
    name: 'Algolia',
    homepage: 'https://www.algolia.com/',
    categories: ['utility'],
    domains: ['*.algolianet.com', '*.algolia.net', '*.algolia.io'],
  },
  {
    name: 'EMX Digital',
    homepage: 'https://emxdigital.com',
    categories: ['ad'],
    domains: ['*.emxdgt.com'],
  },
  {
    name: 'Moxie',
    homepage: 'https://www.gomoxie.com/',
    categories: ['utility'],
    domains: ['*.gomoxie.solutions'],
  },
  {
    name: 'Scripps Network Digital',
    homepage: 'https://www.scrippsnetworksdigital.com/',
    categories: ['ad'],
    domains: ['*.snidigital.com'],
  },
  {
    name: 'TurnTo',
    homepage: 'https://www.turntonetworks.com/',
    categories: ['utility'],
    domains: ['*.turnto.com'],
  },
  {
    name: 'Quantum Metric',
    homepage: 'https://www.quantummetric.com/',
    categories: ['analytics'],
    domains: ['*.quantummetric.com'],
  },
  {
    name: 'Carbon Ads',
    homepage: 'https://www.carbonads.net/',
    categories: ['ad'],
    domains: ['*.carbonads.net', '*.carbonads.com'],
  },
  {
    name: 'Ably',
    homepage: 'https://www.ably.io/',
    categories: ['utility'],
    domains: ['*.ably.io'],
  },
  {
    name: 'Sectigo',
    homepage: 'https://sectigo.com/',
    categories: ['utility'],
    domains: ['*.sectigo.com'],
  },
  {
    name: 'Specless',
    homepage: 'http://gospecless.com/',
    categories: ['ad'],
    domains: ['*.specless.tech'],
  },
  {
    name: 'Loggly',
    homepage: 'https://www.loggly.com/',
    categories: ['analytics'],
    domains: ['*.loggly.com', 'd9jmv9u00p0mv.cloudfront.net'],
    examples: ['logs-01.loggly.com'],
  },
  {
    name: 'Intent Media',
    homepage: 'https://intent.com/',
    categories: ['ad'],
    domains: ['*.intentmedia.net'],
  },
  {
    name: 'Supership',
    homepage: 'https://supership.jp/',
    categories: ['ad'],
    domains: ['*.socdm.com'],
  },
  {
    name: 'F@N Communications',
    homepage: 'https://www.fancs.com/',
    categories: ['ad'],
    domains: ['*.ladsp.com'],
    examples: ['px.ladsp.com'],
  },
  {
    name: 'Vidyard',
    homepage: 'https://www.vidyard.com/',
    categories: ['utility'],
    domains: ['*.vidyard.com'],
  },
  {
    name: 'RapidSSL',
    homepage: 'https://www.rapidssl.com/',
    categories: ['utility'],
    domains: ['*.rapidssl.com'],
  },
  {
    name: 'Coherent Path',
    homepage: 'https://coherentpath.com/',
    categories: ['utility'],
    domains: ['*.coherentpath.com'],
  },
  {
    name: 'Attentive',
    homepage: 'https://attentivemobile.com/',
    categories: ['ad'],
    domains: ['*.attn.tv', '*.attentivemobile.com'],
  },
  {
    name: 'emetriq',
    homepage: 'https://www.emetriq.com/',
    categories: ['ad'],
    domains: ['*.emetriq.de', '*.xplosion.de'],
  },
  {
    name: 'Bonzai',
    homepage: 'https://www.bonzai.co/',
    categories: ['ad'],
    domains: ['*.bonzai.co'],
  },
  {
    name: 'Freshchat',
    homepage: 'https://www.freshworks.com/live-chat-software/',
    categories: ['utility'],
    domains: ['*.freshchat.com'],
  },
  {
    name: 'Contentful',
    homepage: 'https://www.contentful.com/',
    categories: ['utility'],
    domains: ['*.contentful.com'],
    examples: ['cdn.contentful.com'],
  },
  {
    name: 'PureCars',
    homepage: 'https://www.purecars.com/',
    categories: ['marketing'],
    domains: ['*.purecars.com'],
    examples: ['cdn.purecars.com'],
  },
  {
    name: 'Tray Commerce',
    homepage: 'https://www.tray.com.br/',
    categories: ['marketing'],
    domains: ['*.tcdn.com.br'],
    examples: ['images.tcdn.com.br'],
  },
  {
    name: 'AdScore',
    homepage: 'https://www.adscore.com/',
    categories: ['ad'],
    domains: ['*.adsco.re'],
    examples: ['c.adsco.re'],
  },
  {
    name: 'WebsiteBuilder.com',
    homepage: 'https://www.websitebuilder.com',
    categories: ['hosting'],
    domains: ['*.mywebsitebuilder.com'],
  },
  {
    name: 'mParticle',
    homepage: 'https://www.mparticle.com/',
    categories: ['utility'],
    domains: ['*.mparticle.com'],
    examples: ['jssdks.mparticle.com', 'identity.mparticle.com'],
  },
  {
    name: 'Ada',
    homepage: 'https://www.ada.support/',
    categories: ['customer-success'],
    domains: ['*.ada.support'],
    examples: ['static.ada.support'],
  },
  {
    name: 'Quora Ads',
    homepage: 'https://www.quora.com/business/',
    categories: ['ad'],
    domains: ['*.quora.com'],
    examples: ['q.quora.com'],
  },
  {
    name: 'Auth0',
    homepage: 'https://auth0.com/',
    categories: ['utility'],
    domains: ['*.auth0.com'],
    examples: ['cdn.auth0.com'],
  },
  {
    name: 'Bridgewell DSP',
    homepage: 'https://www.bridgewell.com/',
    categories: ['ad'],
    domains: ['*.scupio.com'],
    examples: ['img.scupio.com'],
  },
  {
    name: 'Wicked Reports',
    homepage: 'https://www.wickedreports.com/',
    categories: ['marketing'],
    domains: ['*.wickedreports.com'],
    examples: ['widget.wickedreports.com'],
  },
  {
    name: 'Jaywing',
    homepage: 'https://jaywing.com/',
    categories: ['marketing'],
    domains: ['*.jaywing.com'],
    examples: ['amazon.almanac.jaywing.com'],
  },
  {
    name: 'Holimetrix',
    homepage: 'https://u360.d-bi.fr/',
    categories: ['marketing'],
    domains: ['*.d-bi.fr'],
    examples: ['u360.d-bi.fr'],
  },
  {
    name: 'iZooto',
    homepage: 'https://www.izooto.com',
    categories: ['marketing'],
    domains: ['*.izooto.com'],
    examples: ['cdn.izooto.com'],
  },
  {
    name: 'Ordergroove',
    homepage: 'https://www.ordergroove.com/',
    categories: ['marketing'],
    domains: ['*.ordergroove.com'],
    examples: ['static.ordergroove.com'],
  },
  {
    name: 'PageSense',
    homepage: 'https://www.zoho.com/pagesense/',
    categories: ['analytics'],
    domains: ['*.pagesense.io'],
    examples: ['cdn.pagesense.io'],
  },
  {
    name: 'Vizzit',
    homepage: 'http://www.vizzit.se',
    categories: ['analytics'],
    domains: ['*.vizzit.se'],
    examples: ['www.vizzit.se', 'tag.vizzit.se'],
  },
  {
    name: 'Click Guardian',
    homepage: 'https://www.clickguardian.co.uk/',
    categories: ['advertising'],
    domains: ['*.clickguardian.app', '*.clickguardian.co.uk'],
    examples: ['v2.clickguardian.app', 'protection.clickguardian.co.uk'],
  },
  {
    name: 'Smartsupp',
    company: 'Smartsupp.com',
    homepage: 'https://www.smartsupp.com',
    categories: ['customer-success'],
    domains: [
      '*.smartsuppchat.com',
      '*.smartsupp.com',
      'smartsupp-widget-161959.c.cdn77.org',
      '*.smartsuppcdn.com',
    ],
    examples: ['widget-v1.smartsuppcdn.com'],
  },
  {
    name: 'Smartlook',
    company: 'Smartsupp.com',
    homepage: 'https://www.smartlook.com/',
    categories: ['analytics'],
    domains: ['*.smartlook.com'],
  },
  {
    name: 'Luigi’s Box',
    company: 'Luigi’s Box',
    homepage: 'https://www.luigisbox.com/',
    categories: ['utility'],
    domains: ['*.luigisbox.com'],
  },
  {
    name: 'Targito',
    company: 'VIVmail.cz',
    homepage: 'https://www.targito.com',
    categories: ['marketing'],
    domains: ['*.targito.com'],
  },
  {
    name: 'Foxentry',
    company: 'AVANTRO',
    homepage: 'https://foxentry.cz/',
    categories: ['utility'],
    domains: ['*.foxentry.cz'],
  },
  {
    name: 'Pendo',
    homepage: 'https://www.pendo.io',
    categories: ['analytics'],
    domains: ['*.pendo.io'],
    examples: ['app.pendo.io'],
  },
  {
    name: 'Braze',
    homepage: 'https://www.braze.com',
    categories: ['analytics'],
    domains: ['*.appboycdn.com'],
    examples: ['js.appboycdn.com'],
  },
  {
    name: 'Usersnap',
    homepage: 'https://usersnap.com',
    categories: ['customer-success'],
    domains: ['*.usersnap.com'],
    examples: ['api.usersnap.com', 'cdn.usersnap.com'],
  },
  {
    name: 'Rewardful',
    homepage: 'https://www.getrewardful.com',
    categories: ['analytics'],
    domains: ['*.wdfl.co'],
    examples: ['r.wdfl.co'],
  },
  {
    name: 'Launch Darkly',
    homepage: 'https://launchdarkly.com',
    categories: ['utility'],
    domains: ['*.launchdarkly.com'],
    examples: ['app.launchdarkly.com', 'events.launchdarkly.com'],
  },
  {
    name: 'Statuspage',
    company: 'Atlassian',
    homepage: 'https://www.statuspage.io',
    categories: ['utility'],
    domains: ['*.statuspage.io'],
    examples: ['1k6wzpspjf99.statuspage.io'],
  },
  {
    name: 'HyperInzerce',
    homepage: 'https://hyperinzerce.cz',
    categories: ['ad'],
    domains: ['*.hyperinzerce.cz'],
    examples: ['motorky.hyperinzerce.cz'],
  },
  {
    name: 'POWr',
    homepage: 'https://www.powr.io',
    categories: ['utility'],
    domains: ['*.powr.io'],
    examples: ['www.powr.io'],
  },
  {
    name: 'Coral',
    company: 'Coral',
    homepage: 'https://coralproject.net',
    categories: ['content'],
    domains: ['*.coral.coralproject.net'],
    examples: ['company.coral.coralproject.net'],
  },
  {
    name: 'Bolt',
    homepage: 'https://www.bolt.com/',
    categories: ['utility'],
    domains: ['*.bolt.com'],
    examples: ['connect.bolt.com'],
  },
  {
    name: 'Judge.me',
    homepage: 'https://judge.me/',
    categories: ['marketing'],
    domains: ['*.judge.me'],
    examples: ['cdn.judge.me'],
  },
  {
    name: 'Tilda',
    homepage: 'http://tilda.cc/',
    categories: ['hosting'],
    domains: ['*.tildacdn.com'],
    examples: ['stat.tildacdn.com', 'static.tildacdn.com', 'upwidget.tildacdn.com'],
  },
  {
    name: 'SalesLoft',
    homepage: 'https://salesloft.com/',
    categories: ['analytics', 'marketing'],
    domains: ['*.salesloft.com'],
    examples: ['scout-cdn.salesloft.com'],
  },
  {
    name: 'Accessibe',
    homepage: 'https://accessibe.com/',
    categories: ['utility'],
    domains: ['*.accessibe.com', '*.acsbap.com'],
    examples: ['accessibe.com', 'acsbap.com'],
  },
  {
    name: 'Builder',
    homepage: 'https://www.builder.io/',
    categories: ['hosting'],
    domains: ['*.builder.io'],
    examples: ['cdn.builder.io'],
  },
  {
    name: 'Pepperjam',
    homepage: 'https://www.pepperjam.com/',
    categories: ['marketing', 'analytics'],
    domains: ['*.pepperjam.com', '*.affiliatetechnology.com'],
    examples: ['container.pepperjam.com'],
  },
  {
    name: 'Reach',
    homepage: 'https://withreach.com/',
    categories: ['utility'],
    domains: ['*.gointerpay.net'],
    examples: ['checkout.gointerpay.net'],
  },
  {
    name: 'Chameleon',
    homepage: 'https://www.trychameleon.com/',
    categories: ['marketing'],
    domains: ['*.trychameleon.com'],
    examples: ['fast.trychameleon.com'],
  },
  {
    name: 'Matomo',
    company: 'InnoCraft',
    homepage: 'https://matomo.org/',
    categories: ['analytics'],
    domains: ['*.matomo.cloud'],
  },
  {
    name: 'Segmanta',
    homepage: 'https://segmanta.com/',
    categories: ['marketing', 'analytics'],
    domains: ['*.segmanta.com'],
    examples: ['clientName.segmanta.com'],
  },
  {
    name: 'Podsights',
    homepage: 'https://podsights.com/',
    categories: ['marketing'],
    domains: ['*.pdst.fm'],
    examples: ['cdn.pdst.fm'],
  },
  {
    name: 'Chatwoot',
    homepage: 'https://www.chatwoot.com/',
    categories: ['customer-success'],
    domains: ['*.chatwoot.com'],
    examples: ['cdn.chatwoot.com', 'app.chatwoot.com'],
  },
  {
    name: 'Crisp',
    homepage: 'https://crisp.chat/',
    categories: ['customer-success'],
    domains: ['*.crisp.chat'],
    examples: ['client.crisp.chat', 'client.relay.crisp.chat'],
  },
  {
    name: 'Admiral CMP',
    homepage: 'https://www.getadmiral.com',
    categories: ['consent-provider'],
    domains: ['admiral.mgr.consensu.org', '*.admiral.mgr.consensu.org'],
  },
  {
    name: 'Adnuntius CMP',
    homepage: 'https://adnuntius.com',
    categories: ['consent-provider'],
    domains: ['adnuntiusconsent.mgr.consensu.org', '*.adnuntiusconsent.mgr.consensu.org'],
  },
  {
    name: 'Clickio CMP',
    homepage: 'https://clickio.com',
    categories: ['consent-provider'],
    domains: ['clickio.mgr.consensu.org', '*.clickio.mgr.consensu.org'],
  },
  {
    name: 'AppConsent CMP',
    homepage: 'https://appconsent.io/en',
    categories: ['consent-provider'],
    domains: ['appconsent.mgr.consensu.org', '*.appconsent.mgr.consensu.org'],
  },
  {
    name: 'DMG Media CMP',
    homepage: 'https://www.dmgmedia.co.uk',
    categories: ['consent-provider'],
    domains: ['dmgmedia.mgr.consensu.org', '*.dmgmedia.mgr.consensu.org'],
  },
  {
    name: 'Axel Springer CMP',
    homepage: 'https://www.axelspringer.com',
    categories: ['consent-provider'],
    domains: ['axelspringer.mgr.consensu.org', '*.axelspringer.mgr.consensu.org'],
  },
  {
    name: 'Bedrock CMP',
    homepage: 'https://www.bedrockstreaming.com',
    categories: ['consent-provider'],
    domains: ['bedrock.mgr.consensu.org', '*.bedrock.mgr.consensu.org'],
  },
  {
    name: 'BMIND CMP',
    homepage: 'https://www.bmind.es',
    categories: ['consent-provider'],
    domains: ['bmind.mgr.consensu.org', '*.bmind.mgr.consensu.org'],
  },
  {
    name: 'Borlabs CMP',
    homepage: 'https://borlabs.io',
    categories: ['consent-provider'],
    domains: ['borlabs.mgr.consensu.org', '*.borlabs.mgr.consensu.org'],
  },
  {
    name: 'Civic CMP',
    homepage: 'https://www.civicuk.com',
    categories: ['consent-provider'],
    domains: ['cookiecontrol.mgr.consensu.org', '*.cookiecontrol.mgr.consensu.org'],
  },
  {
    name: 'Commanders Act CMP',
    homepage: 'https://www.commandersact.com',
    categories: ['consent-provider'],
    domains: ['commandersact.mgr.consensu.org', '*.commandersact.mgr.consensu.org'],
  },
  {
    name: 'Complianz CMP',
    homepage: 'https://complianz.io/',
    categories: ['consent-provider'],
    domains: ['complianz.mgr.consensu.org', '*.complianz.mgr.consensu.org'],
  },
  {
    name: 'Consent Desk CMP',
    homepage: 'https://www.consentdesk.com/',
    categories: ['consent-provider'],
    domains: ['consentdesk.mgr.consensu.org', '*.consentdesk.mgr.consensu.org'],
  },
  {
    name: 'Consent Manager CMP',
    homepage: 'https://consentmanager.net',
    categories: ['consent-provider'],
    domains: ['consentmanager.mgr.consensu.org', '*consentmanager.mgr.consensu.org'],
  },
  {
    name: 'Conversant CMP',
    homepage: 'https://www.conversantmedia.eu/',
    categories: ['consent-provider'],
    domains: ['conversant.mgr.consensu.org', '*.conversant.mgr.consensu.org'],
  },
  {
    name: 'Cookie Information CMP',
    homepage: 'https://www.cookieinformation.com/',
    categories: ['consent-provider'],
    domains: ['cookieinformation.mgr.consensu.org', '*.cookieinformation.mgr.consensu.org'],
  },
  {
    name: 'Cookiebot CMP',
    homepage: 'https://www.cookiebot.com',
    categories: ['consent-provider'],
    domains: ['cookiebot.mgr.consensu.org', '*.cookiebot.mgr.consensu.org'],
  },
  {
    name: 'Truendo CMP',
    homepage: 'https://truendo.com/',
    categories: ['consent-provider'],
    domains: ['truendo.mgr.consensu.org', '*.truendo.mgr.consensu.org'],
  },
  {
    name: 'Dentsu CMP',
    homepage: 'https://www.dentsuaegisnetwork.de/',
    categories: ['consent-provider'],
    domains: ['dan.mgr.consensu.org', '*.dan.mgr.consensu.org'],
  },
  {
    name: 'Didomi CMP',
    homepage: 'https://www.didomi.io/en/',
    categories: ['consent-provider'],
    domains: ['didomi.mgr.consensu.org', '*.didomi.mgr.consensu.org'],
  },
  {
    name: 'Ensighten CMP',
    homepage: 'https://www.ensighten.com/',
    categories: ['consent-provider'],
    domains: ['ensighten.mgr.consensu.org', '*.ensighten.mgr.consensu.org'],
  },
  {
    name: 'Evidon CMP',
    homepage: 'https://evidon.com',
    categories: ['consent-provider'],
    domains: ['evidon.mgr.consensu.org', '*.evidon.mgr.consensu.org'],
  },
  {
    name: 'Ezoic CMP',
    homepage: 'https://www.ezoic.com/',
    categories: ['consent-provider'],
    domains: ['ezoic.mgr.consensu.org', '*.ezoic.mgr.consensu.org'],
  },
  {
    name: 'Gemius CMP',
    homepage: 'https://www.gemius.com',
    categories: ['consent-provider'],
    domains: ['gemius.mgr.consensu.org', '*.gemius.mgr.consensu.org'],
  },
  {
    name: 'NitroPay CMP',
    homepage: 'https://nitropay.com/',
    categories: ['consent-provider'],
    domains: ['nitropay.mgr.consensu.org', '*.nitropay.mgr.consensu.org'],
  },
  {
    name: 'Google FundingChoices',
    homepage: 'https://fundingchoices.google.com/start/',
    categories: ['consent-provider'],
    domains: ['fundingchoices.mgr.consensu.org', '*.fundingchoices.mgr.consensu.org'],
  },
  {
    name: 'Gravito CMP',
    homepage: 'https://www.gravito.net/',
    categories: ['consent-provider'],
    domains: ['gravito.mgr.consensu.org', '*.gravito.mgr.consensu.org'],
  },
  {
    name: 'ID Ward CMP',
    homepage: 'https://id-ward.com/enterprise',
    categories: ['consent-provider'],
    domains: ['idward.mgr.consensu.org', '*.idward.mgr.consensu.org'],
  },
  {
    name: 'iubenda CMP',
    homepage: 'https://www.iubenda.com',
    categories: ['consent-provider'],
    domains: ['iubenda.mgr.consensu.org', '*.iubenda.mgr.consensu.org'],
  },
  {
    name: 'Jump CMP',
    homepage: 'https://jumpgroup.it/',
    categories: ['consent-provider'],
    domains: ['avacy.mgr.consensu.org', '*.avacy.mgr.consensu.org'],
  },
  {
    name: 'LiveRamp CMP',
    homepage: 'https://liveramp.com/',
    categories: ['consent-provider'],
    domains: ['faktor.mgr.consensu.org', '*.faktor.mgr.consensu.org'],
  },
  {
    name: 'Madvertise CMP',
    homepage: 'https://madvertise.com/en/',
    categories: ['consent-provider'],
    domains: ['madvertise.mgr.consensu.org', '*.madvertise.mgr.consensu.org'],
  },
  {
    name: 'Mairdumont Netletic CMP',
    homepage: 'https://www.mairdumont-netletix.com/',
    categories: ['consent-provider'],
    domains: ['mdnxmp.mgr.consensu.org', '*.mdnxmp.mgr.consensu.org'],
  },
  {
    name: 'Marfeel CMP',
    homepage: 'https://www.marfeel.com/',
    categories: ['consent-provider'],
    domains: ['marfeel.mgr.consensu.org', '*.marfeel.mgr.consensu.org'],
  },
  {
    name: 'Mediavine CMP',
    homepage: 'https://www.mediavine.com/',
    categories: ['consent-provider'],
    domains: ['mediavine.mgr.consensu.org', '*.mediavine.mgr.consensu.org'],
  },
  {
    name: 'ConsentServe CMP',
    homepage: 'https://www.consentserve.com/',
    categories: ['consent-provider'],
    domains: ['consentserve.mgr.consensu.org', '*.consentserve.mgr.consensu.org'],
  },
  {
    name: 'Next14 CMP',
    homepage: 'https://www.next14.com/',
    categories: ['consent-provider'],
    domains: ['next14.mgr.consensu.org', '*.next14.mgr.consensu.org'],
  },
  {
    name: 'AdRoll CMP',
    homepage: 'https://www.adroll.com/',
    categories: ['consent-provider'],
    domains: ['adroll.mgr.consensu.org', '*.adroll.mgr.consensu.org'],
  },
  {
    name: 'Ogury CMP',
    homepage: 'https://www.ogury.com/',
    categories: ['consent-provider'],
    domains: ['ogury.mgr.consensu.org', '*.ogury.mgr.consensu.org'],
  },
  {
    name: 'OneTag CMP',
    homepage: 'https://onetag.net',
    categories: ['consent-provider'],
    domains: ['onetag.mgr.consensu.org', '*.onetag.mgr.consensu.org'],
  },
  {
    name: 'OneTrust CMP',
    homepage: 'https://onetrust.com',
    categories: ['consent-provider'],
    domains: ['onetrust.mgr.consensu.org', '*.onetrust.mgr.consensu.org'],
  },
  {
    name: 'optAd360 CMP',
    homepage: 'https://www.optad360.com/',
    categories: ['consent-provider'],
    domains: ['optad360.mgr.consensu.org', '*.optad360.mgr.consensu.org'],
  },
  {
    name: 'Osano CMP',
    homepage: 'https://www.osano.com',
    categories: ['consent-provider'],
    domains: ['osano.mgr.consensu.org', '*.osano.mgr.consensu.org'],
  },
  {
    name: 'Playwire CMP',
    homepage: 'http://playwire.com',
    categories: ['consent-provider'],
    domains: ['playwire.mgr.consensu.org', '*.playwire.mgr.consensu.org'],
  },
  {
    name: 'Pulselive CMP',
    homepage: 'https://www.pulselive.com',
    categories: ['consent-provider'],
    domains: ['pulselive.mgr.consensu.org', '*.pulselive.mgr.consensu.org'],
  },
  {
    name: 'Quantcast Choice',
    homepage: 'https://quantcast.com',
    categories: ['consent-provider'],
    domains: ['quantcast.mgr.consensu.org', '*.quantcast.mgr.consensu.org'],
  },
  {
    name: 'RCS Pubblicita CMP',
    homepage: 'http://www.rcspubblicita.it/site/home.html',
    categories: ['consent-provider'],
    domains: ['rcsmediagroup.mgr.consensu.org', '*.rcsmediagroup.mgr.consensu.org'],
  },
  {
    name: 'Rich Audience CMP',
    homepage: 'https://richaudience.com',
    categories: ['consent-provider'],
    domains: ['richaudience.mgr.consensu.org', '*.richaudience.mgr.consensu.org'],
  },
  {
    name: 'Ringier Axel Springer CMP',
    homepage: 'https://www.ringieraxelspringer.pl/en/home/',
    categories: ['consent-provider'],
    domains: ['rasp.mgr.consensu.org', '*.rasp.mgr.consensu.org'],
  },
  {
    name: 'Secure Privacy CMP',
    homepage: 'https://secureprivacy.ai/',
    categories: ['consent-provider'],
    domains: ['secureprivacy.mgr.consensu.org', '*.secureprivacy.mgr.consensu.org'],
  },
  {
    name: 'Securiti CMP',
    homepage: 'https://securiti.ai/',
    categories: ['consent-provider'],
    domains: ['securiti.mgr.consensu.org', '*.securiti.mgr.consensu.org'],
  },
  {
    name: 'Seznam.cz CMP',
    homepage: 'https://www.seznam.cz/',
    categories: ['consent-provider'],
    domains: ['seznam.mgr.consensu.org', '*.seznam.mgr.consensu.org'],
  },
  {
    name: 'ShareThis CMP',
    homepage: 'https://sharethis.com',
    categories: ['consent-provider'],
    domains: ['sharethis.mgr.consensu.org', '*.sharethis.mgr.consensu.org'],
  },
  {
    name: 'ShinyStat CMP',
    homepage: 'https://www.shinystat.com',
    categories: ['consent-provider'],
    domains: ['shinystat.mgr.consensu.org', '*.shinystat.mgr.consensu.org'],
  },
  {
    name: 'Sibbo CMP',
    homepage: 'http://sibboventures.com/en/',
    categories: ['consent-provider'],
    domains: ['sibboventures.mgr.consensu.org', '*.sibboventures.mgr.consensu.org'],
  },
  {
    name: 'Singlespot CMP',
    homepage: 'https://www.singlespot.com/en',
    categories: ['consent-provider'],
    domains: ['singlespot.mgr.consensu.org', '*.singlespot.mgr.consensu.org'],
  },
  {
    name: 'Sirdata CMP',
    homepage: 'https://www.sirdata.com',
    categories: ['consent-provider'],
    domains: ['sddan.mgr.consensu.org', '*.sddan.mgr.consensu.org'],
  },
  {
    name: 'Snigel CMP',
    homepage: 'http://www.snigelweb.com/',
    categories: ['consent-provider'],
    domains: ['snigelweb.mgr.consensu.org', '*.snigelweb.mgr.consensu.org'],
  },
  {
    name: 'Sourcepoint CMP',
    homepage: 'https://sourcepoint.com',
    categories: ['consent-provider'],
    domains: ['sourcepoint.mgr.consensu.org', '*.sourcepoint.mgr.consensu.org'],
  },
  {
    name: 'Pubtech CMP',
    homepage: 'https://www.pubtech.ai/',
    categories: ['consent-provider'],
    domains: ['pubtech.mgr.consensu.org', '*.pubtech.mgr.consensu.org'],
  },
  {
    name: 'AdMetrics Pro CMP',
    homepage: 'https://admetricspro.com',
    categories: ['consent-provider'],
    domains: ['cmp.mgr.consensu.org', '*.cmp.mgr.consensu.org'],
  },
  {
    name: 'Traffective CMP',
    homepage: 'https://traffective.com',
    categories: ['consent-provider'],
    domains: ['traffective.mgr.consensu.org', '*.traffective.mgr.consensu.org'],
  },
  {
    name: 'UniConsent CMP',
    homepage: 'https://uniconsent.com',
    categories: ['consent-provider'],
    domains: ['uniconsent.mgr.consensu.org', '*.uniconsent.mgr.consensu.org'],
  },
  {
    name: 'TrustArc CMP',
    homepage: 'https://trustarc.com/',
    categories: ['consent-provider'],
    domains: ['trustarc.mgr.consensu.org', '*.trustarc.mgr.consensu.org'],
  },
  {
    name: 'Usercentrics CMP',
    homepage: 'https://usercentrics.com',
    categories: ['consent-provider'],
    domains: ['usercentrics.mgr.consensu.org', '*.usercentrics.mgr.consensu.org'],
  },
  {
    name: 'WebAds CMP',
    homepage: 'http://www.webads.nl/',
    categories: ['consent-provider'],
    domains: ['webads.mgr.consensu.org', '*.webads.mgr.consensu.org'],
  },
  {
    name: 'Trustcommander',
    company: 'Commandersact',
    homepage: 'https://www.commandersact.com',
    categories: ['consent-provider'],
    domains: ['*.trustcommander.net'],
    examples: ['cdn.trustcommander.net', 'privacy.trustcommander.net'],
  },
  {
    name: 'Hubvisor',
    homepage: 'https://www.hubvisor.io',
    categories: ['ad'],
    domains: ['*.hubvisor.io'],
    examples: ['cdn.hubvisor.io', 'stream.hubvisor.io'],
  },
  {
    name: 'Castle',
    homepage: 'https://castle.io',
    categories: ['utility'],
    domains: [
      '*.castle.io',
      'd2t77mnxyo7adj.cloudfront.net'
    ],
    examples: ['t.castle.io'],
  },
  {
    name: 'Wigzo',
    homepage: 'https://www.wigzo.com/',
    categories: ['marketing'],
    domains: ['*.wigzo.com','*.wigzopush.com'],
    examples: ['app.wigzo.com', 'tracker.wigzopush.com'],
  },
]
