import React from 'react';
import ReactPlayer from 'react-player/lazy';

class Hero extends React.Component {

  render(){
    return (
        <div className="flex flex-col justify-between gap-9">
          <h2 className="flex-none text-4xl font-bold p-9 pb-0">Hello. My name is Tim and I'm glad your here...</h2>
          <div className="flex-grow px-9">
            <ReactPlayer
              url={'/videos/walkthrough-v3.mp4'}
              controls={true}
              className={'react-player'}
              width={'720px'}
              height={'auto'}
            />
          </div>
          <p className="flex-none p-9 pt-0">Feel free to send an email to tim.bednar@gmail.com or Tweet @timbednar</p>
        </div>
    )
  }

}

export default Hero;
