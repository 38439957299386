import React from 'react';
import Request from '../Request/Request';
import handleOpenRequest from '../Request/handleOpenRequest';

const prettyMilliseconds = require('pretty-ms');
const prettyBytes = require('pretty-bytes');

function EventTimeLabel(props){
  if (props.eventTimingIndex['lcp']===props.index)  {
    return (
      <span className="absolute -top-5 z-50 bg-blue-500 h-10 pt-3 right-0 right-2 rounded-full text-center text-white top-0 w-10 uppercase">
        lcp
      </span>
    )
  } else if (props.eventTimingIndex['fcp']===props.index) {
    return (
      <span className="absolute -top-5 z-50 border-2 border-blue-500 border-dashed h-10 pt-3 right-0 right-2 rounded-full text-center text-blue-500 bg-gray-200 top-0 w-10 uppercase">
        fcp
      </span>
    )
  } else  {
    return null;
  }
}

class Waterfall extends React.Component {

  formatRowId(index){
    return `request-${index}`;
  }

  setWidth(time,fullyloaded){
    //const convertToPixels = (time/1000)*50
    const convertToPercent = (time*100)/fullyloaded;
    if (convertToPercent > 1) {
      return `${convertToPercent}%`;
    } else {
      return '2px';
    }
  }

  setHeight(size,override){
    let convertToPixels = (size/2000<override) ? override : size/2000;
    return `${convertToPixels}px`;
  }

  setBg(){
    //return 'none'
    return 'linear-gradient(to right, #e5e7eb 1px, transparent 1px)'
  }

  setBgSize(fullyloaded,timing){
    let convertToPercent = (timing/fullyloaded)*100;
    return `${convertToPercent}%`;
  }

  hasAudit(checkId,audits){
    for (let audit of audits) {
      if (audit.id===checkId) {
        return true;
      }
    }
    return false;
  }

  countAudits (audits){
    if (audits.length===0) return;
    return audits.length;
  }

  setChartClassNames(request){
    let classList = 'flex-none download rounded-md outline-none focus:outline-none';
    let color = "blue";
    color = (request.resourceType==='Script') ? 'amber' : color;
    color = (request.resourceType==='Stylesheet') ? 'teal' : color;
    color = (request.resourceType==='Font') ? 'pink' : color;
    color = (request.resourceType==='Image') ? 'purple' : color;
    let weight = request.audits.length>0 ? '500' : '300';
    if (this.hasAudit('start-time-gap',request.audits)){
      classList = `${classList} border-l-4 border-red-500`;
    }
    if (this.hasAudit('render-blocking-resources',request.audits)){
      classList = `${classList} border-2 border-red-500 bg-${color}-${weight}`;
    } else {
      classList = `${classList} bg-${color}-${weight}`;
    }
    return classList;
  }

  setTypeColor(type){
    let color = "blue";
    color = (type==='Script') ? 'amber' : color;
    color = (type==='Stylesheet') ? 'teal' : color;
    color = (type==='Font') ? 'pink' : color;
    color = (type==='Image') ? 'purple' : color;
    return color;
  }

  setRequestClassNames(request,index,eventTimingIndex){
    let classNames = `row flex items-center ${(index%2 === 0) ? 'even' : 'odd'} group hover:bg-white cursor-pointer ${(eventTimingIndex.lcp===index||eventTimingIndex.fcp===index) ? 'border-blue-500 border-t-2' : ''} ${(eventTimingIndex.fcp===index) ? 'border-dashed' : 'border-solid'}${request.audits.length>0 ? ' fix' : ''}${index>0 ? '' : ' border-b border-gray-500'}`;
    return classNames;
  }

  setIndexClassName(type,party='first'){
    let color = "blue";

    color = (type==='Script') ? 'amber' : color;
    color = (type==='Stylesheet') ? 'teal' : color;
    color = (type==='Font') ? 'pink' : color;
    color = (type==='Image') ? 'purple' : color;
    let sharedClasses = `text-${color}-600 group-hover:text-${color}-800 text-center ml-1 inline-block w-8`;
    if (party==='third'){
      return `bg-${color}-200 group-hover:bg-${color}-300 border-2 border-${color}-400 group-hover:border-${color}-600 ${sharedClasses}`;
    }
    return `bg-${color}-200 group-hover:bg-${color}-300 ${sharedClasses}`;
  }

  componentDidMount() {
    document.getElementById(`file-0`).click();
    document.getElementById(`filter-fixes`).click();
  }

  render () {
    const fullyloaded = Math.round(this.props.lastRequest.endTime);
    const fcp = prettyMilliseconds(this.props.cwv.metrics.firstContentfulPaint);
    const lcp = prettyMilliseconds(this.props.cwv.metrics.largestContentfulPaint);
    const observedFirstContentfulPaint = Math.round(this.props.cwv.metrics.observedFirstContentfulPaint);
    const observedLargestContentfulPaint = Math.round(this.props.cwv.metrics.observedLargestContentfulPaint);
    const observedLoad = Math.round(this.props.cwv.metrics.observedLoad);

    return (
      <div className="m-0 p-0">
        <div id="chart" className="width-full pb-9 bg-gray-200 font-mono text-xs">
          <div className="flex items-center text-xs border-b border-gray-500">
            <div className="w-80 px-0 pl-4 text-left">
              <p>Showing <span id="tally" className="uppercase">{this.props.requests.length-1}</span> of <span className="uppercase">{this.props.requests.length-1}</span> total files</p>
            </div>
            <div className="flex-grow px-0 text-left">
              <div
                className="flex items-center overflow-hidden"
                style={{
                  height: '26px'
                }}
              >
                <div
                  className="flex-none text-right"
                  style={{
                    width: this.setWidth(observedLargestContentfulPaint,fullyloaded)
                  }}
                >
                  <span className="float-right -mr-1.5 w-18 text-center">{lcp}&#8595;</span>
                </div>
                <div
                  className="flex-none text-right"
                  style={{
                    width: this.setWidth(observedLoad-observedLargestContentfulPaint,fullyloaded)
                  }}
                >
                  <span
                    className="float-right mt-0.5 -mr-1.5 w-18 text-center"
                    >Load&#8595;</span>
                </div>
              </div>
            </div>
          </div>

          {this.props.requests.map((request, index) => (
            <div
              key={index}
              id={this.formatRowId(index)}
              className={this.setRequestClassNames(request,index,this.props.eventTimingIndex)}
              data-party={request.party}
              data-type={request.resourceType}
              data-fix={request.fix}
              data-priority={(request.priority==='') ? '' : request.priority}
              data-host={request.host}
              data-before-event={request.beforeEvent}
              data-start-time={Math.round(request.startTime)}
              data-gap={this.hasAudit('start-time-gap',request.audits) ? 'true' : 'false'}
              data-blocked={this.hasAudit('render-blocking-resources',request.audits) ? 'true' : 'false'}
              data-url={encodeURIComponent(request.url.split( '?' )[0])}
              onClick={() => handleOpenRequest(request.index, request,this.props.fcp,this.props.lcp,this.props.ol,this.props.budget,this.props.pageUrl)}
            >
              <div className={'text-right pl-2 w-8 py-2'+(index===0 ? ' hidden' : '')}>
                {index}
              </div>
              <div
                className={'filename pl-4 overflow-hidden py-2'+(index===0 ? ' w-80' : ' w-72')}
              >
                <Request
                  requestIndex = {index}
                  request = {request}
                  fcp = {fcp}
                  lcp = {lcp}
                  ol = {observedLoad}
                  budget = {this.props.budget}
                />
              </div>
              <div
                className="flex-grow relative p-0 chart py-2"
                style={{
                  background: `linear-gradient(#93C5FD,#93C5FD) calc(1*${this.setBgSize(fullyloaded,observedLargestContentfulPaint)}),linear-gradient(#93C5FD,#93C5FD) calc(1*${this.setBgSize(fullyloaded,observedLoad)})`,
                  backgroundSize: "1px 100%",
                  backgroundRepeat: "no-repeat"
                }}
              >
                <div
                  className="flex items-center overflow-hidden"
                  style={{
                    height: this.setHeight(request.transferSize,16)
                  }}
                >
                  <div
                    className="flex-none wait"
                    style={{
                      width: this.setWidth(request.startTime,fullyloaded),
                      height: this.setHeight(request.transferSize,16),
                      backgroundColor: "transparent"
                    }}
                  >
                  </div>
                  <div
                    className={this.setChartClassNames(request)}
                    style={{
                      width: this.setWidth(request.endTime-request.startTime,fullyloaded),
                      height: this.setHeight(request.transferSize,16)
                    }}
                    //onClick={() => handleOpenRequest(request.index, request,this.props.fcp,this.props.lcp,this.props.ol,this.props.budget,this.props.pageUrl)}
                  ></div>
                  <span className="text-xs ml-2  text-gray-500  group-hover:text-black uppercase">
                    {( (request.audits.length>0)&&(request.startTime<observedLoad) ) ? request.fix : ""}
                  </span>
                  <EventTimeLabel
                    label="lcp"
                    eventTimingIndex={this.props.eventTimingIndex}
                    index={index}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="w-full pt-9">
            <p className="text-center">This {this.props.result.lighthouseResult.configSettings.emulatedFormFactor} test with Lighthouse version {this.props.result.lighthouseResult.lighthouseVersion} took {prettyMilliseconds(this.props.result.lighthouseResult.timing.total)} on {this.props.result.lighthouseResult.fetchTime}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Waterfall;
