import React from 'react';

function saveMarkdown(markdown,pageUrl,timestamp){
  let FileSaver = require('file-saver');
  let blob = new Blob([markdown], {type: "text/plain;charset=utf-8"});
  FileSaver.saveAs(blob, timestamp + "-" + pageUrl.substring(0, 200) + ".txt");
}

class Header extends React.Component {
  render = () => {

    let fails = this.props.crux.fid.status!=="passed" ? 1 : 0;
    fails = this.props.crux.lcp.status!=="passed" ? fails+1 : fails;
    fails = this.props.crux.cls.status!=="passed" ? fails+1 : fails;

    return (
      <header className="py-9 px-4">
        <div className="text-2xl font-bold flex items-center">
          <h1 className="flex-grow">Your guidebook has {this.props.analysis.total-this.props.analysis.tbt} tasks to fix {fails} of 3 failed Core Web Vitals</h1>
          <button
            className="flex-none block rounded text-center hover:bg-gray-200 text-gray-500 p-1 focus:outline-none outline-none rounded text-center text-xs uppercase"
            onClick={() => saveMarkdown(this.props.markdown,this.props.pageUrl,this.props.timestamp)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path></svg>
          </button>
        </div>
        <div className="text-sm truncate blur">
          <a href={this.props.pageUrl} target="_blank" rel="noreferrer">
            {this.props.pageUrl} ↗
          </a>
        </div>
      </header>
    )
  }
}

export default Header;
