import ReactGA from 'react-ga';

function toggleClassName(element,className='hidden',e,category='',action='',value=''){
  console.log(e);
  const header = e.target.parentElement;
  header.classList.toggle('expanded');
  e.target.classList.toggle('clicked');
  document.getElementById(element).classList.toggle(className);
  ReactGA.event({
    category: category,
    action: action,
    value: value
  });
}

export default toggleClassName;
