import React from 'react';
import ReactDOM from 'react-dom';
import { del, keys, get, set } from 'idb-keyval';
import ReactGA from 'react-ga';
import Header from '../Header/Header';
import Filters from '../Filters/Filters';
import Results from '../Results/Results';
import Waterfall from '../Waterfall/Waterfall';
import Screenshot from '../Screenshot/Screenshot';
import Backlog from '../Backlog/Backlog';
import Hero from '../Hero/Hero';
import getThirdPartyEntity from '../../utilities/getThirdPartyEntity';

const DEMO = true;

const prettyMilliseconds = require('pretty-ms');
const prettyBytes = require('pretty-bytes');
const parseUrl = require("parse-url");
const ordinal = require('ordinal');

const TRACKING_ID = "UA-167361927-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID, {
  debug: true,
  titleCase: false,
  siteSpeedSampleRate: 100,
});
ReactGA.pageview(window.location.pathname + window.location.search);

var psl = require('psl');

function extractFilename(url,index) {

  if (index===0) return 'rootfile';

  let filename = url.split( '?' )[0];
  filename = filename.substring(url.lastIndexOf('/')+1);
  if (filename.length === 0 ) {
    filename = "[filename]";
  }

  if ( filename.length>=30 ) {
    let truncatedFilename="",
      firstCharCount = 10,
      endCharCount = 17,
      dotCount = 3;

    truncatedFilename+=filename.substring(0, firstCharCount);
    truncatedFilename += ".".repeat(dotCount);
    truncatedFilename+=filename.substring(filename.length-endCharCount, filename.length);
    return truncatedFilename;

  } else {
    return filename;
  }

}

class Test extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      url: '',
      returned: false,
      runs: [],
      run: {},
      label: 'Start Analysis'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    // TODO: create better component
    let errorEl = document.getElementById("error").innerHTML;
    if (errorEl!=='') {
      document.getElementById("error").innerHTML = '';
    }

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  getPageSpeedOnlineQuery(pageUrl,toggle) {
    const baseUrl = 'https://www.googleapis.com/pagespeedonline/v5/runPagespeed';
    const parameters = {
      url: encodeURIComponent(pageUrl),
      key: 'AIzaSyBzbU0iqROIuhyKu075iBHHWSqiYI2nZ-k',
      strategy: toggle ? 'DESKTOP' : "MOBILE"
      //strategy: 'DESKTOP'
    };

    let query = `${baseUrl}?`;
    for (const parameter in parameters) {
      query += `${parameter}=${parameters[parameter]}&`;
    }
    return query;
  }

  showLoading(){
    document.getElementById("init").classList.add("hidden");
    document.getElementById("marketing").classList.add("hidden");
    document.getElementById("loading").classList.remove("hidden");
    document.getElementById("hero").classList.remove("hidden");
  }

  showReady(){
    document.getElementById("loading").classList.add("hidden");
    document.getElementById("ready").classList.remove("hidden");
    document.getElementById("ready").classList.add("animate-fade");
  }

  showResults(url,device){

    //update title
    window.history.replaceState(null, null, `?url=${encodeURI(url)}&device=${device ? "desktop" : "mobile"}`);
    document.title = `Waterfaller results for ${device ? "desktop" : "mobile"} ${encodeURI(url)}`;

    ReactGA.pageview(`/results/${device ? "desktop" : "mobile"}/${encodeURI(url)}`);
    ReactGA.event({
      category: 'User',
      action: 'Clicked show results',
      value: `${device ? "desktop" : "mobile"}`,
    });

    // remove
    document.getElementById("marketing").remove();
    document.getElementById("loading").remove();
    document.getElementById("test").remove();

    // hide
    document.getElementById("hero").classList.add("hidden");

    // show
    document.getElementById("waterfall").classList.remove("hidden");
    document.getElementById("request").classList.remove("hidden");

    // tabs
    document.getElementById("tab-test").classList.remove("bg-gray-200");
    document.getElementById("tab-test").classList.remove("text-black");
    document.getElementById("tab-test").classList.add("text-white");

    document.getElementById("tab-results").classList.add("bg-gray-200");
    document.getElementById("tab-results").classList.remove("text-white");
    document.getElementById("tab-results").classList.add("text-black");
  }

  getCrUX(data){

    function getstatus(field,lab,source){
      let status = "";
      if ((field>=0.9)&&(lab>=0.9)&&(source==='field')){
        status = "passed";
      } else if ((field<0.9)&&(lab<0.9)&&(source==='field')) {
        status = "failed";
      } else if ((field<0.9)&&(lab>=0.9)&&(source==='field')){
        status = "validated"
      } else if ((field>=0.9)&&(lab<0.9)&&(source==='field')){
        status = "regressed"
      } else if (lab>=0.9){
        status = "passed"
      } else {
        status = "failed"
      }
      return status;
    }

    let crux = {
      "fcp" : {
        "numericValue" : "",
        "score" : "",
        "status" : "",
        "source" : ""
      },
      "lcp" : {
        "numericValue" : "",
        "score" : "",
        "status" : "",
        "source" : ""
      },
      "fid" : {
        "numericValue" : "",
        "score" : "",
        "status" : "",
        "source" : ""
      },
      "cls" : {
        "numericValue" : "",
        "score" : "",
        "status" : "",
        "source" : ""
      }
    };

    let source = "",
      loading = {},
      loadingOrigin = {},
      field = {},
      origin = {};
    if ( data.hasOwnProperty('loadingExperience') ) {
      loading = data.loadingExperience;
      field = (loading.hasOwnProperty('metrics')||loading.metrics!==undefined) ? loading.metrics : {};
      source = ( (loading.hasOwnProperty('origin_fallback')&&(loading.origin_fallback===true)) ) ? "origin" : "field";
    }
    if ( data.hasOwnProperty('originLoadingExperience') ) {
      loadingOrigin =data.originLoadingExperience;
      origin = (loadingOrigin.hasOwnProperty('metrics')||loadingOrigin.metrics!==undefined) ? loadingOrigin.metrics : {};
    }

    //FCP
    if (field.hasOwnProperty('FIRST_CONTENTFUL_PAINT_MS')) {
      crux.fcp = {
        "numericValue" : field.FIRST_CONTENTFUL_PAINT_MS.percentile,
        "score" : field.FIRST_CONTENTFUL_PAINT_MS.percentile<=1800 ? 1 : 0,
        "status" : getstatus(
            field.FIRST_CONTENTFUL_PAINT_MS.percentile<=1800 ? 1 : 0,
            data.lighthouseResult.audits['first-contentful-paint'].score,
            source
        ),
        "source" : source
      };
    } else {
      crux.fcp = {
        "numericValue" : 0,
        "score" : 0,
        "status" : getstatus(
            0,
            data.lighthouseResult.audits['first-contentful-paint'].score,
            source
        ),
        "source" : "origin"
      };
    }

    // LCP
    if (field.hasOwnProperty('LARGEST_CONTENTFUL_PAINT_MS')) {
      crux.lcp = {
        "numericValue" : field.LARGEST_CONTENTFUL_PAINT_MS.percentile,
        "score" : field.LARGEST_CONTENTFUL_PAINT_MS.percentile<=2500 ? 1 : 0,
        "status" : getstatus(
            field.LARGEST_CONTENTFUL_PAINT_MS.percentile<=2500 ? 1 : 0,
            data.lighthouseResult.audits['largest-contentful-paint'].score,
            source
        ),
        "source" : source
      };
    } else {
      crux.lcp = {
        "numericValue" : 0,
        "score" : 0,
        "status" : getstatus(
            0,
            data.lighthouseResult.audits['largest-contentful-paint'].score,
            source
        ),
        "source" : "origin"
      };
    }


    if (field.hasOwnProperty('FIRST_INPUT_DELAY_MS')) {
      crux.fid = {
        "numericValue" : field.FIRST_INPUT_DELAY_MS.percentile, // need to divide by 100
        "score" : field.FIRST_INPUT_DELAY_MS.percentile<=100 ? 1 : 0,
        "status" : field.FIRST_INPUT_DELAY_MS.percentile<=100 ? "passed" : "failed",
        "source" : source
      };
    } else if (origin.hasOwnProperty('FIRST_INPUT_DELAY_MS')) {
      crux.fid = {
        "numericValue" : 0,
        "score" : 0,
        "status" : origin.FIRST_INPUT_DELAY_MS.percentile<=100 ? "passed" : "failed",
        "source" : "origin"
      };
    } else {
      crux.fid = {
        "numericValue" : 0,
        "score" : 0,
        "status" : "n/a",
        "source" : "origin"
      };
    }


    if (field.hasOwnProperty('CUMULATIVE_LAYOUT_SHIFT_SCORE')) {
      crux.cls = {
        "numericValue" : field.CUMULATIVE_LAYOUT_SHIFT_SCORE.percentile/100,
        "score" : field.CUMULATIVE_LAYOUT_SHIFT_SCORE.percentile<100 ? 1 : 0,
        "status" : getstatus(
            field.CUMULATIVE_LAYOUT_SHIFT_SCORE.percentile<100 ? 1 : 0,
            data.lighthouseResult.audits['cumulative-layout-shift'].score,
            source
        ),
        "source" : source
      };
    } else {
      crux.cls = {
        "numericValue" : 0,
        "score" : 0,
        "status" : getstatus(
            0,
            data.lighthouseResult.audits['cumulative-layout-shift'].score,
            source
        ),
        "source" : "origin"
      };
    }

    return crux;
  }

  getCoreWebVitals(data){
    let cwv = {};
    cwv.si = data.lighthouseResult.audits['speed-index'];
    cwv.fcp = data.lighthouseResult.audits['first-contentful-paint'];
    cwv.lcp = data.lighthouseResult.audits['largest-contentful-paint'];
    cwv.tbt = data.lighthouseResult.audits['total-blocking-time'];
    cwv.tti = data.lighthouseResult.audits['interactive'];
    cwv.cls = data.lighthouseResult.audits['cumulative-layout-shift'];
    cwv.metrics = data.lighthouseResult.audits['metrics'].details.items[0];
    return cwv;
  }

  determineFix(audits){
    const Fixes = audits.map(({ fix }) => fix);
    let fixList = [];
    if (Fixes.includes('fcp')) fixList.push('fcp');
    if (Fixes.includes('cls')) fixList.push('cls');
    if (Fixes.includes('lcp')) fixList.push('lcp');
    if (Fixes.includes('fid')) fixList.push('fid');
    if (Fixes.includes('tbt')) fixList.push('tbt');
    if (Fixes.includes('theme')) fixList.push('theme');
    if (Fixes.includes('epic')) fixList.push('epic');
    return fixList.join();
  }

  determinePriority(request,lcpIndex){
    return (request.audits.length>0) ? "P" : "";
  }

  getFilters (resources){
    const labels = {
      "Third-party" : "3rd-party",
      "Script" : "JS",
      "Image" : "Img",
      "Font" : "Font",
      "Stylesheet" : "CSS",
      "Document" : "Doc",
      "Media" : "Media"
    }
    let filters = [];
    for (let resource of resources) {
      if ((resource.label!=="Other")&&(resource.label!=="Total")&&(resource.requestCount>0)) {
        let filter = {};
        filter.name = (resource.label==="Third-party") ? 'party' : 'type';
        filter.value = (resource.label==="Third-party") ? 'third' : resource.label;
        filter.label = labels[resource.label];
        filter.count = resource.requestCount;
        filter.size = resource.transferSize;
        filters.push(filter);
      }
    }
    return filters;
  }

  determineBeforeEvent (start,fcp,lcp,ol){
    if (start<=fcp) return 'fcp';
    if ((start>fcp)&(start<=lcp)) return 'lcp';
    if ((start>lcp)&(start<=ol)) return 'ol';
    return 'fl';
  }

  getLongTasks (tasksByUrl,url){
    if (tasksByUrl[url]===undefined) return [];

    return tasksByUrl[url];

  }

  getBlocking (blockingByUrl,url){
    if (blockingByUrl[url]===undefined) return false;

    return true;
  }

  getSlowScripts(slowScriptsByUrl,url){
    if (slowScriptsByUrl[url]===undefined) return false;

    return true;
  }

  getPreloadable (preloadByUrl,url,index){
    if (Object.keys(preloadByUrl).length === 0&&index===0) return false;

    return true;
  }

  getUnusedJs (unusedJsByUrl,url,index){
    if (Object.keys(unusedJsByUrl).length === 0&&index===0) return false;

    return true;
  }

  getLegacyJs (legacyJsByUrl,url,index){
    if (Object.keys(legacyJsByUrl).length === 0&&index===0) return false;

    return true;
  }

  handleRun(key,e){
    e.preventDefault();
    get(key).then((val) => this.setState({run: val}));

    document.getElementById("init").classList.add("hidden");
    document.getElementById("loading").classList.remove("hidden");

    this.setState({label: 'Opening saved guidebook'});
    this.setState({url: key.substring(30, key.length)});
    this.setState({toggle: key.substring(25, 29) === 'mobi' ? false : true});
    const waitForLocalStorage = setTimeout(this.handleSubmit,2000);
  }

  deleteRun(key,e){
    e.preventDefault();
    del(key);
    keys().then((keys) => this.setState({runs: keys}));
  }

  handleSubmit = async (event={}) => {
    if (event.hasOwnProperty('target')) {
      event.preventDefault();
    }

    ReactDOM.render(
      <React.StrictMode>
        <Hero/>
      </React.StrictMode>,
      document.getElementById('hero')
    );

    console.log('run',this.state.run);

    const isValidUrl = new RegExp('(url:|origin:)?http(s)?://.*','ig');
    const pageUrl = this.state.url;

    if ( isValidUrl.test(pageUrl)|| (Object.keys(this.state.run).length > 0) ) {
      ///this.showLoading();

      let data = {},
        resultsShown = false;
      if (Object.keys(this.state.run).length > 0) {
        this.showResults(encodeURI(this.state.url),this.state.toggle);
        resultsShown = true;
        data = this.state.run;
      } else {
        this.showLoading();
        const query = this.getPageSpeedOnlineQuery(pageUrl,this.state.toggle);
        const response = await fetch(query);
        data = await response.json();
        set(data.analysisUTCTimestamp+(this.state.toggle===false ? '-mobi-' : '-desk-')+data.id, data);
      }

      const requests = data.lighthouseResult.audits['network-requests'].details.items;
      const screenshot = data.lighthouseResult.audits['final-screenshot'];
      const cwv = this.getCoreWebVitals(data);
      const crux = this.getCrUX(data,cwv);
      const audits = data.lighthouseResult.audits;
      const lastRequest = requests[requests.length-1];

      const observedFirstContentfulPaint = Math.round(cwv.metrics.observedFirstContentfulPaint);
      const observedLargestContentfulPaint = Math.round(cwv.metrics.observedLargestContentfulPaint);
      const observedLoad = Math.round(cwv.metrics.observedLoad);

      const reqBeforeFcp = requests.reduce((acc, cur) => cur.startTime <= observedFirstContentfulPaint ? ++acc : acc, 0);
      const reqBeforeLcpImage = requests.reduce((acc, cur) => ((cur.startTime <= observedLargestContentfulPaint)&&(cur.resourceType === 'Image')) ? ++acc : acc, 0);
      const reqBeforeLcpScript = requests.reduce((acc, cur) => ((cur.startTime <= observedLargestContentfulPaint)&&(cur.resourceType === 'Script')) ? ++acc : acc, 0);
      const reqBeforeLcpStylesheet = requests.reduce((acc, cur) => ((cur.startTime <= observedLargestContentfulPaint)&&(cur.resourceType === 'Stylesheet')) ? ++acc : acc, 0);
      const reqBeforeLcpFont = requests.reduce((acc, cur) => ((cur.startTime <= observedLargestContentfulPaint)&&(cur.resourceType === 'Font')) ? ++acc : acc, 0);
      const reqBeforeLcpMedia = requests.reduce((acc, cur) => ((cur.startTime <= observedLargestContentfulPaint)&&(cur.resourceType === 'Media')) ? ++acc : acc, 0);
      const reqBeforeLcpThird = requests.reduce((acc, cur) => ( (cur.startTime <= observedLargestContentfulPaint)&&(cur.party === 'third') ) ? ++acc : acc, 0);
      const reqBeforeLcp = requests.reduce((acc, cur) => cur.startTime <= observedLargestContentfulPaint ? ++acc : acc, 0);
      const reqBeforeOl = requests.reduce((acc, cur) => cur.startTime <= observedLoad ? ++acc : acc, 0);

      const reqRedirects = requests.reduce((acc, cur) => ( (cur.startTime <= observedLargestContentfulPaint)&&(cur.statusCode>=300&cur.statusCode<400) ) ? ++acc : acc, 0);
      const reqNotfound = requests.reduce((acc, cur) => ( (cur.startTime <= observedLargestContentfulPaint)&&(cur.statusCode>=400) ) ? ++acc : acc, 0);

      const blocking = ( (audits['render-blocking-resources'].details!==undefined)&&(audits['render-blocking-resources'].details.items.length>0) ) ? audits['render-blocking-resources'].details.items.length : 0;

      //add longtasks

      let reqBudget = {};
      reqBudget.reqBeforeFcp = reqBeforeFcp;
      reqBudget.reqBeforeLcp = reqBeforeLcp;
      reqBudget.reqBeforeLcpImage = reqBeforeLcpImage;
      reqBudget.reqBeforeLcpScript = reqBeforeLcpScript;
      reqBudget.reqBeforeLcpStylesheet = reqBeforeLcpStylesheet;
      reqBudget.reqBeforeLcpFont = reqBeforeLcpFont;
      reqBudget.reqBeforeLcpThird = reqBeforeLcpThird;
      reqBudget.reqBeforeOl = reqBeforeOl;
      reqBudget.reqBlocking = blocking;
      reqBudget.reqBeforeLcpMedia = reqBeforeLcpMedia;
      reqBudget.reqRedirects = reqRedirects;
      reqBudget.reqNotfound = reqNotfound;
      reqBudget.req = requests.length;

      let eventTimingIndex = {};
      eventTimingIndex.fcp = requests.findIndex((obj) => obj.startTime > observedFirstContentfulPaint);
      eventTimingIndex.lcp = requests.findIndex((obj) => obj.startTime > observedLargestContentfulPaint);
      eventTimingIndex.ols = requests.findIndex((obj) => obj.startTime > observedLoad);

      let auditCounter = 0;
      let prevEndTime = 0;
      let foundFirstScript = false;

      let tasksByUrl = {};
      if ((audits['long-tasks'].details!==undefined)
        &&(audits['long-tasks'].details.items.length>0)) {
        const longtasks = audits['long-tasks'].details.items;
        longtasks.forEach(function (item) {
          if (tasksByUrl[item.url]===undefined) {
            tasksByUrl[item.url] = [{
              "startTime" : item.startTime,
              "endTime" : item.startTime+item.duration,
              "duration" : item.duration,
            }];
          } else {
            tasksByUrl[item.url].push({
              "startTime" : item.startTime,
              "endTime" : item.startTime+item.duration,
              "duration" : item.duration,
            });
          }
        });
      }
      //console.log('tasksByUrl',tasksByUrl)

      let slowScriptsByUrl = {};
      if ((audits['bootup-time'].details!==undefined)
        &&(audits['bootup-time'].details.items.length>0)) {
        const slowScripts = audits['bootup-time'].details.items;
        slowScripts.forEach(function (item) {
          if (slowScriptsByUrl[item.url]===undefined) {
            slowScriptsByUrl[item.url] = [{
              "total" : item.total,
              "scripting" : item.scriptParseCompile,
              "scriptParseCompile" : item.scriptParseCompile,
            }];
          } else {
            tasksByUrl[item.url].push({
              "total" : item.total,
              "scripting" : item.scriptParseCompile,
              "scriptParseCompile" : item.scriptParseCompile,
            });
          }
        });
      }
      //console.log('slowScriptsByUrl',slowScriptsByUrl)

      let legacyJsByUrl = {};
      if ((audits['legacy-javascript'].details!==undefined)
        &&(audits['legacy-javascript'].details.items.length>0)) {
        const legacyScripts = audits['legacy-javascript'].details.items;
        legacyScripts.forEach(function (item) {
          if (legacyJsByUrl[item.url]===undefined) {
            legacyJsByUrl[item.url] = [{
              "wastedBytes" : item.wastedBytes,
            }];
          } else {
            legacyJsByUrl[item.url].push({
              "wastedBytes" : item.wastedBytes,
            });
          }
        });
      }
      //console.log('legacyJsByUrl',legacyJsByUrl)

      let unusedJsByUrl = [];
      if ((audits['unused-javascript'].details!==undefined)
        &&(audits['unused-javascript'].details.items.length>0)) {
        unusedJsByUrl.forEach(function (item) {
          if (unusedJsByUrl[item.url]===undefined) {
            unusedJsByUrl[item.url] = [{
              "wastedBytes" : item.wastedBytes
            }];
          } else {
            unusedJsByUrl[item.url].push({
              "wastedBytes" : item.wastedBytes,
            });
          }
        });
      }
      //console.log('unusedJsByUrl',unusedJsByUrl);

      //duplicated-javascript

      let blockingByUrl = {};
      if ((audits['render-blocking-resources'].details!==undefined)
        &&(audits['render-blocking-resources'].details.items.length>0)) {
        const blockers = audits['render-blocking-resources'].details.items;
        blockers.forEach(function (item) {
          if (blockingByUrl[item.url]===undefined) {
            blockingByUrl[item.url] = true;
          }
        });
      }
      //console.log('blockingByUrl',blockingByUrl);

      let preloadByUrl = {};
      if ((audits['uses-rel-preload'].details!==undefined)
        &&(audits['uses-rel-preload'].details.items.length>0)) {
        const preloadable = audits['uses-rel-preload'].details.items;
        preloadable.forEach(function (item) {
          if (preloadByUrl[item.url]===undefined) {
            preloadByUrl[item.url] = true;
          }
        });
      }
      //console.log('preloadByUrl',preloadByUrl);

      // config['server-response-time'].order
      const config = {
        "waterfaller-field-data" : {
          "order" : 1,
        },
        "waterfaller-performance-budget" : {
          "order" : 2,
        },
        "server-response-time" : {
          "order" : 3,
        },
        "waterfaller-first-contentful-paint" : {
          "order" : 4,
        },
        "render-blocking-resources" : {
          "order" : 5,
        },
        "largest-contentful-paint-element" : {
          "order" : 6,
        },
        "waterfaller-first-input-delay" : {
          "order" : 7,
        },
        "waterfaller-preload-lcp-image" : {
          "order" : 8,
        },
        "layout-shift-elements" : {
          "order" : 9,
        },
        "uses-rel-preload" : {
          "order" : 10,
        },
        "waterfaller-inefficient-script" : {
          "order" : 11,
        },
        "waterfaller-status-code-300" : {
          "order" : 12,
        },
        "waterfaller-file-not-found" : {
          "order" : 13,
        },
        "waterfaller-load-order" : {
          "order" : 14,
        },
        "waterfaller-start-time-delay" : {
          "order" : 15,
        },
        "waterfaller-large-file-size" : {
          "order" : 16,
        },
        "waterfaller-slow-content-download" : {
          "order" : 17,
        },
        "waterfaller-third-party-budget" : {
          "order" : 18,
        },
        "waterfaller-inefficient-font" : {
          "order" : 19,
        },
        "offscreen-images" : {
          "order" : 20,
        },
        "uses-responsive-images" : {
          "order" : 21,
        },
        "uses-optimized-images" : {
          "order" : 22,
        },
        "unused-css-rules" : {
          "order" : 23,
        },
        "unsized-images" : {
          "order" : 24,
        },
        "font-display" : {
          "order" : 25,
        }
      }

const  budgetTable = `The recommended budget is 35 or fewer total files:\n\n
| Type      | Budget | Actual |    +/- |
|-----------|-------:|-------:|-------:|
| CSS       |      2 |${reqBeforeLcpStylesheet.toString().padStart(8, ' ')}|${(reqBeforeLcpStylesheet-2).toString().padStart(8, ' ')}|
| JS        |     10 |${reqBeforeLcpScript.toString().padStart(8, ' ')}|${(reqBeforeLcpScript-10).toString().padStart(8, ' ')}|
| Font      |      5 |${reqBeforeLcpFont.toString().padStart(8, ' ')}|${(reqBeforeLcpFont-5).toString().padStart(8, ' ')}|
| Img       |     15 |${reqBeforeLcpImage.toString().padStart(8, ' ')}|${(reqBeforeLcpImage-15).toString().padStart(8, ' ')}|
| Media     |      0 |${reqBeforeLcpMedia.toString().padStart(8, ' ')}|${(reqBeforeLcpMedia-0).toString().padStart(8, ' ')}|
| 3rd-party |      4 |${reqBeforeLcpThird.toString().padStart(8, ' ')}|${(reqBeforeLcpThird-4).toString().padStart(8, ' ')}|`;

      for (const [index, request] of requests.entries()) {

        request.index = index;
        request.filename = extractFilename(request.url,index);
        request.downloadTime = request.endTime - request.startTime;
        request.host = parseUrl(request.url).resource;
        request.parsedHost = psl.parse(request.host);
        request.query = parseUrl(request.url).search;
        request.hash = parseUrl(request.url).hash;
        //request.party = (ThirdPartyDomains.includes(parseUrl(request.url).resource)) ? 'third' : 'first';

        request.entity = getThirdPartyEntity(request.parsedHost);

        request.party = (request.entity!==null) ? 'third' : 'first';

        let type = request.resourceType;
        let requestsSoFar = requests.slice(0,index+1);
        let typeCount = requestsSoFar.reduce((acc, cur) => cur.resourceType === type ? ++acc : acc, 0);
        request.typeCount = typeCount;

        let thirdPartyCount = requestsSoFar.reduce((acc, cur) => cur.party === 'third' ? ++acc : acc, 0);
        request.thirdPartyCount = thirdPartyCount;

        request.beforeEvent = this.determineBeforeEvent(request.startTime,observedFirstContentfulPaint,observedLargestContentfulPaint,observedLoad);

        request.longtasks = this.getLongTasks(tasksByUrl,request.url);
        request.blocking = this.getBlocking(blockingByUrl,request.url);
        request.preload = this.getPreloadable(preloadByUrl,request.url,request.index);
        request.slow = this.getSlowScripts(blockingByUrl,request.url);
        request.unusedjs = this.getUnusedJs(unusedJsByUrl,request.url);
        request.legacyjs = this.getLegacyJs(legacyJsByUrl,request.url);

        request.audits = [];

        /* Audits for all files
         **********************
        */

        // audit styles load order
        if ((request.resourceType==="Stylesheet")
          &&(request.startTime<cwv.metrics.observedLargestContentfulPaint)
          &&(crux.lcp.status!=="passed")
          &&(foundFirstScript===true)) {
          auditCounter = auditCounter+1;
          request.audits.push({
            "id" : "waterfaller-load-order",
            "index" : auditCounter,
            "order" : config['waterfaller-load-order'].order,
            "type" : request.resourceType,
            "pageUrl" : pageUrl,
            "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false,
            "file" : request.filename,
            "url" : request.url,
            "path" : request.path,
            "party" : request.party,
            "title" : "How to fix stylesheet load order",
            "issue" : `The defect occurs because the stylesheet ${request.filename} is loaded after a script.`,
            "description" : '',
            "impact" : 2,
            "fix" : "lcp",
            "solution" : `The solution is to load ${request.filename} in the <head> before script tags. Browsers require stylesheets loaded to be loaded before rendering the page. If a script loads before a stylesheet, it negatively impacts FCP ${prettyMilliseconds(cwv.fcp.numericValue)} and LCP ${prettyMilliseconds(cwv.lcp.numericValue)}.`,
            "story" : `As a developer, I want to load stylesheets before scripts.`,
            "ac" : `Given that I am a developer,\nwhen I load the page,\nthen stylesheets are loaded before scripts.`,
            "ms" : 0,
            "bytes" : 0
          });
        }

        // audit start time delay
        if ((index>1)
          &&(request.startTime<cwv.metrics.observedLargestContentfulPaint)
          &&((requests[index-1].endTime+10)<request.startTime)
          &&(crux.lcp.status!=="passed")) {
          auditCounter = auditCounter+1;
          request.audits.push({
            "id" : "waterfaller-start-time-delay",
            "index" : auditCounter,
            "order" : config['waterfaller-start-time-delay'].order,
            "type" : request.resourceType,
            "pageUrl" : pageUrl,
            "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false,
            "file" : request.filename,
            "url" : request.url,
            "path" : request.path,
            "party" : request.party,
            "title" : "How to fix a download delay",
            "issue" : `There is an apparent delay between the time the previous file named "${requests[index-1].filename}" finishes loading and the time that "${request.filename}" begins to download.`,
            "description" : "",
            "fix" : "lcp",
            "impact" : 2,
            "estimate" : 1,
            "solution" : `${(requests[index-1].resourceType==="Script") ? `The solution is to async or defer the script named "${requests[index-1].filename}" or load it after the Window Load event.` : `The solution is to change the loading order of the files requested before the file named "${request.filename}".`}`,
            "story" : `As a developer, I want to investigate the previous file named "${requests[index-1].filename}" to discover why it is delaying the start of loading the file named "${request.filename}".`,
            "ac" : `Given that I am a developer,\nwhen I load the page,\nthen do not see a start download delay for the file named "${request.filename}".`,
            "ms" : 0,
            "bytes" : 0
          });
        }

        // audit redirect
        if ((request.statusCode>=300)
          &&(request.statusCode<400)
          &&(request.beforeEvent==="fcp"||request.beforeEvent==="lcp")
          &&(crux.lcp.status!=="passed")) {
          let issue = `The issue is that this file was redirected to another file which increases the download time. ${(request.startTime<cwv.metrics.observedLargestContentfulPaint&&cwv.lcp.score<0.9) ? `This redirect directly contributes to the failing LCP score of ${prettyMilliseconds(cwv.lcp.numericValue)}.` : ""}`;
          let solution = `The solution is to remove the redirect.`;
          if (request.party==='third'){
            issue = `The issue is that this third-party ${request.resourceType} from "${request.entity.name}" redirects.`;
            solution = `The solution is to use a newer version from this vendor (${request.entity.homepage}) or replace it ${request.entity.categories.join()} platform that avoids redirects. It is likely that this redirect is unavoidable.`
          }
          auditCounter = auditCounter+1;
          request.audits.push({
            "id" : "waterfaller-status-code-300",
            "index" : auditCounter,
            "order" : config['waterfaller-status-code-300'].order,
            "type" : request.resourceType,
            "pageUrl" : pageUrl,
            "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false,
            "file" : request.filename,
            "url" : request.url,
            "path" : request.path,
            "party" : request.party,
            "title" : `${request.party==='third' ? "How to fix a third-party redirect" : "How to fix redirecting this file"}`,
            "issue" : issue,
            "description" : "",
            "fix" : "lcp",
            "impact" : 2,
            "estimate" : 1,
            "solution" : solution,
            "story" : `As a developer, I want a 200 status code for ${request.filename} to reduce the total number of files ${reqBudget.req} loaded by the page.`,
            "ac" : `Given that I am a developer,\nwhen I load the page,\nthen ${request.filename} returns a status of 200 OK.`,
            "ms" : 0,
            "bytes" : 0
          });
        }

        // audit file not found
        if ((request.statusCode>=400)
          &&(request.statusCode<500)
          &&(request.startTime<cwv.metrics.observedLoad)
          &&(crux.lcp.status!=="passed")) {
          auditCounter = auditCounter+1;
          request.audits.push({
            "id" : "waterfaller-file-not-found",
            "index" : auditCounter,
            "order" : config['waterfaller-file-not-found'].order,
            "type" : request.resourceType,
            "pageUrl" : pageUrl,
            "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false,
            "file" : request.filename,
            "url" : request.url,
            "path" : request.path,
            "party" : request.party,
            "title" : "How to fix file not found",
            "issue" : "The defect occurs because the file is not found.",
            "description" : "This is a defect. Files that are not found can cause errors and slowdowns.",
            "fix" : "lcp",
            "impact" : 2,
            "estimate" : 1,
            "solution" : `The solution is to load ${request.filename} correctly. If file is not need then the file should be removed from the page.`,
            "story" : `As a developer, I want a 200 status code for ${request.filename} to prevent errors.`,
            "ac" : `Given that I am a developer,\nwhen I load the page,\nthen ${request.filename} is removed or loads with a status of 200.`,
            "ms" : 0,
            "bytes" : 0
          });
        }

        // waterfaller-third-party-budget
        if ((request.party==='third')
          &&(request.startTime<cwv.metrics.observedLargestContentfulPaint)
          &&(crux.lcp.status!=="passed")
          &&(request.thirdPartyCount>4)) {
            auditCounter = auditCounter+1;
            request.audits.push({
              "id" : "waterfaller-third-party-budget",
              "index" : auditCounter,
              "order" : config['waterfaller-third-party-budget'].order,
              "type" : request.resourceType,
              "pageUrl" : pageUrl,
              "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false,
              "file" : request.filename,
              "url" : request.url,
              "path" : request.path,
              "party" : request.party,
              "title" : "How to fix exceeding the third-party performance budget",
              "issue" : `The issues is that this is the ${ordinal(request.thirdPartyCount)} third-party file loaded and now exceeds the recommended budget of 4 third-party files before LCP ${prettyMilliseconds(cwv.lcp.numericValue)}.`,
              "description" : "",
              "fix" : "lcp",
              "impact" : 2,
              "estimate" : 3,
              "solution" : `This file from "${request.entity.name}" is identified by ThirdParyWeb as a ${request.entity.categories.join()} platform. The first solution is to work with stakeholders and make sure it needs to be loaded for rendering (${request.entity.homepage}).\n\nIf it is not needed to render the page, it is recommended to move "${request.filename}" from ${request.entity.name} to your CDN. While there are benefits to using a ${request.entity.name}'s CDN, self-hosting the file will result in faster downloads. Then optimally it should also be loaded after the Window Load event.\n\n${budgetTable}`,
              "story" : `As a developer, I want to remove "${request.filename}" or I want to self-host it in order to download it faster with fewer browser connections and better caching.`,
              "ac" : `Given that I am a developer,\nwhen I load the page,\nthen "${request.filename}" is removed or served by our CDN.`,
              "ms" : 0,
              "bytes" : 0
            });
        }

        // large and slow files
        if ( (request.transferSize>27000)
          &&(request.downloadTime>273)
          &&((request.startTime<cwv.metrics.observedLoad)
          &&(crux.lcp.status!=="passed")) ) {
          let title = `large and slow files`,
            issue = `The issue is that this file is large, slow and loads before LCP.`,
            solution = `Based on httpArchive, this file exceeds our recommended size and download speed of 27KB and 275ms. The solution is to make the file smaller and load faster. ${reqBeforeLcp>35 ? `The size and download speed of this file is critical as this page also loads ${reqBeforeLcp} files before LCP. This exceeds our performance budget of 35.` : ""}`,
            story = `As a developer, I want to make "${request.filename}" smaller to improve LCP.`,
            ac = `Given that I am a developer,\nwhen I load the page,\nthen "${request.filename}" smaller than ${prettyBytes(request.transferSize)} or is loaded after LCP.`;
          auditCounter = auditCounter+1;
          request.audits.push({
            "id" : "waterfaller-large-file-size",
            "index" : auditCounter,
            "order" : config['waterfaller-large-file-size'].order,
            "type" : request.resourceType,
            "pageUrl" : pageUrl,
            "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false,
            "file" : request.filename,
            "url" : request.url,
            "path" : request.path,
            "party" : request.party,
            "title" : `How to fix ${title}`,
            "issue" : issue,
            "description" : "",
            "fix" : "lcp",
            "impact" : 2,
            "estimate" : 3,
            "solution" : solution,
            "story" : story,
            "ac" : ac,
            "ms" : 0,
            "bytes" : 0
          });
        }

        // slow content download time
        if ((request.downloadTime>273)
          //&&index>0
          &&(request.audits.reduce((acc, cur) => cur.id==="waterfaller-large-file-size" ? ++acc : acc, 0)===0)
          &&(request.startTime<cwv.metrics.observedLargestContentfulPaint)
          &&(crux.lcp.status!=="passed") ) {
          auditCounter = auditCounter+1;
          request.audits.push({
            "id" : "waterfaller-slow-content-download",
            "index" : auditCounter,
            "order" : config['waterfaller-slow-content-download'].order,
            "type" : request.resourceType,
            "pageUrl" : pageUrl,
            "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false,
            "file" : request.filename,
            "url" : request.url,
            "path" : request.path,
            "party" : request.party,
            "title" : "How to fix slow file download time",
            "issue" : `The issues is that the file content download time is too slow.`,
            "description" : "",
            "fix" : "lcp",
            "impact" : 2,
            "estimate" : 3,
            "solution" : `The solution is to make the file faster ${request.party==="third" ? "by moving this third-party file to your content delivery network (CDN)" : "by reviewing the performance of your content delivery network (i.e. Cache HIT ratio)"}, make the file smaller than ${prettyBytes(request.transferSize)}, or possibly load the file after the Window Load event.`,
            "story" : `As a developer, I want to make "${request.filename}" download faster.`,
            "ac" : `Given that I am a developer,\nwhen I load the page,\nthen "${request.filename}" download time is faster than ${prettyMilliseconds(request.downloadTime)}.`,
            "ms" : 0,
            "bytes" : 0
          });
        }



        /* Audits for images
         *******************
        */

        if (request.resourceType==="Image") {

          //modern-image-formats

          //offscreen-images
          if ((audits['offscreen-images'].details!==undefined)
            &&(audits['offscreen-images'].details.items.length>0)
            &&(request.startTime<cwv.metrics.observedLargestContentfulPaint)
            &&(crux.lcp.status!=="passed")) {
            audits['offscreen-images'].details.items.forEach(function (item) {
              if (item.url===request.url) {
                auditCounter = auditCounter+1;
                request.audits.push({
                  "id" : "offscreen-images",
                  "index" : auditCounter,
                  "order" : config['offscreen-images'].order,
                  "type" : request.resourceType,
                  "pageUrl" : pageUrl,
                  "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false,
                  "file" : request.filename,
                  "url" : request.url,
                  "path" : request.path,
                  "party" : request.party,
                  "title" : "How to fix offscreen and hidden images",
                  "issue" : `The issue is that loading offscreen or hidden images ${request.filename} wastes ${prettyBytes(item.wastedBytes)}.`,
                  "description" : audits['offscreen-images'].description,
                  "fix" : "lcp",
                  "impact" : 2,
                  "estimate" : 1,
                  "solution" : `The solution is to lazy load all offscreen or hidden images by adding the loading="lazy" attribute on the <img>.`,
                  "story" : `As a developer, I want to lazy load ${request.filename} to reduce total page requests and total page size by ${prettyBytes(item.wastedBytes)}.`,
                  "ac" : `Given that I am a developer,\nwhen I first load the page,\nthen ${request.filename} is not requested, but is loaded on demand and displays as expected.`,
                  "ms" : 0,
                  "bytes" : 0
                });
              }
            });
          }

          // not offscreen images that load before LCP could be preloaded
          if ((request.startTime<cwv.metrics.observedLargestContentfulPaint)
            &&(request.transferSize>27000)
            &&(crux.lcp.status!=="passed")
            &&(request.audits.reduce((acc, cur) => cur.id==="offscreen-images" ? ++acc : acc, 0)===0)) {
            auditCounter = auditCounter+1;
            request.audits.push({
              "id" : "waterfaller-preload-lcp-image",
              "index" : auditCounter,
              "order" : config['waterfaller-preload-lcp-image'].order,
              "type" : request.resourceType,
              "pageUrl" : pageUrl,
              "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false,
              "file" : request.filename,
              "url" : request.url,
              "path" : request.path,
              "party" : request.party,
              "title" : "How to fix slow-loading images",
              "issue" : `The issue is that your page is failing LCP and this ${request.filename} may be a slowdown.`,
              "description" : 'descripting pending',
              "fix" : "lcp",
              "impact" : 2,
              "estimate" : 1,
              "solution" : `The solution is to preload ${request.filename} in the <head>.`,
              "story" : `As a developer, I want to preload ${request.filename} in the <head> so it loads sooner in the waterfall.`,
              "ac" : `Given that I am a developer,\nwhen I load the page,\nthen ${request.filename} loads sooner in the waterfall.`,
              "ms" : 0,
              "bytes" : 0
            });
            request.onscreen = true;
          } else {
            // setting onscreen to false
            request.onscreen = false;
          }

          //unsized-images
          if ((audits['unsized-images'].details!==undefined)
            &&(audits['unsized-images'].details.items.length>0)
            &&(crux.cls.status!=="passed")) {
            audits['unsized-images'].details.items.forEach(function (item) {
              if (item.url===request.url) {
                auditCounter = auditCounter+1;
                request.audits.push({
                  "id" : "unsized-images",
                  "index" : auditCounter,
                  "order" : config['unsized-images'].order,
                  "type" : request.resourceType,  "pageUrl" : pageUrl, "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false, "file" : request.filename, "url" : request.url, "path" : request.path, "party" : request.party,
                  "title" : "How to fix image missing height and width",
                  "issue" : `The issue is that this image ${request.filename} is missing height and width attributes which can cause layout shift as the image loads.`,
                  "description" : audits['unsized-images'].description,
                  "fix" : "cls",
                  "impact" : 3,
                  "estimate" : 1,
                  "solution" : `The solution is set height and width attributes to ${request.filename}. It is okay if styles resize the image as browsers will use the attributes to calculate the visible size maintaining the aspect ratio.`,
                  "story" : `As a developer, I want to set height and width attributes on ${request.filename} to prevent layout shift as the image loads.`,
                  "ac" : `Given that I am a developer,\nwhen I load the page,\nthen ${request.filename} has set height and width\nand the layout does not shift.`,
                  "ms" : 0,
                  "bytes" : 0
                });
              }
            });
          }

          //uses-optimized-images
          if ((audits['uses-optimized-images'].details!==undefined)
            &&(audits['uses-optimized-images'].details.items.length>0)
            &&(request.startTime<cwv.metrics.observedLargestContentfulPaint)
            &&(crux.lcp.status!=="passed")) {
            audits['uses-optimized-images'].details.items.forEach(function (item) {
              if (item.url===request.url) {
                auditCounter = auditCounter+1;
                request.audits.push({
                  "id" : "uses-optimized-images",
                  "index" : auditCounter,
                  "order" : config['uses-optimized-images'].order,
                  "type" : request.resourceType,  "pageUrl" : pageUrl, "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false, "file" : request.filename, "url" : request.url, "path" : request.path, "party" : request.party,
                  "title" : "How to fix image encoding",
                  "issue" : `The issue is that the encoding for ${request.filename} wastes ${item.wastedBytes}`,
                  "description" : audits['uses-optimized-images'].description,
                  "fix" : "lcp",
                  "impact" : 2,
                  "estimate" : 1,
                  "solution" : `The solution is to re-save ${request.filename} as a JPEG using a quality setting between 60-80% depending on visual requirements making sure that the image is sized correctly. Another solution is to use the <picture> element to provide a webp alternative depending on browser. Many CDNs or DAMs offer the ability to send the best format and quality image based on device, browser and connection (see Polish from Cloudflare).`,
                  "story" : `As a developer, I want to encode ${request.filename} to make the request smaller than ${prettyBytes(item.totalBytes)}.`,
                  "ac" : `Given that I am a developer,\nwhen I load the page,\nthen ${request.filename} is smaller than ${prettyBytes(item.totalBytes)}.`,
                  "ms" : 0,
                  "bytes" : 0
                });
              }
            });
          }

          //uses-responsive-images
          if ((audits['uses-responsive-images'].details!==undefined)
            &&(audits['uses-responsive-images'].details.items.length>0)
            &&(request.startTime<cwv.metrics.observedLargestContentfulPaint)
            &&(crux.lcp.status!=="passed")) {
            audits['uses-responsive-images'].details.items.forEach(function (item) {
              if (item.url===request.url) {
                auditCounter = auditCounter+1;
                request.audits.push({
                  "id" : "uses-responsive-images",
                  "index" : auditCounter,
                  "order" : config['uses-responsive-images'].order,
                  "type" : request.resourceType,  "pageUrl" : pageUrl, "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false, "file" : request.filename, "url" : request.url, "path" : request.path, "party" : request.party,
                  "title" : "How to fix image responsiveness",
                  "issue" : `The issue is that size of ${request.filename} wastes ${item.wastedBytes} because larger than expected for this device.`,
                  "description" : audits['uses-responsive-images'].description,
                  "fix" : "lcp",
                  "impact" : 2,
                  "estimate" : 1,
                  "solution" : `The solution is to use <img srcset> or <picture> to set images correctly by device.`,
                  "story" : `As a developer, I want to size ${request.filename} correctly for this device to make the request smaller than ${prettyBytes(item.totalBytes)}.`,
                  "ac" : `Given that I am a developer,\nwhen I load the page,\nthen ${request.filename} is smaller than ${prettyBytes(item.totalBytes)}.`,
                  "ms" : 0,
                  "bytes" : 0
                });
              }
            });
          }

        } else {
          // setting onscreen to false for all non-images
          request.onscreen = false;
        }

        /* Audit Stylesheets
         *************************
        */
        if ((request.resourceType==="Stylesheet")
          &&(audits['unused-css-rules'].details!==undefined)
          &&(crux.fcp.status!=="passed"||crux.lcp.status!=="passed")
          &&(request.startTime<cwv.metrics.observedLoad)
          &&(audits['unused-css-rules'].details.items.length>0)) {
            audits['unused-css-rules'].details.items.forEach(function (item) {
              if (item.url===request.url) {
                auditCounter = auditCounter+1;
                request.audits.push({
                  "id" : "unused-css-rules",
                  "index" : auditCounter,
                  "order" : config['unused-css-rules'].order,
                  "type" : request.resourceType,
                  "pageUrl" : pageUrl,
                  "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false,
                  "file" : request.filename,
                  "url" : request.url,
                  "path" : request.path,
                  "party" : request.party,
                  "title" : "How to fix an inefficient stylesheet",
                  "issue" : `The issue is that over ${Math.round(item.wastedPercent)}% of the rules in ${request.filename} are not used making this stylesheet inefficient.`,
                  "description" : audits['unused-css-rules'].description,
                  "fix" : crux.fcp.status!=="passed" ? "fcp" : "lcp",
                  "impact" : 1,
                  "estimate" : 5,
                  "solution" : `The first solution may be to remove this file if it is not used or add the required rules to another stylesheet file. Another solution can be to remove unused styles from this file. If this is the main stylesheet, then using uncss with Grunt or Gulp may provide an automated workflow for removing unused rules. Oftentimes, removing unused CSS rules can be difficult to automate so the best solution may be to manually remove unused rules. When using a framework, it is possible to customize the output either in SASS or using online tools. If you are not using a component, remove that import from your build.`,
                  "story" : `As a developer, I want to remove unused stylesheet rules for ${request.filename} to reduce the percentage of unused rules to less than ${Math.round(item.wastedPercent)}%.`,
                  "ac" : `Given that I am a developer,\nwhen I load the page,\nthen ${request.filename} has less than ${Math.round(item.wastedPercent)}% rules unused.`,
                  "ms" : 0,
                  "bytes" : 0
                });
              }
            });
          }


        /* Audit Scripts
         *************************
        */

        if ((request.resourceType==="Script")
          &&(request.slow||request.longtasks.length>0)
          &&(crux.fid.status==="failed"||cwv.tbt.score<0.9)
          &&(request.startTime<cwv.metrics.observedLoad)){
            let issue = "",
              title = "",
              story = "",
              ac = "",
              script = request.party==="third" ? "third-party JavaScript" : "JavaScript";

            let sumOfScriptingTime = 0;
            if (slowScriptsByUrl.hasOwnProperty([request.url])) {
              let slowScripts = slowScriptsByUrl[request.url];
              sumOfScriptingTime = slowScripts.reduce((sum, currentValue) => {
                return sum + currentValue.total;
              }, 0);
            }
            let sumOfWastedMsLegacyJS = 0;
            if (legacyJsByUrl.hasOwnProperty([request.url])) {
              let legacyScripts = legacyJsByUrl[request.url];
              sumOfWastedMsLegacyJS = legacyScripts.reduce((sum, currentValue) => {
                return sum + currentValue.total;
              }, 0);
            }

            let sumOfWastedMsUnusedJS = 0;
            if (unusedJsByUrl.hasOwnProperty([request.url])) {
              let unusedScripts = unusedJsByUrl[request.url];
              sumOfWastedMsUnusedJS = unusedScripts.reduce((sum, currentValue) => {
                return sum + currentValue.total;
              }, 0);
            }

            if ((request.slow===true)&&(request.longtasks.length>0)){
              // long tasks and slow
              title = `How to fix an inefficient ${request.party==="third" ? "third-party " : ""}script`;
              issue = `The issue is that this ${script} has ${tasksByUrl[request.url].length} long task(s) that block(s) users from interacting with the page and it occupies the browser's CPU for ${prettyMilliseconds(sumOfScriptingTime)}. This is a strong signal that "${request.filename}" negatively impacts the FID score.`;
              story = `As a developer, I want to reduce ${tasksByUrl[request.url].length} long task(s) and ${prettyMilliseconds(sumOfScriptingTime)} of scripting time to make the script more efficient.`;
              ac = `Given that I am a developer,\nwhen I load the page,\nthen ${tasksByUrl[request.url].length} long task(s) and ${prettyMilliseconds(sumOfScriptingTime)} of scripting time are improved.`;
            } else if ((request.slow===false)&&(request.longtasks.length>0)) {
              // long tasks and not slow
              title = `How to fix a long ${request.party==="third" ? "third-party " : ""}JavaScript tasks`;
              issue = `The issue is that this ${script} has ${tasksByUrl[request.url].length} long task(s) that block(s) users from interacting with the page. This is a signal that "${request.filename}" may negatively impact the FID score.`;
              story = `As a developer, I want to reduce ${tasksByUrl[request.url].length} long task(s) to make the script more efficient.`;
              ac = `Given that I am a developer,\nwhen I load the page,\nthen ${tasksByUrl[request.url].length} long task(s) is improved.`;
            } else if ((request.slow===true)&&(request.longtasks.length===0)){
              // no long tasks and slow
              title = `How to fix an slow ${request.party==="third" ? "third-party " : ""}scripting times`;
              issue = `The issue is that this ${script} occupies the browser's CPU for ${prettyMilliseconds(sumOfScriptingTime)}. This is a signal that "${request.filename}" may negatively impact the FID score.`;
              story = `As a developer, I want to reduce ${prettyMilliseconds(sumOfScriptingTime)} of scripting time to make the script more efficient.`;
              ac = `Given that I am a developer,\nwhen I load the page,\nthen ${prettyMilliseconds(sumOfScriptingTime)} of scripting time is improved.`;
            }

            let priority = "";
            switch(crux.fid.status) {
              case 'failed':
                priority = " This fix should be a top priority since the FID score failed."
                break;
              case 'regressed':
                priority = " First Input Delay regressions indicate that FID passed, but Total Blocking Time failed. Although TBT fixes are always helpful, they will not improve with Core Web Vital scores."
                break;
              case 'validated':
                priority = " First Input Delay validations mean Google's CrUX data indicate that FID failed for this page over the last 28 days. However, it passed Total Blocking Time (TBT) for this run. The good news is that you may be able to \"Validate Fix\" in Google Search Console after further testing."
                break;
              default:
                priority = "";
            }

            let solution = `${request.party==='third' ? `This third-party script from "${request.entity.name}" is inefficient. The solution is to use a newer version from this vendor (${request.entity.homepage}) or replace it with a modern ${request.entity.categories.join()} platform.` : `This script is inefficient. The solution is to use modern JavaScript and module bundlers like webpack, Parcel, and Rollup to split code, load a small file at first, and then lazy load the remaining chunks. Another possible fix is to leave this file unchanged and request it after the Window Load event.`}`;
            if ((sumOfWastedMsLegacyJS>0)&&(sumOfWastedMsUnusedJS>0)&&(request.transferSize>21000)){
              // legacy and unused
              solution = `${request.party==='third'
              ? `This third-party script from "${request.entity.name}" wastes scripting time because it ships unused code and supports outdated browsers. The solution is to use a newer version from this vendor (${request.entity.homepage}) or replace it with a modern "${request.entity.categories.join()}" platform. Another possible fix is to leave this file unchanged and request it after the Window Load event.`
              : `This script wastes scripting time because it ships unused code and supports outdated browsers. The optimal solution is to use modern JavaScript and module bundlers like webpack, Parcel, and Rollup to split code, load a small file at first, and then lazy load the remaining chunks. Another possible fix is to leave this file unchanged and request it after the Window Load event.`
              }`;
            } else if ((request.slow===false)&&(request.longtasks.length>0)&&(request.transferSize>21000)) {
              // legacy and no unused
              solution = `${request.party==='third'
              ? `This third-party script from "${request.entity.name}" wastes scripting time supporting outdated browsers. The solution is to use a newer version from this vendor (${request.entity.homepage}) or replace it with a modern "${request.entity.categories.join()}" platform. Another possible fix is to leave this file unchanged and request it after the Window Load event.`
              : `This script wastes scripting time supporting outdated browsers using Polyfills or coverting modern code with a tool like Babel. The first decision is to support modern browsers. If your business requires legacy browser support, it may be impossible to meet current Core Web Vital standards. The optimal solution is to use modern JavaScript and module bundlers like webpack, Parcel, and Rollup to split code, load a small file at first, and then lazy load the remaining chunks. Another possible fix is to leave this file unchanged and request it after the Window Load event.`
              }`;
            } else if ((request.slow===true)&&(request.longtasks.length===0)&&(request.transferSize>21000)){
              // no legacy and used
              solution = `${request.party==='third'
              ? `This third-party script from "${request.entity.name}" wastes scripting time because it ships unused code. The solution is to use a newer version from this vendor (${request.entity.homepage}) or replace it with a modern "${request.entity.categories.join()}" platform. Another possible fix is to leave this file unchanged and request it after the Window Load event.`
              : `This script wastes scripting time because it ships unused code.  The optimal solution is to use modern JavaScript and module bundlers like webpack, Parcel, and Rollup to split code, load a small file at first, and then lazy load the remaining chunks. Another possible fix is to leave this file unchanged and request it after the Window Load event.`
              }`;
            }

            auditCounter = auditCounter+1;
            request.audits.push({
              "id" : "waterfaller-inefficient-script",
              "index" : auditCounter,
              "order" : config['waterfaller-inefficient-script'].order,
              "type" : request.resourceType,
              "pageUrl" : pageUrl,
              "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false,
              "file" : request.filename,
              "url" : request.url,
              "path" : request.path,
              "party" : request.party,
              "title" : title,
              "issue" : `${issue}${priority}`,
              "description" : "",
              "fix" : (crux.fid.status==="failed") ? "fid" : "tbt",
              "impact" : (crux.fid.status==="failed") ? 1 : 3,
              "estimate" : 3,
              "solution" : solution,
              "story" : story,
              "ac" : ac,
              "ms" : 0,
              "bytes" : 0
            });
          }

        /* Audit render-blocking Scripts and Stylesheets
         *************************
        */

        if (((request.resourceType==="Stylesheet")||(request.resourceType==="Script"))
          &&(audits['render-blocking-resources'].details!==undefined)
          &&(audits['render-blocking-resources'].details.items.length>0)) {
            audits['render-blocking-resources'].details.items.forEach(function (item) {
              if (item.url===request.url) {
                auditCounter = auditCounter+1;
                let solution = "";
                if (request.resourceType==='Script') solution = `The solution is to "async" or "defer" loading this file. If possible, the optimal solution is to load this file after the Window Load event.`;
                if (request.resourceType==='Stylesheet') solution = `A common solution is to inline critical CSS rules and asynchronously load a common stylesheet file (a.k.a critical CSS). This solution can be complicated and difficult to maintain. Another valid solution is to stop loading a single large stylesheet on every page and instead load small stylesheet files containing the required the CSS rules for that page. If these smaller files are preloaded, our research indictes that it can improve start rendering times without implementing critical CSS.`;
                request.audits.push({
                  "id" : "render-blocking-resources",
                  "index" : auditCounter,
                  "order" : config['render-blocking-resources'].order,
                  "type" : request.resourceType,
                  "pageUrl" : pageUrl,
                  "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false,
                  "file" : request.filename,
                  "url" : request.url,
                  "path" : request.path,
                  "party" : request.party,
                  "title" : "How to fix a render-blocking file",
                  "issue" : `The issue is that ${request.filename} is blocking the render of your page wasting ${prettyMilliseconds(item.wastedMs)}`,
                  "description" : audits['render-blocking-resources'].description,
                  "fix" : "fcp",
                  "impact" : (request.startTime<cwv.metrics.observedFirstContentfulPaint) ? 3 : 2,
                  "estimate" : 3,
                  "solution" : solution,
                  "story" : `As a developer, I want to unblock **${request.filename}** to render the page faster.`,
                  "ac" : `Given that I am a developer,\nwhen I load the page,\nthen **${request.filename}** is not a blocking resource.`,
                  "ms" : 0,
                  "bytes" : 0
                });
              }
            });
          }

        /* Audit Fonts
         *************************
        */

        // Font display
        if ((request.resourceType==="Font")
          &&(crux.cls.status!=="passed")
          &&(audits['font-display'].details!==undefined)
          &&(audits['font-display'].details.items.length>0)) {
            audits['font-display'].details.items.forEach(function (item) {
              if (item.url===request.url) {
                auditCounter = auditCounter+1;
                request.audits.push({
                  "id" : "font-display",
                  "index" : auditCounter,
                  "order" : config['font-display'].order,
                  "type" : request.resourceType,
                  "pageUrl" : pageUrl, "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false, "file" : request.filename, "url" : request.url, "path" : request.path, "party" : request.party,
                  "title" : "How to fix font display",
                  "issue" : `The issue is that text using ${request.filename} is not user-visible while font is loading.`,
                  "description" : audits['font-display'].description,
                  "fix" : "cls",
                  "impact" : 1,
                  "estimate" : 1,
                  "solution" : `The solution is to reduce dependancies on custom fonts. If a custom font is required, use font-display:swap in the @font-face rule.`,
                  "story" : `As a developer, I want to to set the font-display:swap for ${request.filename} to make test user-visible while custom fonts are loading.`,
                  "ac" : `Given that I am a developer,\nwhen I load the page,\nthen the text using ${request.filename} is visible.`,
                  "ms" : 0,
                  "bytes" : 0
                });
              }
            });
          }

        // Google font or Font Awesome stylesheet or font
        if ((request.entity!==null)
          &&(request.entity.name==="Google Fonts"||request.entity.name==="FontAwesome CDN"||request.entity.name==="Adobe TypeKit")
          &&(request.startTime<cwv.metrics.observedLoad)
          &&(crux.lcp.score!=="passed")) {
            auditCounter = auditCounter+1;
            request.audits.push({
              "id" : "waterfaller-inefficient-font",
              "index" : auditCounter,
              "order" : config['waterfaller-inefficient-font'].order,
              "type" : request.resourceType,
              "pageUrl" : pageUrl,
              "fileIndex" : index, "beforeLCP" : eventTimingIndex.lcp>=index ? true : false,
              "file" : request.filename,
              "url" : request.url,
              "path" : request.path,
              "party" : request.party,
              "title" : "How to fix inefficient third-party fonts",
              "issue" : `The issue is that we detected a custom font from ${request.entity.name} which can be inefficient and contribute to your ${crux.lcp.status} LCP.`,
              "description" : "",
              "fix" : "lcp",
              "impact" : 2,
              "estimate" : 3,
              "solution" : `The solution to self-host ${request.entity.name}. This can simplify the request chain created for fonts which allows the browser to more quickly display the custom font to the user. If self-hosting is not an option, consider using a variable font where all styles exist in single file.`,
              "story" : `As a developer, I want to self-host ${request.entity.name} to make using custom fonts more efficient.`,
              "ac" : `Given that I am a developer,\nwhen I load the page,\nthen ${request.entity.name} are no longer hosted on ${request.domain}.`,
              "ms" : 0,
              "bytes" : 0
            });
        }

        request.fix = this.determineFix(request.audits);
        request.priority = this.determinePriority(request,eventTimingIndex.lcp);

        if (request.endTime===undefined) {
          request.endTime = requests.startTime+200;
        }
        prevEndTime = request.endTime;

        if (request.resourceType==="Script"){
          foundFirstScript = true;
        }

      }

      /* Rootdocument Analysis
       *************************
      */

      // issue analysis
      let preBacklog  = [].concat.apply([],requests.map(a => a.audits));
      let analysis = {};
      analysis.fcp = preBacklog.reduce((acc, cur) => cur.fix==="fcp" ? ++acc : acc, 0);
      analysis.lcp = preBacklog.reduce((acc, cur) => cur.fix==="lcp" ? ++acc : acc, 0);
      analysis.tbt = preBacklog.reduce((acc, cur) => cur.fix==="tbt" ? ++acc : acc, 0);
      analysis.fid = preBacklog.reduce((acc, cur) => cur.fix==="fid" ? ++acc : acc, 0);
      analysis.cls = preBacklog.reduce((acc, cur) => cur.fix==="cls" ? ++acc : acc, 0);
      analysis.budget = preBacklog.reduce((acc, cur) => cur.fix==="budget" ? ++acc : acc, 0);

      analysis.slow = preBacklog.reduce((acc, cur) => cur.id==="waterfaller-preload-lcp-image" ? ++acc : acc, 0);
      analysis.blocking = preBacklog.reduce((acc, cur) => cur.id==="render-blocking-resources" ? ++acc : acc, 0);
      analysis.total = preBacklog.length;

      // content for guide
      let theme = '',
        epics = '';

      // server-response-time
      if ( (audits['server-response-time'].details!==undefined)
        &&(audits['server-response-time'].numericValue>500) ) {
          auditCounter = auditCounter+1;
          let issue = `The issue is that the server response time of ${prettyMilliseconds(audits['server-response-time'].numericValue)} exceeds the recommended time of 500ms. ${(audits['server-response-time'].numericValue>1500&&cwv.lcp.score<0.9) ? `It is likely that the ${prettyMilliseconds(audits['server-response-time'].numericValue)} response time is caused this page to fail LCP requirements.` : `Despite this, the page is able to meet LCP requirements with a ${prettyMilliseconds(audits['server-response-time'].numericValue)} response time.`}`,
            title = `How to fix ${prettyMilliseconds(audits['server-response-time'].numericValue)} server response time to improve all Core Web Vitals`;
          requests[0].audits.push({
            "id" : "server-response-time",
            "index" : auditCounter,
            "order" : config['server-response-time'].order,
            "type" : requests[0].resourceType,
            "pageUrl" : pageUrl,
            "fileIndex" : 0,
            "beforeLCP" : true,
            "file" : requests[0].filename,
            "url" : requests[0].url,
            "path" : requests[0].path,
            "party" : requests[0].party,
            "title" : title,
            "issue" : issue,
            "description" : audits['server-response-time'].description,
            "fix" : crux.fcp.status!=="passed" ? "fcp" : "lcp",
            "impact" : 3,
            "estimate" : 1,
            "solution" : `This issue with the rootfile directly impacts all page speed and core web vitals metrics. The possible solutions include: (1) eliminating rootdocument redirects, (2) caching the rootdocument response on a content delivery network, (3) configuring long cache time-to-live, (4) enabling local browser cache, and (5) investigating the cache HIT to MISS ratio. If the HIT percentage is below 70%, it is recommended to review the findings with the CDN vendor.`,
            "story" : `As a developer, I want the root document server response time to be less than 500ms in order to improve all Core Web Vitals.`,
            "ac" : `Given that I am a developer,\nwhen I load the page,\nthen the root document server response time is less than 600ms.`,
            "ms" : 0,
            "bytes" : 0
        });
        epics = epics + "- " + title + "\n";
      }

      let configStatus = {
        "fcp" : {
          "regressed" : `The bad news is that First Contentful Paint regressed. This means that ${crux.fcp.source!=="field" ? "Google's CrUX data for your site indicates success" : "Google's CrUX data for this page indicates success"}, but First Contentful Paint ${prettyMilliseconds(cwv.fcp.numericValue)} was slower than  the recommended 1.8s. This contributes to slow ${prettyMilliseconds(cwv.lcp.numericValue)} Largest Contentful Paint. We highly recommend the ${analysis.fcp} ${analysis.fcp>1 ? "tasks" : "task"} we created which will improve your FCP.`,
          "validated" : `The good news is that First Contentful Paint was less than 1.8s, however, ${crux.fcp.source!=="field" ? "Google's CrUX data for your site" : "Google's CrUX data for this page"} is ${prettyMilliseconds(crux.fcp.numericValue)}. Analysis shows your page does not pass Largest Contentful Paint, so we recommend re-running this page to get additional results. It is best to keep FCP under 1.8s in order to improve LCP.`,
          "failed" : `First Contentful Paint for this page is slow ${prettyMilliseconds(cwv.fcp.numericValue)} and contributes to your LCP problems, so we created ${analysis.fcp} ${analysis.fcp>1 ? "tasks" : "task"} to fix these issues.`,
          "passed" : `First Contentful Paint is fast. It is best to keep FCP under 1.8s to maintain good LCP scores.`
        },
        "lcp" : {
          "regressed" : `${crux.fcp.source!=="field" ? "Google's CrUX data for your site" : "Google's CrUX data for this page"} indicates that this passed LCP, however, it failed to meet the requirements on this run. It is recommended that you re-run this page. If it continues to fail, then review the ${analysis.lcp}  LCP tasks we created.`,
          "validated" : `The good news is that this page passed LCP requirements, however, the ${crux.lcp.source} data indicates that it failed to meet requirements. It is recommended that you validate this potential improvement by re-running this page or testing other similar pages.`,
          "failed" : `We created ${analysis.lcp} LCP tasks as a result of this page not meeting expectations.`,
          "passed" : `This page meets LCP requirements.`
        },
        "fid" : {
          "regressed" : "",
          "validated" : "",
          "failed" : `In order to help you fix this issue, we created some FID tasks for this page.`,
          "passed" : `FID requirements have been met${cwv.tbt.score<0.9 ? ", however this run failed TBT requirements. This does not currently affect your FID success, but it may indicate a potential regression." : "."} `,
        },
        "cls" : {
          "regressed" : `${crux.fcp.source!=="field" ? "Google's CrUX data for your site" : "Google's CrUX data for this page"} indicates that this passed CLS, however, it failed to meet the requirements on this run. It is recommended that you re-run this page. If CLS continues to fail, then review the CLS tasks we created.`,
          "validated" : `The good news is that this page passed CLS requirements, however, ${crux.fcp.source!=="field" ? "Google's CrUX data for your site" : "Google's CrUX data for this page"} indicates that it failed to meet requirements. It is recommended that you validate this potential improvement by re-running this page or testing other similar pages.`,
          "failed" : `Our CLS tasks can help you fix this issue since this page doesn't meet CLS requirements.`,
          "passed" : `As this page passes Cumulative Layout Shift requirements, no tasks were created for this metric.`
        }
      };

      // fail field core web vitals
      if (crux.fid.status!=="passed"
        ||crux.lcp.status!=="passed"
        ||crux.cls.status!=="passed"){
          auditCounter = auditCounter+1;
          let fails = crux.fid.status!=="passed" ? 1 : 0;
          fails = crux.lcp.status!=="passed" ? fails+1 : fails;
          fails = crux.cls.status!=="passed" ? fails+1 : fails;
          let title = `This page did not pass ${fails} Core Web Vital ${fails===1 ? "requirement" : "requirements"}`,
            issue = `Using the ${crux.cls.source!=="field" ? "CrUX data for your site" : "CrUX data for this page"}, the problem is that:\n\n- Largest Contentful Paint (LCP) **${crux.lcp.status}**\n- First Input Delay (FID) **${crux.fid.status}**\n- Cumulative Layout Shift (CLS) **${crux.cls.status}**\n\nTo get a Good Page Experience rating from Google, this page must pass all 3 requirements.`;
          requests[0].audits.push({
          "id" : "waterfaller-field-data",
          "index" : auditCounter,
          "order" : config['waterfaller-field-data'].order,
          "type" : requests[0].resourceType,
          "pageUrl" : pageUrl,
          "fileIndex" : 0,
          "beforeLCP" : true,
          "file" : requests[0].filename,
          "url" : requests[0].url,
          "path" : requests[0].path,
          "party" : requests[0].party,
          "title" : title,
          "issue" : issue,
          "description" : "",
          "fix" : "theme",
          "impact" : 3,
          "estimate" : 3,
          "solution" : `- ${configStatus.lcp[crux.lcp.status]}\n- ${configStatus.fid[crux.fid.status]}\n- ${configStatus.cls[crux.cls.status]}`,
          "story" : `As a developer, I want to address the ${fails} failing Core Web Vital ${fails===1 ? "metric" : "metrics"} to achieve a "Good" rating for this page.`,
          "ac" : `Given that I am a developer,\nwhen I load the page,\nthen each Core Web Vital (LCP, CLS, and FID) metric is rated "Good" in Google Search Console.`,
          "ms" : 0,
          "bytes" : 0
        });
        theme = `## ${title}\n\n### ${issue}\n\n- ${configStatus.lcp[crux.lcp.status]}\n\n- ${configStatus.fid[crux.fid.status]}\n\n- ${configStatus.cls[crux.cls.status]}`;
      }

      // layout-shift-elements - 8/6/21
      if ( (audits['layout-shift-elements'].details!==undefined)
        &&(cwv.cls.score<0.9||crux.cls.score<0.9) ) {
        let totalElements = audits['layout-shift-elements'].details.items.length;
        let title = `How to fix ${totalElements} ${totalElements>1 ? "components" : "component"} causing ${cwv.cls.numericValue.toFixed(3)} Cumulative Layout Shift (CLS)`;
        let issue = `The issue is that ${totalElements} ${totalElements>1 ? "components shift" : "component shifts"} by ${cwv.cls.numericValue.toFixed(3)} before it is visually complete.`;
        let solution = `To view layout shifts, please click the Screenshot tab and select CLS to inventory ${totalElements} ${totalElements>1 ? "components" : "component"}. The solutions include: (1) setting height and width on images, (2) reducing the number of custom fonts, (3) replacing font icons with SVGs which used above-the-fold, (4) eliminate adding CSS classes using JavaScript, (5) loading a facade with a height and width for components like video or cookie compliance, and (6) not lazy loading above the fold images.`;
        let story = `As a developer, I want to inventory ${totalElements} ${totalElements>1 ? "components" : "component"} that shift while the page is loading. Then I want to reduce the layout shift for each component to reduce the CLS score to less than 0.1.`;
        if (crux.cls.score<0.9){
          title = `How to fix ${crux.cls.numericValue.toFixed(3)} Cumulative Layout Shift`;
          issue = `For this page, the issue is that Google's data shows a ${crux.cls.numericValue.toFixed(3)} Cumulative Layout Shift which does not meet requirements.`;
          story = `As a developer, I want to investigate Google's data for CLS in the Search Console to validate this page's successful test scores.`
          solution = `The solution is to rerun this page in Waterfaller to see if the test CLS score changes to match Google's data (sometimes that happens). If it does not change, then it is possible that CLS has been fixed and you should "Validate Fix" in the Google Search Console.`;
        }
        auditCounter = auditCounter+1;
        requests[0].audits.push({
          "id" : "layout-shift-elements",
          "index" : auditCounter,
          "order" : config['layout-shift-elements'].order,
          "type" : requests[0].resourceType,
          "pageUrl" : pageUrl,
          "fileIndex" : 0,
          "beforeLCP" : true,
          "file" : requests[0].filename,
          "url" : requests[0].url,
          "path" : requests[0].path,
          "party" : requests[0].party,
          "title" : title,
          "issue" : issue,
          "description" : audits['layout-shift-elements'].description,
          "fix" : "epic",
          "impact" : 3,
          "estimate" : 3,
          "solution" : solution,
          "story" : story,
          "ac" : `Given that I am a developer,\nwhen I load the page,\nthen I reduced the shift for each component identified so the CLS score is less than 0.1.`,
          "ms" : 0,
          "bytes" : 0
        });
        epics = epics + "- " + title + "\n";
      }

      // waterfaller-performance-budget
      if ((audits['largest-contentful-paint-element'].details!==undefined)
        &&(audits['largest-contentful-paint-element'].details.items.length>0)
        &&(crux.lcp.status!=="passed")
        &&(reqBeforeLcp>35)) {
          auditCounter = auditCounter+1;
          let title = `How to start using a web performance budget`;
          requests[0].audits.push({
          "id" : "waterfaller-performance-budget",
          "index" : auditCounter,
          "order" : config['waterfaller-performance-budget'].order,
          "type" : requests[0].resourceType,
          "pageUrl" : pageUrl,
          "fileIndex" : 0,
          "beforeLCP" : true,
          "file" : requests[0].filename,
          "url" : requests[0].url,
          "path" : requests[0].path,
          "party" : requests[0].party,
          "title" : title,
          "issue" : `It has been determined that your page could benefit from a performance budget. This page loads too many files (${reqBeforeLcp}) before the Largest Contentful Paint files. This is a likely reason for ${analysis.fcp.status!=="passed" ? "slow FCP and LCP scores" : "a slow score"}. The rationale is if you load fewer files before LCP, then LCP will get faster.`,
          "description" : audits['largest-contentful-paint-element'].description,
          "fix" : "epic",
          "impact" : 3,
          "estimate" : 3,
          "solution" : `A performance budget is a set of limits that if matched increase the likelihood that your page will pass Core Web Vitals. To create this budget, we reverse engineered pages that passed Core Web Vitals to break down how they were built. With this information, we compared your page to our budget. If you match this budget, your page will likely pass all Core Web Vital metrics. This quanitative budget reflects the goals of the theme and epics.\n\nWe analyzed the files on your page to determine their size, speed, context, and sequence. It did not meet our budget recommendations.\n\nOur performance budget focuses on the number and type of files loaded before Largest Contentful Paint:\n\n* Four or fewer third-party scripts\n* No media files\n* Only 2 stylesheets\n* No more than 10 scripts\n* Up to four custom fonts\n* As many as 15 images\n* Fewer than 35 total files\n\nThe solution is to reduce the ${reqBudget.reqBeforeLcp} files loaded before LCP ${prettyMilliseconds(cwv.lcp.numericValue)} by removing or lazy loading files not required to render the page. On the waterfall, a thick blue line marks the ${ordinal(reqBeforeLcp)} file loaded before LCP.\n\n${budgetTable}`,
          "story" : `As a developer, I want to decrease the current number of files, ${reqBeforeLcp}, loaded before LCP to meet the recommended performance budget of 35.`,
          "ac" : `Given that I am a developer,\nwhen I load the page,\nthen the number of files loaded before LCP is within the performance budget of 35 files.`,
          "ms" : 0,
          "bytes" : 0
        });
        epics = epics + "- " + title + "\n";
      }

      // largest contentful paint element
      // add client side rendering if using react, vue, etc.
      if ((audits['largest-contentful-paint-element'].details!==undefined)
        &&(audits['largest-contentful-paint-element'].details.items.length>0)
        &&(crux.lcp.status!=="passed")) {
          auditCounter = auditCounter+1;
          let blocking = Object.keys(blockingByUrl),
            //blockingList = blocking.join().replace(/,/g, '\n\n'),
            slow = Object.keys(slowScriptsByUrl),
            //slowList = slow.join().replace(/,/g, '\n\n'),
            title = `How to fix slow ${prettyMilliseconds(cwv.lcp.numericValue)} Largest Contentful Paint (LCP)`;
          requests[0].audits.push({
          "id" : "largest-contentful-paint-element",
          "index" : auditCounter,
          "order" : config['largest-contentful-paint-element'].order,
          "type" : requests[0].resourceType,
          "pageUrl" : pageUrl,
          "fileIndex" : 0,
          "beforeLCP" : true,
          "file" : requests[0].filename,
          "url" : requests[0].url,
          "path" : requests[0].path,
          "party" : requests[0].party,
          "title" : title,
          "issue" : `The problem is that the largest component visible in the active viewport loads too slow ${prettyMilliseconds(cwv.lcp.numericValue)} and does not meet requirements.`,
          "description" : "",
          "fix" : "epic",
          "impact" : 3,
          "estimate" : 3,
          "solution" : `The solution is to prioritize the ${analysis.blocking} render-blocking and ${analysis.slow} slow-loading image tasks we created for you.`,
          "story" : `As a developer, I want to load the largest visible component before the required 2.5s. This is visualized in the "Screenshot Workspace" by clicking the "View LCP Component" button.`,
          "ac" : `Given that I am a developer,\nwhen I load the page,\nthen the largest visible component is seen before the required 2.5s.`,
          "ms" : 0,
          "bytes" : 0
        });
        epics = epics + "- " + title + "\n";
      }

      // uses-rel-preload
      if ((requests[0].preload)
      &&crux.lcp.status!=="passed") {
        auditCounter = auditCounter+1;
        let files = Object.keys(preloadByUrl),
          filesList = files.join().replace(/,/g, '\n\n');
        requests[0].audits.push({
          "id" : "uses-rel-preload",
          "index" : auditCounter,
          "order" : config['uses-rel-preload'].order,
          "type" : requests[0].resourceType,
          "pageUrl" : pageUrl,
          "fileIndex" : 0,
          "beforeLCP" : true,
          "file" : requests[0].filename,
          "url" : requests[0].url,
          "path" : requests[0].path,
          "party" : requests[0].party,
          "title" : "How to preload files",
          "issue" : `Analysis shows that the issue is that this page is not preloading ${files.length} eligible files.`,
          "description" : "",
          "fix" : "lcp",
          "impact" : (crux.lcp.status!=="passed") ? 3 : 1,
          "estimate" : 1,
          "solution" : `The solution is to preload the following ${files.length} files:\n\n${filesList}`,
          "story" : `As a developer, I want to verify that ${files.length} eligible files are preloaded by the rootfile.`,
          "ac" : `Given that I am a developer,\nwhen I load the page,\nthen the ${files.length} files listed are preloaded by the browser.`,
          "ms" : 0,
          "bytes" : 0
        });
      }

      // TO-DO uses-preconnect and report on domains (LCP)

      // 3 or 1 - fid or tbt
      if (crux.fid.status==="failed"||cwv.tbt.score<0.9) {
          auditCounter = auditCounter+1;
          let title = (crux.fid.status==="failed") ? `How to fix ${prettyMilliseconds(crux.fid.numericValue)} First Input Delay (FID)` : `How to fix ${prettyMilliseconds(cwv.tbt.numericValue)} Total Blocking Time (TBT)`;
          let issue = (crux.fid.status==="failed") ? `The problem is that this page is not interactive fast enough because the Google's FID data ${prettyMilliseconds(crux.fid.numericValue)} and this test TBT scores ${prettyMilliseconds(cwv.tbt.numericValue)} exceed requirements.` : "The problem is that this page fails the TBT requirement for this test, however, this is currently not impacting your FID Core Web Vital score. Your current Total Blocking Time may indicate a potential regression, however, fixing it may not improve Core Web Vitals.";
          requests[0].audits.push({
          "id" : "waterfaller-first-input-delay",
          "index" : auditCounter,
          "order" : config['waterfaller-first-input-delay'].order,
          "type" : requests[0].resourceType,
          "pageUrl" : pageUrl,
          "fileIndex" : 0,
          "beforeLCP" : true,
          "file" : requests[0].filename,
          "url" : requests[0].url,
          "path" : requests[0].path,
          "party" : requests[0].party,
          "title" : title,
          "issue" : `${issue}`,
          "description" : "",
          "fix" : "epic",
          "impact" : (crux.fid.status==="failed") ? 1 : 3,
          "estimate" : 3,
          "solution" : `The solution includes reducing ${Object.keys(tasksByUrl).length} long JavaScript tasks and ${Object.keys(slowScriptsByUrl).length} files with slow scripting times on this page. Use the waterfall to identify files with long JavaScript tasks and slow scripting times.\n\nOur analysis found that scripts occupy the browser's CPU for ${prettyMilliseconds(audits['bootup-time'].numericValue)} blocked for ${prettyMilliseconds(cwv.tbt.numericValue)}. The main thread should not be occupied for more than 3.5s in total and blocked for less than 100ms.`,
          "story" : `As a developer, I want to reduce the ${Object.keys(tasksByUrl).length} long JavaScript tasks and ${Object.keys(slowScriptsByUrl).length} files with slow scripting times ${(crux.fid.score<0.9) ? "to meet FID requirements" : "to meet TBT requirements"}.`,
          "ac" : `Given that I am a developer,\nwhen I load the page,\nthen the number of ${Object.keys(tasksByUrl).length} on this page and the ${Object.keys(slowScriptsByUrl).length} files with slow scripting times is reduced.`,
          "ms" : 0,
          "bytes" : 0
        });
        epics = epics + "- " + title + "\n";
      }

      // fcp
      if (crux.fcp.status!=="passed"&&crux.lcp.status!=="passed"){
        auditCounter = auditCounter+1;

        //find audits fix = FCP
        //craft solution, story and ac from audits found

        let files = Object.keys(blockingByUrl),
          filesList = files.join().replace(/,/g, '\n\n');

        let issue = `The problem is that ${prettyMilliseconds(cwv.fcp.numericValue)} First Contentful Paint (FCP) does not meet the recommended 1.8s. While FCP is not a Core Web Vital, meeting this standard will positively impact Largest Contentful Paint (LCP).`;

        let story = `As a developer, I want First Contentful Paint (FCP) under 1.8s to improve Largest Contentful Paint (LCP).`

        let solution = configStatus.fcp[crux.fcp.status];

        let ac = `Given that I am a developer,\nwhen I load the page,\nthen FCP is less than the recommended 1.8s.`;

        // solution to fix render blocking
        if (reqBudget.reqBlocking>0) {
          solution = `${solution} The primary solution is to address the ${blocking} render-blocking ${reqBudget.reqBlocking>1 ? "files" : "file"}:\n\n${filesList}`;
          story = `As a developer, I want to reduce the render-blocking ${reqBudget.reqBlocking>1 ? "files" : "file"} loaded by this page so that FCP is less than 1.8s to improve LCP.`;
          ac = `Given that I am a developer,\nwhen I load the page,\nthen the number of render-blocking files is reduced. Other solutions that improve FCP include: limiting the number of files loaded before FCP, reducing server response times, setting @font-face display to "swap", preloading critical resources, preconnecting to critical domains, and avoiding any start delays.`;
        }

        let title = `How to improve ${prettyMilliseconds(cwv.fcp.numericValue)} First Contentful Paint (FCP)`;

        requests[0].audits.push({
          "id" : "waterfaller-first-contentful-paint",
          "index" : auditCounter,
          "order" : config['waterfaller-first-contentful-paint'].order,
          "type" : requests[0].resourceType,
          "pageUrl" : pageUrl,
          "fileIndex" : 0,
          "beforeLCP" : true,
          "file" : requests[0].filename,
          "url" : requests[0].url,
          "path" : requests[0].path,
          "party" : requests[0].party,
          "title" : title,
          "issue" : issue,
          "description" : "",
          "fix" : "epic",
          "impact" : 3,
          "estimate" : 3,
          "solution" : solution,
          "story" : story,
          "ac" : ac,
          "ms" : 0,
          "bytes" : 0
        });
        epics = epics + "- " + title + "\n";
      }

      // rootfile preload filesList

      // rootfile preconnect domains

      // rootfile Fixes
      requests[0].fix = this.determineFix(requests[0].audits);
      requests[0].priority = 'P';

      // slow files (not needed)
      let slowFiles = requests.filter((obj) => obj.priority !== '');
      const priorityCounter = slowFiles.length;
      let topSlowFiles = slowFiles.slice(0, 6);

      // backlog (this is messed up)
      let backlog  = [].concat.apply([],requests.map(a => a.audits));
      backlog.sort((a,b) => (parseFloat(a.order+a.fileIndex) > parseFloat(b.order+b.fileIndex) ) ? 1 : -1);

      function writeMd(greeting,message){
        const hr = `\n\n---\n\n`,
          spacer = `\n\n`;
        let problem = `### WHAT IS THE PROBLEM:\n\n${this.issue}`,
          fix = `### HOW TO FIX IT:\n\n${this.story}`,
          test = `### HOW TO TEST:\n\n${this.ac}`,
          code = `### HOW TO CODE THE FIX:\n\n${this.solution}`,
          filename = `### FILENAME:\n\n${this.url}`,
          page = `### PAGE URL:\n\n${this.pageUrl}`,
          footer = '\n\nhttps://waterfaller.dev',
          title = `## TITLE [${this.fix}]\n\n${this.title}`
        let task = title + spacer + problem + spacer + fix + spacer + test + spacer + code + spacer + filename + spacer + page + footer + hr;
        return task;
      }

      let markdown = "# This is your playbook\n\n";
      for (const task of backlog) {
        markdown += writeMd.apply(task, ['Hello', 'How are you?']);
      }

      //Add rootfile to analysis object
      analysis.epic = backlog.reduce((acc, cur) => cur.fix==="epic" ? ++acc : acc, 0);
      analysis.theme = backlog.reduce((acc, cur) => cur.fix==="theme" ? ++acc : acc, 0);
      analysis.rootfile = backlog.reduce((acc, cur) => cur.fix==="general" ? ++acc : acc, 0);
      // update Analysis
      analysis.fcp = backlog.reduce((acc, cur) => cur.fix==="fcp" ? ++acc : acc, 0);
      analysis.lcp = backlog.reduce((acc, cur) => cur.fix==="lcp" ? ++acc : acc, 0);
      analysis.tbt = backlog.reduce((acc, cur) => cur.fix==="tbt" ? ++acc : acc, 0);
      analysis.fid = backlog.reduce((acc, cur) => cur.fix==="fid" ? ++acc : acc, 0);
      analysis.cls = backlog.reduce((acc, cur) => cur.fix==="cls" ? ++acc : acc, 0);

      //let onscreenFiles = requests.filter((obj) => obj.onscreen === true);

      if (resultsShown===false){
        this.showReady();
      }

      ReactDOM.render(
        <React.StrictMode>
          <Header
            timestamp={data.analysisUTCTimestamp}
            pageUrl={data.id}
            markdown={markdown}
            crux={crux}
            analysis={analysis}
          />
          <Results
            result={data}
            lastRequest={lastRequest}
            analysis={analysis}
            cwv={cwv}
            crux={crux}
            pageUrl={pageUrl}
            finalScreenshot={data.lighthouseResult.audits['final-screenshot'].details.data}
            fcp = {prettyMilliseconds(cwv.metrics.firstContentfulPaint)}
            lcp = {prettyMilliseconds(cwv.metrics.largestContentfulPaint)}
            ol = {Math.round(cwv.metrics.observedLoad)}
            budget = {reqBudget}
          />
          <Filters
            totalRequests={data.lighthouseResult.audits['diagnostics'].details.items[0].numRequests}
            filters={this.getFilters(data.lighthouseResult.audits['resource-summary'].details.items)}
            analysis={analysis}
            priorityCounter={priorityCounter}
            fcp = {Math.round(cwv.metrics.observedFirstContentfulPaint)}
            lcp = {Math.round(cwv.metrics.observedLargestContentfulPaint)}
            ol = {observedLoad}
          />
          <Waterfall
            result={data}
            requests={requests}
            audits={audits}
            lastRequest={lastRequest}
            cwv={cwv}
            //crux={crux}
            diagnostics={data.lighthouseResult.audits['diagnostics'].details.items[0]}
            //filters={this.getFilters(data.lighthouseResult.audits['resource-summary'].details.items)}
            //analysis={analysis}
            //pageUrl={pageUrl}
            //screenshots={data.lighthouseResult.audits['screenshot-thumbnails'].details.items}
            auditCounter={auditCounter}
            budget = {reqBudget}
            eventTimingIndex = {eventTimingIndex}
          />
          <Screenshot
            cwv={cwv}
            src = {data.lighthouseResult.fullPageScreenshot.screenshot.data}
            height = '100%'
            width = '100%'
            pageUrl={pageUrl}
            screenshots={data.lighthouseResult.audits['screenshot-thumbnails'].details.items}
            lcpnodes={data.lighthouseResult.audits['largest-contentful-paint-element'].details.items}
            clsnodes={data.lighthouseResult.audits['layout-shift-elements'].details.items}
          />
        </React.StrictMode>,
        document.getElementById('waterfall')
      );

    } else {
      // handle validation error
      document.getElementById("error").innerHTML = '<span class="text-red-500 text-xs">Please enter URL like this https://waterfaller.dev/</span>';
    }

  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development'){
      window.onbeforeunload = function(e) {
        return "Do you want to exit this page?";
      };
    }
    const isValidUrl = new RegExp('(url:|origin:)?http(s)?://.*','ig');
    // prefill test form with URL params if exist
    if (window.location.href.indexOf("?url=") > -1) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const pageUrl = decodeURI(urlParams.get('url'));
      const device = decodeURI(urlParams.get('device'));
      const isDesktop = (device==="desktop") ? true : false;
      if (isValidUrl.test(pageUrl)) {
        this.setState({returned: true});
        this.setState({url: pageUrl});
        this.setState({toggle: isDesktop});
      }
    }

    // get runs
    keys().then((keys) => this.setState({runs: keys}));

  }

  render() {
    return (
      <>
        <form name="test" onSubmit={this.handleSubmit}>
          <div id="init" className="grid grid-cols-1 gap-4">
            <div className="text-4xl my-9">
              {Object.keys(this.state.run).length > 0 ? "Open your saved guidebook" : "Create a FREE custom guidebook"} to boost {this.state.toggle ? "desktop" : "mobile"} webpages
            </div>
            <label className="block">
              <input
                type="text"
                name="url"
                placeholder="Please enter your URL"
                value={this.state.url}
                onChange={this.handleChange}
                className="block w-full block border-2 border-black p-2 text-left text-lg outline-none focus:outline-none rounded-md"
              />
              <div id="error"></div>
            </label>
            <div>
              <label htmlFor="toggle" className="text-xs text-gray-700 mb-4 mr-2">Mobile</label>
              <div
                className="
                  relative
                  inline-block
                  w-10
                  mr-2
                  align-middle
                  select-none
                  transition
                  duration-200
                  ease-in
                "
              >
                <input
                  type="checkbox"
                  name="toggle"
                  id="toggle"
                  className="
                    toggle-checkbox
                    absolute
                    block
                    w-6
                    h-6
                    rounded-full
                    bg-white
                    border-gray-600
                    border-4
                    appearance-none
                    cursor-pointer"
                    checked={this.state.toggle}
                    onChange={this.handleChange}
                />
                <label
                  htmlFor="toggle"
                  className="
                    toggle-label
                    block
                    overflow-hidden
                    h-6
                    rounded-full
                    bg-gray-600
                    cursor-pointer"
                ></label>
              </div>
              <label htmlFor="toggle" className="text-xs text-gray-700 mb-4">Desktop</label>
            </div>
            <div className="w-full h-18 mb-9">
              <input
                id="analyze"
                type="submit"
                value={this.state.label}
                className="bg-black text-white border-2 border-black hover:bg-white hover:text-black uppercase px-4 py-2 rounded-md outline-none focus:outline-none text-lg cursor-pointer"
              />
              <a
                className="float-right ml-9 border-2 border-bg-200 hover:border-black hover:bg-white px-4 py-2 rounded-md outline-none focus:outline-none text-lg cursor-pointer"
                href="/"
              >
                Reset
              </a>
            </div>
            <div id="runs" className={'grid grid-cols-1 gap-2 mb-9'+(this.state.runs.length===0 ? " hidden" : "")}>
              <h2 className="text-lg font-bold">Welcome back, you can load data from your history or delete what you don't need.</h2>
              {this.state.runs.map((run, index) => (
                <div key={index} className="flex items-center gap-2 mb-1 text-xs w-full">
                  <button
                    className="block flex-none rounded text-center hover:bg-white"
                    onClick={(e) => this.handleRun(run,e)}
                  >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
                  </button>
                  <div className="flex-none w-2/11 font-bold">{run.substring(0, 10)}</div>
                  <div className="flex-grow truncate">{run.substring(30, run.length)}</div>
                  <button
                    className="block flex-none rounded text-center hover:bg-white text-gray-500 p-1"
                    onClick={(e) => this.deleteRun(run,e)}
                  >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </form>
        <div id="loading" className="px-4 hidden">
          <p className="text-4xl text-center mb-9">
            <span className="inline-block mx-auto">
              <svg width="120" height="18" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#000">
                  <circle cx="15" cy="15" r="15">
                      <animate attributeName="r" from="15" to="15"
                               begin="0s" dur="0.8s"
                               values="15;9;15" calcMode="linear"
                               repeatCount="indefinite" />
                      <animate attributeName="fillOpacity" from="1" to="1"
                               begin="0s" dur="0.8s"
                               values="1;.5;1" calcMode="linear"
                               repeatCount="indefinite" />
                  </circle>
                  <circle cx="60" cy="15" r="9" fillOpacity="0.3">
                      <animate attributeName="r" from="9" to="9"
                               begin="0s" dur="0.8s"
                               values="9;15;9" calcMode="linear"
                               repeatCount="indefinite" />
                      <animate attributeName="fillOpacity" from="0.5" to="0.5"
                               begin="0s" dur="0.8s"
                               values=".5;1;.5" calcMode="linear"
                               repeatCount="indefinite" />
                  </circle>
                  <circle cx="105" cy="15" r="15">
                      <animate attributeName="r" from="15" to="15"
                               begin="0s" dur="0.8s"
                               values="15;9;15" calcMode="linear"
                               repeatCount="indefinite" />
                      <animate attributeName="fillOpacity" from="1" to="1"
                               begin="0s" dur="0.8s"
                               values="1;.5;1" calcMode="linear"
                               repeatCount="indefinite" />
                  </circle>
              </svg>
            </span>
          </p>
        </div>
        <div id="ready" className="p-4 hidden grid grid-cols-1 gap-4 rounded-md">
          <h2 className="text-4xl mb-9">Your theme, epics, and user stories are ready</h2>
          <div>
            <button
              className="bg-black text-white border-2 border-black hover:bg-white hover:text-black uppercase px-4 py-2 rounded-md outline-none focus:outline-none text-lg cursor-pointer"
              onClick={() => this.showResults(encodeURI(this.state.url),this.state.toggle)}
            >
              Show Results
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default Test;
