function writeTask(issue,story,ac,solution,url,pageUrl,title,epic){
  const hr = `\n\n---\n\n`,
    spacer = `\n\n`;
  let problem = `### WHAT IS THE PROBLEM:\n\n${issue}`,
    fix = `### HOW TO FIX IT:\n\n${story}`,
    test = `### HOW TO TEST:\n\n${ac}`,
    code = `### HOW TO CODE THE FIX:\n\n${solution}`,
    filename = (process.env.NODE_ENV === 'development') ? `### FILENAME:\n\nfilename.ext` : `### FILENAME:\n\n${url}`,
    page = (process.env.NODE_ENV === 'development') ? `### PAGE URL:\n\nhttps://www.pageurl.com/path/filename.ext` : `### PAGE URL:\n\n${pageUrl}`,
    footer = '\n\nhttps://waterfaller.dev',
    header = `## TITLE [${epic}]:\n\n${title}`
  let task = header + spacer + problem + spacer + fix + spacer + test + spacer + code + spacer + filename + spacer + page + footer;
  return task;
}

export default writeTask;
