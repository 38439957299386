import React from 'react';

function Superscript(props){
  if (props.party==='first') {
    return <span className="text-gray-800 mr-1 blur">{props.index>0 ? props.host + " -" : ''}</span>;
  }
  return (
    <span className="text-gray-500 mr-1">{props.host}<sup>3</sup> -</span>
  )
}

class Request extends React.Component {

  setIndexClassName(type,priority,status){
    let color = "blue";
    color = (type==='Script') ? 'amber' : color;
    color = (type==='Stylesheet') ? 'teal' : color;
    color = (type==='Font') ? 'pink' : color;
    color = (type==='Image') ? 'purple' : color;
    let strikethrough = (status!==200) ? "strikethrough" : "";
    let sharedClasses = `group outline-none block focus:outline-none text-left`;
    return `text-${color}-600 ${sharedClasses} ${strikethrough}`;
  }

  render() {
    let requestIndex = this.props.requestIndex;
    let request = this.props.request;

    return (
      <span
        className="truncate block transition mr-4"
      >
        <span
          id={'file-' + requestIndex}
          className={this.setIndexClassName(request.resourceType,request.priority,request.status)}
        >
          <Superscript
            party={request.party}
            host={request.host}
            index={request.index}
          />
          <span
            className={request.statusCode>=400 ? 'strikethrough' : ''}
          >{request.index===0 ? "Your root file. Start here." : request.filename}</span>
        </span>
      </span>
    )
  }
}

export default Request;
