import React from 'react';
import ReactMarkdown from "react-markdown";
import writeTask from '../../utilities/writeTask';
import toggleClassName from '../../utilities/toggleClassName';

const ATag = props => {
  return (
    <a
      className="hover:underline"
      href={props.href}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
};

function seeScreenshot(e){
  //console.log(e.target.innerHTML)
  if (e.target.innerHTML==="View LCP Component") {
    document.getElementById("screenshot").classList.remove("hidden");
    document.getElementById("chart").classList.add("hidden");
    //document.getElementById("backlog").classList.add("hidden");
    document.getElementById("filters-waterfall").classList.add("hidden");
    //document.getElementById("filters-backlog").classList.add("hidden");
    document.getElementById("filters-screenshot").classList.remove("hidden");
    document.getElementById("tab-1").classList.remove("border-green-400");
    document.getElementById("tab-2").classList.add("border-green-400");
    //document.getElementById("tab-4").classList.remove("border-green-400");
    document.getElementById("filter-lcp").click();
    let lcpScreenshot = document.getElementById("layer-lcp");
    lcpScreenshot.scrollIntoView();
  } else {
    document.getElementById("chart").classList.add("hidden");
    //document.getElementById("backlog").classList.add("hidden");
    document.getElementById("filters-waterfall").classList.add("hidden");
    //document.getElementById("filters-backlog").classList.add("hidden");
    document.getElementById("filters-screenshot").classList.remove("hidden");
    document.getElementById("tab-1").classList.remove("border-green-400");
    document.getElementById("tab-2").classList.add("border-green-400");
    //document.getElementById("tab-4").classList.remove("border-green-400");
    document.getElementById("filter-cls").click();
    let lcpScreenshot = document.getElementById("layer-cls");
    lcpScreenshot.scrollIntoView();
  }
}

function ScreenshotButton (props){
  if (props.auditId!=='largest-contentful-paint-element'
    &&props.auditId!=='layout-shift-elements'
    &&props.fix!=="lcp"
    &&props.fix!=="cls") {
      return null;
  }
  var label = (props.auditId==='largest-contentful-paint-element'||props.fix==="lcp") ? "View LCP Component" : "View Layout Shifts"
  return (
      <button
        className="bg-black border-2 border-black col-span-2 focus:outline-none hover:bg-white hover:text-black inline-block my-2 outline-none px-1 py-0.5 rounded text-white uppercase w-full"
        onClick={(e) => seeScreenshot(e)}
      >
        {label}
      </button>
  )
}

function Agile (props){
  if (props.fix==="theme") {
    return (
        <div>
          <div className="mb-2 guide-item--problem">
            <ReactMarkdown
              source={props.issue}
              renderers={{
                link: ATag
              }}
            />
          </div>
          <div className="flex items-center gap-2 mb-1 w-full mb-4 text-sm text-blue-500">
            <button
              className="block flex-none rounded text-center"
              onClick={(e) => toggleClassName('explainer-theme','hidden',e)}
            >
              <svg className="w-4 h-4 inline-block mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              <span className="hover:underline">How to use your theme to fix Core Web Vitals</span>
            </button>
          </div>
          <div id="explainer-theme" className="prose text-sm mb-4 hidden border-l-4 pl-2 border-blue-500">
            <p>This is the singular theme for your page. It is a collection of epics that drive towards a measurable goal. In this case, the objective is to pass all Core Web Vitals. Our algorithm writes the theme using our realtime analysis of this page and Google's CrUX data (if it exists). The them drives the prioritization and creation of epics.</p>
            <ol>
              <li><em>Regressions</em> indicate that this run's results are worse than the CrUX data indicates. This can indicate that a recent release negatively impacted your performance.</li>
              <li><em>Validations</em> mean the opposite because this run's results are better than the CrUX data available.</li>
              <li><em>Passing</em> means that your page passed expectations on this run and with the CrUX data avaible.</li>
              <li><em>Failing</em> shows that your page did not pass requirements on this run, nor using the CrUX data available.</li>
            </ol>
            <p>These evaluations determine a level of urgency with <strong>failed and regressed</strong> metrics requiring attention.</p>
          </div>
        </div>
    )
  } else if (props.fix==="epic"){
    return (
      <div>
        <div className="mb-2 guide-item--problem">
          <ReactMarkdown
            source={props.issue}
            renderers={{
              link: ATag
            }}
          />
        </div>
        <div className="flex items-center gap-2 mb-1 w-full mb-4 text-sm text-blue-500">
          <button
            className="block flex-none rounded text-center"
            onClick={(e) => toggleClassName("explainer-" + props.id + "-" + props.index,'hidden',e)}
          >
            <svg className="w-4 h-4 inline-block mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <span className="hover:underline">How to use epics to acheive the theme goals</span>
          </button>
        </div>
        <div id={"explainer-" + props.id + "-" + props.index} className="prose text-sm  mb-4 hidden  border-l-4 pl-2 border-blue-500">
          <p>This is an epic correlates to a Core Web Vital as defined by Google and reported by Search Console. Each epic represents a body of work that is then broken down into corresponding user stories. So for the epic to be considered done, all its user stories need to be completed.</p>
          <p>Each user story rolls up to an epic. User stories are full-blown tasts that provide a <strong>problem statement, task, solutions, and testing criteria</strong>. Our algorithm starts with a PageSpeed Insights report, adds over a dozen custom audits, and then formulates a user story.</p>
          <p>To view the user stories for each epic, you can filter the interactive guidebook to show you which files correlate to an epic.</p>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className="mb-2 guide-item--problem">
          <ReactMarkdown
            source={props.issue}
            renderers={{
              link: ATag
            }}
          />
        </div>
        <div className="flex items-center gap-2 mb-1 w-full mb-4 text-sm text-blue-500">
          <button
            className="block flex-none rounded text-center"
            onClick={(e) => toggleClassName("explainer-" + props.id + "-" + props.index,'hidden',e)}
          >
            <svg className="w-4 h-4 inline-block mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <span className="hover:underline">How this user story contribues to the <span className="uppercase">{props.fix}</span> epic</span>
          </button>
        </div>
        <div id={"explainer-" + props.id + "-" + props.index} className="prose text-sm mb-4 hidden  border-l-4 pl-2 border-blue-500">
          <p>This is a user story rolls up to the <span className="uppercase">{props.fix}</span> epic and corresponds to <strong>this file</strong> loaded by your page. This precision makes it testable and it also helps you identify the slowdowns.</p>
        </div>
      </div>
    )
  }
}

class Issue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: `${this.props.title} - ${this.props.pageUrl}`,
      sendToTrello: false,
      task: writeTask(
        this.props.issue,
        this.props.story,
        this.props.ac,
        this.props.solution,
        this.props.url,
        this.props.pageUrl,
        this.props.title,
        this.props.fix
      )
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {

    event.preventDefault();

    let sendToTrello = this.state.sendToTrello;

    document.getElementById(event.target[0].id).select();

    let markdown = encodeURIComponent(document.getElementById(event.target[0].id).value);

    let copied;

    try {
      // Copy the text
      copied = document.execCommand('copy');
    }
    catch (ex) {
      copied = false;
    }
    if(copied&&sendToTrello) {

      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=420,height=640,left=100,top=100`;


      let url = `https://trello.com/add-card?source=trello.com&mode=popup&url=https://waterfaller.dev&name=${this.state.title}&desc=${markdown}`;

      window.open(url,'Send To Trello',params);

    }

  }

  getCopyIssueId(id){
    return `${id}`;
  }

  render() {
    return (
      <div className="ml-8 mr-4">
        <Agile
          fix={this.props.fix}
          issue={this.props.issue}
          id={this.props.id}
          index={this.props.index}
        />
        <ScreenshotButton
          auditId={this.props.auditId}
          fix={this.props.fix}
        />
        <form onSubmit={this.handleSubmit}>
          <textarea
            name="task"
            value={this.state.task}
            onChange={this.handleChange}
            className="issue-editor cursor-default outline-none focus:outline-none rounded bg-blue-50 border-2 border-blue-300 p-4 text-left text-sm lg:text-xs mb-2 w-full block h-96 leading-4 font-mono"
            id={this.getCopyIssueId(this.props.id)}
          />

          <label className="block mb-2">
            {(this.state.sendToTrello) ? "Uncheck" : "Check"} to {(this.state.sendToTrello) ? "copy to clipboard" : "send to Trello"}
            <input
              name="sendToTrello"
              type="checkbox"
              className = "mr-3 float-left mt-1"
              checked={this.state.sendToTrello}
              onChange={this.handleChange}
            />
          </label>
          <label className="block mb-2">
            <input type="submit" value={this.state.sendToTrello ? "Send to Trello" : "Copy to Clipboard"} className="rounded bg-black hover:bg-white border-2 border-black hover:text-black text-white col-span-2 inline-block mr-2 uppercase px-1 py-0.5 outline-none focus:outline-none"/>
          </label>
        </form>
      </div>
    );
  }
}

export default Issue;
