import Entities from "./entities.js";
function getThirdPartyEntity(host){
  const wildcardDomain = `*.${host.domain}`;
  for (const [index, entity] of Entities.entries()) {
    const entityDomains = entity.domains;
    if ( entityDomains.includes(wildcardDomain)||entityDomains.includes(host.input) ) {
      return entity;
    }
  };
  return null;
}

export default getThirdPartyEntity;
