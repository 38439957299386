import React from 'react';
import Log from '../Log/Log';

class Results extends React.Component {

  setOpenClassName(type,party='first'){
    let color = "blue";
    color = (type==='Script') ? 'amber' : color;
    color = (type==='Stylesheet') ? 'teal' : color;
    color = (type==='Font') ? 'pink' : color;
    color = (type==='Image') ? 'purple' : color;
    return `text-${color}-600 group outline-none inline focus:outline-none text-left transition duration-500 ease-in-out  transform hover:underline hover:translate-x-1 hover:scale-110`;
  }

  handleOpenFileAndScroll(index){
    document.getElementById("screenshot").classList.add("hidden");
    document.getElementById("chart").classList.remove("hidden");
    document.getElementById("backlog").classList.add("hidden");
    document.getElementById("filters-waterfall").classList.remove("hidden");
    document.getElementById("filters-backlog").classList.add("hidden");
    document.getElementById("filters-screenshot").classList.add("hidden");
    document.getElementById("tab-1").classList.add("border-green-400");
    document.getElementById("tab-2").classList.remove("border-green-400");
    document.getElementById("tab-4").classList.remove("border-green-400");
    document.getElementById(`file-${index}`).click();
    let rowEl = document.getElementById(`request-${index}`);
    rowEl.scrollIntoViewIfNeeded();
  }

  render () {

    return (
      <section id="results" className="mb-9">
        <div className="flex mx-4 gap-8">
          <div className="flex-none w-1/12">
            <img src={this.props.finalScreenshot} alt="screenshot" className="block blur border border-gray-500 shadow-lg mb-4"/>
          </div>
          <div className="flex-none w-11/12">
            <Log
              cwv={this.props.cwv}
              crux={this.props.crux}
              slow={this.props.priorityCounter}
              analysis={this.props.analysis}
            />
          </div>
        </div>
      </section>
    )
  }
}

export default Results;
